import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Goregaon() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{
                backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)" }}>
                <div className="container mx-auto py-12">
                    <h1 className='text-center text-white text-[32px] md:text-[52px]  font-semibold heading uppercase'>
                        SEO Company in Goregaon
                    </h1>
                </div>
            </div>
        
            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[100%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>SEO Company in Goregaon</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Use data-driven growth to unlock the potential of your online business to maximize your return on investment. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>We use organic SEO to grow your business faster. Bonafide Technologies is among the top search engine optimization companies in Goregaon, capable of placing your website at the top of search engine result pages for popular search engines like Google, Bing, and others. Based on Google's recommendations, we offer excellent SEO services in Goregaon that will undoubtedly benefit your company. </p>
                                <br />
        
                                <h1 className='text-[32px] font-semibold my-3 heading'>Why Bonafide SEO Services Are Better Than the Rest</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>SEO Services from Bonafide Technologies are differentiated by their unwavering commitment to customer success. Our team delivers measurable results by fusing cutting-edge tactics with in-depth industry knowledge. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>Our strategy is personalized, adjusting tactics to meet the specific requirements of each client. By prioritizing honesty and equality, we guarantee that our clients are kept up-to-date and involved at every stage.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>To increase organic traffic and search engine rankings, we go above and above with thorough keyword research and careful on- and off-page optimization. If you want unparalleled dedication to your online success and exceptional results, choose Bonafide SEO Services. </p>
                            </div>
                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Our Services</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Our goal is to grow our clients' businesses and make them completely satisfied with our services. We focus on using marketing strategies and result-oriented techniques to help your company grow exceptionally fast. We'll make it happen and go over each tactic in great detail with you. </p>
                                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                                    <div className="flex flex-wrap justify-between">
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Search Engine Optimization
                                            </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>With our skilled SEO services, you may increase your internet presence and attract more visitors. Boost your search engine ranking right now!</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Digital Marketing </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Boost the online visibility of your company and interact with customers using intelligent digital marketing solutions that are matched to your corporate objectives.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Web Development </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>With the help of our web development services, turn your idea into a beautiful, functional website. Make an impression on the digital world.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Social Media Marketing </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Using our results-driven social media marketing techniques, you can engage, expand, and connect with your audience.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>PPC</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Draw attention to your product or service from your target market to increase quality leads, sales, and leads.</p>
                                        </div>
                                    </div>
                                </div>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Want Us To Be Your Growth Partner</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>As a leading SEO company in Goregaon, we have created SEO services that are affordable for all businesses, enabling them to reach their target audience and increase website visibility. Using our expert SEO services can help you stay one step ahead of your rivals and increase the revenue you bring in from your company. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>Contact us right now without a second thought. We can work directly with you to improve your business, and we can be your best digital marketing partner in Goregaon.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        )
}

export default Goregaon
