import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Error() {
  return (
    <>
    <Header />
    <div className='py-28'>
        <h1 className='text-center text-5xl heading font-semibold'>Page Not Found</h1>    
    </div>
    <Footer/>
    </>
  )
}

export default Error