import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Pune() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{
                backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)" }}>
                <div className="container mx-auto py-12">
                    <h1
                        className='text-center text-white text-[32px] md:text-[52px]  font-semibold heading uppercase'>
                        SEO Company in Pune
                    </h1>
                </div>
            </div>
        
            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[100%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>SEO Company in Pune</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Are you launching your online business? Do you have concerns about finding a position in digital media? Pune's SEO company is the most reliable answer to all of your concerns. We improve your company's online visibility, which will eventually increase brand credibility and propel your website to the top of search engine results pages. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>We see the advantage of internet businesses gaining prominence and recognition as time goes on. In addition to a thoughtful layout and relevant content creation, we optimize the website. To manage all the technical facets of SEO, we have a team of skilled and experienced specialists. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>We are one of the best service providers in Pune which includes SEO, social media, and digital marketing services. </p>
                                <br />
                            </div>
                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Why Choose Us</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>We are really proud to be the first digital marketing agency in Pune, Maharashtra, to hold certifications from Microsoft Bing and Google. We are the go-to source in the area for complete online marketing solutions because of our proficiency in social media and Internet marketing services. </p>
                                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                                    <div className="flex flex-wrap justify-between">
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Strong Credibility
                                            </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>We assist you in conducting research for SEO services. We are a well-known business in Pune with positive relationships with our clients. We always deliver quality results within the specified time frame.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Excellent Analysis And Customization</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>We provide our clients with a tailored solution that ensures they receive good value for their money, thanks to our efficient research results. We always serve the needs of our clients with care. We investigate, evaluate, and put into action the best strategies for your company.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Strategy</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>We each develop a unique approach that fits your company's needs to increase consumer confidence and brand reputation.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>SEO</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Enhancing your business's visibility and ranking on search engines can be achieved through search engine optimization. By increasing your internet presence, SEO helps your company generate the most profit possible.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Brand Image</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Your brand will get more well-known the more search engines can see you. We assist you in increasing your brand's organic visibility so you can count on trust to grow every day.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Complete SEO Reporting</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>At Bonafide SEO Company in Pune, we provide a monthly report to our client that details the scope of our work completed.</p>
                                        </div>
                                    </div>
                                </div>
                                <h1 className='text-[32px] font-semibold my-3 heading'>What We Offer In Our SEO Services In Pune</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>We are really proud to be the first digital marketing agency in Pune, Maharashtra, to hold certifications from Microsoft Bing and Google. We are the go-to source in the area for complete online marketing solutions because of our proficiency in Internet and social media marketing services. </p>
                                <ul>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>Social Media Marketing</strong>
                                        Your key to unlocking the potential of each social media platform—Facebook, LinkedIn, Instagram, Quora. Allow us to improve your online visibility by creating interesting content that speaks to the specific audience of each platform.</li>   
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>Search Engine Optimization</strong>
                                        Use our SEO services to increase your internet presence. We raise your website's position on search engine results pages (SERPs) by using advanced technologies so that when prospective buyers look for your goods or services, they see it on the first page. With our help, enhance your online visibility.</li>   
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>Google Local Business</strong>
                                        Discover why Bonafide is the best option for digital marketing in Pune! With our experience, you can improve your local SEO visibility and build credibility. Use Google My Business to its full potential by working with us, your committed agency.</li>   
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>Pay Per Click (PPC)</strong>
                                        Our services, which include Pay-Per-Click digital marketing solutions, make use of an advertising strategy intended to increase website traffic. Our priorities are lead generation, internet traffic direction, and precise audience targeting with well-placed online ads.</li>     
                                </ul> 
                                <h1 className='text-[32px] font-semibold my-3 heading'>Let’s Work Together</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Reach out to us right now if you are a business owner looking to rank your company highly in search results. Our SEO team is ready to take on the task and quickly deliver 100% of the expected outcomes.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        )
}

export default Pune