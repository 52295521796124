import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Udaipur() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{
                backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)" }}>
                <div className="container mx-auto py-12">
                    <h1
                        className='text-center text-white text-[32px] md:text-[52px]  font-semibold heading uppercase'>
                        SEO Company in Udaipur
                    </h1>
                </div>
            </div>
        
            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[100%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>SEO Company in Udaipur</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Consider using Bonafide Technologies to grow your business. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>We use design and development strategies to help companies and consumers connect. </p>
                                <br />
                                <h1 className='text-[32px] font-semibold my-3 heading'>The Magic of SEO from the Best SEO Company in Udaipur</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>If you believe that having an internet presence for a local business is unnecessary and you also own a local business. Then pause and reconsider. The most well-known SEO company in Udaipur, Bonafide Technologies, has been providing customized SEO services for years. It has helped numerous local businesses rank well on search engines so that clients searching for your products or services in their area can find them quickly. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>Our team of professionals is skilled at creating effective SEO strategies that successfully help you spread your brand to your target market. We attentively create every element of your website, from the very beginning to the very end, to guarantee an amazing user experience. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>Our experienced SEO executives use exclusive link-building and advertising strategies to drive your business to the top of search engines. We also provide customized SEO services to meet your unique company requirements. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>We are a reputable digital marketing company that provides a wide range of services, such as social media marketing, pay-per-click (PPC) campaigns, and search engine optimization (SEO). Our data-driven tactics are made to increase conversions, increase organic traffic, and improve your online presence. </p>
                            </div>
                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>What We Offer in Our SEO Services in Udaipur</h1>
                                <p className='text-[18px] font-normal text-[#636363] '><strong>Now Is Your Chance to Expand With Digital Marketing!</strong></p>
                                <br />
                                <p className='text-[18px] font-normal text-[#636363] '>We take great care in crafting our SEO services to increase your online presence and increase organic traffic to your website. Our team of professionals optimizes your website for search engines using industry best practices to help you rank higher and generate quality leads.</p><br />
                                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                                    <div className="flex flex-wrap justify-between">
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>SMO 
                                            </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Our social media marketing services are centered on using social media channels to increase the exposure and interaction of your company. We design effective campaigns to engage your audience, create a strong online community, and drive viral PR.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Content Writing</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Create engaging content for your audience with the help of our skilled content writers. We write content for websites and blogs that not only engages readers but also strengthens your online presence and establishes you as an authority in your field.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>PPC</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Use our PPC services to increase targeted traffic to your website. Our professionals create and manage successful PPC campaigns, putting your advertising in strategic locations on search engines such as Google. With our results-driven PPC campaigns, you can increase your return on investment and grow your business.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Keyword Analysis</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Selecting the ideal keyword is essential to getting the most out of SEO tactics that work. We determine the best selection of keywords by researching online searches made by potential clients and competitive tactics used by other businesses.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Website & E-Commerce Development</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>In order to provide your brand with a powerful online presence, we design creative, mobile-friendly websites and e-commerce solutions.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Local SEO</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>The main goals of local search engine optimization (Google My Business) are to raise sales and brand awareness.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Google Ads </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Increase Conversion Rates Due To Incredible Traffic Elevate the caliber of traffic to your website, and observe as your conversion rates soar.</p>
                                        </div>
                                    </div>
                                </div>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Contact Us For More!</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Working with Bonafide Technologies gives you access to a team of devoted experts who are all about producing top-notch work. Whether you want to build effective digital marketing campaigns, optimize your website for search engines, or have a strong online presence, we have the knowledge and enthusiasm to support the growth of your company. Discover the real potential of your web presence with Bonafide.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        )
}

export default Udaipur