import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Kolkata() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{
                backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)"
            }}>
                <div className="container mx-auto py-12">
                    <h1
                        className='text-center text-white text-[32px] md:text-[42px] font-semibold heading uppercase'>SEO Company in Kolkata
                    </h1>
                </div>
            </div>

            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[100%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>SEO Company in Kolkata</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>The process of getting your website to appear on Google, Yahoo, and Bing's first page for your desired business keywords is known as SEO. Thus, this is the most effective way to grow your company online. Many companies in Kolkata are linked to us because of our SEO services. Additionally, they enjoy their Google first page ranking and daily increase in company leads. Thus, contact us if you also want to grow your company in Kolkata. We will help you take your business to new heights of success with our tried and tested SEO strategies. </p>
                                <br />
                                <h1 className='text-[32px] font-semibold my-3 heading'>What is SEO and Why you need it</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Search Engine Optimization, or SEO, is getting your website to appear on search engine result pages. You must communicate with others and explain the benefits of your product if you want to succeed. Since organic traffic generates a higher return on investment than paid search traffic, investing in SEO is an intelligent strategy to strengthen the future of your business. Only the organic search results are viewed by the bulk of search traffic. Hence, you lose a significant portion of your online audience if your website does not appear on the first page with the most searched keywords.</p>
                            </div>
                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Best SEO Agency in Kolkata</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Here's where we take the charge. The passionate members of our team are experienced in SEO and marketing. You will have our shoulder to help you generate more leads and sales online.<strong> Bonafide Technologies</strong> stands out from the competition due to our unique and practical approaches, tools, procedures, knowledge, and content. We are among the top SEO companies in Kolkata. Therefore, we can ensure a rise in search rankings for the most competitive phrases in India.</p>
                                <br />
                                <p className='text-[18px] font-normal text-[#636363] '>You've come to the right spot if you're seeking the top SEO company or SEO expert in Kolkata. Bonafide Technologies, an Indian digital marketing and web development business, offers advanced SEO services worldwide. We are a group of Google-certified SEO experts with over fifteen years of experience. We exclusively use White Hat SEO strategies and stick to search engine algorithms. We offer a Google first-page guarantee for the target keywords relevant to your business. Hence, we are your best choice if you intend to work with an SEO consultant or agency in Kolkata.</p><br />
                                <p className='text-[18px] font-normal text-[#636363] '>We focus on giving our clients positive results, from link building to on-page SEO. We know that the quantity of relevant, high-quality backlinks your website gets plays a significant factor in any campaign's success. Also, we offer frequent updates on the most recent modifications to the Google algorithm to help you stay ahead of the game and take the top spot on search engine results pages (SERPs). Additionally, we provide a complete selection of SEO services to fit your needs and budget. Please contact us directly if you want more information about how our search engine optimization services can help your company grow tremendously.</p><br />
                                <h1 className='text-[32px] font-semibold my-3 heading'>What is Included in Our SEO Service?</h1>
                                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                                    <div className="flex flex-wrap justify-between">
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Pre-SEO Consultation
                                            </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Following a free consultation, we examine the websites of your competitors and your company. We will present you our SEO service pack based on this analysis.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>SEO Audit</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Our knowledgeable staff audits your website and suggests changes to raise its search engine ranks. This involves improving its technological elements, content, and link profile.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Keywords Research</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>We conduct in-depth keyword research and examine the competition, market trends, and more elements. We provide a list of optimized keywords for your website based on this.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Content Strategy</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Using your company's objectives as a guide, we will create a detailed plan for producing content. This plan will use relevant keywords, create original and captivating content, and more.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>On-Page SEO Optimization</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>We ensure your website is optimized correctly by following best practices. For instance, we look for and remove duplicate content and modify Meta tags, titles, URLs, etc.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Off-Page SEO Optimization</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>To get high-quality backlinks for your website, we engage in link building. These connections improve search engine visibility for your website. All off-page tasks, such as article submissions and backlinking, will be done by us.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Reporting</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>To keep you updated on any potential problems, you will receive regular reports on the status of your SEO strategy.</p>
                                        </div>
                                    </div>
                                </div>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Why Choose Us</h1>
                                <ul>
                                    <li className='text-[18px] font-normal text-[#636363] '><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>As one of Kolkata's most successful businesses, we guarantee your company's best possible growth.</li>
                                    <li className='text-[18px] font-normal text-[#636363] '><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>As technology advances, we deliver the highest return on investment regarding leads.</li>
                                    <li className='text-[18px] font-normal text-[#636363] '><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>We guarantee your business will rank organically well and appear in the top 10 search results.</li>
                                    <li className='text-[18px] font-normal text-[#636363] '><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>With us, you can unwind while getting 100% relevant and tailored visitors.</li>
                                    <li className='text-[18px] font-normal text-[#636363] '><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>We pledge to be a reliable and worthy SEO company in Kolkata, helping your companies reach significant heights.</li>
                                    <li className='text-[18px] font-normal text-[#636363] '><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>Helped achieve a remarkable growth curve in website traffic, with some clients noticing 100% growths.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Kolkata
