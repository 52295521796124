import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Vadodara() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{
                backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)" }}>
                <div className="container mx-auto py-12">
                    <h1 className='text-center text-white text-[32px] md:text-[52px]  font-semibold heading uppercase'>
                        SEO Company in Vadodara 
                    </h1>
                </div>
            </div>
        
            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[100%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>SEO Company in Vadodara </h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Let's make you reach out to your dream position. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>Why settle for less when you can have the Best on your side? Bonafide Technologies, the Best SEO Company in Vadodara offers top-notch SEO services. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>Every company wants to expand and succeed, but achieving these goals requires consistent effort carried out more efficiently. Choosing the top SEO company in Vadodara is crucial to make your business successful. A digital marketing specialist can help your business stand out. Bonafide Technologies is the best digital marketing company in Vadodara, which offers top-notch digital marketing and organic SEO services at affordable costs.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>Pick Bonafide Technologies, give us complete control over your social media and digital marketing needs, and get the results you want.</p>
                                <br />
        
                                <h1 className='text-[32px] font-semibold my-3 heading'>What is Search Engine Optimization (SEO), and Why is it Important?</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>The process of making website improvements to increase targeted visitors through search engine rankings is known as SEO or search engine optimization.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>You can receive more clicks if you rank high for your target keywords on search engines like Google, Bing, Yahoo, etc.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>When using SEO, marketers need to concentrate on getting more traffic from search engines and attracting highly targeted users interested in your website content. Bonafide Technologies can effectively handle your digital marketing, SEO, and social media marketing demands. Their SEO services will undoubtedly boost your company's search engine ranking.</p>
        
                                <h1 className='text-[32px] font-semibold my-3 heading'>Why Choose Us?</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>One of the Best SEO Companies in Vadodara, Bonafide Technologies uses various technologies to improve your webpages and takes a holistic approach to SEO. All these things make us unique from most other SEO specialists.</p>
                                <ul>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span></strong>
                                        Concentrate on generating results.</li>   
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span></strong>
                                        Use the Best SEO Technique</li>   
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span></strong>
                                        Pay Attention to Organic Quality Leads</li>   
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span></strong>
                                        We are open and honest.</li>         
                                </ul> 
                                <p className='text-[18px] font-normal text-[#636363] '>Our SEO strategies are built around your target market, target locations, budget, and Google guidelines and algorithm, which keeps your website at the top of search engine results pages (SERPs) and increases the quality of visitors to it, increasing its visibility. We provide SEO services that offer you a competitive advantage over rivals and build brand awareness, which results in a devoted clientele.</p>
        
        
                                <h1 className='text-[32px] font-semibold my-3 heading'>SEO Services We Offer</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>As the best SEO company in Vadodara, Gujarat, we mainly focus on lead creation using organic SEO traffic to maintain its top ranking on Google search.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>Search engines will find your business more quickly if you use SEO techniques. Here are the services that we offer in our SEO services in Vadodara.</p>
                                        <ul>
                                            <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span></strong>
                                                Local Business Listing</li>   
                                            <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span></strong>
                                                Link Building</li>   
                                            <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span></strong>
                                                Google search console</li>   
                                            <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span></strong>
                                                On-Page SEO</li>   
                                                <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span></strong>
                                                    Google Analytics</li>   
                                                <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span></strong>
                                                    Schema Implement</li>   
                                                <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span></strong>
                                                    Website Structure Optimization</li>      
                                        </ul> 
                                        
                                        <h1 className='text-[32px] font-semibold my-3 heading'>Do You Want to Improve Your Website's Search Engine Ranking?</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Allow us to handle complete SEO services under one roof so you can unwind countless business leads. We use various effective SEO techniques and technologies to help you grow your business. These tools enable us to spot possibilities and solve problems, enabling us to create, market, and raise awareness. Contact us to make your dream into reality!</p>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        )
}

export default Vadodara