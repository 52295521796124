import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Kalyan() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{
                backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)" }}>
                <div className="container mx-auto py-12">
                    <h1 className='text-center text-white text-[32px] md:text-[52px]  font-semibold heading uppercase'>
                        SEO Company in Kalyan
                    </h1>
                </div>
            </div>
        
            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[100%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>SEO Company in Kalyan</h1>
                                <p className='text-[18px] font-normal text-[#636363] '><strong>We Can Grow Your Company Faster
                                        Organically!</strong></p>
                                <p className='text-[18px] font-normal text-[#636363] '>To maximize your return on investment,
                                    use data-driven growth to unlock the potential of your online business. </p>
                            </div>
                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>What Actions Do We Take? </h1>
                                <p className='text-[18px] font-normal text-[#636363] '><strong> Successfully navigate using our
                                        SEO tool. </strong></p>
                                <br />
                                <p className='text-[18px] font-normal text-[#636363] '>Our goal is to empower your website and
                                    provide it with all the necessary SEO approaches to put it on top of search results. As a
                                    top SEO company in Kalyan, we provide digital marketing solutions that increase ROI and
                                    accelerate brand growth. </p><br />
                                <p className='text-[18px] font-normal text-[#636363] '>Our SEO services are result-driven and
                                    tailored to each client's requirements. With our extensive industry experience, we offer
                                    custom SEO solutions that can raise your company's profile and position it at the top of
                                    search engine results pages.</p><br />
                                <p className='text-[18px] font-normal text-[#636363] '>As a leading SEO and web development
                                    company, Bonafide Technologies specializes in creating useful websites that are also packed
                                    with reliable SEO solutions. We prioritize using the most effective strategies to raise
                                    sales and the ranking. We are committed to exceeding your needs and providing only the best.
                                </p><br />
                                <h1 className='text-[32px] font-semibold my-3 heading'>What We Offer</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>We guarantee high-quality leads and
                                    natural traffic with our customized SEO services. We can provide you with long-lasting
                                    solutions thanks to our knowledge. We provide a wide range of SEO services, such as the
                                    following: </p>
                                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                                    <div className="flex flex-wrap justify-between">
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Local SEO
                                            </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>To increase a business's
                                                visibility to nearby clients in certain geographic areas, local SEO involves
                                                enhancing one's web presence to rank better in local search results. Our
                                                specialty is offering local SEO services that raise the search engine rating of
                                                your website.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>eCommerce SEO </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>eCommerce SEO is the process
                                                of making online stores more visible in search results, generating more organic
                                                traffic and sales for online goods. More focused traffic and increased
                                                conversions can be attained with the aid of our SEO business in Mumbai.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Shopify SEO</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Shopify SEO is the process of
                                                making e-commerce websites hosted on the Shopify platform more visible in search
                                                results, which increases organic traffic and sales. Discover how we, as a Mumbai
                                                SEO company, can help transform your Shopify website into a profitable venture.
                                            </p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Magento SEO</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Magento SEO is the process of
                                                improving e-commerce websites that use the Magento platform in order to draw in
                                                more organic visitors, improve search engine visibility, and boost online sales.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Why Do You Need an SEO Company In Mumbai?
                                </h1>
                                <p className='text-[18px] font-normal text-[#636363] '>As a well-known SEO company, we have seen
                                    companies generate enormous profits from their websites after applying these methods. The
                                    cornerstone of our digital marketing approach is SEO. For years to come, larger returns on
                                    this investment are certain. </p><br />
                                <p className='text-[18px] font-normal text-[#636363] '>There is fierce competition, but you are
                                    driven to place well. </p><br />
                                <p className='text-[18px] font-normal text-[#636363] '>By using SEO, one can equally boost
                                    earnings and establish credibility online. Users begin to trust your website more as it
                                    rises in search engine results. Furthermore, organic traffic is a resource that will yield
                                    bigger benefits for you. As more potential customers swiftly become aware of your brand, the
                                    conversion rate rises dramatically.</p><br />
                                <p className='text-[18px] font-normal text-[#636363] '>When you first start in the digital
                                    world, SEO implementation can be intimidating. Here's where we simplify the procedure for
                                    you. As a Mumbai web design business, we start by selecting SEO-friendly websites and then
                                    incorporating the necessary SEO components to make an online impact. </p><br />
                                <p className='text-[18px] font-normal text-[#636363] '>Here at Bonafide Technologies, we
                                    streamline and ease the process of obtaining SEO solutions for you. Our specialized
                                    solutions aim to raise conversion rates, produce high-quality leads, and improve visibility.
                                    Our SEO services in Mumbai are focused on producing measurable outcomes. We provide our
                                    clients with tools to monitor their development and real-time insights.</p><br />
        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        )
}

export default Kalyan