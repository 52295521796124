import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Thane() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{
                backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)" }}>
                <div className="container mx-auto py-12">
                    <h1 className='text-center text-white text-[32px] md:text-[52px]  font-semibold heading uppercase'>
                        SEO Company in Thane
                    </h1>
                </div>
            </div>
        
            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[100%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>SEO Company in Thane</h1>
                                <p className='text-[18px] font-normal text-[#636363] '><strong>Let's Improve the Growth and Visibility of Your Business.</strong></p>
                                <p className='text-[18px] font-normal text-[#636363] '>While it may seem apparent to work with a digital marketing agency that genuinely understands the field, many businesses regrettably fall short of expectations. Our tactics have shown to be effective.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>Bonafide Technologies is the greatest and highest caliber SEO Service provider in Mumbai. We offer excellent performance in Website Design, Web Development, and Digital Marketing Services, such as Search Engine Optimization (SEO), Social Media Marketing (SMM), Search Engine Marketing (SEM), or Pay Per Click (PPC), Graphics & Banner, Brochure Design, etc. </p>
                                <br />
                                <h1 className='text-[32px] font-semibold my-3 heading'>Why Choose Us</h1>
        
                                <p className='text-[18px] font-normal text-[#636363] '><strong>Completely Functional Digital Marketing Company In Thane, Mumbai, India</strong></p>
                                <p className='text-[18px] font-normal text-[#636363] '>Bonafide Technologies is a well-known Digital Marketing company in Mumbai that produces effective digital strategies and showcases best-in-class talent.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>With a combined relevant experience of over 15 years, the team of SEO executives in Mumbai has a versatile skill set that enables them to deliver tried and tested SEO solutions.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>With the help of our efficient digital marketing services, expand your company. We can handle all of your digital marketing requirements and support you in reaching your online business objectives.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>Work with a business that can offer all the services you require to create awareness, attract customers, and establish connections. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>Our team of data-driven experts leads a 360° digital marketing agency located in Thane, Mumbai, India. We create digital experiences that help brands stand out and grab your attention using strategy, design, and technology. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>With data-driven and goal-oriented digital marketing solutions, we help you increase your online sales and branding by more than 10X so you can get the best returns on your investment and increase the number of repeat orders. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>With over a decade of experience in this field, our team is the greatest resource for improving Search Engine Result Page (SERP) visibility on Google, Bing, Yahoo, and other platforms. Our goal is to have more satisfied and finest customers. </p>
                            </div>
                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>What We Offer </h1>
                                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                                    <div className="flex flex-wrap justify-between">
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Website Design & Development 
                                            </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>We create business websites that are engaging, fascinating, and able to hold the interest of their intended audience.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Search Engine Optimization</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>In order to help brands reach their target audience in the realm of digital marketing, we offer SEO services.​</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>PPC and SEM Management</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>SEM can assist you in connecting with customers who are seeking you online in only a few clicks.
                                            </p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Social Media Marketing</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>We offer social media to promote businesses and items online so that your customers can see them.</p>
                                        </div>
                                    </div>
                                </div>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Contact Us Now!</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Do you want to increase your leads and sales ten times? Let's get to work together!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        )
}

export default Thane
