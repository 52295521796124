import React from 'react'
import { Link } from "react-router-dom";
function Footer() {
  return (

    <>
      <div className="container-fluid pt-10 pb-4 md:pt-16 md:pb-8 bg-black lg:px-3">
        <div className="container mx-auto">
          <div className="flex flex-wrap lg:flex-nowrap">
            <div className="w-full md:w-[50%] lg:w-[31%] p-3 md:p-8 lg:p-0 mb-8 md:mb-0">
              <Link to="/" className="hero_img ">
                <img src="img/logo_w.png" alt="" />
              </Link>
              <p className='text-[18px] font-normal text-white mt-5'>If you are in search of social bookmarking sites list then you have landed on the right article.</p>

              <div className="icons flex mt-5">
                <div className='cursor-pointer h-10 w-10 border border-2 rounded-full pl-[10px] pt-[8px] text-white hover:'><a href="https://www.facebook.com/bonafidetechnologies/"><i class="fa-brands fa-facebook text-[18px]"></i></a></div>
                <div className='cursor-pointer h-10 w-10 ml-3 border border-2 rounded-full pl-[10px] pt-[8px] text-white'><a href="https://www.instagram.com/bonafidetechnologies/"><i class="fa-brands text-[18px] fa-instagram"></i></a></div>
                <div className='cursor-pointer h-10 w-10 ml-3 border border-2 rounded-full pl-[10px] pt-[8px] text-white'><a href="https://linkedin.com/authwall?trk=bf&trkInfo=AQGbKx7WEyvV6AAAAY5aag9gxYuh8zKj0IJX7iqLuHhmI_j4sR4uxw7dBuGVoBnMt3vyuEQcx1Z0zD_Wsz46B5amIjBk187dYtQII1Nbvx0qd3DDA7FXxImAFBmVpFvZeoiURxQ=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fbonafide-technologies"><i class="fa-brands text-[18px] fa-linkedin-in"></i></a></div>
                <div className='cursor-pointer h-10 w-10 ml-3 border border-2 rounded-full pl-[10px] pt-[8px] text-white'><a href="https://wa.me/7814471295"><i class="fa-brands fa-whatsapp text-[18px]"></i></a></div>
                <div className='cursor-pointer h-10 w-10 ml-3 border border-2 rounded-full pl-[10px] pt-[8px] text-white'><a href="https://www.upwork.com/agencies/bonafidetech/"><i class="fa-brands fa-upwork text-[18px]"></i></a></div>
                <div className='cursor-pointer h-10 w-10 ml-3 border border-2 rounded-full pl-[10px] pt-[8px] text-white'><a href="https://pro.fiverr.com/agencies/BFTConsultants"><img src="img/fiverr.png" alt="" /></a></div>
              </div>
            </div>

            <div className="w-full md:w-[50%] lg:w-[23%] mb-8 md:mb-0 p-3  md:p-8 lg:py-0 lg:pl-12">
              <h1 className='text-[28px] font-semibold heading  mb-8 text-white'>Links</h1>
              <ul className='items-center'>
                <Link to="/"><li className='font-semibold text-[16px]  mb-4 cursor-pointer heading hover:text-[#e3b299] text-white'>HOME</li></Link>
                <Link to="/about-us"><li className='font-semibold text-[16px]  mb-4 cursor-pointer heading hover:text-[#e3b299] text-white'>ABOUT US</li></Link>
                <Link to="/"><li className='font-semibold text-[16px]  mb-4 cursor-pointer heading hover:text-[#e3b299] text-white'>BLOG</li></Link>
                <Link to="/our-work"><li className='font-semibold text-[16px]  mb-4 cursor-pointer heading hover:text-[#e3b299] text-white'>OUR WORK</li></Link>
                <Link to="/contact-us"><li className='font-semibold text-[16px]  mb-4 cursor-pointer heading hover:text-[#e3b299] text-white'>CONTACT US</li></Link>
              </ul>
            </div>

            <div className="w-full md:w-[50%] lg:w-[23%] mb-8 md:mb-0 p-3 md:p-8 lg:p-0">
              <h1 className='text-[28px] font-semibold heading  mb-8 text-white'>Pages</h1>
              <ul className='items-center'>
                <Link to="/our-work"> <li className='font-semibold text-[16px]  mb-4 cursor-pointer heading hover:text-[#e3b299] text-white'>DIGITAL INTERNET MARKETING</li></Link>
                <Link to="/our-work"><li className='font-semibold text-[16px]  mb-4 cursor-pointer heading hover:text-[#e3b299] text-white'>WEB DESIGNING</li></Link>
                <Link to="/our-work"> <li className='font-semibold text-[16px]  mb-4 cursor-pointer heading hover:text-[#e3b299] text-white'>WEB DEVELOPMENT</li></Link>
                <li className='font-semibold text-[16px]  mb-4 cursor-pointer heading hover:text-[#e3b299] text-white'>CONTENT WRITING </li>
                <Link to="/gigs"><li className='font-semibold text-[16px]  mb-4 cursor-pointer heading hover:text-[#e3b299] text-white'>GIGS</li></Link>
              </ul>
            </div>

            <div className="w-full md:w-[50%] lg:w-[23%] mb-8 md:mb-0 p-3  md:p-8 lg:p-0">
              <h1 className='text-[28px] font-semibold heading  mb-8 text-white'>Contact Us</h1>

              <div className="footer_icons">
                <p className="text-[18px] text-white cursor-pointer mb-4">
                  <a href="mailto:info@bonafidetech.com"><span class="mr-[10px] text-[18px]"><i class="fa-regular fa-envelope"></i></span>info@bonafidetech.com</a>
                </p>

                <p className="text-[18px] text-white cursor-pointer mb-4">
                  <a href="tel:7814471295">  <span class="mr-[10px] text-[18px]"><i class="fa-solid fa-mobile-screen-button"></i></span>7814471295</a>
                </p>

                <p className="text-[16px] text-white cursor-pointer mb-4 heading">
                  <span class="mr-[10px] text-[20px]"><i class="fa-solid fa-location-dot"></i></span>Plot No: D-160, First Floor, Phase-8, Industrial Area, Sahibzada Ajit Singh Nagar, Punjab 160071
                </p>
              </div>
            </div>
          </div>
          <div >
            <p className='text-[24px] text-white mt-5 font-semibold p-3 md:p-8 lg:p-0'>Location</p>
            <div className='flex flex-wrap lg:flex-nowrap justify-between mt-5 mb-8'>
              <div className="w-full md:w-[50%] lg:w-[20%] mb-8 md:mb-0 p-3 md:p-8 lg:p-0">
                <Link to="/mumbai">
                  <p className='text-[16px] text-white font-normal hover:text-[#e3b299] my-2'>SEO Company in Mumbai</p>
                </Link>

                <Link to="/jaipur">
                  <p className='text-[16px] text-white font-normal hover:text-[#e3b299] my-2'>SEO Company in Jaipur</p>
                </Link>

                <Link to="/chennai">
                  <p className='text-[16px] text-white font-normal hover:text-[#e3b299] my-2'>SEO Company in Chennai</p>
                </Link>

                <Link to="/kolkata">
                  <p className='text-[16px] text-white font-normal hover:text-[#e3b299] my-2'>SEO Company in Kolkata</p>
                </Link>

                <Link to="/vashi">
                  <p className='text-[16px] text-white font-normal hover:text-[#e3b299] my-2'>SEO Company in Vashi</p>
                </Link>

                <Link to="/dombivli">
                  <p className='text-[16px] text-white font-normal hover:text-[#e3b299] my-2'>SEO Company in Dombivli</p>
                </Link>
                <Link to="/delhincr">
                  <p className='text-[16px] text-white font-normal hover:text-[#e3b299] my-2'>SEO Company in Delhi NCR</p>
                </Link>
              </div>
              <div className="w-full md:w-[50%] lg:w-[20%] mb-8 md:mb-0 p-3 md:p-8 lg:p-0">
                <Link to="/thane">
                  <p className='text-[16px] text-white font-normal hover:text-[#e3b299] my-2'>SEO Company in Thane</p>
                </Link>
                <Link to="/nashik">
                  <p className='text-[16px] text-white font-normal hover:text-[#e3b299] my-2'>SEO Company in Nashik</p>
                </Link>
                <Link to="/bangalore">
                  <p className='text-[16px] text-white font-normal hover:text-[#e3b299] my-2'>SEO Company in Bangalore</p>
                </Link>
                <Link to="/goregaon">
                  <p className='text-[16px] text-white font-normal hover:text-[#e3b299] my-2'>SEO Company in Goregaon</p>
                </Link>
                <Link to="/noida">
                  <p className='text-[16px] text-white font-normal hover:text-[#e3b299] my-2'>SEO Company in Noida</p>
                </Link>
                <Link to="/andheri">
                  <p className='text-[16px] text-white font-normal hover:text-[#e3b299] my-2'>SEO Company in Andheri</p>
                </Link>
                <Link to="/ahmedabad">
                <p className='text-[16px] text-white font-normal hover:text-[#e3b299] my-2'>SEO Company in Ahmedabad</p>
                </Link>

                {/* <p className='text-[16px] text-white font-normal hover:text-[#e3b299] my-2'>SEO Company in London</p> */}
              </div>
              <div className="w-full md:w-[50%] lg:w-[20%] mb-8 md:mb-0 p-3 md:p-8 lg:p-0">
                <Link to="/vadodara">
                 <p className='text-[16px] text-white font-normal hover:text-[#e3b299] my-2'>SEO Company in Vadodara</p>
                </Link>
                <Link to="/surat">
                  <p className='text-[16px] text-white font-normal hover:text-[#e3b299] my-2'>SEO Company in Surat</p>
                </Link>
                <Link to="/hyderabad">
                  <p className='text-[16px] text-white font-normal hover:text-[#e3b299] my-2'>SEO Company in Hyderabad</p>
                </Link>
                <Link to="/gurugram">
                 <p className='text-[16px] text-white font-normal hover:text-[#e3b299] my-2'>SEO Company in Gurugram</p>
                </Link>
                <Link to="/borivali">
                 <p className='text-[16px] text-white font-normal hover:text-[#e3b299] my-2'>SEO Company in Borivali</p>
                </Link>
                <Link to="/indore">
                 <p className='text-[16px] text-white font-normal hover:text-[#e3b299] my-2'>SEO Company in Indore</p>
                </Link>
                <Link to="/navimumbai">
                  <p className='text-[16px] text-white font-normal hover:text-[#e3b299] my-2'>SEO Company in Navi Mumbai</p>
                </Link>
              </div>
              <div className="w-full md:w-[50%] lg:w-[20%] mb-8 md:mb-0 p-3 md:p-8 lg:p-0">
                <Link to="/bandra">
                  <p className='text-[16px] text-white font-normal hover:text-[#e3b299] my-2'>SEO Company in Bandra</p>
                </Link>
                <Link to="/kalyan">
                <p className='text-[16px] text-white font-normal hover:text-[#e3b299] my-2'>SEO Company in Kalyan</p>
                </Link>
                <Link to="/udaipur">
                <p className='text-[16px] text-white font-normal hover:text-[#e3b299] my-2'>SEO Company in Udaipur</p>
                </Link>
                <Link to="/ghaziabad">
                <p className='text-[16px] text-white font-normal hover:text-[#e3b299] my-2'>SEO Company in Ghaziabad</p>
                </Link>
                <Link to="/dehradun">
                  <p className='text-[16px] text-white font-normal hover:text-[#e3b299] my-2'>SEO Company in Dehradun</p>
                </Link>
                <Link to="/gwalior">
                  <p className='text-[16px] text-white font-normal hover:text-[#e3b299] my-2'>SEO Company in Gwalior</p>
                </Link>
                <Link to="/chandigarh"> 
                  <p className='text-[16px] text-white font-normal hover:text-[#e3b299] my-2'>SEO Company in Chandigarh</p>
                </Link>
              </div>
              <div className="w-full md:w-[50%] lg:w-[20%] mb-8 md:mb-0 p-3 md:p-8 lg:p-0">
                <Link to="/allahabad">
                  <p className='text-[16px] text-white font-normal hover:text-[#e3b299] my-2'>SEO Company in Allahabad</p>
                </Link>
                <Link to="/aurangabad">
                  <p className='text-[16px] text-white font-normal hover:text-[#e3b299] my-2'>SEO Company in Aurangabad</p>
                </Link>
                <Link to="/pune">
                  <p className='text-[16px] text-white font-normal hover:text-[#e3b299] my-2'>SEO Company in Pune</p>
                </Link>
             
              
                {/* <p className='text-[16px] text-white font-normal hover:text-[#e3b299] my-2'>SEO Company in Vapi</p> */}
              </div>
            </div>
            <hr />

          </div>
          <p className='font-normal text-[15px]  mb-4 cursor-pointer heading text-white mt-5 text-center'>© Copyright 2024 By <span className='text-[#e3b299] text-[16px]'>Bonafide Technologies</span></p>
        </div>
      </div>
    </>
  )
}

export default Footer