import React from 'react'
import { Link } from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
function Pages() {
  return (
    <>
    <Header/>
    <div className="container-fluid p-3 py-12">
        <div className="container mx-auto">
            <div className="flex flex-wrap md:flex-nowrap items-center">
              <div className="w-full md:w-[58%]">
                <h1 className='text-[34px] md:text-[42px] lg:text-[56px] xxl:text-[66px] font-bold heading '> <span className='text-[#e3b299] heading'>SEO Services: </span>Boost Your Online Presence.</h1>
                <p className='text-[18px] md:text-[22px] font-medium text-[#636363] mt-3'>We are a digital agency that helps businesses reach their full potential online.</p>
              </div>
              <div className=" w-full md:w-[58%] ">
                <div className="hero_img ">
                   <img src="img/page_hero.png" className='w-full lg:w-[70%] float-right' alt="" />
                </div>
              </div>
            </div>
        </div>
    </div>

    <div className="container-fluid p-3">
        <div className="container mx-auto">
           <h1 className='text-[36px] font-bold heading text-center'>What We Offer For Your Business</h1>
           <div className="flex justify-between items-center flex-wrap py-12">
              <div className="w-full md:w-[48%] lg:w-[24%] cursor-pointer border hover:shadow-lg hover:shadow-[#00000047] rounded-xl overflow-hidden p-4 bg-[#f6f6f6] page_box mt-10 lg:mt-0">
                <div className="main_box overflow-hidden relative rounded-xl">
                  <img src="img/page_img_1.jpg" className='object-cover md:h-[320px] lg:h-[400px]  transition-all duration-500' alt="" />
                  <div className="page_text px-3 py-6 relative">
                    <Link to="/top-free-social-bookmarking-sites-lists">
                      <h1 className='text-[18px] md:text-[22px] font-medium text-white capitalize'>Top Free Social Bookmarking Sites Lists</h1>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-[48%] lg:w-[24%] cursor-pointer border hover:shadow-lg hover:shadow-[#00000047] rounded-xl overflow-hidden p-4 bg-[#f6f6f6] page_box mt-10 lg:mt-0">
                <div className="main_box overflow-hidden relative rounded-xl">
                  <img src="img/page_img_2.jpg" className='object-cover md:h-[320px] lg:h-[400px]  transition-all duration-500' alt="" />
                  <div className="page_text px-3 py-6 relative">
                    <Link to="/profile-creation-sites-list">
                     <h1 className='text-[18px] md:text-[22px] font-medium text-white capitalize'>Top High Do Follow Profile Creation Sites List</h1>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-[48%] lg:w-[24%] cursor-pointer border hover:shadow-lg hover:shadow-[#00000047] rounded-xl overflow-hidden p-4 bg-[#f6f6f6] page_box mt-10 lg:mt-0">
                <div className="main_box overflow-hidden relative rounded-xl">
                  <img src="img/page_img_3.jpg" className='object-cover md:h-[320px] lg:h-[400px]  transition-all duration-500' alt="" />
                  <div className="page_text px-3 py-6 relative">
                    <Link to="/image-submission-sites-list">
                       <h1 className='text-[18px] md:text-[22px] font-medium text-white capitalize'>image submission sites</h1>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-[48%] lg:w-[24%] cursor-pointer border hover:shadow-lg hover:shadow-[#00000047] rounded-xl overflow-hidden p-4 bg-[#f6f6f6] page_box mt-10 lg:mt-0">
                <div className="main_box overflow-hidden relative rounded-xl">
                  <img src="img/page_img_4.jpg" className='object-cover md:h-[320px] lg:h-[400px]  transition-all duration-500' alt="" />
                  <div className="page_text px-3 py-6 relative">
                  <Link to="/classified-ads-websites">
                    <h1 className='text-[18px] md:text-[22px] font-medium text-white capitalize'>classified ads websites</h1>
                  </Link>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-[48%] lg:w-[24%] cursor-pointer border hover:shadow-lg hover:shadow-[#00000047] rounded-xl overflow-hidden p-4 bg-[#f6f6f6] page_box mt-10">
                <div className="main_box overflow-hidden relative rounded-xl">
                  <img src="img/page_img_5.jpg" className='object-cover md:h-[320px] lg:h-[400px]  transition-all duration-500' alt="" />
                  <div className="page_text px-3 py-6 relative">
                  <Link to="/instant-approval-article-submissions-sites-list">
                    <h1 className='text-[18px] md:text-[22px] font-medium text-white capitalize'>free article submission sites</h1>
                  </Link>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-[48%] lg:w-[24%] cursor-pointer border hover:shadow-lg hover:shadow-[#00000047] rounded-xl overflow-hidden p-4 bg-[#f6f6f6] page_box mt-10">
                <div className="main_box overflow-hidden relative rounded-xl">
                  <img src="img/page_img_6.jpg" className='object-cover md:h-[320px] lg:h-[400px]  transition-all duration-500' alt="" />
                  <div className="page_text px-3 py-6 relative">
                    <Link to="/free-high-quality-directory-submission-sites">
                      <h1 className='text-[18px] md:text-[22px] font-medium text-white capitalize'>Directory Submission Sites list</h1>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-[48%] lg:w-[24%] cursor-pointer border hover:shadow-lg hover:shadow-[#00000047] rounded-xl overflow-hidden p-4 bg-[#f6f6f6] page_box mt-10">
                <div className="main_box overflow-hidden relative rounded-xl">
                  <img src="img/page_img_7.jpg" className='object-cover md:h-[320px] lg:h-[400px]  transition-all duration-500' alt="" />
                  <div className="page_text px-3 py-6 relative">
                    <Link to="/instant-approval-directory-submission-sites-list">
                       <h1 className='text-[18px] md:text-[22px] font-medium text-white capitalize'>Instant Directory Submission Sites List</h1>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-[48%] lg:w-[24%] cursor-pointer border hover:shadow-lg hover:shadow-[#00000047] rounded-xl overflow-hidden p-4 bg-[#f6f6f6] page_box mt-10">
                <div className="main_box overflow-hidden relative rounded-xl">
                  <img src="img/page_img_8.jpg" className='object-cover md:h-[320px] lg:h-[400px]  transition-all duration-500' alt="" />
                  <div className="page_text px-3 py-6 relative">
                  <Link to="/free-web-2.0-sites-list">
                    <h1 className='text-[18px] md:text-[22px] font-medium text-white capitalize'>Free Dofollow web 2.0 sites List</h1>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-[48%] lg:w-[24%] cursor-pointer border hover:shadow-lg hover:shadow-[#00000047] rounded-xl overflow-hidden p-4 bg-[#f6f6f6] page_box mt-10">
                <div className="main_box overflow-hidden relative rounded-xl">
                  <img src="img/page_img_9.jpg" className='object-cover md:h-[320px] lg:h-[400px]  transition-all duration-500' alt="" />
                  <div className="page_text px-3 py-6 relative">
                    <Link to="/profile-creation-sites-list">
                     <h1 className='text-[18px] md:text-[22px] font-medium text-white capitalize'>profile creation websites</h1>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-[48%] lg:w-[24%] cursor-pointer border hover:shadow-lg hover:shadow-[#00000047] rounded-xl overflow-hidden p-4 bg-[#f6f6f6] page_box mt-10">
                <div className="main_box overflow-hidden relative rounded-xl">
                  <img src="img/page_img_10.webp" className='object-cover w-full h-[320px] lg:h-[400px] transition-all duration-500' alt="" />
                  <div className="page_text px-3 py-6 relative">
                    <Link to="/video-submission-sites-list">
                      <h1 className='text-[18px] md:text-[22px] font-medium text-white capitalize'>video submission sites</h1>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-[48%] lg:w-[24%] cursor-pointer border hover:shadow-lg hover:shadow-[#00000047] rounded-xl overflow-hidden p-4 bg-[#f6f6f6] page_box mt-10">
                <div className="main_box overflow-hidden relative rounded-xl">
                  <img src="img/page_img_11.webp" className='object-cover w-full h-[320px] lg:h-[400px] transition-all duration-500' alt="" />
                  <div className="page_text px-3 py-6 relative">
                    <Link to="/business-listing-sites-in-india">
                      <h1 className='text-[18px] md:text-[22px] font-medium text-white capitalize'>business listing site</h1>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-[48%] lg:w-[24%] cursor-pointer border hover:shadow-lg hover:shadow-[#00000047] rounded-xl overflow-hidden p-4 bg-[#f6f6f6] page_box mt-10">
                <div className="main_box overflow-hidden relative rounded-xl">
                  <img src="img/page_img_12.webp" className='object-cover w-full h-[320px] lg:h-[400px] transition-all duration-500' alt="" />
                  <Link to="/blog-submission-sites" className="page_text px-3 py-6 relative">
                    <h1 className='text-[18px] md:text-[22px] font-medium text-white capitalize'>blog submission sites</h1>
                  </Link>
                </div>
              </div>
           </div>
        </div>  
    </div>
    <Footer/>
    </>
  )
}

export default Pages