import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Chennai() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{
                backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)"
            }}>
                <div className="container mx-auto py-12">
                    <h1
                        className='text-center text-white text-[32px] md:text-[42px] lg:text-[56px] font-semibold heading uppercase'>SEO Company in Chennai
                    </h1>
                </div>
            </div>

            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[100%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>SEO Company in Chennai</h1>
                                <p className='text-[18px] font-normal text-[#636363] '><strong>Join the SEO company with a track
                                    record of generating more leads, customers, and traffic. </strong></p>
                                <br />
                                <p className='text-[18px] font-normal text-[#636363] '>Bonafide Technologies is one of the top
                                    SEO companies in Chennai. For each customer, we use specifically designed SEO-friendly
                                    tactics. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>The goal of Bonafide Technologies is to
                                    become a versatile, multi-functional SEO service provider with a focus on rewarding
                                    strategic solutions. We have assisted numerous businesses in getting their websites to
                                    appear on Google's first page. With ten years of experience in the field, our SEO
                                    consultants in Chennai are aware of what it takes to rank highly on Google.</p>
                            </div>
                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Our Work Vision</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Our goal is to assist all companies, no
                                    matter how big or little, in creating an online presence, utilizing the Internet's
                                    potential, and experiencing growth. A future where everyone has the opportunity to thrive
                                    and display their skills and abilities is made possible by empowered businesses, which in
                                    turn create jobs and growth possibilities. Also, we are here to assist businesses to grow,
                                    even if they only want to improve their products and utilize their current setup to the
                                    greatest extent possible! </p><br />
                                <h1 className='text-[32px] font-semibold my-3 heading'>What SEO Services Do We Offer Our
                                    Clients?</h1>
                                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                                    <div className="flex flex-wrap justify-between">
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Search Engine Optimization
                                            </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>We know the best ways to
                                                boost sales. Although search engine algorithms have changed significantly over
                                                the last ten years, the traffic originating from search results continues to
                                                have the greatest conversion rate. Because of this, the sales channel remains a
                                                possibility through the latest search engine ranking algorithms. Our strategy
                                                for search engine optimization has evolved. </p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Social Media Marketing </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Social media marketing is the
                                                practice of using social media platforms to increase brand or product visibility
                                                or traffic. While nearly every company tries to reach out to customers through
                                                every avenue, many are unsatisfied with the results after several months of
                                                effort and see it as a waste of money and time. Do you want to start using
                                                social media to promote your brand but are unsure of where to begin? We'll
                                                assist you in setting up the relevant social media presence.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Pay Per Click </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>When used by experts, this is
                                                one of the most potent Internet marketing strategies. With pay per click (PPC)
                                                advertising, you may easily locate customers who are already looking for the
                                                goods or services you provide in the search results pages of Google Adwords,
                                                Yahoo, and Bing Ads. Your advertisements will show up among hundreds of
                                                thousands of requests. There will be a significant increase in targeted visitors
                                                to the website, which will turn into calls and orders. </p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Web Design </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Over 500 web projects of
                                                diverse complexity have been sold by us to various business sectors. The
                                                significant majority of our clients are now regulars. We have a team of project
                                                managers, designers, internet marketers, coders, programmers, content editors,
                                                testers, and other professionals. Our experts have experience, which certifies
                                                their ability to build websites that work for our clients.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Web Development </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>We offer bespoke website
                                                development for social networking, corporate websites, e-commerce websites,
                                                information portals, and online portals of any complexity. Our business is
                                                centered on providing each client with individualized attention and productive
                                                collaboration. We have created dynamic websites for many years. All portals and
                                                websites are made with well-known open-source software.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Web Maintenance </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>There are many circumstances
                                                during site maintenance or promotion where your site will need technical help or
                                                have questions that need to be answered, but you don't have the time or
                                                knowledge to do it. By working with us, you will have access to a group of
                                                experts whose tasks are managed by a dedicated project manager. Since everything
                                                will be handled swiftly, you won't need to worry about this part of your
                                                business.</p>
                                        </div>
                                    </div>
                                </div>
                                <h1 className='text-[32px] font-semibold my-3 heading'>What are The Benefits When You Engage
                                    with Us?</h1>
                                <p className='text-[18px] font-normal text-[#636363] '><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>At a reasonable cost, Bonafide
                                    Technologies provides the greatest solutions. With years of expertise working on challenging
                                    projects, our talented team of specialists can provide top-notch solutions and support your
                                    success.</p>
                                <p className='text-[18px] font-normal text-[#636363] '><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>We are a seasoned SEO company in Chennai
                                    with a long list of satisfied clients who have successfully increased their internet
                                    visibility through SEO. To demonstrate the success we have had on Google, we have a ton of
                                    SEO case studies. Our professional SEO advice is based on the past performance of your
                                    website. We'll figure out how to optimize your website so it can compete in Google's organic
                                    search results. </p>
                                <p className='text-[18px] font-normal text-[#636363] '><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>We provide reasonably priced SEO packages
                                    to suit the needs of both small and large enterprises. Examine the favorable SEO
                                    testimonials regarding our SEO offerings and add your website to the rapidly expanding list
                                    of Google first-page rankings in our portfolio. </p>
                                <p className='text-[18px] font-normal text-[#636363] '><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>We are a seasoned SEO company in Chennai
                                    with a long list of satisfied clients who have successfully increased their internet
                                    visibility through SEO. To demonstrate the success we have had on Google, we have a ton of
                                    SEO case studies. Our professional SEO advice is based on the past performance of your
                                    website. We'll figure out how to optimize your website so it can compete in Google's organic
                                    search results. </p>
                                <p className='text-[18px] font-normal text-[#636363] '><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>We provide reasonably priced SEO packages
                                    to suit the needs of both small and large enterprises. Examine the favorable SEO
                                    testimonials regarding our SEO offerings and add your website to the rapidly expanding list
                                    of Google first-page rankings in our portfolio. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Chennai
