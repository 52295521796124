import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Dombivli() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{
                backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)" }}>
                <div className="container mx-auto py-12">
                    <h1 className='text-center text-white text-[32px] md:text-[52px]  font-semibold heading uppercase'>
                        SEO Company in Dombivli
                    </h1>
                </div>
            </div>
        
            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[100%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'> SEO Company in Dombivli</h1>
                                <p className='text-[18px] font-normal text-[#636363] '><strong>Revolutionize Your Brand With Bonafide Technologies</strong></p>
                                <p className='text-[18px] font-normal text-[#636363] '>Due to the increasing usage of the Internet, company owners now have countless ways to offer their products and services to Internet users. This is the point at which SEO becomes useful. SEO is a type of online advertising that increases a website's visibility to the intended audience. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>Here at Bonafide Technologies, influence, and innovation come together. As your committed partner in digital marketing, we are experts in developing unique plans that boost online visibility, elevate brands, and propel company expansion. We empower your digital journey with state-of-the-art solutions catered to your specific demands, from SEO to social media marketing.</p>
                                <br />
                                <h1 className='text-[32px] font-semibold my-3 heading'>Why Choose Us</h1>
        
                                <p className='text-[18px] font-normal text-[#636363] '><strong>Unleash Your Company's Potential in the Digital Age!</strong></p>
                                <p className='text-[18px] font-normal text-[#636363] '>White hat SEO services from Bonafide Technologies, the top SEO company in Dombivli, can raise your company's website's ranking. Hiring our SEO Company in Dombivli is essential if you want people to find your website and encourage the expansion of your company. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>Among the numerous things that differentiate us from other SEO Companies in Dombivli are our credibility, dedication, and top-notch services. Our exceptionally skilled and knowledgeable SEO experts do all possible to increase your company's revenue.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>Bonafide Technologies is available to help with a range of well-liked choices. Whether you require assistance with digital marketing, lead generation, branding, social media marketing, online marketing, SEO, PPC, paid marketing, or other areas, additionally, we can assist with digital platform advertising on Facebook, Instagram, Youtube, LinkedIn, and other major platforms. We have experience working with a variety of industries, including Education, Travel, Automotive, Security, and Jewelry.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>Look through our portfolio to see the digital success stories we've created for companies just like yours. Our work speaks eloquently about our commitment to excellence, from creative marketing to exquisite designs.</p>
                            </div>
                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Our Digital Marketing Services</h1>
                                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                                    <div className="flex flex-wrap justify-between">
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Lead Generation
                                            </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Generate Leads to Increase Sales</p>
                                                <p className='text-[18px] font-normal text-[#636363] '>In a brief amount of time, your company will generate new leads, calls, and money thanks to our expert lead-generation digital marketing services.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Content Writing Service</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Creating captivating stories that captivate and motivate your audience is known as content marketing.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Social Media Marketing (SMM)</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Connect, Entail, and Retain Customers instead of an Audience
                                            </p>
                                            <p className='text-[18px] font-normal text-[#636363] '>We have the know-how to establish, maintain, and strengthen your brand's connections with fans, users, visitors, etc., to expand your company.
                                            </p>
                                        </div>
                                        
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Search Engine Optimisation (SEO)</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Boost Free Visits, Leads, and Conversions </p>
                                            <p className='text-[18px] font-normal text-[#636363] '>Our SEO specialists will raise the rating of your website by utilizing only the most technologically advanced digital marketing services, ensuring that potential clients find you online before your rivals.</p>
                                        </div>
                                    </div>
                                </div>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Contact Us Right Away!</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Start the process of redefining your brand narrative through a digital makeover. Let Bonafide Technologies be your growth partner for digital marketing solutions, where every question is a chance to help create a prosperous future. Our motivation comes from your success; let's create it together. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        )
}

export default Dombivli
