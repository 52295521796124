import React, { useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';


function ProposalForm() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    company: '',
    url: '',
    comment: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    phone: '',
    company: '',
    url: '',
    comment: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: '' }); // Clear the error when the user starts typing
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };

    Object.entries(formData).forEach(([key, value]) => {
      if (value.trim() === '') {
        newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required`;
        valid = false;
      }
    });

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const response = await fetch('http://localhost:5007/api/submitProposal', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });

        if (response.ok) {
          console.log('Proposal submitted successfully!');
          alert('Form submitted successfully!');
          navigate("/");
        } else {
          console.error('Failed to submit proposal');
          // You can add additional logic here (e.g., show an error message)
        }
      } catch (error) {
        console.error('Error submitting proposal', error);
      }
    } else {
      console.log('Form validation failed');
    }
  };

  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="container mx-auto py-24">
          <div className="flex justify-between flex-wrap">
            <div className="w-full lg:w-[50%] px-5 lg:px-10">
              <h1 className="text-[22px] lg:text-[46px] font-medium heading text-center">
                Request a FREE Proposal Now!
              </h1>
              <section className="mt-5">
                <div className="flex flex-col items-center justify-center mx-auto">
                  <div className="w-full bg-white rounded-lg darks:border ">
                    <div className="">
                      <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                        <div>
                          <label
                            htmlFor="name"
                            className="block mb-2 text-md font-medium text-gray-900 darks:text-white"
                          >
                            Full Name <span className="text-red-600"> *</span>
                          </label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            className={`bg-gray-50 border border-[#222] text-gray-900 sm:text-md rounded-lg focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500 ${errors.name && 'border-red-500'
                              }`}
                            placeholder="Your Name"
                            onChange={handleChange}
                            required
                          />
                          {errors.name && (
                            <p className="text-red-500 text-sm mt-1">{errors.name}</p>
                          )}
                        </div>

                        <div>
                          <label
                            htmlFor="email"
                            className="block mb-2 text-md font-medium text-gray-900 darks:text-white"
                          >
                            Email Address <span className="text-red-600"> *</span>
                          </label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            className={`bg-gray-50 border border-[#222] text-gray-900 sm:text-md rounded-lg focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500 ${errors.email && 'border-red-500'
                              }`}
                            placeholder="name@company.com"
                            onChange={handleChange}
                            required
                          />
                          {errors.email && (
                            <p className="text-red-500 text-sm mt-1">{errors.email}</p>
                          )}
                        </div>

                        <div>
                          <label
                            htmlFor="phone"
                            className="block mb-2 text-md font-medium text-gray-900 darks:text-white"
                          >
                            Phone <span className="text-red-600"> *</span>
                          </label>
                          <input
                            type="text"
                            name="phone"
                            id="phone"
                            className={`bg-gray-50 border border-[#222] text-gray-900 sm:text-md rounded-lg focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500 ${errors.phone && 'border-red-500'
                              }`}
                            placeholder="1236547896"
                            onChange={handleChange}
                            required
                          />
                          {errors.phone && (
                            <p className="text-red-500 text-sm mt-1">{errors.phone}</p>
                          )}
                        </div>

                        <div>
                          <label
                            htmlFor="company"
                            className="block mb-2 text-md font-medium text-gray-900 darks:text-white"
                          >
                            Company <span className="text-red-600"> *</span>
                          </label>
                          <input
                            type="text"
                            name="company"
                            id="company"
                            className={`bg-gray-50 border border-[#222] text-gray-900 sm:text-md rounded-lg focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500 ${errors.company && 'border-red-500'
                              }`}
                            placeholder="Your Company"
                            onChange={handleChange}
                            required
                          />
                          {errors.company && (
                            <p className="text-red-500 text-sm mt-1">{errors.company}</p>
                          )}
                        </div>

                        <div>
                          <label
                            htmlFor="url"
                            className="block mb-2 text-md font-medium text-gray-900 darks:text-white"
                          >
                            URL <span className="text-red-600"> *</span>
                          </label>
                          <input
                            type="text"
                            name="url"
                            id="url"
                            className={`bg-gray-50 border border-[#222] text-gray-900 sm:text-md rounded-lg focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500 ${errors.url && 'border-red-500'
                              }`}
                            placeholder="https://example.com"
                            onChange={handleChange}
                            required
                          />
                          {errors.url && <p className="text-red-500 text-sm mt-1">{errors.url}</p>}
                        </div>

                        <div>
                          <label
                            htmlFor="comment"
                            className="block mb-2 text-md font-medium text-gray-900 darks:text-white"
                          >
                            Comment <span className="text-red-600"> *</span>
                          </label>
                          <textarea
                            name="comment"
                            id="comment"
                            className={`bg-gray-50 border border-[#222] text-gray-900 sm:text-md rounded-lg focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500 ${errors.comment && 'border-red-500'
                              }`}
                            placeholder="Hi there, I would like to ..."
                            onChange={handleChange}
                            required
                          />
                          {errors.comment && (
                            <p className="text-red-500 text-sm mt-1">{errors.comment}</p>
                          )}
                        </div>

                        <button
                          type="submit"
                          className="w-full bg-[#222] text-white focus:outline-none font-medium rounded-lg text-md px-5 py-2.5 text-center darks:bg-primary-600 darks:hover:bg-primary-700 darks:focus:ring-primary-800"
                        >
                          Get My Custom Quote
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="w-full lg:w-[50%] p-10 bg-cover bg-center rounded-2xl h-[350px] lg:h-auto mt-24 lg:mt-0" style={{ backgroundImage: "url(img/Proposalimg.jpg)" }}>

            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ProposalForm;
