import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Hyderabad() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{
                backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)" }}>
                <div className="container mx-auto py-12">
                    <h1 className='text-center text-white text-[32px] md:text-[52px]  font-semibold heading uppercase'>
                        Best SEO Company in Hyderabad
                    </h1>
                </div>
            </div>
        
            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[100%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Best SEO Company in Hyderabad</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Due to the current switch towards
                                    digitization, having a strong online presence is mandatory. Even if brands intend to grow,
                                    the internet has changed everything.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>As one of the top SEO service providers
                                    in Hyderabad, Bonafide Technologies helps its clients establish realistic and practical
                                    exposure plans. We are passionate about improving websites' rankings in search engine
                                    results! For every client, our SEO agency specialists provide the best possible plan. We
                                    tailor a strategy to meet your traffic asset goals! For your online activity to be
                                    successful and long-lasting, organic reference is essential! Thus, it's crucial to work with
                                    the top SEO company in Hyderabad, which uses the latest techniques in the digital world!</p>
                                <p className='text-[18px] font-normal text-[#636363] '>Ranking #1 on Google is more than just a
                                    benefit for your business. Useless keywords should not be used solely to rank first. We only
                                    wish to provide our future customers with excellent visibility! After gathering all the
                                    content you provide, our SEO specialists prepare the tastiest meal for you. We add the last
                                    touch to help you achieve your goals because we can support and believe in you.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>Consumers who spend the majority of their
                                    time on laptops and mobile phones have made Google searches a habit. Whether looking for the
                                    best SEO services in Hyderabad or the best car mechanic in town, search engine results
                                    typically point users toward particular companies! Organic lead generation is achieved
                                    through the use of search engine optimization. The ideal lead generation and branding
                                    solution is to use SEO techniques correctly. The top SEO strategies created by our committed
                                    SEO specialists at Bonafide Technologies, the top SEO company in Hyderabad, ensure that your
                                    company generates the most leads possible. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>For all SEO services, Bonafide
                                    Technologies is the ideal choice if you're searching for a top SEO company in Hyderabad.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>Utilizing the top SEO company in
                                    Hyderabad, you must be in front of users' eyes and appear higher on search engine result
                                    pages to reach your target clients and grab their attention. This is precisely the point at
                                    which SEO enters the picture. Bonafide Technologies provides powerful and complete solutions
                                    for search engine optimization, brand visibility, and free SEO review services. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>Speaking of SEO services in Hyderabad,
                                    the top SEO company in Hyderabad, Bonafide Technologies has a proven track record of giving
                                    its clients the finest possible experience and assisting them in achieving many results from
                                    the top advertising agency in Hyderabad.</p>
                                <br />
        
        
        
                            </div>
                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>HOW WE WORK IN SEO</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>The methods that have been in use for
                                    years—and resistors retain all of their effectiveness—determine your website's reputation.
                                    We will recommend your website and make the necessary, quantifiable changes, both big and
                                    small.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>Our Hyderabad-based SEO services company
                                    takes the following actions:</p>
                                <br />
                                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                                    <div className="flex flex-wrap justify-between">
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Conduct an SEO Audit
                                            </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>An SEO audit measures your
                                                website's technical aspects and onsite and offsite audits. Our SEO services firm
                                                in Hyderabad looks at your website's advantages and disadvantages to give you a
                                                list of carefully considered pledges.
                                            </p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Strategy</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Strategies are worthy of
                                                diligence! Our organic referencing strategy often creates a strategic action
                                                plan to raise your Google ranks. The following three categories of actions were
                                                completed: technical, offsite, and onsite. Our SEO team practices concepts to
                                                make your business stand out in the marketplace.
                                            </p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Optimizing Content</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>"Web content optimization" is
                                                a crucial one that enhances consumer reputation and search engine ranking. The
                                                SEO dealer in Hyderabad offers updated, high-quality content that consistently
                                                brings in new clients.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Position Monitoring</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>In the interim, we track
                                                optimizations and report on shifts in a site's Google position. Due to time
                                                constraints, we collaborate with you on the website in an orderly fashion,
                                                ensuring every SEO is noticed. Additionally, it arranges frequent training
                                                sessions to instruct you on proper forms and best practices.</p>
                                        </div>
                                    </div>
                                </div>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Get Free Consultation From Our SEO
                                    Experts!</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Tell us about your needs and query, and
                                    we'll schedule a call so you can speak with one of our specialists! Provide you with an
                                    organic SEO solution that will enable you to increase revenue while staying within your
                                    budget. Get free project advice from our SEO services experts right now.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        )
}

export default Hyderabad