import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Houston() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{
                backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)" }}>
                <div className="container mx-auto py-12">
                    <h1
                        className='text-center text-white text-[32px] md:text-[52px]  font-semibold heading uppercase'>
                        SEO Company in Houston
                    </h1>
                </div>
            </div>
        
            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[100%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>SEO Company in Houston</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>The need for SEO services is rising, and this is leading to a massive boom in SEO companies. With every new day comes an increase in the popularity of search engine optimization. With its tried-and-true methods and strategies, Bonafide Technologies will undoubtedly raise the ranking of your website. Our clientele comes from a variety of nations and industry areas. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>We provide ROI-driven SEO services to all kinds of organizations. We can assist you in boosting website traffic and organic visibility.</p><br />

                                <h1 className='text-[32px] font-semibold my-3 heading'>Why You Need Search Engine Optimization</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>The process of optimizing a website is continuous, which is why working with a top-notch digital marketing company like Bonafide Technologies is recommended. Our SEO marketing team is thrilled to support the growth of your company. For your success, we only use the most effective and goal-oriented SEO techniques. Your conversion rate will rise with our comprehensive marketing plan. </p>

                                <h1 className='text-[32px] font-semibold my-3 heading'>Why Choose Us</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Use our website design and SEO company to increase your clientele, leads, and sales!</p>
                                <br />
                                <p className='text-[18px] font-normal text-[#636363] '>Our team of Houston SEO specialists wants to improve your website so that it appears on search engine results pages, attracts visitors, and eventually turns leads into customers with just one search!</p>
                                <p className='text-[18px] font-normal text-[#636363] '>Being your one-stop shop for all things internet is something we take great pride in. However, components like Pay-Per-Click (PPC) advertising and unique online applications are also necessary for a truly effective campaign. </p>
                                <br />
                                <p className='text-[18px] font-normal text-[#636363] '>Locally, We Improve Your Visibility And Immediately Address Urgent Issues. We make sure that business owners, marketers, entrepreneurs, and others profit by constantly disseminating our most recent tactics and research to the sector.  </p>
                                <p className='text-[18px] font-normal text-[#636363] '>We have finished more than a thousand web projects for various businesses all around the world. Guaranteed improved search engine placement for numerous websites and the ability to quickly resolve complex website issues.</p>
                                <br />
                            </div>
                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>What We Offer In Our SEO Services</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Complete online solutions help improve your website's visibility, solve problems, produce more leads, raise sales, and build brand awareness.</p>
                                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                                    <div className="flex flex-wrap justify-between">
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Keyword Research
                                            </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>We conduct in-depth keyword research to find the most relevant and effective keywords to achieve your company's objectives. This ensures that your SEO strategy will generate targeted traffic.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>On-Page Optimization</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Our team of experts carefully optimizes every web page, making sure that all of the meta tags, content, multimedia, and overall structure are optimized for better search exposure.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Off-Page Optimization</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>We increase the authority of your website through smart alliances, social signals, and high-quality backlinks, resulting in a strong off-page SEO plan that supports your on-page work.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Technical SEO</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>The technical parts of SEO are handled by our professionals, who also make sure that search engine guidelines are followed, loading speeds are quick, and mobile responsiveness is at its best.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Content Strategy</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>We develop interesting, educational, and search engine optimization-friendly material that connects with your audience, naturally including keywords and cultivating a devoted following.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Analytics and Reporting</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Our thorough analytics and reporting offer perceptions into user behavior, conversion rates, and performance, facilitating ongoing development and quantifiable success.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Local SEO</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>We help you reach clients in Houston by making your website more visible for local searches. This increases local engagement and makes your company stand out.</p>
                                        </div>
                                    </div>
                                </div>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Let's Work Together</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Please get in touch with us and provide some information about your goals and how we can support you. We are excited to speak with you!</p> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        )
}

export default Houston