import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { Link } from "react-router-dom";

function ClassifiedAdsWebsites() {
    return (
        <>
            <Header />
            <div className="container-fluid p-3 lg:p-0">
                <div className="container mx-auto py-12">
                    <h1 className='text-[28px] md:text-[42px] font-medium heading  mb-8 '> Free Classified Submission Sites List
                        in 2024</h1>
                    <div className="flex flex-wrap md:flex-nowrap pt-12 border-t-2">
                        <div className="w-full md:w-[70%] p-10 box-shadow mr-10 rounded-lg">
                            <div className="text_contenr pt-3 pb-8">
                                <p className='text-[18px] font-normal text-[#636363] '>Submitting classified ads is one of the
                                    most effective ways to promote your company and services both locally and globally.</p>
                                <br />

                                <p className='text-[18px] font-normal text-[#636363] '>It will help you to make sales of your
                                    goods or services and has SEO techniques for directing prospective customers to your
                                    website.</p> <br />

                                <p className='text-[18px] font-normal text-[#636363] '>You can easily display adverts for your
                                    products or services on several classified sites and directories available on the internet.
                                </p> <br />

                                <p className='text-[18px] font-normal text-[#636363] '>Additionally, it has made posting
                                    classified advertising to free classified directories a highly valued service for search
                                    engine optimization of websites.</p> <br />

                                <p className='text-[18px] font-normal text-[#636363] '>Promoting your products or services has
                                    never been easier thanks to classified Ads websites. The reason for this is that you can
                                    create a virtual nest for your business and quickly target potential clients online by
                                    posting a classified ad for your good or service on this platform.</p> <br />

                                <p className='text-[18px] font-normal text-[#636363] '>Would you like to discover which list of
                                    free classified submission sites to use to maximize sales for your company? We will also
                                    tell you everything there is to know about classified submission sites. If you want to
                                    promote your product or service as effectively as possible, all you have to do is follow
                                    this post.</p> <br />

                                <h1 className='text-[32px] font-medium my-3 heading'> What are Classified Submission Sites?</h1>
                                <br />

                                <p className='text-[18px] font-normal text-[#636363] '>Posting your advertising campaign on
                                    classified submission sites is known as classified submission. It is among the greatest
                                    methods to make your good or service more visible in different search engines.</p> <br />

                                <p className='text-[18px] font-normal text-[#636363] '>You can advertise your goods and services
                                    by posting them on classified submission sites. Classified ads submission was traditionally
                                    obtained from publications such as newspapers, pamphlets, magazines, etc. Nonetheless,
                                    individuals are living in the digital marketing realm in the internet world. Furthermore, as
                                    technology is the main component of the digital world, the demand for classified submission
                                    sites has grown significantly in the last several years.</p> <br />

                                <p className='text-[18px] font-normal text-[#636363] '>Additionally, these websites create a
                                    strong commercial dialogue by offering a wonderful way to interact with both sellers and
                                    buyers. While buyers visit sellers' ad submission sites to purchase specific goods or
                                    services, sellers list their goods and services there. With efficient email or direct phone
                                    calls, it is a great way for both to increase the leads for your business.</p> <br />

                                <h1 className='text-[32px] font-medium my-3 heading'> How Do Classified Submission Sites Work?
                                </h1><br />

                                <p className='text-[18px] font-normal text-[#636363] '>The way classified submission sites
                                    operate is by letting users publish advertisements for goods and services. The
                                    advertisements are typically arranged according to a particular category, which facilitates
                                    users in finding what they're looking for. Users can search for adverts using keywords or
                                    browse through the categories to locate what they need.</p> <br />

                                <p className='text-[18px] font-normal text-[#636363] '>An advertisement that has been uploaded
                                    will be seen by everyone who visits the website. In addition, some classified posting
                                    services could provide extra features like the opportunity to upload images or videos to
                                    your ad or the choice to upgrade to a premium listing for increased exposure.</p> <br />

                                <h1 className='text-[32px] font-medium my-3 heading'> Significance of Free Classified Submission
                                    Sites
                                </h1><br />

                                <ul className='bg-[#f4dacd70] p-8'>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Makes it Easier to Reach Out
                                        Globally:</strong>
                                        Reaching out to a wide range of readers, even globally, has become simple with the aid
                                        of classified submission websites. Because of this, several online retailers have
                                        increased their sales by listing their goods and services on these platforms. First and
                                        foremost, classified advertising increases your product's visibility in the
                                        international market, which in turn increases sales.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>User-Friendly:</strong> It's
                                        incredibly easy to post classified advertisements on submission sites. Because it is
                                        search engine friendly, posting your goods or services doesn't take a lot of
                                        understanding. Additionally, sharing these advertisements on social media is a breeze.
                                        This is an awesome thing right now! You may attract large audiences' attention by
                                        advertising your good or service on social media sites. It's one of the factors that
                                        makes classified advertisements superior to regular ones.
                                    </li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Compose Ads Swiftly:</strong>
                                        Classified submission websites facilitate the creation of adverts quickly. A lot of
                                        tools make it easy and quick to draft classified advertising. This implies you won't
                                        have to pay more to have professionals make your advertisements.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Suitable for
                                        Everyone:</strong>The classified advertisements are suitable for all types of
                                        businesses, regardless of size. The ability for business owners to publish their
                                        advertising according to the category of the good or service they are offering, coupled
                                        with an attention-grabbing headline and main keywords, is another fantastic feature of
                                        classified ads.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span> Free of Cost:</strong> There
                                        is no charge for creating and publishing classified advertising. While not all of them
                                        are free, the majority are. OLX, Click.in, Quickr, and other platforms allow business
                                        owners to post their websites for free to increase brand visibility.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span> Increases Traffic and
                                        Sales:</strong>The probability of sales will rise in tandem with an increase in
                                        website traffic. More website visitors indicate that more people are interested in
                                        purchasing your goods or services. Blogging about your goods or services is one of the
                                        finest techniques to get more visitors. From an SEO standpoint, publishing blogs as
                                        classified advertisements will undoubtedly improve traffic, generate leads for your
                                        company, and raise your search engine ranking.</li>
                                    <br />
                                </ul>

                                <h1 className='text-[32px] font-medium my-3 heading'> Things to Consider While Posting Your
                                    Classified Ad
                                </h1><br />
                                <p className='text-[18px] font-normal text-[#636363] '>It is crucial to take into account the
                                    following factors when selecting from the 2021 list of free classified submission sites to
                                    achieve the best possible outcome:</p> <br />

                                <ul className='bg-[#f4dacd70] p-8'>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Select Among the Best Free
                                        Classified Submission Sites List:</strong>
                                        Selecting a free classified that meets all of your troubleshooting requirements is one
                                        of the most important things to figure out. After you've determined that a certain
                                        submission site offers goods and services related to your business category, make sure
                                        you select the classified submission site. Using backlinks to enhance website traffic
                                        will be a cinch for you as a business owner</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Give an Impeccable
                                        Title:</strong>The next crucial step is to write an enticing ad title after
                                        selecting the best-classified submission site. Initially capturing the customer's
                                        attention will be beneficial. Establishing a strong impression on the audience is mostly
                                        dependent on the title. Research indicates that in classified ads, the title might have
                                        a 60% greater impact.
                                    </li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Choosing an Appropriate
                                        Category:</strong>
                                        Selecting a category unrelated to your goods can prevent you from reaching the intended
                                        audience and from benefiting from those advertisements. Therefore, when you post your
                                        classified ad on these websites, make sure you select the appropriate product category.
                                    </li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Concise Product
                                        Description:</strong>Give a brief product description that explains the nature of
                                        your product or service. Make sure the product description is never too long (risking
                                        losing the consumer's interest) or too brief (preventing the customer from understanding
                                        the purpose of your offering). To draw in more customers, you must provide a succinct
                                        and accurate description of your goods. An optimal product description should not exceed
                                        50–70 words in length.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span> Keywords and
                                        Links:</strong>Your website and product can improve their search engine position if
                                        you use the appropriate keywords and linkages to business websites. Many keyword
                                        planners assist retailers by giving them a list of keywords that are highly search
                                        engine-optimized.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span> Upload Relevant
                                        Pictures:</strong>Your advertisement will be more visible if you use high-definition
                                        photos of your goods. It will be ideal, though, if the images you click are authentic
                                        and unaltered—rather than animated or Photoshopped.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span> Improve the Visibility of
                                        Your Classified Ad:</strong>After posting your classified ad on the submission site,
                                        the next step is to share it on social media platforms like Facebook, Instagram,
                                        Twitter, and so on to boost the ad's visibility. After promoting their advertisements on
                                        popular social media platforms for their particular business promotion, several firms
                                        have seen amazing outcomes.</li>
                                    <br />
                                </ul>
                                <h1 className='text-[32px] font-medium my-3 heading'> Process of Posting Classified Ads on
                                    Submission Sites
                                </h1><br />

                                <p className='text-[18px] font-normal text-[#636363] '>It can be difficult to select from the
                                    list of free classified submission sites. But if you follow our guidance, finding the
                                    perfect one that meets all of your needs will be a breeze.</p> <br />

                                <p className='text-[18px] font-normal text-[#636363] '>The procedure that is followed on all
                                    submission sites when you post a classified ad is mentioned below:</p> <br />

                                <ul className='bg-[#f4dacd70] p-8'>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span></strong>
                                        To use Google, Yahoo, or any other search engine, you must have a registered email
                                        address.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span></strong>When registering for
                                        a classified submission site, use the same email address.
                                    </li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span></strong>
                                        Select "Post Classified" or "Place a New Classified."
                                    </li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span></strong>Based on the type of
                                        your company, select the listing category that is right for you.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span></strong>The next step is to
                                        decide where you want the traffic to come from.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span></strong>Make sure the
                                        product/service's title, description, pricing, and other data are appealing.
                                    </li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span></strong>Make your
                                        advertisement easier to find by including a relevant term in the headline.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span></strong>One must maintain
                                        constant focus when addressing one's target audience.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span></strong>Entering your
                                        personal information, such as your name, phone number, email address, etc., is the last
                                        step.
                                    </li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span></strong>Always give your
                                        advertisement a preview. Next, make it available for publication.</li>
                                    <br />
                                </ul>
                                <h1 className='text-[32px] font-medium my-3 heading'> Top 10 Classified Submission Sites for
                                    2024</h1>
                            </div>
                            <div class="relative overflow-x-auto shadow-md sm:rounded-lg ">
                                <table class="w-full text-sm text-left rtl:text-right text-gray-500 darks:text-gray-400">
                                    <thead
                                        class="text-xs text-gray-700 uppercase bg-gray-50 darks:bg-gray-700 darks:text-gray-400">
                                        <tr>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                SNo.
                                            </th>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                Classified Sites
                                            </th>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                DA
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://craiglist-https//www.craigslist.org"><span>Craiglist-https://www.craigslist.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>88</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>2</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://gumtree-https//www.gumtree.com"><span>Gumtree-https://www.gumtree.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>83</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>3</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://sulekha-https//www.sulekha.com"><span>Sulekha-https://www.sulekha.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>78</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>4</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://justlanded-https//www.justlanded.com"><span>Justlanded-https://www.justlanded.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>76</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>5</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://classifiedads-https//www.classifiedads.com"><span>Classifiedads-https://www.classifiedads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>63</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://expat-https//www.expat.com"><span>Expat-https://www.expat.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://oodle-https//www.oodle.com"><span>Oodle-https://www.oodle.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>58</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://adpost-https//www.adpost.com"><span>Adpost-https://www.adpost.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://hoobly-https//www.hoobly.com"><span>Hoobly-https://www.hoobly.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>54</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://locanto-https//www.locanto.com"><span>Locanto-https://www.locanto.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>46</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <h1 className='text-[32px] font-medium my-3 heading'> Free Classified Submission Sites List 2024
                            </h1>
                            <div class="relative overflow-x-auto shadow-md sm:rounded-lg ">
                                <table class="w-full text-sm text-left rtl:text-right text-gray-500 darks:text-gray-400">
                                    <thead
                                        class="text-xs text-gray-700 uppercase bg-gray-50 darks:bg-gray-700 darks:text-gray-400">
                                        <tr>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                SNo.
                                            </th>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                Classified Sites
                                            </th>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                DA
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://classifieds.usatoday.com/"><span>http://classifieds.usatoday.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>94</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>2</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://classifieds.al.com/"><span>http://classifieds.al.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>89</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>3</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://dubai.craigslist.org/"><span>https://dubai.craigslist.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>88</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>4</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://london.craigslist.org/"><span>https://london.craigslist.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>88</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>5</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://bangkok.craigslist.org/"><span>https://bangkok.craigslist.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>88</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.iol.co.za/classifieds"><span>https://www.iol.co.za/classifieds</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>88</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://singapore.craigslist.org/"><span>https://singapore.craigslist.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>88</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://buzzon.khaleejtimes.com/"><span>https://buzzon.khaleejtimes.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>86</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.gumtree.com/"><span>https://www.gumtree.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>83</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://us.classifieds.sulekha.com/"><span>http://us.classifieds.sulekha.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>78</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.justlanded.com/"><span>https://www.justlanded.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>76</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.justlanded.com/"><span>https://www.justlanded.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>76</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.justlanded.com/english/Singapore/Classifieds"><span>https://www.justlanded.com/english/Singapore/Classifieds</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>76</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://classifieds.thaivisa.com/"><span>https://classifieds.thaivisa.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>74</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.trovit.com/"><span>https://www.trovit.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>72</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.gumtree.co.za/"><span>https://www.gumtree.co.za</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>67</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://thethaiger.com/classifieds"><span>https://thethaiger.com/classifieds</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>66</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.classifiedads.com/"><span>https://www.classifiedads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>63</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.classifiedads.com/"><span>https://www.classifiedads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>63</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://uae.dubizzle.com/"><span>https://uae.dubizzle.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://dubai.dubizzle.com/classified"><span>https://dubai.dubizzle.com/classified</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.expat.com/en/classifieds/asia/thailand"><span>https://www.expat.com/en/classifieds/asia/thailand</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://za.postadverts.com/en"><span>https://za.postadverts.com/en</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://sg.postadverts.com/en"><span>https://sg.postadverts.com/en</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.takarat.com/"><span>http://www.takarat.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>60</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.oodle.com/regions/usa"><span>https://www.oodle.com/regions/usa</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>58</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://showme.co.za/classifieds"><span>https://showme.co.za/classifieds</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>58</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.kahi.in/"><span>http://www.kahi.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>57</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.advertigo.net/"><span>http://www.advertigo.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.advertigo.net/"><span>https://www.advertigo.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.adpost.com/us"><span>https://www.adpost.com/us</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://classifieds.singaporeexpats.com/"><span>https://classifieds.singaporeexpats.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.singaporeexpats.com/"><span>https://www.singaporeexpats.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>34</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.adpost.com/sg"><span>https://www.adpost.com/sg</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.postonlineads.com/"><span>http://www.postonlineads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>36</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.expatriates.com/"><span>https://www.expatriates.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>37</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.expatriates.com/classifieds/thailand"><span>https://www.expatriates.com/classifieds/thailand</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.expatriates.com/classifieds/singapore"><span>https://www.expatriates.com/classifieds/singapore</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>39</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adproceed.com/"><span>http://www.adproceed.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>54</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>40</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.advertisingflux.com/"><span>http://www.advertisingflux.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>54</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>41</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.uaelinks.com/"><span>http://www.uaelinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>54</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>42</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://advertisingflux.com/"><span>https://advertisingflux.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>54</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>43</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://mystify.co.za/"><span>http://mystify.co.za</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>54</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>44</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freeclassifieds4u.in/"><span>http://www.freeclassifieds4u.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>53</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>45</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://farangmart.co.th/"><span>https://farangmart.co.th</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>53</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>46</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.classads.co.za/"><span>https://www.classads.co.za</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>53</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>47</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.olx.co.za/"><span>https://www.olx.co.za</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>52</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>48</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.globaladstorm.com/c/singapore"><span>https://www.globaladstorm.com/c/singapore</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>52</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>49</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.global-free-classified-ads.com/"><span>http://www.global-free-classified-ads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>50</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>50</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://ae.global-free-classified-ads.com/"><span>https://ae.global-free-classified-ads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>50</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>51</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://usa.global-free-classified-ads.com/"><span>https://usa.global-free-classified-ads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>50</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>52</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.global-free-classified-ads.com/"><span>https://www.global-free-classified-ads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>50</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>53</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://elclasificado.com/losangeles"><span>https://elclasificado.com/losangeles</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>50</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>54</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://thailand.global-free-classified-ads.com/"><span>https://thailand.global-free-classified-ads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>50</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.allyoucanread.com/"><span>https://www.allyoucanread.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>50</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://za.global-free-classified-ads.com/"><span>https://za.global-free-classified-ads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>50</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>57</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.junkmail.co.za/"><span>https://www.junkmail.co.za</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>50</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>58</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://singapore.global-free-classified-ads.com/"><span>https://singapore.global-free-classified-ads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>50</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>59</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://loot.com/"><span>https://loot.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>49</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>60</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adlandpro.com/"><span>http://www.adlandpro.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>47</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.locanto.com/"><span>https://www.locanto.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>46</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.pennysaverusa.com/"><span>https://www.pennysaverusa.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>46</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>63</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.clasf.co.za/"><span>https://www.clasf.co.za</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>46</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.tuffclassified.com/"><span>http://www.tuffclassified.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>44</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>65</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.rususa.com/classifieds"><span>http://www.rususa.com/classifieds</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>44</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>66</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://tuffclassified.com/"><span>https://tuffclassified.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>44</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>67</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://claz.org/"><span>https://claz.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>44</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>68</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://tuffclassified.com/"><span>https://tuffclassified.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>44</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>69</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://geebo.com/"><span>https://geebo.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>43</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>70</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.surfindia.com/"><span>http://www.surfindia.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>42</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>71</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.muamat.com/classifieds/-20_UAE"><span>https://www.muamat.com/classifieds/-20_UAE</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>42</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>72</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.buysellcommunity.com/"><span>http://www.buysellcommunity.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>42</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>73</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://muamat.com/"><span>https://muamat.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>42</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>74</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.releasemyad.com/"><span>http://www.releasemyad.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>41</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>75</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.usnetads.com/"><span>http://www.usnetads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>41</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>76</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.jobmail.co.za/"><span>https://www.jobmail.co.za</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>41</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>77</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.usnetads.com/"><span>http://www.usnetads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>41</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>78</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.gumtree.sg/"><span>https://www.gumtree.sg</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>41</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>79</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.ananzi.co.za/ads"><span>https://www.ananzi.co.za/ads</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>40</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>80</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.expatads.com/Singapore/19/Classifieds"><span>https://www.expatads.com/Singapore/19/Classifieds</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>40</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>81</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freead1.net/"><span>http://www.freead1.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>39</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>82</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.thisismyindia.com/"><span>http://www.thisismyindia.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>39</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>83</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://freead1.net/freeads/UAE:40"><span>https://freead1.net/freeads/UAE:40</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>39</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>84</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.ukclassifieds.co.uk/"><span>https://www.ukclassifieds.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>39</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>85</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.domesticsale.com/"><span>http://www.domesticsale.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>86</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.qwikad.com/"><span>http://www.qwikad.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>87</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.yellowpages.ae/"><span>https://www.yellowpages.ae</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>88</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.locanto.ae/"><span>https://www.locanto.ae</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>89</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.domesticsale.com/"><span>https://www.domesticsale.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>90</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.usa.freeclassifieds.com/"><span>http://www.usa.freeclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>91</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.usaonlineclassifieds.com/"><span>http://www.usaonlineclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>92</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://free-classifieds-usa.com/"><span>https://free-classifieds-usa.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>93</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://usa.freeclassifieds.com/default.aspx"><span>http://usa.freeclassifieds.com/default.aspx</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>94</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freeclassifieds.com/"><span>http://www.freeclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>95</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.canetads.com/"><span>http://www.canetads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>96</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.stclassifieds.sg/"><span>http://www.stclassifieds.sg</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>37</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>97</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.thefreeadforum.com/"><span>http://www.thefreeadforum.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>37</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>98</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.bedpage.com/"><span>http://www.bedpage.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>37</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>99</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.in.classi4u.com/"><span>http://www.in.classi4u.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>37</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>100</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.thefreeadforum.com/"><span>http://www.thefreeadforum.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>37</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>101</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://ae.classi4u.com/"><span>https://ae.classi4u.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>37</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>102</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.american-classifieds.net/"><span>http://www.american-classifieds.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>37</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>103</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://epage.com/js/epmain.jsp"><span>http://epage.com/js/epmain.jsp</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>37</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>104</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.stclassifieds.sg/"><span>https://www.stclassifieds.sg</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>37</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>105</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://in.classi4u.com/"><span>https://in.classi4u.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>37</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>106</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.kugli.com/"><span>http://www.kugli.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>36</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>107</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ukadslist.com/"><span>http://www.ukadslist.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>36</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>108</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.mitula.co.za/"><span>https://www.mitula.co.za</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>36</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>109</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ukadslist.com/"><span>http://www.ukadslist.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>36</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>110</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.cityclassify.com/"><span>http://www.cityclassify.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>111</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.eknazar.com/"><span>http://www.eknazar.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>112</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freeadstime.org/"><span>http://www.freeadstime.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>113</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.delhi-city.adeex.in/"><span>http://www.delhi-city.adeex.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>114</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freeadstime.org/"><span>http://www.freeadstime.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>115</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.mitula.in/"><span>http://www.mitula.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>116</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.freeadstime.org/"><span>https://www.freeadstime.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>117</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://classifiedsforfree.com/"><span>http://classifiedsforfree.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>118</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.th.locanto.asia/"><span>https://www.th.locanto.asia</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>119</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://thailand.freeadstime.org/"><span>https://thailand.freeadstime.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>120</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.freeadstime.org/"><span>https://www.freeadstime.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>121</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.freeadstime.org/"><span>https://www.freeadstime.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>122</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.getjob.us/"><span>http://www.getjob.us</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>123</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.freeadstime.org/"><span>https://www.freeadstime.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>124</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.adeex.in/"><span>https://www.adeex.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>125</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.webclassifieds.us/"><span>http://www.webclassifieds.us</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>34</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>126</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adsandclassifieds.com/"><span>http://www.adsandclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>34</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>127</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.askmeclassifieds.com/"><span>http://www.askmeclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>34</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>128</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freeadsbook.com/"><span>http://www.freeadsbook.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>34</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>129</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.quickfinds.in/"><span>http://www.quickfinds.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>34</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>130</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freeadsbook.com/"><span>http://www.freeadsbook.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>34</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>131</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.adsfare.com/uae/dubai"><span>https://www.adsfare.com/uae/dubai</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>34</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>132</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.hot-web-ads.com/"><span>http://www.hot-web-ads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>34</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>133</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://sg.claseek.com/"><span>https://sg.claseek.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>34</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>134</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.hot-web-ads.com/"><span>http://www.hot-web-ads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>34</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>135</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freeadsonline.biz/"><span>http://www.freeadsonline.biz</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>136</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.nullads.org/"><span>http://www.nullads.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>137</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.classifiedsciti.com/"><span>http://www.classifiedsciti.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>138</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.jobiba.com/"><span>http://www.jobiba.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>139</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.topclassifieds.com/"><span>http://www.topclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>140</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.classifiedsciti.com/"><span>http://www.classifiedsciti.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>141</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.globoanuncio.com/"><span>https://www.globoanuncio.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>142</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.oodle.co.uk/"><span>https://www.oodle.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>143</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.mitula.ae/"><span>https://www.mitula.ae</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>144</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.globoanuncio.com/"><span>https://www.globoanuncio.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>145</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.topclassifieds.com/ads"><span>https://www.topclassifieds.com/ads</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>146</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.trovit.co.za/"><span>https://www.trovit.co.za</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>147</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.jobiba.com/"><span>https://www.jobiba.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>148</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.topclassifieds.com/ads/-609"><span>https://www.topclassifieds.com/ads/-609</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>149</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.globalclassified.net/"><span>http://www.globalclassified.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>150</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.posthereads.com/"><span>http://www.posthereads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>151</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.classifieds4free.biz/"><span>http://www.classifieds4free.biz</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>152</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.sellbuystuffs.com/"><span>http://www.sellbuystuffs.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>153</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adsblue.com/"><span>http://www.adsblue.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>154</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adsyellowpages.com/"><span>http://www.adsyellowpages.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>155</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.flegoo.com/"><span>http://www.flegoo.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>156</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adsnity.com/"><span>http://www.adsnity.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>157</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.vivastreet.co.in/"><span>http://www.vivastreet.co.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>158</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.finderguru.com/"><span>http://www.finderguru.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>159</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.innetads.com/"><span>http://www.innetads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>160</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.vivastreet.co.in/"><span>http://www.vivastreet.co.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>161</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.finderguru.com/"><span>http://www.finderguru.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>162</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://uk.sellbuystuffs.com/"><span>http://uk.sellbuystuffs.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>163</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ablewise.com/"><span>http://ablewise.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>164</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.mourjan.com/ae/dubai/en"><span>https://www.mourjan.com/ae/dubai/en</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>165</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://adsnity.com/"><span>http://adsnity.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>166</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.locanto.co.za/"><span>https://www.locanto.co.za</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>167</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://gauteng.locanto.co.za/"><span>https://gauteng.locanto.co.za</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>168</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://sg.sellbuystuffs.com/"><span>http://sg.sellbuystuffs.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>169</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adsyellowpages.com/"><span>http://www.adsyellowpages.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>170</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.globalclassified.net/"><span>http://www.globalclassified.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>171</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.hotfreelist.com/"><span>http://www.hotfreelist.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>172</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.rackons.com/"><span>http://www.rackons.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>173</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.citynews.com/"><span>http://www.citynews.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>174</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.indiaconnect.com/"><span>http://www.indiaconnect.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>175</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.unolist.in/"><span>http://www.unolist.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>176</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.indiabook.com/"><span>http://www.indiabook.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>177</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.hotfreelist.com/"><span>https://www.hotfreelist.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>178</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.citynews.com/"><span>http://www.citynews.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>179</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adsriver.com/"><span>http://www.adsriver.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>180</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://unolist.in/"><span>http://unolist.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>181</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.hotfreelist.com/"><span>https://www.hotfreelist.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>182</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.classified4free.net/"><span>http://www.classified4free.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>183</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.koolbusiness.com/"><span>http://www.koolbusiness.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>184</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.koolbusiness.com/"><span>http://www.koolbusiness.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>185</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ibackpage.com/"><span>http://www.ibackpage.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>186</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.postfreeadshere.com/"><span>http://www.postfreeadshere.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>187</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.princeclassified.com/"><span>http://www.princeclassified.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>188</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freewebads.biz/"><span>http://www.freewebads.biz</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>189</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.postfreeadshere.com/"><span>http://www.postfreeadshere.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>190</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.abudhabiclassifieds.com/"><span>https://www.abudhabiclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>191</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.usedsandwell.co.uk/"><span>https://www.usedsandwell.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>192</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.adeex.co.uk/"><span>https://www.adeex.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>193</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adsglobe.com/"><span>http://www.adsglobe.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>194</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://malaysia.adhoards.com/"><span>https://malaysia.adhoards.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>195</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.phing.com/"><span>https://www.phing.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>196</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.classified4free.net/"><span>http://www.classified4free.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>197</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://eventsking.com/"><span>https://eventsking.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>198</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.99localads.com/"><span>http://www.99localads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>199</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.99localads.com/"><span>http://www.99localads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>200</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.beatyourprice.com/"><span>http://www.beatyourprice.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>201</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.web-free-ads.com/"><span>http://www.web-free-ads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>202</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freewebads.us/"><span>http://www.freewebads.us</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>203</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.admaya.in/"><span>http://www.admaya.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>204</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freeadsciti.com/"><span>http://www.freeadsciti.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>205</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.khojle.in/"><span>http://www.khojle.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>206</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.indiacatalog.com/"><span>http://www.indiacatalog.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>207</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adfreeposting.com/"><span>http://www.adfreeposting.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>208</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adfreeposting.com/"><span>http://www.adfreeposting.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>209</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.dewalist.co.in/"><span>http://www.dewalist.co.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>210</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.khojle.in/"><span>http://www.khojle.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>211</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.indiacatalog.com/"><span>http://www.indiacatalog.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>212</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://adfreeposting.com/"><span>http://adfreeposting.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>213</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.usadsciti.com/"><span>http://www.usadsciti.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>214</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.beatyourprice.com/"><span>https://www.beatyourprice.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>215</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.adsafrica.co.za/"><span>https://www.adsafrica.co.za</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>216</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.bahtsold.com/"><span>https://www.bahtsold.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>217</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.dewalist.co.in/"><span>http://www.dewalist.co.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>218</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.kippee.com/"><span>http://www.kippee.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>219</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freeclassifiedssites.com/"><span>http://www.freeclassifiedssites.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>220</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.oozz.in/"><span>http://www.oozz.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>221</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.10dayads.com/"><span>http://www.10dayads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>222</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.postallads4free.com/"><span>http://www.postallads4free.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>223</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freebestads.com/"><span>http://www.freebestads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>224</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.kippee.com/"><span>http://www.kippee.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>225</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://quickmarket.co.uk/"><span>https://quickmarket.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>226</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.postallads4free.com/"><span>https://www.postallads4free.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>227</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.chaosads.com/"><span>https://www.chaosads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>228</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://clickooz.com/"><span>http://clickooz.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>229</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://cityclassified.online/"><span>http://cityclassified.online</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>230</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.findinall.com/"><span>http://www.findinall.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>231</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.findinall.com/"><span>http://www.findinall.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>232</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ezclassifiedads.com/"><span>http://www.ezclassifiedads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>233</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.getadsonline.com/"><span>http://www.getadsonline.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>234</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adsciti.com/"><span>http://www.adsciti.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>235</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adskorner.com/"><span>http://www.adskorner.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>236</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.classtize.com/"><span>http://www.classtize.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>237</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://getthat.com/classifieds"><span>https://getthat.com/classifieds</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>238</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adsciti.com/"><span>http://www.adsciti.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>239</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.gunafrica.co.za/"><span>https://www.gunafrica.co.za</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>240</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.howzit.co.za/"><span>http://www.howzit.co.za</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>241</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adsciti.com/"><span>http://www.adsciti.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>242</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://singapore.locanto.sg/"><span>https://singapore.locanto.sg</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>243</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://west-region.locanto.sg/"><span>https://west-region.locanto.sg</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>244</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.mypetads.com/"><span>http://www.mypetads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>245</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adskeep.com/"><span>http://www.adskeep.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>246</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.classifiedsuae.com/"><span>http://www.classifiedsuae.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>247</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://adskeep.com/"><span>https://adskeep.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>248</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.farmzilla.com/"><span>https://www.farmzilla.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>249</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://adskeep.com/"><span>https://adskeep.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>250</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://dealsclassified.online/"><span>http://dealsclassified.online</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>251</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.indianweb.com/"><span>http://www.indianweb.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>252</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adonview.com/"><span>http://www.adonview.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>253</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ebackpage.com/"><span>http://www.ebackpage.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>254</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.indoclassified.com/"><span>http://www.indoclassified.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>255</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.indoclassified.com/"><span>http://www.indoclassified.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>256</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.classifiedsdekho.com/"><span>http://www.classifiedsdekho.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>257</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.oclicker.com/"><span>http://www.oclicker.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>258</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adclassified.in/"><span>http://www.adclassified.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>259</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://saudi-arabia.marcyads.com/"><span>http://saudi-arabia.marcyads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>260</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.planetclassifieds.com/"><span>https://www.planetclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>261</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.adeex.us/"><span>https://www.adeex.us</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>262</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.usaonline.us/"><span>https://www.usaonline.us</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>263</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://buyandsell.com/home"><span>http://buyandsell.com/home</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>264</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.indiae.in/"><span>https://www.indiae.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>265</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://planetclassifieds.com/"><span>https://planetclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>266</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.classifiedsfactor.com/"><span>http://www.classifiedsfactor.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>267</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.classifiedsfactor.com/"><span>http://www.classifiedsfactor.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>268</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.indiadynamics.com/"><span>http://www.indiadynamics.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>269</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.bizmartindia.com/"><span>http://www.bizmartindia.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>270</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.indiadynamics.com/"><span>http://www.indiadynamics.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>271</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.localsearch.ae/en"><span>https://www.localsearch.ae/en</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>272</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://kargal.ae/ad-tag/dubai-classifieds"><span>https://kargal.ae/ad-tag/dubai-classifieds</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>273</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.dubaidirecttrade.com/classifieds"><span>https://www.dubaidirecttrade.com/classifieds</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>274</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.photoads.co.uk/"><span>http://www.photoads.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>275</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.classifiedsfactor.com/"><span>https://www.classifiedsfactor.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>276</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.classifiedsfactor.com/"><span>https://www.classifiedsfactor.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>277</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://pta-online.co.za/"><span>https://pta-online.co.za</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>278</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.classifiedsfactor.com/"><span>https://www.classifiedsfactor.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>279</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.4ufreeclassifiedads.com/"><span>http://www.4ufreeclassifiedads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>280</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.classifiedsfactor.com/"><span>https://www.classifiedsfactor.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>281</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://singapore.yalwa.sg/"><span>https://singapore.yalwa.sg</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>282</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.indiadynamics.com/"><span>https://www.indiadynamics.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>283</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.xoocal.com/"><span>http://www.xoocal.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>284</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adoak.com/"><span>http://www.adoak.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>285</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.budu.com/"><span>http://www.budu.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>286</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.findermaster.com/"><span>http://www.findermaster.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>287</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.findermaster.com/"><span>https://www.findermaster.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>288</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.giganticlist.com/"><span>https://www.giganticlist.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>289</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://adpress.in/"><span>http://adpress.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>290</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.findermaster.com/"><span>https://www.findermaster.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>291</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.giganticlist.com/"><span>https://www.giganticlist.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>292</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.findermaster.com/"><span>https://www.findermaster.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>293</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.giganticlist.com/"><span>https://www.giganticlist.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>294</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adpress.in/"><span>http://www.adpress.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>295</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.findermaster.com/"><span>https://www.findermaster.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>296</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.giganticlist.com/"><span>https://www.giganticlist.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>297</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://xoocal.com/"><span>https://xoocal.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>298</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.buynow-us.com/"><span>https://www.buynow-us.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>299</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.wallclassifieds.com/"><span>http://www.wallclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>300</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.wallclassifieds.com/"><span>http://www.wallclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>301</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.wikidok.com/"><span>http://www.wikidok.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>302</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.twarak.com/"><span>http://www.twarak.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>303</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.postfreeadvertising.com/"><span>http://www.postfreeadvertising.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>304</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.namanas.com/"><span>http://www.namanas.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>305</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.delhi.namanas.com/"><span>http://www.delhi.namanas.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>306</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.bhartiads.com/"><span>http://www.bhartiads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>307</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.dubaiclassified.com/ads/jobs"><span>https://www.dubaiclassified.com/ads/jobs</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>308</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://classifieds.hotline.ae/"><span>http://classifieds.hotline.ae</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>309</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.nomtimes.co.uk/"><span>https://www.nomtimes.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>310</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.wallclassifieds.com/"><span>https://www.wallclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>311</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.dubaiclassified.com/ads"><span>https://www.dubaiclassified.com/ads</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>312</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.kallivalli.ae/"><span>https://www.kallivalli.ae</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>313</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freeads24.us/"><span>http://www.freeads24.us</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>314</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.wallclassifieds.com/"><span>https://www.wallclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>315</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://ads.thailandeducation.info/index.html"><span>https://ads.thailandeducation.info/index.html</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>316</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.wallclassifieds.com/"><span>https://www.wallclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>317</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.twarak.com/"><span>https://www.twarak.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>318</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.wallclassifieds.com/"><span>https://www.wallclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>319</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.trovit.com.sg/"><span>https://www.trovit.com.sg</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>320</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adspostfree.com/"><span>http://www.adspostfree.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>321</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adspostfree.com/"><span>http://www.adspostfree.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>322</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.h1ad.com/"><span>http://www.h1ad.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>323</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.viceads.com/"><span>http://www.viceads.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>324</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adslynk.com/"><span>http://www.adslynk.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>325</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ismartads.com/"><span>http://www.ismartads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>326</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.malayaliclassifieds.com/"><span>http://www.malayaliclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>327</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://allindubai.com/"><span>http://allindubai.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>328</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://malayaliclassifieds.com/"><span>https://malayaliclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>329</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://vivauae.com/"><span>https://vivauae.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>330</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.h1ad.com/"><span>https://www.h1ad.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>331</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.advertiseera.com/"><span>https://www.advertiseera.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>332</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.dewalist.ae/0"><span>http://www.dewalist.ae/0</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>333</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://oc2o.com/en?d=US"><span>https://oc2o.com/en?d=US</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>334</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.h1ad.com/"><span>https://www.h1ad.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>335</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.advertiseera.com/"><span>https://www.advertiseera.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>336</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.classifiedny.com/"><span>http://www.classifiedny.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>337</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://oc2o.com/en?d=TH"><span>https://oc2o.com/en?d=TH</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>338</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.justlanded.co.in/"><span>https://www.justlanded.co.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>339</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.h1ad.com/"><span>https://www.h1ad.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>340</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.advertiseera.com/"><span>https://www.advertiseera.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>341</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.h1ad.com/"><span>https://www.h1ad.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>342</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.advertiseera.com/"><span>https://www.advertiseera.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>343</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.h1ad.com/"><span>https://www.h1ad.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>344</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.bananadirectories.com/"><span>http://www.bananadirectories.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>345</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.mytiruvarur.com/"><span>http://www.mytiruvarur.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>346</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.bananadirectories.com/"><span>http://www.bananadirectories.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>347</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.mytiruvarur.com/"><span>http://www.mytiruvarur.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>348</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.fmclassifieds.com/"><span>http://www.fmclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>349</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.selfiads.com/"><span>http://www.selfiads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>350</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.india.sebule.com/"><span>http://www.india.sebule.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>351</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.way2ad.com/"><span>http://www.way2ad.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>352</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.itsbharat.com/"><span>http://www.itsbharat.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>353</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://classifiedarabia.com/"><span>https://classifiedarabia.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>354</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.fmclassifieds.com/"><span>https://www.fmclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>355</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.fmclassifieds.com/"><span>https://www.fmclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>356</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.upostfreeads.com/"><span>http://www.upostfreeads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>357</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adtoad.com/"><span>http://www.adtoad.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>358</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://classifiedsplace.com/"><span>https://classifiedsplace.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>359</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://macherusa.com/"><span>https://macherusa.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>360</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://usa.sebule.com/"><span>https://usa.sebule.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>361</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://th.adswan.com/"><span>http://th.adswan.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>362</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.freelocalclassifiedads.co.za/"><span>https://www.freelocalclassifiedads.co.za</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>363</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.freeclassified.co.za/"><span>https://www.freeclassified.co.za</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>364</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://adswan.com/"><span>http://adswan.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>365</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.gofreeclassified.com/"><span>http://www.gofreeclassified.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>366</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.gofreeclassified.com/"><span>http://www.gofreeclassified.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>367</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freeads24.eu/"><span>http://www.freeads24.eu</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>368</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.xookr.in/"><span>http://www.xookr.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>369</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adrevu.com/"><span>http://www.adrevu.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>370</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.helptobuynsell.in/"><span>http://www.helptobuynsell.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>371</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.classifiedzoo.com/"><span>https://www.classifiedzoo.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>372</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://olwayz.com/"><span>https://olwayz.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>373</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.mapleme.com/"><span>http://www.mapleme.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>374</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://adpiece.in/"><span>http://adpiece.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>375</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adwayer.in/"><span>http://www.adwayer.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>376</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adsapt.com/"><span>http://www.adsapt.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>377</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.corporatehours.com/"><span>http://www.corporatehours.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>378</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.epageindia.com/"><span>http://www.epageindia.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>379</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.kps.co.in/"><span>http://www.kps.co.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>380</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adsapt.com/"><span>http://www.adsapt.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>381</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.corporatehours.com/"><span>http://www.corporatehours.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>382</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.epageindia.com/"><span>http://www.epageindia.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>383</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.kps.co.in/"><span>http://www.kps.co.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>384</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.zicfy.com/"><span>http://www.zicfy.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>385</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.sacdesi.com/"><span>http://www.sacdesi.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>386</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.zamroo.com/"><span>http://www.zamroo.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>387</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.tradetu.com/"><span>http://www.tradetu.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>388</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.gnn9.com/"><span>http://www.gnn9.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>389</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.myadmonster.com/us"><span>http://www.myadmonster.com/us</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>390</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.knightvet.com/classifieds.html"><span>http://www.knightvet.com/classifieds.html</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>391</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.thailand-classifieds.net/"><span>https://www.thailand-classifieds.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>392</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.obszone.com/"><span>https://www.obszone.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>393</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://sitesforads.com/en?d=SG"><span>https://sitesforads.com/en?d=SG</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>394</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.chaosads-singapore.com/"><span>https://www.chaosads-singapore.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>395</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.zamroo.com/"><span>https://www.zamroo.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>396</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freeclassified.biz/"><span>http://www.freeclassified.biz</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>397</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.sgclassify.com/"><span>http://www.sgclassify.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>398</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freeclassified.biz/"><span>http://www.freeclassified.biz</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>399</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.sgclassify.com/"><span>http://www.sgclassify.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>400</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.flatfy.in/"><span>http://www.flatfy.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>401</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.onlyforads.com/"><span>http://www.onlyforads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>402</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.goodlinksindia.com/"><span>http://www.goodlinksindia.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>403</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.fastadpost.com/"><span>http://www.fastadpost.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>404</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.mumbaiclassic.com/"><span>http://www.mumbaiclassic.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>405</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.abentra.com/ae-en"><span>https://www.abentra.com/ae-en</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>406</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.abentra.com/"><span>https://www.abentra.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>407</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.fastadpost.com/"><span>http://www.fastadpost.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>408</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://everythingglobe.com/"><span>http://everythingglobe.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>409</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.universelisting.com/"><span>http://www.universelisting.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>410</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://hubbae.ae/"><span>https://hubbae.ae</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>411</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://equalifieds.com/"><span>https://equalifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>412</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://alawooz.com/ae/dubai"><span>https://alawooz.com/ae/dubai</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>413</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://usa-classifieds.com/index.php"><span>https://usa-classifieds.com/index.php</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>414</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://equalifieds.com/"><span>https://equalifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>415</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.universelisting.com/"><span>http://www.universelisting.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>416</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://thailand.onlyforads.com/"><span>http://thailand.onlyforads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>417</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://alltheweb.co.za/"><span>https://alltheweb.co.za</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>418</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.afribaba.co.za/"><span>https://www.afribaba.co.za</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>419</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://singapore.onlyforads.com/"><span>http://singapore.onlyforads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>420</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://sgadsonline.com/"><span>http://sgadsonline.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>421</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://sgclassify.com/"><span>http://sgclassify.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>422</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.sgadsonline.com/"><span>http://www.sgadsonline.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>423</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.youpost.org/"><span>http://www.youpost.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>424</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.egoota.com/"><span>http://www.egoota.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>425</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.quickyads.in/"><span>http://www.quickyads.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>426</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.egoota.com/"><span>http://www.egoota.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>427</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.quickyads.in/"><span>http://www.quickyads.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>428</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.indianreunited.net/"><span>http://www.indianreunited.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>429</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.indiaaclick.com/"><span>http://www.indiaaclick.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>430</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.viewfreeads.com/"><span>http://www.viewfreeads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>431</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adsnity.in/"><span>http://www.adsnity.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>432</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ad.i1corner.com/"><span>http://www.ad.i1corner.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>433</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.bavun.in/"><span>http://www.bavun.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>434</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.tips4india.in/"><span>http://www.tips4india.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>435</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.anunico.ae/"><span>https://www.anunico.ae</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>436</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://doukani.com/"><span>https://doukani.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>437</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://adpiece.com/"><span>http://adpiece.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>438</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.mxanuncios.com/"><span>https://www.mxanuncios.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>439</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.goodideo.com/index.php"><span>http://www.goodideo.com/index.php</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>440</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.searchbuzz.co.uk/"><span>https://www.searchbuzz.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>441</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.bahtlist.com/classifieds"><span>https://www.bahtlist.com/classifieds</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>442</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.bahtlist.com/"><span>https://www.bahtlist.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>443</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://classifiedssouthafrica.co.za/"><span>https://classifiedssouthafrica.co.za</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>444</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://classifiedssouthafrica.co.za/"><span>http://classifiedssouthafrica.co.za</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>445</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.technodia.sg/"><span>http://www.technodia.sg</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>446</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://technodia.sg/"><span>http://technodia.sg</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>447</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.bavun.in/"><span>https://www.bavun.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>448</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ads4uk.com/"><span>http://ads4uk.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>449</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.clankart.com/"><span>http://www.clankart.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>450</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.cootera.com/"><span>http://www.cootera.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>451</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.cootera.com/"><span>http://www.cootera.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>452</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.rentlx.com/"><span>http://www.rentlx.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>453</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.clankart.com/"><span>http://www.clankart.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>454</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.cootera.com/"><span>http://www.cootera.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>455</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.cootera.com/"><span>http://www.cootera.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>456</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.rentlx.com/"><span>http://www.rentlx.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>457</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.indiaza.net/"><span>http://www.indiaza.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>458</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.indiaza.net/"><span>http://www.indiaza.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>459</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.inddealz.com/"><span>http://www.inddealz.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>460</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.chennaiclassic.com/"><span>http://www.chennaiclassic.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>461</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.chennaiclassic.com/"><span>http://www.chennaiclassic.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>462</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.multidimensions.net/"><span>http://www.multidimensions.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>463</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.totaldubai.com/"><span>https://www.totaldubai.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>464</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.classifiedadsuae.com/"><span>http://www.classifiedadsuae.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>465</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.rectanglead.com/"><span>https://www.rectanglead.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>466</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.rectanglead.com/"><span>https://www.rectanglead.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>467</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.post-free-ads.com/"><span>http://www.post-free-ads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>468</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://th.classifiedads4free.com/"><span>http://th.classifiedads4free.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>469</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://azclassifiedads.com/?d=TH"><span>https://azclassifiedads.com/?d=TH</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>470</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.rectanglead.com/"><span>https://www.rectanglead.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>471</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://southafrica.rentline.co/"><span>http://southafrica.rentline.co</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>472</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.classifiedposts.co.za/index.php?page"><span>http://www.classifiedposts.co.za/index.php?page</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>473</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.anunico.co.za/"><span>https://www.anunico.co.za</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>474</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://sg.redad.com/"><span>http://sg.redad.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>475</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.delhifinder.com/"><span>https://www.delhifinder.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>476</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.rectanglead.com/"><span>https://www.rectanglead.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>477</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.rectanglead.com/"><span>https://www.rectanglead.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>478</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adycart.in/"><span>http://www.adycart.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>479</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freeclassads.eu/"><span>http://www.freeclassads.eu</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>480</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.indiansclassifieds.com/"><span>http://www.indiansclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>481</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adsfree.in/"><span>http://www.adsfree.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>482</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.helloindia.co/"><span>http://www.helloindia.co</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>483</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adycart.in/"><span>http://www.adycart.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>484</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freeclassads.eu/"><span>http://www.freeclassads.eu</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>485</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.indiansclassifieds.com/"><span>http://www.indiansclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>486</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adsfree.in/"><span>http://www.adsfree.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>487</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.helloindia.co/"><span>http://www.helloindia.co</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>488</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.dubizubi.com/"><span>http://www.dubizubi.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>489</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://dubaiclassify.com/"><span>http://dubaiclassify.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>490</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.pgfreeads.ae/"><span>https://www.pgfreeads.ae</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>491</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.liveuae.com/"><span>http://www.liveuae.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>492</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.chatuchak.com/"><span>https://www.chatuchak.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>493</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://thaizzle.com/"><span>https://thaizzle.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>494</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.sgclassic.com/"><span>https://www.sgclassic.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>495</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.masig.sg/"><span>http://www.masig.sg</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>496</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://meetpark.com/en"><span>https://meetpark.com/en</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>497</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.everestads.com/"><span>http://www.everestads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>498</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.indiasfreeclassified.com/"><span>http://www.indiasfreeclassified.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>499</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.classifiedindia.co.in/"><span>http://www.classifiedindia.co.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>500</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.india-classifieds.in/"><span>http://www.india-classifieds.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>501</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.everestads.com/"><span>http://www.everestads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>502</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.indiasfreeclassified.com/"><span>http://www.indiasfreeclassified.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>503</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.classifiedindia.co.in/"><span>http://www.classifiedindia.co.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>504</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.india-classifieds.in/"><span>http://www.india-classifieds.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>505</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.247zoom.com/"><span>https://www.247zoom.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>506</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://expressads.ae/"><span>https://expressads.ae</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>507</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://searchuae.ae/"><span>http://searchuae.ae</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>508</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.dubaifreeclassified.com/"><span>https://www.dubaifreeclassified.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>509</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.fclassifieds.com/"><span>https://www.fclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>510</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.localclassified.co.za/"><span>http://www.localclassified.co.za</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>511</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.afrorama.com/en"><span>http://www.afrorama.com/en</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>512</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://classifiedsfree.co.za/south-africa-classifieds"><span>https://classifiedsfree.co.za/south-africa-classifieds</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>513</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.kiliks.com/"><span>https://www.kiliks.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>514</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.indian-freeads.com/"><span>http://www.indian-freeads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>515</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.coimbatoreclassified.com/"><span>http://www.coimbatoreclassified.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>516</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.k2classifieds.com/"><span>http://www.k2classifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>517</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.dealtalk.co.in/"><span>http://www.dealtalk.co.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>518</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.indian-freeads.com/"><span>http://www.indian-freeads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>519</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.coimbatoreclassified.com/"><span>http://www.coimbatoreclassified.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>520</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.k2classifieds.com/"><span>http://www.k2classifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>521</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.dealtalk.co.in/"><span>http://www.dealtalk.co.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>522</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.goldclassifieds.com/"><span>http://www.goldclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>523</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.yallalist.com/"><span>http://www.yallalist.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>524</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://uae.binjos.com/"><span>https://uae.binjos.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>525</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.gulfjoy.com/"><span>https://www.gulfjoy.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>526</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://thailand-classified-ads.com/"><span>https://thailand-classified-ads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>527</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.digitalbhoomi.com/"><span>http://www.digitalbhoomi.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>528</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.admyad.com/"><span>http://www.admyad.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>529</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.digitalbhoomi.com/"><span>http://www.digitalbhoomi.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>530</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.admyad.com/"><span>http://www.admyad.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>531</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.krislon.net/"><span>http://www.krislon.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>532</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.bestfreeads.co.uk/"><span>http://www.bestfreeads.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>533</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.freeadsus.com/united-states"><span>https://www.freeadsus.com/united-states</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>534</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://cheapwb.com/"><span>http://cheapwb.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>535</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://southafrica.onlinelist.com/home"><span>http://southafrica.onlinelist.com/home</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>536</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.thailandads.com/"><span>https://www.thailandads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>537</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.booc.co.za/"><span>http://www.booc.co.za</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>538</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.eclassified.co.za/"><span>https://www.eclassified.co.za</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>539</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://sgads.com/"><span>https://sgads.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>540</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://cheapwb.com/"><span>http://cheapwb.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>541</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.gyanijiweb.in/"><span>http://www.gyanijiweb.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>542</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://sgadpost.com/"><span>http://sgadpost.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>543</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adway24.com/"><span>http://www.adway24.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>544</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adway24.com/"><span>http://www.adway24.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>545</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://sellam-online.com/"><span>https://sellam-online.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>546</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.autodeal.ae/"><span>http://www.autodeal.ae</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>547</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://adshare.ae/"><span>https://adshare.ae</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>548</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.meshngo.com/us"><span>http://www.meshngo.com/us</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>549</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.shakvihimam.com/"><span>https://www.shakvihimam.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>550</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.theafricancorner.com/"><span>http://www.theafricancorner.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>551</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://thaisellbuy.com/"><span>https://thaisellbuy.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>552</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.thaitrademe.com/classifieds/index.php"><span>http://www.thaitrademe.com/classifieds/index.php</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>553</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.flashselling.com/"><span>http://www.flashselling.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>554</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.singaporeclassifieds.net/"><span>http://www.singaporeclassifieds.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>555</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.singaporeclassifieds.net/"><span>https://www.singaporeclassifieds.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>556</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.singaporespot.com/classifieds"><span>http://www.singaporespot.com/classifieds</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>557</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://clickooz.in/"><span>https://clickooz.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>558</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://lamudi.co.za/"><span>https://lamudi.co.za</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>559</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.gyanijinetwork.in/"><span>http://www.gyanijinetwork.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>560</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.smallbusinessads.co.uk/"><span>http://www.smallbusinessads.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>561</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://uberads.co.uk/"><span>https://uberads.co.uk</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>562</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://sadverts.com/"><span>https://sadverts.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>563</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.proadsafrica.co.za/"><span>http://www.proadsafrica.co.za</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>564</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://fastadsgo.com/en?d=SG"><span>https://fastadsgo.com/en?d=SG</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>565</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://sgclassifiedads.com/"><span>http://sgclassifiedads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>566</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://dubrazy.com/"><span>https://dubrazy.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>567</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://pattaya-classifieds.com/"><span>https://pattaya-classifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>568</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.crestedmail.com/"><span>http://www.crestedmail.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            <h1 className='text-[32px] font-medium my-3 heading'> Free Classified Submission Sites List for
                                India</h1>
                            <div class="relative overflow-x-auto shadow-md sm:rounded-lg ">
                                <table class="w-full text-sm text-left rtl:text-right text-gray-500 darks:text-gray-400">
                                    <thead
                                        class="text-xs text-gray-700 uppercase bg-gray-50 darks:bg-gray-700 darks:text-gray-400">
                                        <tr>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                SNo.
                                            </th>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                Websites
                                            </th>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                DA
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.classifieds.mid-day.com"><span>www.classifieds.mid-day.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>84</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>2</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.classifieds.sulekha.com"><span>www.classifieds.sulekha.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>78</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>3</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.classifieds.webindia123.com"><span>www.classifieds.webindia123.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>68</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>4</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.olx.in"><span>www.olx.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>5</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.quikr.com"><span>www.quikr.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>60</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.classifieds.chennaionline.com"><span>www.classifieds.chennaionline.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>51</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.clickindia.com"><span>www.clickindia.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>47</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.india9.com"><span>www.india9.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>39</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.locanto.in"><span>www.locanto.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>37</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adeex.in"><span>www.adeex.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.indiabook.com"><span>www.indiabook.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.hindustanlink.com"><span>www.hindustanlink.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.khojle.in"><span>www.khojle.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.classifieds.shopmania.in"><span>www.classifieds.shopmania.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adoos.in"><span>www.adoos.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.indialist.com"><span>www.indialist.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.delhi.indialist.com"><span>www.delhi.indialist.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.bhartiads.com"><span>www.bhartiads.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freeads.in"><span>www.freeads.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adpiece.in"><span>www.adpiece.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.expressclassifieds.in"><span>www.expressclassifieds.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adsapt.com"><span>www.adsapt.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.bechvech.com"><span>www.bechvech.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.indianadz.com"><span>www.indianadz.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.tips4india.in"><span>www.tips4india.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.indianreunited.net"><span>www.indianreunited.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.boostime.in"><span>www.boostime.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.delhi.oodle.in"><span>www.delhi.oodle.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.epageindia.com"><span>www.epageindia.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.allindiaads.com"><span>www.allindiaads.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.goforads.com"><span>www.goforads.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adycart.in"><span>www.adycart.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.indiaclassifieds.com"><span>www.indiaclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>34</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.asianclassified.com"><span>www.asianclassified.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.viewmyclassified.com"><span>www.viewmyclassified.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>36</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.justforindian.com"><span>www.justforindian.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>37</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.kanosa.com"><span>www.kanosa.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freelocalclassifiedads.in"><span>www.freelocalclassifiedads.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>39</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.localadsindia.com"><span>www.localadsindia.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>40</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.classicsads.com"><span>www.classicsads.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>41</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.citybazaar.in"><span>www.citybazaar.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>42</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.i24.in"><span>www.i24.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>43</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.citytimes.in"><span>www.citytimes.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>44</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.justeclick.com"><span>www.justeclick.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>45</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.jihoy.com"><span>http://www.jihoy.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>40</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>46</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.thisismyindia.com/free_ads"><span>http://www.thisismyindia.com/free_ads</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>40</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>47</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.thisismyindia.com"><span>https://www.thisismyindia.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>40</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>48</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.mondinion.com"><span>http://www.mondinion.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>39</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>49</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://freead1.net"><span>https://freead1.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>39</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>50</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.canetads.com"><span>http://www.canetads.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>39</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>51</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.usaonlineclassifieds.com"><span>http://www.usaonlineclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>39</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>52</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.bedpage.com"><span>https://www.bedpage.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>53</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.stclassifieds.sg"><span>https://www.stclassifieds.sg</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>54</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://epage.com/js/epmain.jsp"><span>http://epage.com/js/epmain.jsp</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://classifieds.meramaal.com"><span>https://classifieds.meramaal.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.thefreeadforum.com/postclassifieds"><span>http://www.thefreeadforum.com/postclassifieds</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>57</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.thefreeadforum.com"><span>http://www.thefreeadforum.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>58</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.izydaisy.com"><span>https://www.izydaisy.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>59</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.bedpage.com"><span>https://www.bedpage.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>60</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ukadslist.com"><span>http://www.ukadslist.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>37</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.cbseguess.com/classifieds"><span>https://www.cbseguess.com/classifieds</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>37</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.freeadstime.org"><span>https://www.freeadstime.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>36</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>63</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://classifiedsforfree.com"><span>http://classifiedsforfree.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>36</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://qwikad.com"><span>https://qwikad.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>36</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>65</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freeadsbook.com"><span>http://www.freeadsbook.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>66</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.adeex.in"><span>https://www.adeex.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>67</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.adpost4u.com"><span>https://www.adpost4u.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>68</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.eknazar.com"><span>https://www.eknazar.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>34</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>69</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.webclassifieds.us"><span>https://www.webclassifieds.us</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>34</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>70</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://adsandclassifieds.com"><span>http://adsandclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>34</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>71</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.classifiedsciti.com"><span>http://www.classifiedsciti.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>34</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>72</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://in.classi4u.com"><span>https://in.classi4u.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>34</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>73</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.topclassifieds.com"><span>https://www.topclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>34</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>74</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.hot-web-ads.com"><span>http://www.hot-web-ads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>34</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>75</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.innetads.com"><span>http://www.innetads.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>34</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>76</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.ibackpage.com"><span>https://www.ibackpage.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>77</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.sellbuystuffs.com"><span>https://www.sellbuystuffs.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>78</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://nullads.org"><span>http://nullads.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>79</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://quickfinds.in"><span>https://quickfinds.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>80</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://adoos.co.uk/public"><span>https://adoos.co.uk/public</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>81</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.aunetads.com"><span>http://www.aunetads.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>82</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.in.locan.to"><span>https://www.in.locan.to</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>83</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://classified4free.net"><span>http://classified4free.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>84</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.ibackpage.com"><span>https://www.ibackpage.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>85</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://in.globoanuncio.com"><span>https://in.globoanuncio.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>86</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adsyellowpages.com"><span>http://www.adsyellowpages.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>87</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://adsyellowpages.com"><span>http://adsyellowpages.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>88</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://flegoo.com"><span>http://flegoo.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>89</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adsblue.com"><span>http://www.adsblue.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>90</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://indiaconnect.com"><span>https://indiaconnect.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>91</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.citynews.com"><span>http://www.citynews.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>92</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.askmeclassifieds.com"><span>http://www.askmeclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>93</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.vivastreet.co.in"><span>https://www.vivastreet.co.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>94</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://askmeclassifieds.com"><span>https://askmeclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>95</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.finderguru.com"><span>http://www.finderguru.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>96</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.indiabook.com"><span>https://www.indiabook.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>97</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://posthereads.com"><span>http://posthereads.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>98</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.googoclassifieds.com"><span>http://www.googoclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>99</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://unolist.in"><span>http://unolist.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>100</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.postfreeadshere.com"><span>http://www.postfreeadshere.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>101</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.postallads4free.com"><span>https://www.postallads4free.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>102</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://freeadsciti.com"><span>http://freeadsciti.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>103</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ezclassifiedads.com"><span>http://www.ezclassifiedads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>104</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ezclassifiedads.com"><span>http://ezclassifiedads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>105</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://rackons.com"><span>http://rackons.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>106</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://classifieds4free.biz"><span>http://classifieds4free.biz</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>107</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.ad-mart.co.uk"><span>https://www.ad-mart.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>108</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.khojle.in"><span>http://www.khojle.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>109</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.jobiba.com"><span>https://www.jobiba.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>110</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://freewebads.biz"><span>http://freewebads.biz</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>111</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://globalclassified.net"><span>http://globalclassified.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>112</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://freeadsonline.biz"><span>http://freeadsonline.biz</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>113</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://99localads.com"><span>http://99localads.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>114</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://adguru.net/en"><span>https://adguru.net/en</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>115</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://admaya.in"><span>http://admaya.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>116</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://dewalist.co.in"><span>http://dewalist.co.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>117</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.10dayads.com"><span>https://www.10dayads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>118</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://adfreeposting.com"><span>http://adfreeposting.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>119</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://clickooz.com"><span>http://clickooz.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>120</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://adhoards.com"><span>https://adhoards.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>121</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://cityclassify.com"><span>https://cityclassify.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>122</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://freewebads.us"><span>http://freewebads.us</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>123</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://postherefree.com"><span>http://postherefree.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>124</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://nextfreeads.com"><span>http://nextfreeads.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>125</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://indiacatalog.com"><span>http://indiacatalog.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>126</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adsciti.com"><span>http://www.adsciti.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>127</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://ads2india.com"><span>https://ads2india.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>128</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.hotfreelist.com"><span>https://www.hotfreelist.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>129</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://freebestads.com"><span>http://freebestads.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>130</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.freeclassifiedssites.com"><span>https://www.freeclassifiedssites.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>131</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://adskorner.com"><span>http://adskorner.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>132</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://beatyourprice.com"><span>https://beatyourprice.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>133</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://secondsmall.com"><span>http://secondsmall.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>134</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://in.postadverts.com/en"><span>https://in.postadverts.com/en</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>135</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.secondhandbazaar.in"><span>https://www.secondhandbazaar.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>136</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.delhionline.in"><span>https://www.delhionline.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>137</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.classtize.com"><span>http://www.classtize.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>138</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.princeclassified.com"><span>https://www.princeclassified.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>139</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://adsglobe.com"><span>http://adsglobe.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>140</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.kippee.com"><span>https://www.kippee.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>141</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.findinall.com"><span>https://www.findinall.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>142</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.oclicker.com"><span>http://www.oclicker.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>143</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://adclassified.in"><span>https://adclassified.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>144</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.myadsclassified.com"><span>https://www.myadsclassified.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>145</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.mypetads.com"><span>http://www.mypetads.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>146</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://mypetads.com"><span>http://mypetads.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>147</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.indianweb.com"><span>https://www.indianweb.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>148</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://classified4u.biz"><span>http://classified4u.biz</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>149</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://eventsking.com"><span>https://eventsking.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>150</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.4ufreeclassifiedads.com"><span>http://www.4ufreeclassifiedads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>151</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.eonlineads.com"><span>http://www.eonlineads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>152</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://eonlineads.com"><span>http://eonlineads.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>153</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.claadss.com"><span>https://www.claadss.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>154</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://adsnity.com"><span>http://adsnity.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>155</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.freeprachar.com"><span>https://www.freeprachar.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>156</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://web-free-ads.com"><span>http://web-free-ads.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>157</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.web-free-ads.com"><span>https://www.web-free-ads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>158</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.indiae.in"><span>https://www.indiae.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>159</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://adonview.com"><span>http://adonview.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>160</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://indoclassified.com"><span>https://indoclassified.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>161</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.giganticlist.com"><span>https://www.giganticlist.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>162</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://getadsonline.com"><span>http://getadsonline.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>163</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://xoocal.com"><span>https://xoocal.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>164</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://emoment.net"><span>http://emoment.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>165</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://freeadshome.com"><span>http://freeadshome.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>166</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.classifiedslive.com"><span>http://www.classifiedslive.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>167</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.findermaster.com"><span>https://www.findermaster.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>168</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.ebackpage.com"><span>https://www.ebackpage.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>169</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://malayaliclassifieds.com"><span>https://malayaliclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>170</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.spicyadz.in"><span>http://www.spicyadz.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>171</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.nvnickel.com"><span>http://www.nvnickel.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>172</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://wikidok.com"><span>http://wikidok.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>173</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.twarak.com"><span>http://www.twarak.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>174</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.twarak.com"><span>https://www.twarak.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>175</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.addlistsite.com/en.php"><span>http://www.addlistsite.com/en.php</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>176</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.ebackpage.com"><span>https://www.ebackpage.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>177</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.postforads.com"><span>https://www.postforads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>178</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.postfreeadvertising.com"><span>https://www.postfreeadvertising.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>179</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://classifiedz.in"><span>https://classifiedz.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>180</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.classifiedsdekho.com"><span>http://www.classifiedsdekho.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>181</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.click4post.com"><span>https://www.click4post.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>182</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freeadsinindia.in"><span>http://www.freeadsinindia.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>183</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://gvanga.com/en"><span>https://gvanga.com/en</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>184</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.h1ad.com"><span>https://www.h1ad.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>185</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://jobs.freeadsinindia.in"><span>http://jobs.freeadsinindia.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>186</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://mysearchindia.com"><span>http://mysearchindia.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>187</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.wallclassifieds.com"><span>https://www.wallclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>188</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.adslynk.com"><span>https://www.adslynk.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>189</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.themirch.com"><span>https://www.themirch.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>190</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.makuv.in"><span>https://www.makuv.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>191</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.searchingurgaon.com"><span>http://www.searchingurgaon.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>192</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://imclassified.com"><span>http://imclassified.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>193</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://in.zobazo.com"><span>http://in.zobazo.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>194</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adsmantra.com"><span>http://www.adsmantra.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>195</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.dtwdesi.com"><span>https://www.dtwdesi.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>196</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://highlandclassifieds.com"><span>http://highlandclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>197</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://viceads.com"><span>http://viceads.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>198</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.1smartlist.com"><span>http://www.1smartlist.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>199</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://1smartlist.com"><span>http://1smartlist.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>200</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.advertiseera.com"><span>https://www.advertiseera.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>201</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.icracker.com.au"><span>https://www.icracker.com.au</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>202</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://way2ad.com"><span>http://way2ad.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>203</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.bajar.in"><span>https://www.bajar.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>204</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.classifiedsfactor.com"><span>https://www.classifiedsfactor.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>205</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.topfreeclassifieds.com"><span>https://www.topfreeclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>206</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://freeads24.eu"><span>http://freeads24.eu</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>207</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.adaalo.com"><span>https://www.adaalo.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>208</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.worldslist.com"><span>http://www.worldslist.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>209</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://adaalo.com"><span>https://adaalo.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>210</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.adaalo.com/classifieds"><span>https://www.adaalo.com/classifieds</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>211</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ncrpages.in"><span>http://www.ncrpages.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>212</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.rajb2b.com"><span>https://www.rajb2b.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>213</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://helpadya.com"><span>https://helpadya.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>214</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freeead.com"><span>http://www.freeead.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>215</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.adbazzaar.com"><span>https://www.adbazzaar.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>216</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.adrevu.com"><span>https://www.adrevu.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>217</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.myclassifiedad.in"><span>https://www.myclassifiedad.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>218</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://greatclassified.com"><span>http://greatclassified.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>219</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.itsbharat.com"><span>https://www.itsbharat.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>220</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://mytiruvarur.com"><span>http://mytiruvarur.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>221</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.selfiads.com"><span>https://www.selfiads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>222</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://india.sebule.com"><span>https://india.sebule.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>223</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.spotclassifieds.com"><span>http://www.spotclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>224</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://in.salenearn.com"><span>https://in.salenearn.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>225</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.classifiedadindia.com"><span>https://www.classifiedadindia.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>226</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://kluky.in"><span>http://kluky.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>227</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://oyedekh.com"><span>http://oyedekh.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>228</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://sitesforads.com"><span>http://sitesforads.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>229</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.myfreeads.in"><span>https://www.myfreeads.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>230</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.sacdesi.com"><span>https://www.sacdesi.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>231</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://adpiece.in"><span>http://adpiece.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>232</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://helptobuynsell.in"><span>https://helptobuynsell.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>233</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://addsera.in"><span>https://addsera.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>234</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://dymm.com"><span>https://dymm.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>235</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.tradetu.com"><span>http://www.tradetu.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>236</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://india.dastiab.com"><span>http://india.dastiab.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>237</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.zolum.com/india"><span>http://www.zolum.com/india</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>238</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://india.dastiab.com"><span>https://india.dastiab.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>239</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://kps.co.in"><span>https://kps.co.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>240</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.gofreeclassified.com"><span>http://www.gofreeclassified.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>241</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.indiafinder.in"><span>http://www.indiafinder.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>242</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://myadmonster.com"><span>http://myadmonster.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>243</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.zamroo.com"><span>https://www.zamroo.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>244</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.biz15.co.in"><span>https://www.biz15.co.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>245</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.zicfy.com"><span>https://www.zicfy.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>246</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://zicfy.com"><span>https://zicfy.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>247</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://biggestclassifieds.com"><span>http://biggestclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>248</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://clicknsell.in"><span>http://clicknsell.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>249</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.dicker.in"><span>https://www.dicker.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>250</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://r4sales.com"><span>https://r4sales.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>251</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.corporatehours.com"><span>http://www.corporatehours.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>252</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.a2zclassified.com"><span>https://www.a2zclassified.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>253</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.superadpost.com"><span>https://www.superadpost.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>254</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.daslot.in"><span>http://www.daslot.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>255</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.withinmile.com"><span>https://www.withinmile.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>256</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.77traders.com"><span>https://www.77traders.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>257</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.pinposter.com"><span>https://www.pinposter.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>258</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.goodlinksindia.com"><span>http://www.goodlinksindia.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>259</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://youradsite.com"><span>http://youradsite.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>260</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://tips4india.in"><span>http://tips4india.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>261</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.fastadpost.com"><span>http://www.fastadpost.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>262</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ishuads.in"><span>http://www.ishuads.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>263</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.egoota.com"><span>https://www.egoota.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>264</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://demo.tekhspy.com"><span>https://demo.tekhspy.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>265</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://adtrack.in"><span>https://adtrack.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>266</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.help24.in"><span>http://www.help24.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>267</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://sgclassify.com"><span>http://sgclassify.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>268</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.gurgaonbazaar.co.in"><span>http://www.gurgaonbazaar.co.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>269</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.ad2max.in"><span>https://www.ad2max.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>270</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.99clix.in"><span>http://www.99clix.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>271</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.jobsclassified.in"><span>https://www.jobsclassified.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>272</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://siteplugger.com"><span>https://siteplugger.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>273</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.indads.in"><span>https://www.indads.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>274</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://olxclassified.com"><span>http://olxclassified.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>275</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.indiaza.net"><span>https://www.indiaza.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>276</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.youpost.org"><span>http://www.youpost.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>277</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.faltumarket.com"><span>https://www.faltumarket.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>278</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.indianreunited.net"><span>https://www.indianreunited.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>279</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.clankart.com"><span>https://www.clankart.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>280</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://pearlweb.in"><span>https://pearlweb.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>281</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://adsnity.in"><span>http://adsnity.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>282</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.indiaaclick.com"><span>https://www.indiaaclick.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>283</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.onlyforads.com"><span>https://www.onlyforads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>284</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://rentlx.com"><span>http://rentlx.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>285</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://gnn9.com"><span>http://gnn9.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>286</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.indiansclassifieds.com"><span>http://www.indiansclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>287</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://resalerental.com"><span>https://resalerental.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>288</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.fairsearches.com"><span>https://www.fairsearches.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>289</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://directories.net.in"><span>http://directories.net.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>290</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://freeclassipress.com"><span>http://freeclassipress.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>291</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.gurugramonline.in"><span>https://www.gurugramonline.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>292</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.eazyseller.com"><span>https://www.eazyseller.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>293</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://mlmad.in"><span>https://mlmad.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>294</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.bisell.in"><span>http://www.bisell.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>295</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.inddealz.com"><span>http://www.inddealz.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>296</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.chennaiclassic.com"><span>https://www.chennaiclassic.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>297</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.epageindia.com"><span>http://www.epageindia.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>298</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://cootera.com"><span>https://cootera.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>299</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.cootera.com"><span>https://www.cootera.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>300</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://in.stockkcots.com"><span>http://in.stockkcots.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>301</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://osyska.com"><span>https://osyska.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>302</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://swapon.co.in"><span>https://swapon.co.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>303</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://helloindia.co"><span>https://helloindia.co</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>304</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.lookforad.com"><span>http://www.lookforad.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>305</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://adbizindia.com"><span>https://adbizindia.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>306</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://browsemycity.com"><span>https://browsemycity.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>307</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://publicdial.com"><span>http://publicdial.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>308</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.rectanglead.com"><span>https://www.rectanglead.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>309</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://justklick.in/classifieds/public"><span>http://justklick.in/classifieds/public</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>310</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://2findnow.com"><span>https://2findnow.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>311</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.indiasfreeclassified.com"><span>https://www.indiasfreeclassified.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>312</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freeclassads.eu"><span>http://www.freeclassads.eu</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>313</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.adresourcechannel.com"><span>https://www.adresourcechannel.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>314</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.postallfreeads.com"><span>http://www.postallfreeads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>315</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.localshout.in"><span>https://www.localshout.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>316</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://websouq.com"><span>https://websouq.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>317</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.touchndial.com"><span>https://www.touchndial.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>318</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.arjunaddz.com"><span>https://www.arjunaddz.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>319</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://arjunaddz.com"><span>https://arjunaddz.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>320</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.searchcityclassifieds.com"><span>https://www.searchcityclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>321</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://classifiedindia.co.in"><span>http://classifiedindia.co.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>322</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.hotwebads.net"><span>http://www.hotwebads.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>323</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.multidimensions.net"><span>http://www.multidimensions.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>324</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.bangaloreclassic.com"><span>https://www.bangaloreclassic.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>325</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://adycart.in"><span>http://adycart.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>326</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://clickadlink.com/newsite"><span>http://clickadlink.com/newsite</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>327</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.adsfree.in"><span>https://www.adsfree.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>328</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://clickadpost.online"><span>https://clickadpost.online</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>329</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.indiadial.com"><span>http://www.indiadial.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>330</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.universeclassifieds.com"><span>http://www.universeclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>331</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.cityclassified.co.in"><span>https://www.cityclassified.co.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>332</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://pgfreeads.co.in"><span>https://pgfreeads.co.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>333</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.pgfreeads.co.in"><span>https://www.pgfreeads.co.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>334</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.bestdial.in"><span>http://www.bestdial.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>335</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://everestads.com"><span>http://everestads.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>336</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.digitalbhoomi.com"><span>http://www.digitalbhoomi.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>337</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://digitalbhoomi.com"><span>http://digitalbhoomi.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>338</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.eclassifiedsweb.com"><span>https://www.eclassifiedsweb.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>339</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.aapkaclassified.in"><span>http://www.aapkaclassified.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>340</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://coimbatoreclassified.com"><span>http://coimbatoreclassified.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>341</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freeforads.com"><span>http://www.freeforads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>342</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.india-classifieds.in"><span>http://www.india-classifieds.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>343</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://adazpoint.com"><span>https://adazpoint.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>344</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.dealtalk.co.in"><span>https://www.dealtalk.co.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>345</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.asianclassified.com"><span>http://www.asianclassified.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>346</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.indbc.in"><span>http://www.indbc.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>347</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.goodcorner.in"><span>http://www.goodcorner.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>348</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.clickwala.in"><span>https://www.clickwala.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>349</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://justinfoline.com"><span>http://justinfoline.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>350</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://perfectads.in"><span>http://perfectads.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>351</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://izifound.in"><span>https://izifound.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>352</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.nevoie.com"><span>https://www.nevoie.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>353</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.admestic.com"><span>https://www.admestic.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>354</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://indiaclassifieds.ind.in"><span>https://indiaclassifieds.ind.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>355</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://sharmaclassified.com"><span>https://sharmaclassified.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>356</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.freelocalclassifiedads.in"><span>https://www.freelocalclassifiedads.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>357</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://locopost.com/index.html"><span>http://locopost.com/index.html</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>358</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.postforads.in"><span>http://www.postforads.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>359</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://admyad.com"><span>http://admyad.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>360</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.adexpert.in"><span>https://www.adexpert.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>361</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.delhifinder.com"><span>https://www.delhifinder.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>362</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.localsclassified.in"><span>http://www.localsclassified.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>363</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://classifiedsfree.info"><span>https://classifiedsfree.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>364</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.bnsmarket.in"><span>https://www.bnsmarket.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>365</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://localpost.in"><span>https://localpost.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>366</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://brandmyad.com"><span>https://brandmyad.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>367</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://hubbin.in"><span>https://hubbin.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>368</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://bookinglike.net"><span>https://bookinglike.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>369</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://krislon.net"><span>http://krislon.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>370</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freeonlineviewads.com"><span>http://www.freeonlineviewads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>371</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://freeonlineviewads.com"><span>http://freeonlineviewads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>372</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://avosysads.com"><span>http://avosysads.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>373</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://aligarhclassifieds.com"><span>https://aligarhclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>374</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.uttarads.com"><span>https://www.uttarads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>375</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://pittdesi.com"><span>http://pittdesi.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>376</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.jumpindeals.com"><span>https://www.jumpindeals.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>377</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://iklanbandung.com"><span>http://iklanbandung.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>378</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.ysells.com"><span>https://www.ysells.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>379</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://in.gl"><span>https://in.gl</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>380</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.ads4call.com"><span>https://www.ads4call.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>381</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://adpostchacha.com"><span>http://adpostchacha.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>382</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.mistiji.com"><span>https://www.mistiji.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>383</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.listingwala.com"><span>https://www.listingwala.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>384</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.goforads.in"><span>http://www.goforads.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>385</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.webclassi.com"><span>http://www.webclassi.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>386</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.listpe.com"><span>https://www.listpe.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>387</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.adkwi.com"><span>https://www.adkwi.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>388</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://justklassify.com"><span>http://justklassify.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>389</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://ginder.in"><span>https://ginder.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>390</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://exportersandimporters.com"><span>http://exportersandimporters.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>391</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://vadji.com"><span>http://vadji.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>392</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.delclassified.com"><span>http://www.delclassified.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>393</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.infoattouch.com"><span>http://www.infoattouch.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>394</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://indelhincr.in"><span>http://indelhincr.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>395</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.okji.in/index.php"><span>http://www.okji.in/index.php</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>396</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://nowclassifieds.com"><span>https://nowclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>397</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.zeds.in"><span>http://www.zeds.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>398</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.n1ads.com"><span>http://www.n1ads.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>399</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.heroclassifieds.com"><span>https://www.heroclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>400</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://pilxo.com"><span>https://pilxo.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>401</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.ezzly.in"><span>https://www.ezzly.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>402</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://vooqq.com"><span>http://vooqq.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>403</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://classifiedsfree.in"><span>https://classifiedsfree.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            <h1 className='text-[32px] font-medium my-3 heading'> Free Classifieds Submission Sites List for USA
                                2024</h1>
                            <div class="relative overflow-x-auto shadow-md sm:rounded-lg ">
                                <table class="w-full text-sm text-left rtl:text-right text-gray-500 darks:text-gray-400">
                                    <thead
                                        class="text-xs text-gray-700 uppercase bg-gray-50 darks:bg-gray-700 darks:text-gray-400">
                                        <tr>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                SNo.
                                            </th>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                Websites
                                            </th>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                DA
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://classifieds.miamiherald.com"><span>classifieds.miamiherald.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>90</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>2</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.newsday.com"><span>www.newsday.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>89</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>3</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.oregonlive.com"><span>www.oregonlive.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>87</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>4</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.stltoday.com"><span>www.stltoday.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>86</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>5</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.nola.com"><span>www.nola.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>85</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://classmart.post-gazette.com"><span>classmart.post-gazette.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>85</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.palmbeachpost.com"><span>www.palmbeachpost.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>84</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://marketplace.dailyherald.com"><span>marketplace.dailyherald.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>82</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.syracuse.com"><span>www.syracuse.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>80</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://classifieds.stripes.com"><span>classifieds.stripes.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>80</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://classifieds.arkansasonline.com"><span>classifieds.arkansasonline.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>80</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://us.classifieds.sulekha.com"><span>us.classifieds.sulekha.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>78</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.postandcourier.com"><span>www.postandcourier.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>76</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://classifieds.postcrescent.com"><span>classifieds.postcrescent.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>76</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.backpage.com"><span>www.backpage.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>75</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.postbulletin.com"><span>www.postbulletin.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>75</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://us.letgo.com"><span>us.letgo.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>73</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.trovit.com"><span>www.trovit.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>73</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://classified.hamptonroads.com"><span>classified.hamptonroads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>72</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.classifiedads.com"><span>www.classifiedads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>63</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.americanlisted.com"><span>www.americanlisted.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.bestclassifiedsusa.com"><span>www.bestclassifiedsusa.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>59</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.postonlineads.com"><span>www.postonlineads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>59</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.theflorentine.net"><span>www.theflorentine.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>57</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://classifieds.thefencepost.com"><span>classifieds.thefencepost.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>57</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.dropzone.com"><span>www.dropzone.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.expatriates.com"><span>www.expatriates.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.hoobly.com"><span>www.hoobly.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>54</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adpost.com"><span>www.adpost.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>54</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.racingjunk.com"><span>www.racingjunk.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>54</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.sell.com"><span>www.sell.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>51</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.yakaz.com"><span>www.yakaz.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>51</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.loot.com"><span>www.loot.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>51</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>34</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.recycler.com"><span>www.recycler.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>50</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.usfreeads.com"><span>www.usfreeads.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>50</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>36</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>www.dance.nyc</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>49</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>37</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.locanto.com"><span>www.locanto.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>47</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adlandpro.com"><span>www.adlandpro.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>46</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>39</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ponyclub.org"><span>www.ponyclub.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>46</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>40</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.usa.global-free-classified-ads.com"><span>www.usa.global-free-classified-ads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>45</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>41</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.pennysaverusa.com"><span>www.pennysaverusa.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>45</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>42</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.whereincity.com"><span>www.whereincity.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>44</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>43</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.geebo.com"><span>www.geebo.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>43</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>44</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.muamat.com"><span>www.muamat.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>43</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>45</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.claz.org"><span>www.claz.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>42</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>46</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://whofish.org"><span>whofish.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>42</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>47</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.buysellcommunity.com"><span>www.buysellcommunity.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>42</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>48</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.usnetads.com"><span>www.usnetads.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>41</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>49</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.petclassifieds.us"><span>www.petclassifieds.us</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>40</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>50</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://thriftynickel.com"><span>thriftynickel.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>40</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>51</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://highlightstory.com"><span>highlightstory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>40</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>52</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.usa.freeclassifieds.com"><span>www.usa.freeclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>39</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>53</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freead1.net"><span>www.freead1.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>39</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>54</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.usaonlineclassifieds.com"><span>www.usaonlineclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>39</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.classifieds.ivarta.com"><span>www.classifieds.ivarta.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.domesticsale.com"><span>www.domesticsale.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>57</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.yourclassifieds.ca"><span>www.yourclassifieds.ca</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>58</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ocala4sale.com"><span>www.ocala4sale.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>37</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>59</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.mitula.in"><span>www.mitula.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>36</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>60</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.classifiedsforfree.com"><span>www.classifiedsforfree.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>36</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://classifiedsforfree.com"><span>classifiedsforfree.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>36</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.globelink.ca"><span>www.globelink.ca</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>36</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>63</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.kugli.com"><span>www.kugli.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.webclassifieds.us"><span>www.webclassifieds.us</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>34</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>65</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.citybase.com"><span>www.citybase.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>34</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>66</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.50statesclassifieds.com"><span>www.50statesclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>34</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>67</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://classified4free.net"><span>classified4free.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>68</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.nullads.org"><span>www.nullads.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>69</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://citynews.com"><span>citynews.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>70</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ablewise.com"><span>www.ablewise.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>71</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.whynotad.com"><span>www.whynotad.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>72</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adsriver.com"><span>www.adsriver.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>73</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.postallads4free.com"><span>www.postallads4free.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>74</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.postfreeadshere.com"><span>www.postfreeadshere.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>75</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://globalclassified.net"><span>globalclassified.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>76</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.jobiba.com"><span>www.jobiba.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>77</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.pressmania.com"><span>www.pressmania.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>78</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.99localads.com"><span>www.99localads.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>79</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://adfreeposting.com"><span>adfreeposting.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>80</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.free-classifieds-usa.com"><span>www.free-classifieds-usa.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>81</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.10dayads.com"><span>www.10dayads.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>82</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://listitsellit.us"><span>listitsellit.us</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>83</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adsciti.com"><span>www.adsciti.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>84</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freeadvertisingexchange.com"><span>www.freeadvertisingexchange.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>85</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.beatyourprice.com"><span>www.beatyourprice.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>86</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.golocalclassified.com"><span>www.golocalclassified.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>87</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freeadsinus.com"><span>www.freeadsinus.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>88</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.classifieds4me.com"><span>www.classifieds4me.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>89</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adsglobe.com"><span>www.adsglobe.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>90</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.classtize.com"><span>www.classtize.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>91</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://us.princeclassified.com"><span>us.princeclassified.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>92</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.tendermeforfree.com"><span>www.tendermeforfree.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>93</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.askalo.com"><span>www.askalo.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>94</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.myadsclassified.com"><span>www.myadsclassified.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>95</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://planetclassifieds.com"><span>planetclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>96</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.cycleclassifieds.us"><span>www.cycleclassifieds.us</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>97</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.boatclassifieds.us"><span>www.boatclassifieds.us</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>98</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://adoist.com"><span>adoist.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>99</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freeadscity.com"><span>www.freeadscity.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>100</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.usonlineads.com"><span>www.usonlineads.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>101</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.apneecommunity.com"><span>www.apneecommunity.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>102</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.kedna.com"><span>www.kedna.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>103</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://bizdays.com"><span>bizdays.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>104</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.classifiedny.com"><span>www.classifiedny.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>105</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.fmclassifieds.com"><span>www.fmclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>106</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://oc2o.com"><span>oc2o.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>107</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.oc2o.com"><span>www.oc2o.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>108</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://busylisting.com"><span>busylisting.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>109</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.buynow-us.com"><span>www.buynow-us.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>110</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ismartads.com"><span>www.ismartads.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>111</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://adeum.com"><span>adeum.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>112</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.upostfreeads.com"><span>www.upostfreeads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>113</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.imakedeal.com"><span>www.imakedeal.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>114</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.cathaylist.com"><span>www.cathaylist.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>115</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://usa.sebule.com"><span>usa.sebule.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>116</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.gofreeclassified.com"><span>www.gofreeclassified.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>117</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.evolskill.com"><span>www.evolskill.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>118</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.newyorkcity.anunico.us"><span>www.newyorkcity.anunico.us</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>119</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ipostfreeads.com"><span>www.ipostfreeads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>120</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.myadmonster.com"><span>www.myadmonster.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>121</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://biggestclassifieds.com"><span>biggestclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>122</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.reifreeclassifieds.com"><span>www.reifreeclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>123</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.buyfromseller.com"><span>www.buyfromseller.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>124</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://usa.classifiedsfree.co"><span>usa.classifiedsfree.co</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>125</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adsanddeals.com"><span>www.adsanddeals.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>126</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://adclassified.com"><span>adclassified.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>127</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.aladeen.com"><span>www.aladeen.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>128</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.doplim.us"><span>www.doplim.us</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>129</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>www.freeclassified.biz</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>130</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.publicdial.com"><span>www.publicdial.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>131</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.usahotads.com"><span>www.usahotads.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>132</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adsfeast.com"><span>www.adsfeast.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>133</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.post-free-ads.com"><span>www.post-free-ads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>134</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.growingtab.com"><span>www.growingtab.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>135</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.goodideo.com"><span>www.goodideo.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>136</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.postads.com"><span>www.postads.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>137</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.classifiedadsguru.com"><span>www.classifiedadsguru.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>138</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://mashoble.com"><span>mashoble.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>139</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.gotoretailer.com"><span>www.gotoretailer.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>140</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.multidimensions.net"><span>www.multidimensions.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>141</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.worldfreeads.com"><span>www.worldfreeads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>142</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.exgood.com"><span>www.exgood.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>143</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.kiclassifieds.com"><span>www.kiclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>144</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://betterbizlist.com"><span>betterbizlist.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>145</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.superads.com"><span>www.superads.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>146</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.postandcore.com"><span>www.postandcore.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>147</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.vfreeads.com"><span>www.vfreeads.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>148</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://sellbox.info"><span>sellbox.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>149</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.postfreeonlineads.com"><span>www.postfreeonlineads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>150</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freeclassifiedads.us"><span>www.freeclassifiedads.us</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>151</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.pgfreeads.com"><span>www.pgfreeads.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>152</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://advertclassifieds.com"><span>advertclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>153</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.hpjclassifieds.com"><span>www.hpjclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>154</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://orlandodesi.net"><span>orlandodesi.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>155</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freeadsus.com"><span>www.freeadsus.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>156</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freepennysaver.com"><span>www.freepennysaver.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>157</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freelocalclassifiedads.us"><span>www.freelocalclassifiedads.us</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>158</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://classifiedsforfree.org"><span>classifiedsforfree.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>159</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://selkr.com"><span>selkr.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>160</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.arizonaslist.com"><span>www.arizonaslist.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>161</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://classifiedadsunlimited.com"><span>classifiedadsunlimited.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>162</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.usmilitaryclassifieds.com"><span>www.usmilitaryclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>163</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freeclassified4u.com"><span>www.freeclassified4u.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>164</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ww.classifiedsmate.com"><span>ww.classifiedsmate.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>165</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.zalooz.com"><span>www.zalooz.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            <h1 className='text-[32px] font-medium my-3 heading'>Free Classified Sites for the UK 2024</h1>
                            <div class="relative overflow-x-auto shadow-md sm:rounded-lg ">
                                <table class="w-full text-sm text-left rtl:text-right text-gray-500 darks:text-gray-400">
                                    <thead
                                        class="text-xs text-gray-700 uppercase bg-gray-50 darks:bg-gray-700 darks:text-gray-400">
                                        <tr>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                SNo.
                                            </th>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                Websites
                                            </th>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                DA
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://gumtree.com"><span>gumtree.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>84</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>2</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://adpost.com"><span>adpost.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>54</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>3</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://vivastreet.co.uk"><span>vivastreet.co.uk</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>53</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>4</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.vivastreet.co.uk"><span>www.vivastreet.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>53</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>5</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://friday-ad.co.uk"><span>friday-ad.co.uk</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>50</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.friday-ad.co.uk"><span>www.friday-ad.co.uk</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>50</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ukclassifieds.co.uk"><span>ukclassifieds.co.uk</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.locanto.co.uk"><span>www.locanto.co.uk</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://adoos.co.uk"><span>adoos.co.uk</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ad-mart.co.uk"><span>ad-mart.co.uk</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.chaosads.com"><span>www.chaosads.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.postadverts.com"><span>www.postadverts.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://adeex.co.uk"><span>adeex.co.uk</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.london-village.co.uk"><span>www.london-village.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adsfarm.co.uk"><span>www.adsfarm.co.uk</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://classifieds.co.uk"><span>classifieds.co.uk</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ukfreeads.ws"><span>ukfreeads.ws</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://cambridgedirectory.co.uk"><span>cambridgedirectory.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://portsmouthexchange.co.uk"><span>portsmouthexchange.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://exeterexchange.co.uk"><span>exeterexchange.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.petsinparticular.co.uk"><span>www.petsinparticular.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.your-ads.co.uk"><span>www.your-ads.co.uk</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://lutonexchange.co.uk"><span>lutonexchange.co.uk</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://dundeeexchange.co.uk"><span>dundeeexchange.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.gbads.co.uk"><span>www.gbads.co.uk</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://newcastleexchange.co.uk"><span>newcastleexchange.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://wakefieldexchange.co.uk"><span>wakefieldexchange.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://edinburghexchange.co.uk"><span>edinburghexchange.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://plymouthexchange.co.uk"><span>plymouthexchange.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://southamptonexchange.co.uk"><span>southamptonexchange.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://sheffieldexchange.co.uk"><span>sheffieldexchange.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://bradford-exchange.co.uk"><span>bradford-exchange.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://leedsexchange.co.uk"><span>leedsexchange.co.uk</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>34</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.secondhandfinder.co.uk"><span>www.secondhandfinder.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://liverpoolexchange.co.uk"><span>liverpoolexchange.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>36</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://sunderlandexchange.co.uk"><span>sunderlandexchange.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>37</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://southendexchange.co.uk"><span>southendexchange.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://cardiffexchange.co.uk"><span>cardiffexchange.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>39</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://brightonexchange.co.uk"><span>brightonexchange.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>40</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://glasgowexchange.co.uk"><span>glasgowexchange.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>41</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://bournemouthexchange.co.uk"><span>bournemouthexchange.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>42</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://aberdeenexchange.co.uk"><span>aberdeenexchange.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>43</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://belfastexchange.co.uk"><span>belfastexchange.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            <h1 className='text-[32px] font-medium my-3 heading'>Free Classified Sites for Australia 2024</h1>
                            <div class="relative overflow-x-auto shadow-md sm:rounded-lg ">
                                <table class="w-full text-sm text-left rtl:text-right text-gray-500 darks:text-gray-400">
                                    <thead
                                        class="text-xs text-gray-700 uppercase bg-gray-50 darks:bg-gray-700 darks:text-gray-400">
                                        <tr>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                SNo.
                                            </th>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                Websites
                                            </th>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                DA
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://geo.craigslist.org"><span>geo.craigslist.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>89</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>2</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.truelocal.com.au"><span>www.truelocal.com.au</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>82</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>3</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.gumtree.com.au"><span>www.gumtree.com.au</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>70</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>4</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.examiner.com.au"><span>www.examiner.com.au</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>65</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>5</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.classifiedads.com"><span>www.classifiedads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>63</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.qt.com.au"><span>www.qt.com.au</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>63</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adpost.com"><span>www.adpost.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>54</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.quicksales.com.au"><span>www.quicksales.com.au</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>49</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.tradingpost.com.au"><span>www.tradingpost.com.au</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>46</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://tradingpost.com.au"><span>tradingpost.com.au</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>46</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://australia.global-free-classified-ads.com"><span>australia.global-free-classified-ads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>45</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.portpirierecorder.com.au"><span>www.portpirierecorder.com.au</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>42</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://australia.cracker.com.au"><span>australia.cracker.com.au</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>40</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.locanto.com.au"><span>www.locanto.com.au</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.au.jantareview.com"><span>www.au.jantareview.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.newsclassifieds.com.au"><span>www.newsclassifieds.com.au</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.aufreeads.com"><span>www.aufreeads.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.bondiclassifieds.com.au"><span>www.bondiclassifieds.com.au</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.fiwiclassifieds.com"><span>www.fiwiclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.australia.qtellads.com"><span>www.australia.qtellads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://adelaideexchange.com.au"><span>adelaideexchange.com.au</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://hukes.com"><span>hukes.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.gopost.com.au"><span>www.gopost.com.au</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.melbourneaustraliaclassifieds.com"><span>www.melbourneaustraliaclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            <h1 className='text-[32px] font-medium my-3 heading'>Free Classified Sites for Canada</h1>
                            <div class="relative overflow-x-auto shadow-md sm:rounded-lg ">
                                <table class="w-full text-sm text-left rtl:text-right text-gray-500 darks:text-gray-400">
                                    <thead
                                        class="text-xs text-gray-700 uppercase bg-gray-50 darks:bg-gray-700 darks:text-gray-400">
                                        <tr>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                SNo.
                                            </th>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                Websites
                                            </th>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                DA
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://classifieds.nationalpost.com"><span>classifieds.nationalpost.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>90</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>2</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.kijiji.ca"><span>www.kijiji.ca</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>79</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>3</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://classifieds.justlanded.com"><span>classifieds.justlanded.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>76</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>4</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://sggreek.com"><span>sggreek.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>63</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>5</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.expat.com"><span>www.expat.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.oorgin.com"><span>www.oorgin.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://classifiedmake.host22.com"><span>classifiedmake.host22.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>59</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://shiftkiya.com"><span>shiftkiya.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>59</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://botw.org"><span>botw.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adpost.com"><span>www.adpost.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>54</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.hupso.com"><span>www.hupso.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>54</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://freeadshare.com"><span>freeadshare.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>50</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://orizorsoftech.com"><span>orizorsoftech.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>48</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.canadiancyclist.com"><span>www.canadiancyclist.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>47</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adlandpro.com"><span>www.adlandpro.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>46</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.muamat.com"><span>www.muamat.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>43</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.abc-directory.com"><span>www.abc-directory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freeadstime.org"><span>www.freeadstime.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>36</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.classifiedsforfree.com"><span>www.classifiedsforfree.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>36</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://dewalist.com"><span>dewalist.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ablewise.com"><span>www.ablewise.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.locanto.ca"><span>www.locanto.ca</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.jobiba.com"><span>www.jobiba.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://au.jantareview.com"><span>au.jantareview.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.indiagrid.com"><span>www.indiagrid.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://education-training-lessons.freeadsaustralia.com"><span>education-training-lessons.freeadsaustralia.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://westernaustralia.khrido.com"><span>westernaustralia.khrido.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.indyapages.com"><span>www.indyapages.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://addsera.com"><span>addsera.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.fiwiclassifieds.com"><span>www.fiwiclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.redleafads.com"><span>www.redleafads.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.mapleme.com"><span>www.mapleme.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://nambour.chaosads-australia.com"><span>nambour.chaosads-australia.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>34</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.myadmonster.com"><span>www.myadmonster.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://australia.qtellads.com"><span>australia.qtellads.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>36</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://australia.metrosources.com"><span>australia.metrosources.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>37</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://cheapwb.com"><span>cheapwb.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.cdncc.com"><span>www.cdncc.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>39</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://au.bizzyo.com"><span>au.bizzyo.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>40</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.classified.ca"><span>www.classified.ca</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>41</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://hukes.com"><span>hukes.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>42</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.postad.ca"><span>www.postad.ca</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>43</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.bargaincity.ca"><span>www.bargaincity.ca</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>44</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.free4uclassifieds.com"><span>www.free4uclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>45</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://perthonline.net"><span>perthonline.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                            <h1 className='text-[32px] font-medium my-3 heading mt-12'> How to Make a Blog Submission?</h1>
                            <p className='text-[18px] font-normal text-[#636363] '>The basic procedures for submitting your blog
                                to a blog submission platform are as follows:</p> <br />
                            <ul className='bg-[#f4dacd70] p-8'>
                                <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                    className='text-[#63E6BE] mr-2'><i
                                        class="fa-regular fa-square-check"></i></span>Research Blog Submission
                                    Sites:</strong> Look for well-known blog submission sites associated with your niche
                                    when researching blog submission sites. To locate these websites, utilize directories,
                                    social media, or search engines.</li>
                                <br />
                                <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                    className='text-[#63E6BE] mr-2'><i
                                        class="fa-regular fa-square-check"></i></span>Create an Account:</strong> If
                                    required, establish an account after selecting a blog submission website to utilize. Before
                                    submitting your blog, you may be required to sign up on some websites.</li>
                                <br />
                                <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                    className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>Go
                                    Over the Rules:</strong> There are rules specific to each blog submission site that you
                                    must adhere to. Ensure you meet all the requirements by thoroughly reading the guidelines
                                    before submitting your blog.</li>
                                <br />
                                <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                    className='text-[#63E6BE] mr-2'><i
                                        class="fa-regular fa-square-check"></i></span>Write a Pickup Headline:</strong>
                                    Create a compelling title because that's what readers will immediately see. Ensure it
                                    captures the essence of your blog's content and is visually appealing.</li>
                                <br />
                                <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                    className='text-[#63E6BE] mr-2'><i
                                        class="fa-regular fa-square-check"></i></span>Create a Compelling
                                    Summary:</strong> Provide a summary of your blog that draws the reader in and highlights
                                    the essential ideas. Ensure that it is compelling and compels viewers to visit your blog.
                                </li>
                                <br />
                                <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                    className='text-[#63E6BE] mr-2'><i
                                        class="fa-regular fa-square-check"></i></span>Include a Link to Your
                                    Blog:</strong> When submitting, include a link to your blog article in the form. Verify
                                    that the link is accurate and points to the right post.</li>
                                <br />
                                <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                    className='text-[#63E6BE] mr-2'><i
                                        class="fa-regular fa-square-check"></i></span>Submit Your Blog:</strong> Once
                                    the submission form has been completed, send in your blog. The approval and publication of
                                    your blog on the website could take several days or weeks.</li>
                                <br />
                                <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                    className='text-[#63E6BE]'><i class="fa-regular fa-square-check "></i></span>
                                    Promote Your Published Blog:</strong> Share it on social media, your website, and other
                                    channels to increase its visibility and audience once published on the submission site.</li>
                            </ul>

                            <div className="faqs mb-12">
                                <h1 className='text-[32px] font-medium my-3 heading'> FAQs
                                </h1>
                                <div id="accordion-collapse" data-accordion="collapse">
                                    <h2 id="accordion-collapse-heading-1">
                                        <button type="button"
                                            class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200  hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                            data-accordion-target="#accordion-collapse-body-1" aria-expanded="true"
                                            aria-controls="accordion-collapse-body-1">
                                            <span className='text-[20px] text-[#222] text-left'>What Types of Ads Can I Post on Classified Submission Sites?</span>
                                            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                    strokeWidth="2" d="M9 5 5 1 1 5" />
                                            </svg>
                                        </button>
                                    </h2>
                                    <div id="accordion-collapse-body-1" class="hidden"
                                        aria-labelledby="accordion-collapse-heading-1">
                                        <div
                                            class="p-5 border border-b-0 border-gray-200 darks:border-gray-700 darks:bg-gray-900">
                                            <p className='text-[18px] font-normal text-[#636363] '>The majority of classified submission websites don't charge for posting ads. However, some might want premium placement or other benefits. Posts can be made in a number of areas, such as employment, property, cars, services, and goods for sale.</p>
                                        </div>
                                    </div>
                                    <h2 id="accordion-collapse-heading-2">
                                        <button type="button"
                                            class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                            data-accordion-target="#accordion-collapse-body-2" aria-expanded="false"
                                            aria-controls="accordion-collapse-body-2">
                                            <span className='text-[20px] text-[#222] text-left'>Can I Post Ads For My Business on Classified Ads Websites?</span>
                                            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                    strokeWidth="2" d="M9 5 5 1 1 5" />
                                            </svg>
                                        </button>
                                    </h2>
                                    <div id="accordion-collapse-body-2" class="hidden"
                                        aria-labelledby="accordion-collapse-heading-2">
                                        <div class="p-5 border border-b-0 border-gray-200 darks:border-gray-700">
                                            <p className='text-[18px] font-normal text-[#636363] '>Yes, you can use classified advertisements to advertise your business. Websites that let users publish advertisements for their goods or services are known as classified submission sites.</p>
                                        </div>
                                    </div>
                                    <h2 id="accordion-collapse-heading-3">
                                        <button type="button"
                                            class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                            data-accordion-target="#accordion-collapse-body-3" aria-expanded="false"
                                            aria-controls="accordion-collapse-body-3">
                                            <span className='text-[20px] text-[#222] text-left'>How Do I Create an Effective Ad For Classified Submission Sites?</span>
                                            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                    strokeWidth="2" d="M9 5 5 1 1 5" />
                                            </svg>
                                        </button>
                                    </h2>
                                    <div id="accordion-collapse-body-3" class="hidden"
                                        aria-labelledby="accordion-collapse-heading-3">
                                        <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                            <p className='text-[18px] font-normal text-[#636363] '>It is important to pay close attention to a few essential components to guarantee that your classified posting site advertisement attracts attention and prompts answers. Use Clear and Concise Headlines, Compelling Descriptions, High-Quality Images, Relevant Keywords, Add Contact Information, etc., to make the most out of your submission. </p>
                                        </div>
                                    </div>

                                    <h2 id="accordion-collapse-heading-4">
                                        <button type="button"
                                            class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                            data-accordion-target="#accordion-collapse-body-4" aria-expanded="false"
                                            aria-controls="accordion-collapse-body-4">
                                            <span className='text-[20px] text-[#222] text-left'>How Long Does It Take For an Ad to Go Live on a Classified Submission Site?</span>
                                            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                    strokeWidth="2" d="M9 5 5 1 1 5" />
                                            </svg>
                                        </button>
                                    </h2>
                                    <div id="accordion-collapse-body-4" class="hidden"
                                        aria-labelledby="accordion-collapse-heading-4">
                                        <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                            <p className='text-[18px] font-normal text-[#636363] '>An advertisement on a classified submission site may take a few minutes to several hours to go up. However, this can vary.

                                                Sites that accept classified submissions typically try to evaluate and approve advertising as soon as possible, frequently in a matter of hours or days. Still, certain websites might require more time, particularly if they employ a manual review procedure or get a large number of submissions.
                                            </p>
                                        </div>
                                    </div>

                                    <h2 id="accordion-collapse-heading-5">
                                        <button type="button"
                                            class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                            data-accordion-target="#accordion-collapse-body-5" aria-expanded="false"
                                            aria-controls="accordion-collapse-body-5">
                                            <span className='text-[20px] text-[#222] text-left'>Can I Edit or Delete My Ad on Classified Ads Websites?</span>
                                            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                    strokeWidth="2" d="M9 5 5 1 1 5" />
                                            </svg>
                                        </button>
                                    </h2>
                                    <div id="accordion-collapse-body-5" class="hidden"
                                        aria-labelledby="accordion-collapse-heading-5">
                                        <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                            <p className='text-[18px] font-normal text-[#636363] '>Sure, most websites that accept classified submissions let users change or remove their advertising as needed. All of your placed ads will show up in the center screen once you log in by just clicking the 'My Ads' link. Clicking "Update" will bring up the ad posting box if you want to update your ad. Additionally, you have the option to edit or remove your photos.</p>
                                        </div>
                                    </div>

                                    <h2 id="accordion-collapse-heading-6">
                                        <button type="button"
                                            class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                            data-accordion-target="#accordion-collapse-body-7" aria-expanded="false"
                                            aria-controls="accordion-collapse-body-7">
                                            <span className='text-[20px] text-[#222] text-left'>Are Classified Submission Sites Safe To Use?</span>
                                            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                    strokeWidth="2" d="M9 5 5 1 1 5" />
                                            </svg>
                                        </button>
                                    </h2>
                                    <div id="accordion-collapse-body-7" class="hidden"
                                        aria-labelledby="accordion-collapse-heading-6">
                                        <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                            <p className='text-[18px] font-normal text-[#636363] '>Considering dependability and safety, classified submitting sites can differ greatly. As with any internet platform, it's crucial to use caution and abide by the rules in order to protect your safety. Deal carefully with reliable buyers or sellers and be on the lookout for scams or false adverts.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h1 className='text-[32px] font-medium my-3 heading mt-12'> Conclusion</h1>
                            <p className='text-[18px] font-normal text-[#636363] '>Sites for posting classified ads are an effective resource for companies and private citizens wishing to advertise their goods and services online.</p>

                            <p className='text-[18px] font-normal text-[#636363] '>You may use classified posting sites to improve your internet presence, drive traffic to your website, and generate leads if you follow these guidelines.</p>

                            <p className='text-[18px] font-normal text-[#636363] '>Don't forget to pick the right website, make an engaging advertisement, utilize relevant keywords, post in the appropriate category, and keep an eye on and update your ad frequently. You may maximize the benefits of classified submission sites for your company by using these tactics.</p>
                        </div>
                        <div className="w-[30%] hidden lg:block relative">
                            <div className='box-shadow rounded-lg ml-5 p-8 sidebar sticky top-[-150px]'>
                                <div className="sidebar_banner">
                                    <img src="img/page_img_4.jpg" alt="" />
                                </div>

                                <div className="sidebar_tabs mt-5">
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/profile-creation-sites-list">
                                            <h1 className='text-[18px] font-semibold capitalize'>Top High Do Follow Profile Creation
                                                Sites List</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/image-submission-sites-list">
                                            <h1 className='text-[18px] font-semibold capitalize'>image-submission-sites-list</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/classified-ads-websites">
                                            <h1 className='text-[18px] font-semibold capitalize'>classified-ads-websites</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/instant-approval-article-submissions-sites-list">
                                            <h1 className='text-[18px] font-semibold capitalize'>instant-approval-article-submissions-sites-list</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/free-high-quality-directory-submission-sites">
                                            <h1 className='text-[18px] font-semibold capitalize'>free-high-quality-directory-submission-sites
                                                Sites List</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/blog-submission-sites">
                                            <h1 className='text-[18px] font-semibold capitalize'>blog-submission-sites</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/business-listing-sites-in-india">
                                            <h1 className='text-[18px] font-semibold capitalize'>business-listing-sites-in-india</h1>
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ClassifiedAdsWebsites