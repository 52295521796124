import React from 'react'
import Header from './Header';
import Footer from './Footer';
import { Link } from "react-router-dom";

function FreeHighQualityDirectorySubmissionSites() {
    return (
        <>
            <Header />
            <div className="container-fluid p-3 lg:p-0">
                <div className="container mx-auto py-12">
                    <h1 className='text-[28px] md:text-[42px] font-medium heading  mb-8 '> Free High-Quality Directory
                        Submission Sites List in 2024</h1>
                    <div className="flex flex-wrap md:flex-nowrap pt-12 border-t-2">
                        <div className="w-full md:w-[70%] p-10 box-shadow mr-10 rounded-lg">
                            <div className="text_contenr pt-3 pb-8">
                                <p className='text-[18px] font-normal text-[#636363] '>One of the most overlooked strategies in
                                    the toolkit of modern SEO might be link-building from reputable, locally relevant,
                                    industry-related directories.</p><br />

                                <p className='text-[18px] font-normal text-[#636363] '>One can think of sites that submit spam
                                    links. Many people think of directories as the kind that will link to any website,
                                    regardless of whether it originates from the lower reaches of the web's underworld. </p>
                                <br />
                                <p className='text-[18px] font-normal text-[#636363] '>Directory submission is the main
                                    component of off-page search engine optimization, submitting website links and data to a web
                                    directory that falls under a particular group.</p> <br />

                                <p className='text-[18px] font-normal text-[#636363] '>You can optimize link-building results
                                    and observe an improvement in a website's overall SEO by submitting it to directories.</p>
                                <br />

                                <p className='text-[18px] font-normal text-[#636363] '>We have an example to help you better
                                    understand the directory submission principle. Since you are the site's owner, you can
                                    publish your blog with technical content to science and technology archives, which is an
                                    incredible approach to getting backlinks for well-ranked blogs. Web archives are similar to
                                    phone directories.</p> <br />

                                <h1 className='text-[32px] font-medium my-3 heading'> What is Directory Submission in SEO?</h1>
                                <br />

                                <p className='text-[18px] font-normal text-[#636363] '>Submitting a website's details, including
                                    its URL, description, and category, to online directories to include it in suitable
                                    categories is known as directory submission in SEO. Increasing traffic, search engine
                                    rankings, and website visibility are the objectives of directory submission.</p> <br />

                                <p className='text-[18px] font-normal text-[#636363] '>Directory submission is one off-page SEO
                                    tactic that helps increase website backlinks. Search engine algorithms use backlinks, or
                                    links pointing to your website from other websites, to decide how highly ranked a website
                                    should appear in search results.</p> <br />

                                <p className='text-[18px] font-normal text-[#636363] '>A website's domain authority, a gauge of
                                    its online legitimacy and reputation, can also be enhanced by directory submission. A
                                    website's domain authority and search engine appeal can increase when featured in reputable
                                    directories.</p> <br />

                                <p className='text-[18px] font-normal text-[#636363] '>It's crucial to remember that not every
                                    website that accepts directory submissions is made equal. A website's SEO may be harmed by
                                    directories that are of low quality or that have a bad reputation. To minimize any
                                    detrimental effects on a website's internet presence, pick reputable directories and abide
                                    by their rules.</p> <br />

                                <h1 className='text-[32px] font-medium my-3 heading'> What are the Benefits of Directory
                                    Submission?</h1><br />

                                <p className='text-[18px] font-normal text-[#636363] '>Submitting a website to different online
                                    directories to include it in relevant categories is known as directory submission. The
                                    following are a few advantages of submitting to directories:</p> <br />

                                <ul className='bg-[#f4dacd70] p-8'>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Enhanced visibility:</strong>
                                        Websites can reach a larger audience by submitting to directories. Users can find
                                        websites more quickly when they are listed in directories. Potential clients and more
                                        traffic may result from this.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Targeted traffic:</strong>
                                        Directory submission websites typically have topical categories that facilitate users'
                                        discovery of websites about their interests. This implies that companies can connect
                                        with a more niche market eager to use their goods or services.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Brand recognition: </strong>
                                        Companies can build their brand and improve brand recognition by listing in directories.
                                        Potential clients may view you more favourably and with greater trustworthiness.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Increased domain
                                        authority:</strong> A website's domain authority may rise due to its listing in
                                        reliable directories. Search engines utilize domain authority as a ranking criterion to
                                        assess the authority and relevancy of a website.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Better Search Engine
                                        Ranking:</strong> When you list your website in reputable directories, it will
                                        receive the most exposure possible on the internet. Submitting to directories is a
                                        fantastic method to increase link building and improve your search engine results.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Increase Link
                                        Popularity:</strong> Obtaining superior backlinks is a primary advantage of
                                        submitting to directories. Search engines always consider the number of backlinks you
                                        receive. The directory submission will provide the necessary backlinks to your website.
                                    </li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Indexing of Pages and Blog
                                        Posts: </strong> Search engine indexing is ensured through directory submission.
                                        When you submit your website to the directories, search engines will index your website.
                                        The search engines will find your website by following the backlinks from other
                                        websites.</li>
                                    <br />
                                </ul>

                                <p className='text-[18px] font-normal text-[#636363] '>Developing backlinks for your website is
                                    an easy process. You should choose the best high PR directory submission sites or
                                    appropriate DA sites when submitting your website. Your sites will benefit from free
                                    directory submission to increase your SEO ranking or link diversity.</p> <br />


                                <h1 className='text-[32px] font-medium my-3 heading'> What are the Types of Directory Submission
                                    Sites?
                                </h1><br />

                                <p className='text-[18px] font-normal text-[#636363] '>Building specialized backlinks to your
                                    website through directory submission is a step-by-step process that involves submitting a
                                    URL to Web Free directory submission services. It is among the most effective and simple
                                    methods for creating high-quality backlinks.</p> <br />

                                <p className='text-[18px] font-normal text-[#636363] '>Submissions to directories can be made in
                                    various ways, including reciprocal, paid, and free.</p> <br />

                                <ul className='bg-[#f4dacd70] p-8'>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Free Directory Submission
                                        Sites:</strong>
                                        This type of directory submission is ideal if you desire to submit your blog URL for
                                        free. The drawback is that adding your URL to the directory list and approving it will
                                        take many weeks. It is still the most effective method for submitting a URL to
                                        directories</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Paid Directory Submission
                                        Sites:</strong>This is an option if you want to develop links fast. The website URL
                                        you submit here will be added in a few hours. The quickest method for creating a
                                        high-quality link is this one.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Reciprocal Directory
                                        Submission Sites:</strong>To submit your URL in this type, you must include a link
                                        to your own website on the directory website. Once the website owner verifies your link,
                                        it will be accepted to the directory submission site.</li>
                                    <br />
                                </ul>

                                <h1 className='text-[32px] font-medium my-3 heading'> How Do You Submit a Website to a Directory
                                    Site?
                                </h1><br />

                                <p className='text-[18px] font-normal text-[#636363] '>The following instructions will assist
                                    you in submitting your website to any directory website. To successfully approve a link,
                                    adhere to all the requirements below.</p> <br />

                                <ul className='bg-[#f4dacd70] p-8'>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Step 1:</strong>
                                        Click on any link in the provided website list.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Step 2:</strong> Choose the
                                        kind of submission from the list of options.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Step 3:</strong>Enter the
                                        email address, website URL, description, and title.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Step 4:</strong>From the
                                        drop-down list, choose the category.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Step 5:</strong>Enter the URL
                                        of the page on your website where you placed the code if you choose the reciprocal type.
                                    </li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Step 6:</strong>To complete
                                        the link submission, click the proceed button.</li>
                                    <br />
                                </ul>


                                <h1 className='text-[32px] font-medium my-3 heading'> Free Directory Submission Sites List With
                                    High Page Rank</h1>

                                <p className='text-[18px] font-normal text-[#636363] '>We constantly need high-quality backlinks
                                    for our website to rank on search engines like Google, Yahoo, and others. Using this
                                    High-Quality Directory Submission Sites list, you can get High-Quality Dofollow Backlinks
                                    for your website.</p> <br />

                            </div>
                            <div class="relative overflow-x-auto shadow-md sm:rounded-lg ">
                                <table class="w-full text-sm text-left rtl:text-right text-gray-500 darks:text-gray-400">
                                    <thead
                                        class="text-xs text-gray-700 uppercase bg-gray-50 darks:bg-gray-700 darks:text-gray-400">
                                        <tr>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                SNo.
                                            </th>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                DIRECTORY SUBMISSION SITES
                                            </th>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                DA (DOMAIN AUTHORITY)
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://pinterest.com/"><span>pinterest.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>94</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>2</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://reddit.com/"><span>reddit.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>92</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>3</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://stumbleupon.com/"><span>stumbleupon.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>90</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>4</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://boingboing.net/"><span>boingboing.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>89</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>5</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.dmoz.org/"><span>www.dmoz.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>84</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://starsdirectory.com.ar/"><span>starsdirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>69</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.bidsyndicate.com.ar/"><span>https://www.bidsyndicate.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>69</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.redlavadirectory.com.ar/"><span>http://www.redlavadirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>69</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.redlavadirectory.com.ar/"><span>http://www.redlavadirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>69</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://starsdirectory.com.ar/"><span>http://starsdirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>69</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://redlavadirectory.com.ar/"><span>http://redlavadirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>69</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://unionofdirectories.com/"><span>unionofdirectories.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>68</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://seofriendly.com.ar/"><span>seofriendly.com.ar</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>68</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://freewebdirectory.com.ar/"><span>freewebdirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>68</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://surat.directorycloud.info/"><span>http://surat.directorycloud.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>68</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.directorycloud.info/"><span>http://www.directorycloud.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>68</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.hotfrogbiz.com.ar/"><span>http://www.hotfrogbiz.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>68</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://seofriendly.com.ar/"><span>http://seofriendly.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>68</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.unionofdirectories.com/"><span>http://www.unionofdirectories.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>68</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://dirjournal.info/"><span>http://dirjournal.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>68</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://unionofdirectories.com/"><span>http://unionofdirectories.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>68</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.linkforfree.com.ar/"><span>http://www.linkforfree.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>68</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://biddingdirectory.com.ar/"><span>http://biddingdirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>68</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://karnataka.seofriendly.com.ar/"><span>http://karnataka.seofriendly.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>68</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freewebdirectory.com.ar/"><span>http://www.freewebdirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>68</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://freewebdirectory.com.ar/"><span>http://freewebdirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>68</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://directorycloud.info/"><span>http://directorycloud.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>68</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.linkforfree.com.ar/"><span>http://www.linkforfree.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>68</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.seofriendly.com.ar/"><span>http://www.seofriendly.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>68</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://mywebdirectory.com.ar/"><span>mywebdirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>67</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://linkshere.com.ar/"><span>linkshere.com.ar</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>67</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ncdirectory.com.ar/"><span>ncdirectory.com.ar</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>67</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.intercambioseo.com/"><span>http://www.intercambioseo.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>67</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>34</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://directorycodes.com/"><span>http://directorycodes.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>67</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.websitelist.com.ar/"><span>http://www.websitelist.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>67</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>36</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ncdirectory.com.ar/"><span>http://www.ncdirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>67</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>37</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.zendirectory.com.ar/"><span>http://www.zendirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>67</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ncdirectory.com.ar/"><span>http://www.ncdirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>67</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>39</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.24directory.com.ar/"><span>http://www.24directory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>67</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>40</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.mywebdirectory.com.ar/"><span>http://www.mywebdirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>67</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>41</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://intercambioseo.com/"><span>http://intercambioseo.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>67</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>42</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://mywebdirectory.com.ar/"><span>http://mywebdirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>67</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>43</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ncdirectory.com.ar/"><span>http://ncdirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>67</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>44</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://websitelist.com.ar/"><span>http://websitelist.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>67</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>45</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://zendirectory.com.ar/"><span>http://zendirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>67</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>46</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://prdirectory.com.ar/"><span>prdirectory.com.ar</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>66</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>47</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://submitlink.com.ar/"><span>submitlink.com.ar</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>66</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>48</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://corpdirectory.info/"><span>corpdirectory.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>66</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>49</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://gujarat.submitlink.com.ar/"><span>http://gujarat.submitlink.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>66</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>50</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.submitlink.com.ar/"><span>http://www.submitlink.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>66</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>51</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://premium.submitlink.com.ar/"><span>http://premium.submitlink.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>66</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>52</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://gardendirectory.com.ar/"><span>https://gardendirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>66</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>53</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.gardendirectory.com.ar/"><span>https://www.gardendirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>66</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>54</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://submitlink.com.ar/"><span>http://submitlink.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>66</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.newfreedirectory.com.ar/"><span>http://www.newfreedirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>66</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.gardendirectory.com.ar/"><span>https://www.gardendirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>66</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>57</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://corpdirectory.info/"><span>http://corpdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>66</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>58</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.corpdirectory.info/"><span>http://www.corpdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>66</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>59</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://websitedir.info/"><span>http://websitedir.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>66</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>60</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://travel.corpdirectory.info/"><span>http://travel.corpdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>66</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://newfreedirectory.com.ar/"><span>http://newfreedirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>66</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://universaldirectory.info/"><span>universaldirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>65</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>63</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.besttopdir.info/"><span>https://www.besttopdir.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>65</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.universaldirectory.info/"><span>http://www.universaldirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>65</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>65</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://chicagointernetdirectory.com/"><span>http://chicagointernetdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>65</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>66</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://datelinks.info/"><span>http://datelinks.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>65</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>67</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://besttopdir.info/"><span>https://besttopdir.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>65</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>68</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://bangladesh.universaldirectory.info/"><span>http://bangladesh.universaldirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>65</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>69</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.vbdirectory.info/"><span>http://www.vbdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>65</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>70</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://vbdirectory.info/"><span>http://vbdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>65</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>71</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.widedir.info/"><span>http://www.widedir.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>65</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>72</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.connectseo.info/"><span>http://www.connectseo.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>65</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>73</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://universaldirectory.info/"><span>http://universaldirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>65</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>74</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://connectseo.info/"><span>http://connectseo.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>65</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>75</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://widedir.info/"><span>http://widedir.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>65</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>76</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.datelinks.info/"><span>http://www.datelinks.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>65</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>77</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.chicagointernetdirectory.com/"><span>http://www.chicagointernetdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>65</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>78</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://optimisationdirectory.info/"><span>optimisationdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>79</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://escortlinkdirectory.info/"><span>escortlinkdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>80</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://harddirectory.info/"><span>harddirectory.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>81</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://searchdirectory.info/"><span>searchdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>82</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://hostingtres.com/"><span>hostingtres.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>83</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://telangana.submitbusinessdirectory.info/"><span>http://telangana.submitbusinessdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>84</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.escortlinkdirectory.info/"><span>http://www.escortlinkdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>85</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://hostingtres.com/"><span>http://hostingtres.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>86</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://india.harddirectory.info/"><span>http://india.harddirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>87</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://visakhapatnam.linknarrative.info/"><span>http://visakhapatnam.linknarrative.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>88</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://linkdirectory.com.ar/"><span>http://linkdirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>89</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.harddirectory.info/"><span>http://www.harddirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>90</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.linkdirectory.com.ar/"><span>http://www.linkdirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>91</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.hostingtres.com/"><span>http://www.hostingtres.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>92</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://maharashtra.prsl.info/"><span>http://maharashtra.prsl.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>93</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.linknarrative.info/"><span>http://www.linknarrative.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>94</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://optimisationdirectory.info/"><span>http://optimisationdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>95</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://escortlinkdirectory.info/"><span>http://escortlinkdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>96</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://harddirectory.info/"><span>http://harddirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>97</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://searchdirectory.info/"><span>http://searchdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>98</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://prsl.info/"><span>http://prsl.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>99</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://submitbusinessdirectory.info/"><span>http://submitbusinessdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>100</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.submitbusinessdirectory.info/"><span>http://www.submitbusinessdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>101</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://linknarrative.info/"><span>http://linknarrative.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>102</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://firstlinkonline.info/"><span>http://firstlinkonline.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>103</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://nationdirectory.info/"><span>http://nationdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>104</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://seo.optimisationdirectory.info/"><span>http://seo.optimisationdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>105</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://link.searchdirectory.info/"><span>http://link.searchdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>106</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.firstlinkonline.info/"><span>http://www.firstlinkonline.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>107</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.hostingtres.com/"><span>http://www.hostingtres.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>108</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://projectcollabmanila.com/"><span>http://projectcollabmanila.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>109</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.nationdirectory.info/"><span>http://www.nationdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>110</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.prsl.info/"><span>http://www.prsl.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>111</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.searchdirectory.info/"><span>http://www.searchdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>112</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://workdirectory.info/"><span>workdirectory.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>63</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>113</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://10directory.info/"><span>10directory.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>63</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>114</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://gurgaon.workdirectory.info/"><span>http://gurgaon.workdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>63</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>115</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://business.10directory.info/"><span>http://business.10directory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>63</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>116</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://corporate.10directory.info/"><span>http://corporate.10directory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>63</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>117</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.blogdir.info/"><span>http://www.blogdir.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>63</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>118</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://10directory.info/"><span>http://10directory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>63</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>119</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://design.10directory.info/"><span>http://design.10directory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>63</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>120</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.linksef.info/"><span>http://www.linksef.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>63</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>121</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.workdirectory.info/"><span>http://www.workdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>63</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>122</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://workdirectory.info/"><span>http://workdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>63</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>123</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.stansmithshoes.org/"><span>http://www.stansmithshoes.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>63</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>124</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ourdirectory.info/"><span>http://www.ourdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>63</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>125</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.82470.com/"><span>http://www.82470.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>63</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>126</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://indore.linksef.info/"><span>http://indore.linksef.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>63</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>127</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.directoryempire.info/"><span>http://www.directoryempire.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>63</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>128</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://82470.com/"><span>http://82470.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>63</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>129</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://linksef.info/"><span>http://linksef.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>63</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>130</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ourdirectory.info/"><span>http://ourdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>63</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>131</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://directoryempire.info/"><span>http://directoryempire.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>63</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>132</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://blogdir.info/"><span>http://blogdir.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>63</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>133</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://linkboost.info/"><span>http://linkboost.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>63</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>134</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.10directory.info/"><span>http://www.10directory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>63</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>135</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://thetusker.biz/"><span>thetusker.biz</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>136</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://thane.linkwindfall.info/"><span>http://thane.linkwindfall.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>137</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.vine8.net/"><span>http://www.vine8.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>138</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://inyectronicawc.com/"><span>http://inyectronicawc.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>139</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.citymaildirectory.info/"><span>http://www.citymaildirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>140</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.linkwindfall.info/"><span>http://www.linkwindfall.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>141</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://asia.linksdirectory.info/"><span>http://asia.linksdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>142</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.652186.com/"><span>https://www.652186.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>143</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.khersonrent.com/"><span>http://www.khersonrent.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>144</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.directorysimple.com.ar/"><span>http://www.directorysimple.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>145</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://652186.com/"><span>https://652186.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>146</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://thetusker.biz/"><span>http://thetusker.biz</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>147</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.vipweblinks.info/"><span>http://www.vipweblinks.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>148</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://rajasthan.vipweblinks.info/"><span>http://rajasthan.vipweblinks.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>149</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://alexa.com.ar/"><span>alexa.com.ar</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>150</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adultsdirectory.info/"><span>http://www.adultsdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>151</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.edgetrans.net/"><span>https://www.edgetrans.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>152</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.gtspauae.com/"><span>http://www.gtspauae.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>153</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://khersonrent.com/"><span>http://khersonrent.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>154</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://gtspauae.com/"><span>http://gtspauae.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>155</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://azurtrading.com/"><span>http://azurtrading.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>156</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://adultsdirectory.info/"><span>http://adultsdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>157</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://linksdirectory.info/"><span>http://linksdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>158</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://vine8.net/"><span>http://vine8.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>159</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://citymaildirectory.info/"><span>http://citymaildirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>160</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://vipweblinks.info/"><span>http://vipweblinks.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>161</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.thetusker.biz/"><span>http://www.thetusker.biz</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>162</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://linkwindfall.info/"><span>http://linkwindfall.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>163</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.redirectplus.info/"><span>http://www.redirectplus.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>164</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://book.thetusker.biz/"><span>http://book.thetusker.biz</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>165</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://directory.azurtrading.com/"><span>http://directory.azurtrading.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>166</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.azurtrading.com/"><span>http://www.azurtrading.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>167</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.linksdirectory.info/"><span>http://www.linksdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>168</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://kolkata.deeperlinks.info/"><span>http://kolkata.deeperlinks.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>169</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://hyderabad.backlinkbeat.com/"><span>http://hyderabad.backlinkbeat.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>170</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://bangalore.alinkdirectory.info/"><span>http://bangalore.alinkdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>171</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://backlinkbeat.com/"><span>http://backlinkbeat.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>172</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://fozzeyandvanc.com/"><span>https://fozzeyandvanc.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>173</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.backlinkbeat.com/"><span>http://www.backlinkbeat.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>174</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.gamelinkdirectory.com/"><span>http://www.gamelinkdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>175</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.futbollinker.com/"><span>http://www.futbollinker.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>176</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.executivedirectory.com.ar/"><span>https://www.executivedirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>177</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.gamelinkdirectory.com/"><span>http://www.gamelinkdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>178</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.futbollinker.com/"><span>http://www.futbollinker.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>179</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.executivedirectory.com.ar/"><span>https://www.executivedirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>180</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://deeperlinks.info/"><span>http://deeperlinks.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>181</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.fozzeyandvanc.com/"><span>https://www.fozzeyandvanc.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>182</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.directorymaster.com.ar/"><span>https://www.directorymaster.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>183</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.alinkdirectory.info/"><span>http://www.alinkdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>184</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://jaipur.futbollinker.com/"><span>http://jaipur.futbollinker.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>185</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://lucknow.gamelinkdirectory.com/"><span>http://lucknow.gamelinkdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>186</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://executivedirectory.com.ar/"><span>https://executivedirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>187</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.web-host-industry.com/"><span>http://www.web-host-industry.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>188</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://web-host-industry.com/"><span>http://web-host-industry.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>189</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://allsoldier.com/"><span>https://allsoldier.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>190</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.allsoldier.com/"><span>https://www.allsoldier.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>191</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://directorymaster.com.ar/"><span>https://directorymaster.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>192</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://gamelinkdirectory.com/"><span>http://gamelinkdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>193</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://futbollinker.com/"><span>http://futbollinker.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>194</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://alinkdirectory.info/"><span>http://alinkdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>195</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.orangelinker.com/"><span>http://www.orangelinker.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>196</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://addnewlink.com.ar/"><span>addnewlink.com.ar</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>60</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>197</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://weblister.com.ar/"><span>weblister.com.ar</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>60</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>198</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://bihar.weblister.com.ar/"><span>http://bihar.weblister.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>60</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>199</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://searchfrog.com.au/"><span>https://searchfrog.com.au</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>60</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>200</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://delhi.addnewlink.com.ar/"><span>http://delhi.addnewlink.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>60</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>201</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://search.fenixdirectory.info/"><span>http://search.fenixdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>60</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>202</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.fenixdirectory.info/"><span>http://www.fenixdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>60</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>203</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.thedirectory.com.ar/"><span>http://www.thedirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>60</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>204</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.fenixdirectory.info/"><span>http://www.fenixdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>60</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>205</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.thedirectory.com.ar/"><span>http://www.thedirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>60</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>206</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://business.fenixdirectory.info/"><span>http://business.fenixdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>60</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>207</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ukdirectory.com.ar/"><span>http://www.ukdirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>60</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>208</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ukdirectory.com.ar/"><span>http://ukdirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>60</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>209</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ukdirectory.com.ar/"><span>http://www.ukdirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>60</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>210</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.ocsum.org/"><span>https://www.ocsum.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>60</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>211</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://addnewlink.com.ar/"><span>http://addnewlink.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>60</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>212</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://weblister.com.ar/"><span>http://weblister.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>60</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>213</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://coastradar.info/"><span>https://coastradar.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>60</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>214</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.coastradar.info/"><span>https://www.coastradar.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>60</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>215</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://midgetsexgalleries.com/"><span>http://midgetsexgalleries.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>60</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>216</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://vipdirectory.com.ar/"><span>http://vipdirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>60</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>217</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.midgetsexgalleries.com/"><span>http://www.midgetsexgalleries.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>60</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>218</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.vipdirectory.com.ar/"><span>http://www.vipdirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>60</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>219</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.weblister.com.ar/"><span>http://www.weblister.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>60</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>220</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.addnewlink.com.ar/"><span>http://www.addnewlink.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>60</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>221</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.moonsunfavor.com/"><span>http://www.moonsunfavor.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>60</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>222</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://tkseo.net/"><span>tkseo.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>59</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>223</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://000directory.com.ar/"><span>000directory.com.ar</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>59</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>224</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://simpledirectory.com.ar/"><span>simpledirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>59</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>225</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://directorync.com.ar/"><span>directorync.com.ar</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>59</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>226</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://directorycritic.info/"><span>directorycritic.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>59</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>227</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.directorycritic.info/"><span>http://www.directorycritic.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>59</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>228</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://addyourwebsite.com.ar/"><span>http://addyourwebsite.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>59</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>229</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.directorync.com.ar/"><span>http://www.directorync.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>59</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>230</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://000directory.com.ar/"><span>http://000directory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>59</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>231</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://mumbai.000directory.com.ar/"><span>http://mumbai.000directory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>59</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>232</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://backlinkpower.com.ar/"><span>http://backlinkpower.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>59</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>233</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.simpledirectory.com.ar/"><span>http://www.simpledirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>59</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>234</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.submityourlink.com.ar/"><span>http://www.submityourlink.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>59</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>235</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://webguide.directorycritic.com/"><span>http://webguide.directorycritic.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>59</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>236</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ahmedabad.backlinkpower.com.ar/"><span>http://ahmedabad.backlinkpower.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>59</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>237</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.000directory.com.ar/"><span>http://www.000directory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>59</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>238</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.simpledirectory.com.ar/"><span>http://www.simpledirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>59</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>239</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://directorycritic.info/"><span>http://directorycritic.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>59</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>240</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://directorync.com.ar/"><span>http://directorync.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>59</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>241</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://submityourlink.com.ar/"><span>http://submityourlink.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>59</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>242</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://simpledirectory.com.ar/"><span>http://simpledirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>59</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>243</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.addyourwebsite.com.ar/"><span>http://www.addyourwebsite.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>59</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>244</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.backlinkpower.com.ar/"><span>http://www.backlinkpower.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>59</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>245</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.submityourlink.com.ar/"><span>http://www.submityourlink.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>59</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>246</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://bangalore.directorycritic.info/"><span>http://bangalore.directorycritic.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>59</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>247</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://xxx-69.net/"><span>xxx-69.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>58</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>248</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://welcomelinks.info/"><span>welcomelinks.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>58</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>249</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://powerdirectory.com.ar/"><span>powerdirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>58</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>250</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://uklinks.info/"><span>uklinks.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>58</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>251</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.welcomelinks.info/"><span>http://www.welcomelinks.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>58</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>252</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.india.sizzlingdirectory.com/"><span>http://www.india.sizzlingdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>58</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>253</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://bhopal.monsterdirectory.com.ar/"><span>http://bhopal.monsterdirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>58</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>254</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.sizzlingdirectory.com/"><span>http://www.sizzlingdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>58</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>255</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://sizzlingdirectory.com/"><span>http://sizzlingdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>58</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>256</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://blpdirectory.info/"><span>https://blpdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>58</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>257</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://namedirectory.com.ar/"><span>http://namedirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>58</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>258</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://welcomelinks.info/"><span>http://welcomelinks.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>58</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>259</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://welcomelinks.info/"><span>http://welcomelinks.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>58</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>260</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.goaflam.net/"><span>http://www.goaflam.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>58</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>261</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://monsterdirectory.com.ar/"><span>http://monsterdirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>58</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>262</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.jasminedirectory.com/"><span>https://www.jasminedirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>58</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>263</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.blpdirectory.info/"><span>https://www.blpdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>58</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>264</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.goaflam.net/"><span>http://www.goaflam.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>58</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>265</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.namedirectory.com.ar/"><span>http://www.namedirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>58</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>266</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://fastdirectory.com.ar/"><span>http://fastdirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>58</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>267</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.fastdirectory.com.ar/"><span>http://www.fastdirectory.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>58</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>268</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://uklinks.info/"><span>http://uklinks.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>58</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>269</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://premium.uklinks.info/"><span>http://premium.uklinks.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>58</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>270</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://sizzlingdirectory.com/"><span>https://sizzlingdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>58</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>271</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.uklinks.info/"><span>http://www.uklinks.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>58</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>272</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freelinkdirectory.info/"><span>http://www.freelinkdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>57</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>273</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://pune.freelinkdirectory.info/"><span>http://pune.freelinkdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>57</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>274</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.darksdir.info/"><span>http://www.darksdir.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>57</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>275</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.kengairu.com/"><span>http://www.kengairu.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>57</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>276</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://freelinkdirectory.info/"><span>http://freelinkdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>57</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>277</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://darksdir.info/"><span>http://darksdir.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>57</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>278</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://mhurley.org/"><span>http://mhurley.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>57</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>279</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://kompower.com/"><span>kompower.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>280</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.80245.com/"><span>http://www.80245.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>281</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.cawausa.org/"><span>https://www.cawausa.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>282</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://consumer.golddirectory.info/"><span>http://consumer.golddirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>283</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.addyourwebsite.neobacklinks.com/"><span>http://www.addyourwebsite.neobacklinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>284</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://80245.com/"><span>http://80245.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>285</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://linkshere.neobacklinks.com/"><span>http://linkshere.neobacklinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>286</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://mhurley.neobacklinks.com/"><span>http://mhurley.neobacklinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>287</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.mhurley.neobacklinks.com/"><span>http://www.mhurley.neobacklinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>288</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://inyectronicawc.neobacklinks.com/"><span>http://inyectronicawc.neobacklinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>289</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://inyectronicawc.neobacklinks.com/"><span>http://inyectronicawc.neobacklinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>290</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://kengairu.neobacklinks.com/"><span>http://kengairu.neobacklinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>291</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://hostingtresar.neobacklinks.com/"><span>http://hostingtresar.neobacklinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>292</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://hotfrogbiz.neobacklinks.com/"><span>http://hotfrogbiz.neobacklinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>293</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://khersonrent.neobacklinks.com/"><span>http://khersonrent.neobacklinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>294</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://goaflam.neobacklinks.com/"><span>http://goaflam.neobacklinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>295</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://addyourwebsite.neobacklinks.com/"><span>http://addyourwebsite.neobacklinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>296</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.gtspauae.neobacklinks.com/"><span>http://www.gtspauae.neobacklinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>297</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.hostingtres.neobacklinks.com/"><span>http://www.hostingtres.neobacklinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>298</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.linkforfree.neobacklinks.com/"><span>http://www.linkforfree.neobacklinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>299</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.golddirectory.info/"><span>http://www.golddirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>300</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://hostingtres.neobacklinks.com/"><span>http://hostingtres.neobacklinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>301</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://india.neobacklinks.com/"><span>http://india.neobacklinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>302</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://golddirectory.info/"><span>http://golddirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>303</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.inyectronicawc.neobacklinks.com/"><span>http://www.inyectronicawc.neobacklinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>304</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.kengairu.neobacklinks.com/"><span>http://www.kengairu.neobacklinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>305</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.khersonrent.neobacklinks.com/"><span>http://www.khersonrent.neobacklinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>306</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://gtspauae.neobacklinks.com/"><span>http://gtspauae.neobacklinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>307</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://starsdirectory.neobacklinks.com/"><span>http://starsdirectory.neobacklinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>308</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://simpledirectory.neobacklinks.com/"><span>http://simpledirectory.neobacklinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>309</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ukdirectory.neobacklinks.com/"><span>http://ukdirectory.neobacklinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>310</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.goaflam.neobacklinks.com/"><span>http://www.goaflam.neobacklinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>311</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://linkforfree.neobacklinks.com/"><span>http://linkforfree.neobacklinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>312</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.imseo.info/"><span>http://www.imseo.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>313</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.linkshere.neobacklinks.com/"><span>http://www.linkshere.neobacklinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>314</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.india.neobacklinks.com/"><span>http://www.india.neobacklinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>315</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://gigablast.com/"><span>gigablast.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>316</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://hostingtres.com.ar/"><span>hostingtres.com.ar</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>317</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.652186.neobacklinks.net/"><span>https://www.652186.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>318</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.addyourwebsite.neobacklinks.net/"><span>https://www.addyourwebsite.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>319</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.bidsyndicate.neobacklinks.net/"><span>https://www.bidsyndicate.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>320</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.poec.neobacklinks.net/"><span>http://www.poec.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>321</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.zendirectory.neobacklinks.net/"><span>http://www.zendirectory.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>322</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.gtspauae.neobacklinks.net/"><span>https://www.gtspauae.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>323</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.goaflam.neobacklinks.net/"><span>https://www.goaflam.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>324</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.hotfrogbiz.neobacklinks.net/"><span>https://www.hotfrogbiz.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>325</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.hostingtresar.neobacklinks.net/"><span>https://www.hostingtresar.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>326</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.kengairu.neobacklinks.net/"><span>https://www.kengairu.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>327</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://websitelist.neobacklinks.net/"><span>http://websitelist.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>328</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://652186.neobacklinks.net/"><span>https://652186.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>329</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://addyourwebsite.neobacklinks.net/"><span>https://addyourwebsite.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>330</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://alexa.neobacklinks.net/"><span>https://alexa.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>331</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://allsoldier.neobacklinks.net/"><span>https://allsoldier.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>332</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://linkforfree.neobacklinks.net/"><span>https://linkforfree.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>333</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://moonsunfavor.neobacklinks.net/"><span>http://moonsunfavor.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>334</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://midgetsexgalleries.neobacklinks.net/"><span>http://midgetsexgalleries.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>335</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://hotfrogbiz.neobacklinks.net/"><span>https://hotfrogbiz.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>336</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://kengairu.neobacklinks.net/"><span>https://kengairu.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>337</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://stansmithshoes.neobacklinks.net/"><span>http://stansmithshoes.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>338</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.khersonrent.neobacklinks.net/"><span>https://www.khersonrent.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>339</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://besttopdir.neobacklinks.net/"><span>https://besttopdir.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>340</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://web-host-industry.neobacklinks.net/"><span>http://web-host-industry.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>341</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.coastradar.neobacklinks.net/"><span>https://www.coastradar.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>342</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://projectcollabmanila.neobacklinks.net/"><span>http://projectcollabmanila.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>343</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://gardendirectory.neobacklinks.net/"><span>https://gardendirectory.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>344</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://coastradar.neobacklinks.net/"><span>https://coastradar.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>345</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://cawausa.neobacklinks.net/"><span>https://cawausa.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>346</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://bidsyndicate.neobacklinks.net/"><span>https://bidsyndicate.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>347</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://blpdirectory.neobacklinks.net/"><span>https://blpdirectory.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>348</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.besttopdir.neobacklinks.net/"><span>https://www.besttopdir.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>349</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.alexa.neobacklinks.net/"><span>https://www.alexa.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>350</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://hostingtresar.neobacklinks.net/"><span>https://hostingtresar.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>351</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://hostingtres.com.ar/"><span>http://hostingtres.com.ar</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>352</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.vine8.neobacklinks.net/"><span>http://www.vine8.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>353</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.midgetsexgalleries.neobacklinks.net/"><span>http://www.midgetsexgalleries.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>354</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.moonsunfavor.neobacklinks.net/"><span>http://www.moonsunfavor.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>355</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.websitelist.neobacklinks.net/"><span>http://www.websitelist.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>356</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.web-host-industry.neobacklinks.net/"><span>http://www.web-host-industry.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>357</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.newfreedirectory.com.ar.neobacklinks.net/"><span>http://www.newfreedirectory.com.ar.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>358</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.indiacom.com/"><span>https://www.indiacom.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>359</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.submityourlink.neobacklinks.net/"><span>http://www.submityourlink.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>360</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://vine8.neobacklinks.net/"><span>http://vine8.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>361</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.projectcollabmanila.neobacklinks.net/"><span>http://www.projectcollabmanila.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>362</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://zendirectory.neobacklinks.net/"><span>http://zendirectory.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>363</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.neobacklinks.net/"><span>https://www.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>364</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.gardendirectory.neobacklinks.net/"><span>https://www.gardendirectory.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>365</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.fozzeyandvanc.neobacklinks.net/"><span>https://www.fozzeyandvanc.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>366</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.sizzlingdirectory.com.neobacklinks.net/"><span>http://www.sizzlingdirectory.com.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>367</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://submityourlink.neobacklinks.net/"><span>http://submityourlink.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>368</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ncdirectory.neobacklinks.net/"><span>http://www.ncdirectory.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>369</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://sizzlingdirectory.com.neobacklinks.net/"><span>http://sizzlingdirectory.com.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>370</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.linkforfree.neobacklinks.net/"><span>https://www.linkforfree.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>371</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://gcast.neobacklinks.net/"><span>https://gcast.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>372</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://goaflam.neobacklinks.net/"><span>https://goaflam.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>373</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://gtspauae.neobacklinks.net/"><span>https://gtspauae.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>374</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://hostingtres.neobacklinks.net/"><span>https://hostingtres.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>375</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://fozzeyandvanc.neobacklinks.net/"><span>https://fozzeyandvanc.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>376</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://newfreedirectory.com.ar.neobacklinks.net/"><span>http://newfreedirectory.com.ar.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>377</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://poec.neobacklinks.net/"><span>http://poec.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>378</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://executivedirectory.neobacklinks.net/"><span>https://executivedirectory.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>379</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.cawausa.neobacklinks.net/"><span>https://www.cawausa.neobacklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>380</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://bizsugar.com/"><span>bizsugar.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>52</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>381</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://blogtopsites.com/"><span>blogtopsites.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>51</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>382</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.entireweb.com/"><span>www.entireweb.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>50</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>383</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://scrubtheweb.com/"><span>scrubtheweb.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>50</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>384</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://blogflux.com/"><span>blogflux.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>49</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>385</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.blazemp.com/dir"><span>http://www.blazemp.com/dir</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>49</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>386</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.linkcentre.com/"><span>https://www.linkcentre.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>49</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>387</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://blogtoplist.com/"><span>blogtoplist.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>47</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>388</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ontoplist.com/"><span>ontoplist.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>46</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>389</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://youmob.com/"><span>youmob.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>43</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>390</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.poec.info/"><span>http://www.poec.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>43</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>391</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://poec.info/"><span>http://poec.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>43</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>392</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://poec.info/"><span>http://poec.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>43</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>393</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.goworkable.com/"><span>http://www.goworkable.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>42</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>394</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.avivadirectory.com/"><span>https://www.avivadirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>42</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>395</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://plazoo.com/"><span>plazoo.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>41</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>396</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.gcast.info/"><span>https://www.gcast.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>41</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>397</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://gcast.info/"><span>https://gcast.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>41</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>398</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.justadirectory.com/directory"><span>https://www.justadirectory.com/directory</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>40</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>399</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.stpt.com/directory"><span>http://www.stpt.com/directory</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>40</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>400</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ticotimes.com/"><span>http://www.ticotimes.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>39</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>401</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://operationuplink.org/"><span>operationuplink.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>402</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://bloglisting.net/"><span>bloglisting.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>403</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://directory.ac/"><span>https://directory.ac</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>404</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.itechgyan.com/"><span>www.itechgyan.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>37</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>405</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://hotvsnot.com/"><span>hotvsnot.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>37</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>406</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ilor.com/"><span>http://www.ilor.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>37</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>407</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.mddir.com/"><span>https://www.mddir.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>37</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>408</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://somuch.com/"><span>somuch.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>36</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>409</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://sqwosh.com/"><span>sqwosh.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>36</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>410</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freeadstime.org/"><span>www.freeadstime.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>411</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://free-weblink.com/"><span>free-weblink.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>412</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.elecdir.com/"><span>www.elecdir.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>413</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.01webdirectory.com/"><span>https://www.01webdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>414</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://royaldirectory.biz/"><span>royaldirectory.biz</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>34</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>415</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.royaldirectory.biz/"><span>http://www.royaldirectory.biz</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>34</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>416</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.highstuff.com/"><span>https://www.highstuff.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>34</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>417</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://fuelmyblog.com/"><span>fuelmyblog.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>418</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ad-links.org/"><span>http://www.ad-links.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>419</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.pulso.org/"><span>http://www.pulso.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>420</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://topsiteswebdirectory.com/"><span>topsiteswebdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>421</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://bloghints.com/"><span>bloghints.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>422</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.ukdirectory.co.uk/"><span>https://www.ukdirectory.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>423</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://beegdirectory.com/"><span>beegdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>424</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://domainnamesseo.com/"><span>domainnamesseo.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>425</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://link-your-site.com/"><span>link-your-site.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>426</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.link-your-site.com/"><span>https://www.link-your-site.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>427</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://pakranks.com/"><span>pakranks.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>428</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://pegasusdirectory.com/"><span>pegasusdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>429</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://prolinkdirectory.com/"><span>prolinkdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>430</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://alistdirectory.com/"><span>alistdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>431</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://trafficdirectory.org/"><span>trafficdirectory.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>432</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://businessfreedirectory.biz/"><span>businessfreedirectory.biz</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>433</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://addgoodsites.com/"><span>addgoodsites.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>434</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.trafficdirectory.org/"><span>http://www.trafficdirectory.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>435</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://infotiger.com/"><span>infotiger.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>436</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://1abc.org/"><span>1abc.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>437</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://020.co.uk/"><span>020.co.uk</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>438</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://highrankdirectory.com/"><span>highrankdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>439</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://mastermoz.com/"><span>mastermoz.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>440</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://cipinet.com/"><span>cipinet.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>441</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://info-listings.com/"><span>info-listings.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>442</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://blogdirs.com/"><span>blogdirs.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>443</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.linksgiving.com/"><span>http://www.linksgiving.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>444</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://usalistingdirectory.com/"><span>usalistingdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>445</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://marketinginternetdirectory.com/"><span>marketinginternetdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>446</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ellysdirectory.com/"><span>ellysdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>447</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://one-sublime-directory.com/"><span>one-sublime-directory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>448</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://classdirectory.org/"><span>classdirectory.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>449</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://unique-listing.com/"><span>unique-listing.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>450</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://justdirectory.org/"><span>justdirectory.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>451</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://theguidex.com/"><span>https://theguidex.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>452</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://blogrollcenter.com/"><span>blogrollcenter.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>453</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.emedinews.com/"><span>http://www.emedinews.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>454</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.alivedirectory.com/"><span>http://www.alivedirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>455</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.usalistingdirectory.com/"><span>http://www.usalistingdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>456</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.unique-listing.com/"><span>http://www.unique-listing.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>457</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.suicidepreventionhelp.com/directory"><span>http://www.suicidepreventionhelp.com/directory</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>458</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.justdirectory.org/"><span>http://www.justdirectory.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>459</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.alivedirectory.com/"><span>http://www.alivedirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>460</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://netinsert.com/"><span>netinsert.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>461</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://submissionwebdirectory.com/"><span>submissionwebdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>462</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://9sites.net/"><span>9sites.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>463</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://amray.com/"><span>amray.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>464</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://directory3.org/"><span>directory3.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>465</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://alive-directory.com/"><span>alive-directory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>466</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://globaldir.org/"><span>globaldir.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>467</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://alivelinks.org/"><span>alivelinks.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>468</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://sitepromotiondirectory.com/"><span>sitepromotiondirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>469</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://alivelink.org/"><span>alivelink.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>470</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://hdvconnect.com/"><span>hdvconnect.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>471</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://wldirectory.com/"><span>wldirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>472</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://freewebsitedirectory.com/"><span>freewebsitedirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>473</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.linkedin-directory.com/"><span>http://www.linkedin-directory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>474</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freeweblink.org/"><span>http://www.freeweblink.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>475</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.thecgisite.com/"><span>http://www.thecgisite.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>476</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://wilsdomain.com/"><span>wilsdomain.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>477</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.clickmybrick.com/"><span>http://www.clickmybrick.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>478</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.efdir.com/"><span>http://www.efdir.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>479</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://1websdirectory.com/"><span>1websdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>480</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://happal.com/"><span>happal.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>481</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://sonicrun.com/"><span>sonicrun.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>482</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://lemon-directory.com/"><span>lemon-directory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>483</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://pr8directory.com/"><span>pr8directory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>484</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://quicklinks.net/"><span>quicklinks.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>485</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.craigslistdir.org/"><span>http://www.craigslistdir.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>486</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.lemon-directory.com/"><span>http://www.lemon-directory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>487</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.interesting-dir.com/"><span>http://www.interesting-dir.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>488</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.quicklinks.net/"><span>http://www.quicklinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>489</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.bhanvad.com/"><span>http://www.bhanvad.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>490</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.pr8directory.com/"><span>https://www.pr8directory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>491</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.cannylink.com/"><span>https://www.cannylink.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>492</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://w3catalog.com/"><span>w3catalog.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>493</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://secretsearchenginelabs.com/"><span>secretsearchenginelabs.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>494</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://directory5.org/"><span>directory5.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>495</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://gainweb.org/"><span>gainweb.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>496</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://a2place.com/"><span>a2place.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>497</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://britainbusinessdirectory.com/"><span>britainbusinessdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>498</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ukinternetdirectory.net/"><span>ukinternetdirectory.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>499</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://elitesitesdirectory.com/"><span>elitesitesdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>500</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://allfreethings.com/"><span>allfreethings.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>501</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://blogville.us/"><span>blogville.us</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>502</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.exampledir.com/"><span>http://www.exampledir.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>503</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.limboportal.com/"><span>http://www.limboportal.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>504</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.business-directory.org.uk/"><span>https://www.business-directory.org.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>505</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://prosconsreviews.info/"><span>http://prosconsreviews.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>506</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://addyourblog.com/"><span>addyourblog.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>507</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://blogratedirectory.com/"><span>blogratedirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>508</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ntdirectory.com/"><span>http://ntdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>509</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.a2place.com/"><span>http://www.a2place.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>510</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://a1webdirectory.org/"><span>a1webdirectory.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>511</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://nctweb.com/"><span>nctweb.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>512</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://seanwise.com/"><span>seanwise.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>513</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ezistreet.com/"><span>ezistreet.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>514</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://weboworld.com/"><span>weboworld.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>515</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://canadawebdir.com/"><span>canadawebdir.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>516</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://populardirectory.org/"><span>populardirectory.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>517</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://usawebsitesdirectory.com/"><span>usawebsitesdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>518</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.populardirectory.org/"><span>http://www.populardirectory.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>519</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.searchdomainhere.com/"><span>http://www.searchdomainhere.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>520</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.directorydirect.net/"><span>http://www.directorydirect.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>521</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.gowwwlist.com/"><span>https://www.gowwwlist.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>522</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.uklistings.org/"><span>https://www.uklistings.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>523</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.incrawler.com/"><span>http://www.incrawler.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>524</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.allinfodir.com/"><span>http://www.allinfodir.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>525</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.clora.net/"><span>https://www.clora.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>526</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://rocktheadored.com/"><span>rocktheadored.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>527</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://urlshack.com/"><span>urlshack.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>528</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://rapidenetwork.eu/"><span>rapidenetwork.eu</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>529</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.ezistreet.com/"><span>https://www.ezistreet.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>530</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.sevenseek.com/"><span>https://www.sevenseek.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>531</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.onecooldir.com/"><span>https://www.onecooldir.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>532</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.pattayabridge.com/directory.htm"><span>https://www.pattayabridge.com/directory.htm</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>533</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://247webdirectory.com/"><span>247webdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>534</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://nonar.com/"><span>nonar.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>535</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://bedwan.com/"><span>bedwan.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>536</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.classifiedsfactor.com/"><span>www.classifiedsfactor.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>537</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://realplayerlive.com/"><span>realplayerlive.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>538</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://businessfreedirectory.com/"><span>businessfreedirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>539</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://picktu.com/"><span>picktu.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>540</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://linkspurt.com/"><span>linkspurt.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>541</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://linknom.com/"><span>linknom.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>542</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://gmawebdirectory.com/"><span>gmawebdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>543</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://blogsrater.com/"><span>blogsrater.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>544</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://freedirectorysubmit.com/"><span>freedirectorysubmit.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>545</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ranaf.com/"><span>ranaf.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>546</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.linkspurt.com/"><span>http://www.linkspurt.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>547</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.johnnylist.org/"><span>https://www.johnnylist.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>548</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.smartseolink.org/"><span>https://www.smartseolink.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>549</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.siteswebdirectory.com/"><span>http://www.siteswebdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>550</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.bluebook-directory.com/"><span>https://www.bluebook-directory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>551</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.webguiding.net/"><span>https://www.webguiding.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>552</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.businessfreedirectory.com/"><span>http://www.businessfreedirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>553</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://addbusiness.net/"><span>addbusiness.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>554</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.findermaster.com/"><span>www.findermaster.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>555</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.giganticlist.com/"><span>www.giganticlist.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>556</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://dondir.com/"><span>dondir.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>557</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://clicksordirectory.com/"><span>clicksordirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>558</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://fivestarscenter.com/"><span>fivestarscenter.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>559</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://quickdirectory.biz/"><span>quickdirectory.biz</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>560</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://generalwebdirectory.org/"><span>generalwebdirectory.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>561</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://hitwebdirectory.com/"><span>hitwebdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>562</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://traveltourismdirectory.info/"><span>traveltourismdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>563</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://directmylink.com/"><span>directmylink.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>564</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://blogsearch.com/"><span>blogsearch.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>565</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.blackandbluedirectory.com/"><span>https://www.blackandbluedirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>566</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.dicedirectory.com/"><span>https://www.dicedirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>567</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.colorblossomdirectory.com/"><span>http://www.colorblossomdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>568</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.darksschemedirectory.com/"><span>http://www.darksschemedirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>569</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.dbsdirectory.com/"><span>https://www.dbsdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>570</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.finditnowdirectory.com.au/"><span>https://www.finditnowdirectory.com.au</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>571</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.alive2directory.com/"><span>https://www.alive2directory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>572</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.alive2directory.com/"><span>https://www.alive2directory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>573</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.kingbloom.com/"><span>http://www.kingbloom.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>574</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.keytradeinvest.com/"><span>https://www.keytradeinvest.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>575</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.allwebdirectory.com/"><span>http://www.allwebdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>576</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://dir.specialistauctions.net/"><span>http://dir.specialistauctions.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>577</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://add-url.in/"><span>http://add-url.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>578</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://acewebdirectory.com/"><span>acewebdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>579</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://add-oncon.com/"><span>add-oncon.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>580</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.wallclassifieds.com/"><span>www.wallclassifieds.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>581</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://smsweb.org/"><span>smsweb.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>582</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://finest4.com/"><span>finest4.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>583</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://correctedbyreality.com/"><span>correctedbyreality.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>584</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://directory.ishprash.com/"><span>directory.ishprash.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>585</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://taurusdirectory.com/"><span>taurusdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>586</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://fat64.net/"><span>fat64.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>587</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://sohobiztube.com/"><span>sohobiztube.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>588</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://incitylife.com/"><span>http://incitylife.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>589</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://promotebusinessdirectory.com/"><span>promotebusinessdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>590</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://directoryfire.com/"><span>directoryfire.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>591</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://the-web-directory.co.uk/"><span>the-web-directory.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>592</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.direct-directory.com/"><span>https://www.direct-directory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>593</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.aurora-directory.com/"><span>https://www.aurora-directory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>594</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.acewebdirectory.com/"><span>https://www.acewebdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>595</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.aurora-directory.com/"><span>https://www.aurora-directory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>596</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.acewebdirectory.com/"><span>https://www.acewebdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>597</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.anirbans.com/"><span>https://www.anirbans.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>598</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.taurusdirectory.com/"><span>http://www.taurusdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>599</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://freeaddurl.org/"><span>freeaddurl.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>600</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://mvomrat.com/"><span>mvomrat.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>601</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.celestialdirectory.com/"><span>http://www.celestialdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>602</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.topdot.org/"><span>http://www.topdot.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>603</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.bizz-directory.com/"><span>https://www.bizz-directory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>604</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.findelio.com/"><span>http://www.findelio.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>605</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.acewebdirectory.com/"><span>https://www.acewebdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>606</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.bluesparkledirectory.com/"><span>https://www.bluesparkledirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>607</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.advertiseera.com/"><span>www.advertiseera.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>608</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.h1ad.com/"><span>www.h1ad.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>609</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://edom.co.uk/directory"><span>edom.co.uk/directory</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>610</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://yellowlinker.com/"><span>yellowlinker.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>611</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://wvchoops.com/"><span>wvchoops.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>612</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://sound-directory.com/"><span>sound-directory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>613</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://evolvingcritic.com/"><span>evolvingcritic.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>614</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://herlight.com/"><span>herlight.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>615</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://thedailysubmit.com/"><span>thedailysubmit.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>616</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://wlddirectory.com/"><span>wlddirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>617</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://zopso.com/"><span>zopso.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>618</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://vipdig.com/"><span>vipdig.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>619</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://h-log.com/"><span>h-log.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>620</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://freeinternetwebdirectory.com/"><span>freeinternetwebdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>621</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://linkaddurl.com/"><span>linkaddurl.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>622</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://royallinkup.com/"><span>royallinkup.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>623</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://financebuster.com/"><span>financebuster.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>624</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://cluboo.com/"><span>cluboo.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>625</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://freetoprankdirectory.com/"><span>freetoprankdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>626</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://marketingwebdirectory.com/"><span>marketingwebdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>627</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freeinternetwebdirectory.com/"><span>http://www.freeinternetwebdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>628</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.groovy-directory.com/"><span>https://www.groovy-directory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>629</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.earthlydirectory.com/"><span>https://www.earthlydirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>630</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.deepbluedirectory.com/"><span>https://www.deepbluedirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>631</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.koollog.com/"><span>https://www.koollog.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>632</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.thedailysubmit.com/"><span>http://www.thedailysubmit.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>633</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.smartbusinessdirectory.co.uk/"><span>https://www.smartbusinessdirectory.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>634</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.kwikgoblin.com/"><span>https://www.kwikgoblin.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>635</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://urlchief.com/"><span>http://urlchief.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>636</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://zopso.com/"><span>https://zopso.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>637</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.blackgreendirectory.com/"><span>https://www.blackgreendirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>638</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.arcticdirectory.com/"><span>https://www.arcticdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>639</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.brownedgedirectory.com/"><span>https://www.brownedgedirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>640</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://thinkfla.com/"><span>thinkfla.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>641</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://usgeo.org/"><span>usgeo.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>642</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://10directory.com/"><span>10directory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>643</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://iceved.com/"><span>iceved.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>644</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://salemcg.com/"><span>salemcg.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>645</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ecctrade.com/"><span>ecctrade.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>646</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://svguia.com/"><span>svguia.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>647</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://linkdir4u.com/"><span>linkdir4u.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>648</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://add-page.com/"><span>add-page.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>649</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://dracodirectory.com/"><span>dracodirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>650</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://pakadtrader.com/"><span>pakadtrader.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>651</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://greylinker.com/"><span>greylinker.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>652</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://webdirectory.co.in/"><span>webdirectory.co.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>653</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://jeitacave.net/"><span>jeitacave.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>654</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://linkpedia.net/"><span>linkpedia.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>655</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://canadiandirectory.org/"><span>canadiandirectory.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>656</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://domico.info/"><span>domico.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>657</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://anaximanderdirectory.com/"><span>anaximanderdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>658</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://einternetindex.com/"><span>einternetindex.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>659</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://nexusdirectory.com/"><span>nexusdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>660</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://blogdir.co.uk/"><span>blogdir.co.uk</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>661</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.coles-directory.com/"><span>http://www.coles-directory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>662</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.cleangreendirectory.com/"><span>http://www.cleangreendirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>663</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://svguia.com/"><span>http://svguia.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>664</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.intwebdirectory.com/"><span>https://www.intwebdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>665</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.dirhello.com/"><span>https://www.dirhello.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>666</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.designerlistings.org/"><span>https://www.designerlistings.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>667</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://anaximanderdirectory.com/"><span>http://anaximanderdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>668</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.propellerdir.com/"><span>https://www.propellerdir.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>669</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.fionadates.com/"><span>http://www.fionadates.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>670</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.jeitacave.net/"><span>http://www.jeitacave.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>671</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.zorg-directory.com/"><span>http://www.zorg-directory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>672</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.einternetindex.com/"><span>https://www.einternetindex.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>673</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://livepopular.com/"><span>livepopular.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>674</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://momsdirectory.net/"><span>momsdirectory.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>675</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://abovealldirectory.com/"><span>abovealldirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>676</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.mrbloggers.com/"><span>www.mrbloggers.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>677</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://generalbusinesswebdirectory.com/"><span>generalbusinesswebdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>678</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://directory-free.com/"><span>directory-free.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>679</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://seodirectoryonline.org/"><span>seodirectoryonline.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>680</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://productselectoren.com/"><span>productselectoren.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>681</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://abstractdirectory.com/"><span>abstractdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>682</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://thehillel.org/"><span>thehillel.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>683</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://stare-at.com/"><span>stare-at.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>684</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://phillyfirstonthefourth.com/"><span>phillyfirstonthefourth.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>685</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://bestfreewebsites.net/"><span>bestfreewebsites.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>686</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://blahoo.net/"><span>blahoo.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>687</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://targetsviews.com/"><span>targetsviews.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>688</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://fidofindit.com/"><span>fidofindit.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>689</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://fivestarsautopawn.com/"><span>fivestarsautopawn.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>690</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://drtest.net/"><span>drtest.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>691</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://webglance.com/"><span>webglance.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>692</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://dmoz.org.in/"><span>dmoz.org.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>693</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://towebmaster.net/"><span>towebmaster.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>694</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://informationcrawler.com/"><span>informationcrawler.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>695</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://jewana.com/"><span>jewana.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>696</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://bari.biz/"><span>bari.biz</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>697</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://piseries.com/"><span>piseries.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>698</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://homepageseek.com/"><span>homepageseek.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>699</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://bocaiw.net/"><span>bocaiw.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>700</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.fivestarsautopawn.com/"><span>http://www.fivestarsautopawn.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>701</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.robolinks.com/"><span>http://www.robolinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>702</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.lifetimelinks.com/"><span>http://www.lifetimelinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>703</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.lifetimelinks.com/"><span>http://www.lifetimelinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>704</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.lcdintouch.org/"><span>http://www.lcdintouch.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>705</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.webglance.com/"><span>http://www.webglance.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>706</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.abovealldirectory.com/"><span>http://www.abovealldirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>707</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.dmoz.org.in/"><span>http://www.dmoz.org.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>708</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.blahoo.net/"><span>https://www.blahoo.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>709</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.cadillactight.com/"><span>https://www.cadillactight.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>710</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.abstractdirectory.com/"><span>http://www.abstractdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>711</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ambradirectory.com/"><span>http://www.ambradirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>712</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.daduru.com/"><span>https://www.daduru.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>713</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.directory-free.com/"><span>https://www.directory-free.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>714</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.craftytips.com/"><span>http://www.craftytips.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>715</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.awayaway.com/"><span>https://www.awayaway.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>716</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.cadillactight.com/"><span>https://www.cadillactight.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>717</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://wewebware.com/"><span>wewebware.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>718</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://link-minded.com/"><span>link-minded.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>719</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.superadpost.com/"><span>www.superadpost.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>720</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://trafficsimulator.net/"><span>trafficsimulator.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>721</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://marsstem.com/"><span>marsstem.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>722</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://mhdw.org/"><span>mhdw.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>723</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ec123.net/"><span>ec123.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>724</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://triplewdirectory.com/"><span>triplewdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>725</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://alabamaindex.com/"><span>alabamaindex.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>726</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ecfcpug.org/"><span>ecfcpug.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>727</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://linkresell.com/"><span>linkresell.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>728</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://enirgonorge.com/"><span>enirgonorge.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>729</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://lambusango.com/"><span>lambusango.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>730</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://worldweb-directory.com/"><span>worldweb-directory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>731</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ampledirectory.com/"><span>ampledirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>732</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://gosearchdirectory.com/"><span>gosearchdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>733</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://canopusdirectory.com/"><span>canopusdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>734</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://directorystaff.com/"><span>directorystaff.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>735</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://postfreedirectory.com/"><span>postfreedirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>736</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://mydannyseo.com/"><span>mydannyseo.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>737</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://dn2i.com/"><span>dn2i.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>738</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://excitedirectory.com/"><span>excitedirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>739</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://tiptopdirectory.com/"><span>tiptopdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>740</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://directoryseo.biz/"><span>directoryseo.biz</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>741</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://hottestblogs.com/"><span>hottestblogs.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>742</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://ranchlinks.com/"><span>https://ranchlinks.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>743</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.in.uk.com/"><span>https://www.in.uk.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>744</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.mediafiredirectlink.com/"><span>http://www.mediafiredirectlink.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>745</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://worldweb-directory.com/"><span>https://worldweb-directory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>746</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.postfreedirectory.com/"><span>http://www.postfreedirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>747</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ampledirectory.com/"><span>http://www.ampledirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>748</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.seekzap.com/"><span>http://www.seekzap.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>749</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.digabusiness.com/"><span>http://www.digabusiness.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>750</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.canopusdirectory.com/"><span>http://www.canopusdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>751</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.allworldgymnastics.org/"><span>https://www.allworldgymnastics.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>752</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.argusvision.net/"><span>http://www.argusvision.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>753</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ananar.com/"><span>ananar.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>754</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://dizila.com/"><span>dizila.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>755</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://mugro.info/"><span>mugro.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>756</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://gainesvillesbest.com/"><span>gainesvillesbest.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>757</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://thetortellini.com/"><span>thetortellini.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>758</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://tandiversity.com/"><span>tandiversity.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>759</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://adverttree.com/"><span>adverttree.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>760</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://cafeatlantico.info/"><span>cafeatlantico.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>761</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://idahoindex.com/"><span>idahoindex.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>762</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://generalshoppingdirectory.com/"><span>generalshoppingdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>763</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://addlinkzfree.com/"><span>addlinkzfree.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>764</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://allsitessorted.com/"><span>allsitessorted.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>765</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://7starsdirectory.com/"><span>7starsdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>766</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://redlinker.com/"><span>redlinker.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>767</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://pinklinker.com/"><span>pinklinker.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>768</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://seowebdir.net/"><span>seowebdir.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>769</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://fearinc.org/"><span>fearinc.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>770</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://listinkerala.com/"><span>listinkerala.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>771</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://txtlinks.com/"><span>txtlinks.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>772</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://contentclear.org/"><span>contentclear.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>773</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://onemilliondirectory.com/"><span>onemilliondirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>774</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://nuclearland.com/"><span>nuclearland.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>775</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://reliancedir.com/"><span>reliancedir.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>776</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://saffo.info/"><span>saffo.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>777</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://pr3plus.com/"><span>pr3plus.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>778</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://urldirecting.com/"><span>urldirecting.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>779</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://directory.bradford-webdesign.co.uk/"><span>directory.bradford-webdesign.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>780</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://directorybusinesssite.org/"><span>directorybusinesssite.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>781</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://salondugolf.biz/"><span>salondugolf.biz</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>782</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://bubblesdirectory.com/"><span>bubblesdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>783</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://freeprwebdirectory.com/"><span>freeprwebdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>784</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://the-net-directory.com/"><span>the-net-directory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>785</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://lollj.com/"><span>lollj.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>786</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://abicloud.org/"><span>abicloud.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>787</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://riverwrap.com/"><span>riverwrap.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>788</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://linkroo.com/"><span>linkroo.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>789</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://elf08.com/"><span>http://elf08.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>790</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://vlinkyou.com/"><span>http://vlinkyou.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>791</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.medicalhealthdirectory.net/"><span>http://www.medicalhealthdirectory.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>792</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.affiliateprogramslocator.com/"><span>http://www.affiliateprogramslocator.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>793</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.paiddirectory.com/"><span>http://www.paiddirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>794</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.submitdotcom.com/"><span>http://www.submitdotcom.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>795</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.seowebdir.net/"><span>https://www.seowebdir.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>796</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.addlinkzfree.com/"><span>http://www.addlinkzfree.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>797</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://blogsthatfollow.com/"><span>blogsthatfollow.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>798</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.fearinc.org/"><span>http://www.fearinc.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>799</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.submitdotcom.com/"><span>http://www.submitdotcom.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>800</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.contentclear.org/"><span>http://www.contentclear.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>801</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.directorybusinesssite.org/"><span>https://www.directorybusinesssite.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>802</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.the-net-directory.com/"><span>http://www.the-net-directory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>803</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://yobizniz.com/"><span>http://yobizniz.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>804</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://abacusseo.com/"><span>abacusseo.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>805</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://etaaps.org/"><span>etaaps.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>806</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://handtucher.net/"><span>handtucher.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>807</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://diamondoa.org/"><span>diamondoa.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>808</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://5submission.com/"><span>5submission.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>809</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://dovetaildirectory.com/"><span>dovetaildirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>810</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://tele-script.com/"><span>tele-script.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>811</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://bobresources.com/"><span>bobresources.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>812</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://addurltolinkdirectory.com/"><span>addurltolinkdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>813</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://seorange.com/"><span>seorange.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>814</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://gogru.in/"><span>gogru.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>815</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ayroo.com/"><span>ayroo.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>816</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://callyourcountry.com/"><span>callyourcountry.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>817</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ourss.org/"><span>ourss.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>818</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://cidideas.com/"><span>cidideas.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>819</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://amazingdir.com/"><span>amazingdir.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>820</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://amazingweblinks.net/"><span>amazingweblinks.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>821</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://sageshark.com/"><span>sageshark.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>822</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://tremblemag.com/"><span>tremblemag.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>823</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://d-i-r.com/"><span>d-i-r.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>824</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://hotnews.org/"><span>hotnews.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>825</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://tagshub.com/"><span>tagshub.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>826</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://abstractdirectory.org/"><span>abstractdirectory.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>827</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://bpdir.com/"><span>bpdir.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>828</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://diolead.com/"><span>diolead.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>829</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://feedup.info/"><span>feedup.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>830</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://thats.info/"><span>thats.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>831</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://blogswirl.com/"><span>blogswirl.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>832</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.keyadir.com/"><span>http://www.keyadir.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>833</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.bergerdirectory.com/"><span>https://www.bergerdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>834</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.bergerdirectory.com/"><span>https://www.bergerdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>835</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.photographerlistings.org/"><span>https://www.photographerlistings.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>836</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.seorange.com/"><span>https://www.seorange.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>837</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.dirfaith.com/"><span>http://www.dirfaith.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>838</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.travellistings.org/"><span>https://www.travellistings.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>839</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.etaaps.org/"><span>http://www.etaaps.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>840</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.healthandbeautylistings.org/"><span>https://www.healthandbeautylistings.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>841</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.ukhotellistings.co.uk/"><span>https://www.ukhotellistings.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>842</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.balti.biz/"><span>http://www.balti.biz</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>843</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://blogsdb.com/"><span>blogsdb.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>844</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.abstractdirectory.org/"><span>http://www.abstractdirectory.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>845</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.deeplinker.net/"><span>https://www.deeplinker.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>846</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.bid1up.com/"><span>http://www.bid1up.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>847</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.rectanglead.com/"><span>www.rectanglead.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>848</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://vietsites.net/"><span>vietsites.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>849</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://omcommunication.net/"><span>omcommunication.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>850</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://directoryofglendale.com/"><span>directoryofglendale.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>851</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://xtians.com/"><span>xtians.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>852</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://alligatordirectory.com/"><span>alligatordirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>853</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://hawkdirectory.com/"><span>hawkdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>854</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ahdlrl.com/"><span>ahdlrl.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>855</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://truopto.net/"><span>truopto.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>856</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ranksuper.com/"><span>ranksuper.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>857</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://tgp-internet.com/"><span>tgp-internet.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>858</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://urltrawler.com/"><span>urltrawler.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>859</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://webdirectory1.biz/"><span>webdirectory1.biz</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>860</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://holovibes.biz/"><span>holovibes.biz</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>861</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://businesswebdirectory.info/"><span>businesswebdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>862</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ncb.biz/"><span>ncb.biz</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>863</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://directories-links.com/"><span>directories-links.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>864</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://1dir.biz/"><span>1dir.biz</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>865</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://compshock.com/"><span>compshock.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>866</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://link01directory.com/"><span>link01directory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>867</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://dmozo.org/"><span>dmozo.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>868</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://medivators.biz/"><span>medivators.biz</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>869</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://imsp.info/"><span>imsp.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>870</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://thelinkssys.com/"><span>thelinkssys.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>871</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://emsbrokers.com/"><span>emsbrokers.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>872</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://royaltyfreemusic-1.com/"><span>royaltyfreemusic-1.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>873</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://lilink.com/"><span>lilink.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>874</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://findsites.net/"><span>findsites.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>875</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.e-bizda.com/"><span>https://www.e-bizda.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>876</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.maxumise.biz/"><span>http://www.maxumise.biz</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>877</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.beacon-directory.com/"><span>http://www.beacon-directory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>878</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.beacon-directory.com/"><span>http://www.beacon-directory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>879</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.hawkdirectory.com/"><span>http://www.hawkdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>880</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://sites-plus.com/"><span>sites-plus.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>881</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.webdirectory1.biz/"><span>http://www.webdirectory1.biz</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>882</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://premiumsites.org/"><span>http://premiumsites.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>883</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://freewebhosting.cc/"><span>http://freewebhosting.cc</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>884</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.holovibes.biz/"><span>http://www.holovibes.biz</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>885</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.businesswebdirectory.info/"><span>http://www.businesswebdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>886</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.callbuster.net/"><span>https://www.callbuster.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>887</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.scraze.net/"><span>http://www.scraze.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>888</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://edulogasp.com/"><span>edulogasp.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>889</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://concertstudy.net/"><span>concertstudy.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>890</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://textlinkdirectory.com/"><span>textlinkdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>891</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://linkmysite.net/"><span>linkmysite.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>892</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://shenwaste.com/"><span>shenwaste.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>893</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://sitetweets.net/"><span>sitetweets.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>894</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://10stardirectory.com/"><span>10stardirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>895</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://field-by.com/"><span>field-by.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>896</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://aresdirectoryresource.com/"><span>aresdirectoryresource.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>897</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://jseo.biz/"><span>jseo.biz</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>898</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://directory.gtsee.com/"><span>directory.gtsee.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>899</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://acrpe.com/"><span>acrpe.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>900</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://linksindexed.com/"><span>linksindexed.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>901</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://greatdirectorylinks.com/"><span>greatdirectorylinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>902</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://seodeeplinks.net/"><span>seodeeplinks.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>903</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://directory7.biz/"><span>directory7.biz</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>904</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://vip7star.com/"><span>vip7star.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>905</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://links.snbuysell.com/"><span>links.snbuysell.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>906</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://cafelaunch.com/"><span>cafelaunch.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>907</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://freeweblink.net/"><span>freeweblink.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>908</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://myb2b.in/"><span>myb2b.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>909</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://businesspremium.biz/"><span>businesspremium.biz</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>910</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ecesummit.com/"><span>ecesummit.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>911</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://deep-links.org/"><span>deep-links.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>912</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://seotarget.net/"><span>seotarget.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>913</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://rajaf.com/"><span>rajaf.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>914</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ukinternetdirectory.com/"><span>ukinternetdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>915</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://blogrific.com/"><span>blogrific.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>916</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://businessmarketingdirectory.com/"><span>http://businessmarketingdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>917</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://financeblender.com/"><span>http://financeblender.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>918</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.italywebdirectory.net/"><span>http://www.italywebdirectory.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>919</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.topbusinessdirectory.biz/"><span>http://www.topbusinessdirectory.biz</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>920</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.italywebdirectory.net/"><span>http://www.italywebdirectory.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>921</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.topbusinessdirectory.biz/"><span>http://www.topbusinessdirectory.biz</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>922</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.uker.co.uk/"><span>http://www.uker.co.uk</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>923</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.thewebdirectory.org/"><span>https://www.thewebdirectory.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>924</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.lookmoz.org/"><span>http://www.lookmoz.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>925</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.seodeeplinks.net/"><span>https://www.seodeeplinks.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>926</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ecesummit.com/"><span>http://www.ecesummit.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>927</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.seotarget.net/"><span>https://www.seotarget.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>928</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ewilla.com/"><span>http://www.ewilla.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>929</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://officialdir.net/"><span>https://officialdir.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>930</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.businesspremium.biz/"><span>http://www.businesspremium.biz</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>931</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://directory4website.com/"><span>http://directory4website.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>932</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://firstppt.com/"><span>firstppt.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>933</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://arithum.com/"><span>arithum.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>934</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://seowebdirectory.org/"><span>seowebdirectory.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>935</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://1188la.net/"><span>1188la.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>936</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://linksbids.net/"><span>linksbids.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>937</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://paradaise.net/"><span>paradaise.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>938</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://adgaz.biz/"><span>adgaz.biz</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>939</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://gatewayoflinks.com/"><span>gatewayoflinks.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>940</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://weblinksresources.com/"><span>weblinksresources.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>941</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://skimiz.com/"><span>skimiz.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>942</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://goldendirectory.info/"><span>goldendirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>943</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://mayorken.org/"><span>mayorken.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>944</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://orcca.org/"><span>orcca.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>945</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://efficientdirectory.com/"><span>efficientdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>946</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://limedir.com/"><span>limedir.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>947</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://seokeeper.com/"><span>seokeeper.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>948</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://bis-project.eu/"><span>bis-project.eu</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>949</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.uscolist.com/"><span>https://www.uscolist.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>950</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.smartlinkdir.co.uk/"><span>http://www.smartlinkdir.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>951</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.businesslinkdirectory.biz/"><span>http://www.businesslinkdirectory.biz</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>952</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://1188la.net/"><span>http://1188la.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>953</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.blahoo.info/"><span>https://www.blahoo.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>954</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.w3directory.org/"><span>http://www.w3directory.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>955</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.lobolinks.com/"><span>http://www.lobolinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>956</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.efficientdirectory.com/"><span>http://www.efficientdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>957</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adgaz.biz/"><span>http://www.adgaz.biz</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>958</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.seokeeper.com/"><span>https://www.seokeeper.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>959</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.dating-list.com/"><span>http://www.dating-list.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>960</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.mayorken.org/"><span>http://www.mayorken.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>961</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.1directory.net/"><span>https://www.1directory.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>962</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.spanishtradedirectory.com/"><span>https://www.spanishtradedirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>963</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://zelsec.com/"><span>zelsec.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>964</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://qiaas.com/"><span>qiaas.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>965</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://master.org.in/"><span>master.org.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>966</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ec3d.com/"><span>ec3d.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>967</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://monalisadirectory.com/"><span>monalisadirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>968</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://brestlinks.com/"><span>brestlinks.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>969</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://vinylcartel.com/"><span>vinylcartel.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>970</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://thomsondirectory.com/"><span>thomsondirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>971</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://d1mm.net/"><span>d1mm.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>972</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://autopilotdirectory.com/"><span>autopilotdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>973</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://athenelinks.com/"><span>athenelinks.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>974</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://netdirectorylistings.org/"><span>netdirectorylistings.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>975</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://iaddurl.com/"><span>iaddurl.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>976</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://crystallinks.info/"><span>crystallinks.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>977</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://pelicandirectory.com/"><span>pelicandirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>978</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://caida.eu/"><span>caida.eu</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>979</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://olarex.eu/"><span>olarex.eu</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>980</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://organisedlinks.com/"><span>organisedlinks.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>981</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://majordir.com/"><span>majordir.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>982</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://seoseek.net/"><span>seoseek.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>983</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://pantherdirectory.com/"><span>pantherdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>984</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://link1directory.com/"><span>link1directory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>985</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://lignol.net/"><span>lignol.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>986</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://csubilc.org/"><span>csubilc.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>987</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://graydir.com/"><span>graydir.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>988</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://2daydir.com/"><span>2daydir.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>989</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://mvpweekly.com/"><span>mvpweekly.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>990</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://dirweblisting.com/"><span>dirweblisting.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>991</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://linkindexdirectory.com/"><span>linkindexdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>992</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://mintdir.com/"><span>mintdir.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>993</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://directseek.info/"><span>directseek.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>994</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://cabdir.com/"><span>cabdir.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>995</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://esjoub.com/"><span>esjoub.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>996</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://linksmaximum.com/"><span>linksmaximum.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>997</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.businessflow.info/"><span>http://www.businessflow.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>998</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.thegeekshow.pl/"><span>http://www.thegeekshow.pl</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>999</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.shoppingandservices.net/"><span>http://www.shoppingandservices.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1000</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://addedtheurl.com/"><span>http://addedtheurl.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1001</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://allinfolisted.com/"><span>http://allinfolisted.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1002</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://codedlistings.com/"><span>http://codedlistings.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1003</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://getlistedurl.com/"><span>http://getlistedurl.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1004</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://savedwebsites.com/"><span>http://savedwebsites.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1005</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.li-nks.com/"><span>https://www.li-nks.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1006</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.li-nks.com/"><span>https://www.li-nks.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1007</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ultimatedir.com/"><span>http://www.ultimatedir.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1008</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.allucdirectory.com/"><span>http://www.allucdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1009</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.cruiserrodparts.com/"><span>http://www.cruiserrodparts.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1010</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.onlineaddirectory.com/"><span>http://www.onlineaddirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1011</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.allmediadirectory.com/"><span>http://www.allmediadirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1012</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://master.org.in/"><span>http://master.org.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1013</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.online-websites-directory.com/"><span>http://www.online-websites-directory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1014</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.directorysphere.com/"><span>http://www.directorysphere.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1015</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.kbitsoftware.com/"><span>http://www.kbitsoftware.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1016</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.pantherdirectory.com/"><span>http://www.pantherdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1017</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.freedirectoryrecord.com/"><span>http://www.freedirectoryrecord.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1018</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://carefullypicked.com/"><span>carefullypicked.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1019</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.a1linkin.com/"><span>http://www.a1linkin.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1020</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.mathi.info/"><span>http://www.mathi.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1021</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://sitetrawler.com/"><span>sitetrawler.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1022</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://litetopia.com/"><span>litetopia.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1023</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://list-listings.org/"><span>list-listings.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1024</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://magpiedirectory.com/"><span>magpiedirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1025</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://reservedlinks.com/"><span>reservedlinks.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1026</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://generaldironline.com/"><span>generaldironline.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1027</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://linksofchoice.com/"><span>linksofchoice.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1028</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://dolphinlist.com/"><span>dolphinlist.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1029</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ofcpage.info/"><span>ofcpage.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1030</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://at-uk.com/"><span>at-uk.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1031</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://goldinsight.com/"><span>goldinsight.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1032</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://homepagedeal.com/"><span>homepagedeal.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1033</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://arbecey.com/"><span>arbecey.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1034</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://browselists.com/"><span>browselists.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1035</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://linkssubmit.net/"><span>linkssubmit.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1036</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://nasadirectory.com/"><span>nasadirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1037</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://webworlddir.com/"><span>webworlddir.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1038</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://beatmylink.com/"><span>beatmylink.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1039</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://caplus.co.uk/"><span>caplus.co.uk</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1040</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ontheweblisted.com/"><span>http://ontheweblisted.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1041</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.indiabusinessindex.com/"><span>http://www.indiabusinessindex.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1042</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://ufrog.xyz/"><span>https://ufrog.xyz</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1043</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://edgeyourlevel.com/"><span>http://edgeyourlevel.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1044</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://linksontherise.com/"><span>http://linksontherise.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1045</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://listingrocks.com/"><span>http://listingrocks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1046</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://listingwaves.com/"><span>http://listingwaves.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1047</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://reservedlinks.com/"><span>http://reservedlinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1048</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://reviewyourentries.com/"><span>http://reviewyourentries.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1049</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://simplytheurl.com/"><span>http://simplytheurl.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1050</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://sitemaximum.com/"><span>http://sitemaximum.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1051</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://multiplevisitors.com/"><span>http://multiplevisitors.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1052</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.nolosvotes.com/"><span>http://www.nolosvotes.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1053</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.deeplinksdirectory.co/"><span>http://www.deeplinksdirectory.co</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1054</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.nolosvotes.com/"><span>http://www.nolosvotes.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1055</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.deeplinksdirectory.co/"><span>http://www.deeplinksdirectory.co</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1056</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.wgshost.com/"><span>https://www.wgshost.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1057</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://fourseek.com/"><span>http://fourseek.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1058</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://hunwebdirectory.info/"><span>http://hunwebdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1059</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.globetory.com/"><span>http://www.globetory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1060</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.blogaboutmysite.com/directory"><span>http://www.blogaboutmysite.com/directory</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1061</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.usatohouse.com/"><span>https://www.usatohouse.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1062</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.iqdir.com/"><span>http://www.iqdir.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1063</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://gowebguide.com/"><span>http://gowebguide.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1064</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.addurlzone.com/"><span>http://www.addurlzone.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1065</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.hsdirectory.com/"><span>http://www.hsdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1066</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ruce.org/"><span>http://ruce.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1067</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.aussieimporters.com/"><span>http://www.aussieimporters.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1068</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.indexpawn.com/"><span>http://www.indexpawn.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1069</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.pubandrestaurantlistings.co.uk/"><span>https://www.pubandrestaurantlistings.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1070</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://homepagedeal.com/"><span>http://homepagedeal.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1071</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.rowrug.com/"><span>https://www.rowrug.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1072</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.kalajack.com/"><span>https://www.kalajack.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1073</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.submit-link.net/"><span>http://www.submit-link.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1074</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://directory.edu.vn/"><span>directory.edu.vn</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1075</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://umoz.org/"><span>umoz.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1076</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://rowma.com/"><span>rowma.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1077</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://seoabcs.com/"><span>seoabcs.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1078</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://hamsterdirectory.com/"><span>hamsterdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1079</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://411canadayellowpages.ca/"><span>411canadayellowpages.ca</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1080</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://sergiuungureanu.com/"><span>sergiuungureanu.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1081</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://linksplaced.com/"><span>linksplaced.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1082</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://submissionsbank.com/"><span>submissionsbank.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1083</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://indexedlist.com/"><span>indexedlist.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1084</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://directorysuper.com/"><span>directorysuper.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1085</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://the.topentry.info/"><span>the.topentry.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1086</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://arcwm.org/"><span>arcwm.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1087</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://linkindex.biz/"><span>linkindex.biz</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1088</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://redumavida.net/"><span>redumavida.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1089</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://submitrelevantsites.com/"><span>submitrelevantsites.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1090</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://uniquesubmitter.com/"><span>uniquesubmitter.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1091</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://evoasp.org/"><span>evoasp.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1092</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://freeindexonline.com/"><span>freeindexonline.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1093</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://copperdirectory.com/"><span>copperdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1094</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://1webdirectory.org/"><span>1webdirectory.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1095</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://chameleonwebservices.com/"><span>chameleonwebservices.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1096</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://rockinglinks.com/"><span>rockinglinks.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1097</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://siteseek.net/"><span>siteseek.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1098</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://regularseo.com/"><span>regularseo.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1099</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://mohawkdirectory.info/"><span>mohawkdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1100</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://greatlinksontheweb.com/"><span>greatlinksontheweb.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1101</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://bestseodir.info/"><span>bestseodir.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1102</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.uniquesubmitter.com/"><span>http://www.uniquesubmitter.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1103</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.xwebdirectory.com/"><span>http://www.xwebdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1104</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ratelocal.com/"><span>http://www.ratelocal.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1105</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.mitcheltarterlaw.com/"><span>http://www.mitcheltarterlaw.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1106</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://direcoryteam.com/"><span>http://direcoryteam.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1107</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://getassuredlinks.com/"><span>http://getassuredlinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1108</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://happytolist.com/"><span>http://happytolist.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1109</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://linksplaced.com/"><span>http://linksplaced.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1110</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://swedesborobsa.org/"><span>http://swedesborobsa.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1111</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://theacdreunion.org/"><span>http://theacdreunion.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1112</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://businessdippo.com/"><span>http://businessdippo.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1113</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.sergiuungureanu.com/"><span>http://www.sergiuungureanu.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1114</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.arcwm.org/"><span>http://www.arcwm.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1115</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.goodprlinks.com/"><span>http://www.goodprlinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1116</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ahewd.com/"><span>http://www.ahewd.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1117</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.mpiperu.com/"><span>https://www.mpiperu.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1118</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://freeonlinewebdirectory.com/"><span>http://freeonlinewebdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1119</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.hamsterdirectory.com/"><span>http://www.hamsterdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1120</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.chameleonwebservices.com/"><span>http://www.chameleonwebservices.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1121</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://rockinglinks.com/"><span>http://rockinglinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1122</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.webappsdirectory.com/"><span>https://www.webappsdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1123</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://submitrelevantsites.com/"><span>http://submitrelevantsites.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1124</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.iamnatural.com.au/"><span>https://www.iamnatural.com.au</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1125</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://spiffysearch.com/"><span>https://spiffysearch.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1126</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.seozoic.com/"><span>https://www.seozoic.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1127</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.mpiperu.com/"><span>https://www.mpiperu.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1128</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.dirville.com/"><span>http://www.dirville.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1129</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://phpseodirectory.com/"><span>phpseodirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1130</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://nta-tu.com/"><span>nta-tu.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1131</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://jaguardirectory.com/"><span>jaguardirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1132</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://crocodiledirectory.com/"><span>crocodiledirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1133</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://quoteabyss.com/"><span>quoteabyss.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1134</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://templarscrusade.com/"><span>templarscrusade.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1135</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://webmasteranalyzer.com/"><span>webmasteranalyzer.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1136</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://yourlinkmarket.com/"><span>yourlinkmarket.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1137</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://erskinedesign.eu/"><span>erskinedesign.eu</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1138</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://usawl.com/"><span>usawl.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1139</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://poliana.org/"><span>poliana.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1140</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ciberespiral.eu/"><span>ciberespiral.eu</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1141</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://adopsweb.com/"><span>adopsweb.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1142</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://istriebro.com/"><span>istriebro.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1143</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://uk-locate.co.uk/"><span>uk-locate.co.uk</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1144</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://admax.co.uk/"><span>admax.co.uk</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1145</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://weblink-directory.com/"><span>weblink-directory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1146</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://circlewithin.com/"><span>circlewithin.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1147</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://jungleelink.com/"><span>jungleelink.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1148</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://orbitaldirectory.com/"><span>orbitaldirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1149</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://infoseomaster.com/"><span>infoseomaster.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1150</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://linkscasual.com/"><span>linkscasual.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1151</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://fivestarfastlane.info/"><span>fivestarfastlane.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1152</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://listandgethighrank.com/"><span>listandgethighrank.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1153</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://infolistings.com/"><span>infolistings.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1154</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.slushdir.com/"><span>http://www.slushdir.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1155</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.dir.uk.com/"><span>https://www.dir.uk.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1156</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.usinvestmentdirectory.com/"><span>http://www.usinvestmentdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1157</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.phpseodirectory.com/"><span>http://www.phpseodirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1158</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://linkingdirectly.com/"><span>http://linkingdirectly.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1159</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.chinafdsh.com/"><span>http://www.chinafdsh.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1160</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://tipstopromote.com/"><span>http://tipstopromote.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1161</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://highqualitypostings.com/"><span>http://highqualitypostings.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1162</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://visionoutline.com/"><span>http://visionoutline.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1163</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://commentlistings.com/"><span>http://commentlistings.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1164</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://directoryfollow.com/"><span>http://directoryfollow.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1165</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://everyonerated.com/"><span>http://everyonerated.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1166</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://featuredconcepts.com/"><span>http://featuredconcepts.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1167</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://fetchedlinks.com/"><span>http://fetchedlinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1168</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://humaneditedlistings.com/"><span>http://humaneditedlistings.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1169</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://increaselisting.com/"><span>http://increaselisting.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1170</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://linkextras.com/"><span>http://linkextras.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1171</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://outstandingentries.com/"><span>http://outstandingentries.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1172</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://regularsubmissions.com/"><span>http://regularsubmissions.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1173</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://releasedsites.com/"><span>http://releasedsites.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1174</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://reportedsites.com/"><span>http://reportedsites.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1175</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://siteinformatics.com/"><span>http://siteinformatics.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1176</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://siteisvisible.com/"><span>http://siteisvisible.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1177</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://submissionclick.com/"><span>http://submissionclick.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1178</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://webmasteranalyzer.com/"><span>http://webmasteranalyzer.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1179</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://popularentries.com/"><span>http://popularentries.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1180</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://portmancapitalpartners.com/"><span>http://portmancapitalpartners.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1181</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://variousplatforms.com/"><span>http://variousplatforms.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1182</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://lifetimevotes.com/"><span>http://lifetimevotes.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1183</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://pointyourlinks.com/"><span>http://pointyourlinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1184</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.allwebreference.com/"><span>http://www.allwebreference.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1185</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://variousplatforms.com/"><span>http://variousplatforms.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1186</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://lifetimevotes.com/"><span>http://lifetimevotes.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1187</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://pointyourlinks.com/"><span>http://pointyourlinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1188</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.allwebreference.com/"><span>http://www.allwebreference.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1189</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.dir.uk.com/"><span>https://www.dir.uk.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1190</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.championdirectory.info/"><span>http://www.championdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1191</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.biz-dir.co.uk/"><span>http://www.biz-dir.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1192</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.acedirectory.info/"><span>http://www.acedirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1193</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.actiondir.info/"><span>http://www.actiondir.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1194</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.premiumdir.com/"><span>http://www.premiumdir.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1195</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.nta-tu.com/"><span>http://www.nta-tu.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1196</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.onecorps.org/"><span>http://www.onecorps.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1197</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.dwsdirectory.net/"><span>https://www.dwsdirectory.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1198</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.jaguardirectory.com/"><span>http://www.jaguardirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1199</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.ukgardening-directory.co.uk/"><span>https://www.ukgardening-directory.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1200</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.poliana.org/"><span>http://www.poliana.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1201</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adopsweb.com/"><span>http://www.adopsweb.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1202</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.flameu.com/"><span>https://www.flameu.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1203</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.directorycorner.com/"><span>http://www.directorycorner.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1204</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.callfor.co.in/"><span>http://www.callfor.co.in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1205</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.flameu.com/"><span>https://www.flameu.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1206</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://generaldirectorylistings.org/"><span>generaldirectorylistings.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1207</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://linkdirectorylistings.org/"><span>linkdirectorylistings.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1208</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://lushdirectory.com/"><span>lushdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1209</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://stagdirectory.com/"><span>stagdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1210</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://shveaa.com/"><span>shveaa.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1211</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://alisani.com/"><span>alisani.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1212</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://faviconstyle.com/"><span>faviconstyle.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1213</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://squidlinks.com/"><span>squidlinks.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1214</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ohitson.com/"><span>ohitson.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1215</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://321webdev.com/"><span>321webdev.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1216</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://completedirectory.org/"><span>completedirectory.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1217</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://sponsoredhomepage.com/"><span>sponsoredhomepage.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1218</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://flamingodirectory.com/"><span>flamingodirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1219</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://purplehazedirectory.com/"><span>purplehazedirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1220</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://directorysitesubmit.com/"><span>directorysitesubmit.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1221</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://directoryhost.org/"><span>directoryhost.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1222</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://socal-lawyerdirectory.com/"><span>https://socal-lawyerdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1223</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://sponsoredhomepage.com/"><span>http://sponsoredhomepage.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1224</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.botatlas.com/"><span>http://www.botatlas.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1225</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://morevaluedtraffic.com/"><span>http://morevaluedtraffic.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1226</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.global-web-directory.com/"><span>http://www.global-web-directory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1227</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://buzzhappenings.com/"><span>http://buzzhappenings.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1228</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://datacreated.com/"><span>http://datacreated.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1229</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://everythingallowed.com/"><span>http://everythingallowed.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1230</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://businessbroadway.com/"><span>http://businessbroadway.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1231</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://directorybenefits.com/"><span>http://directorybenefits.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1232</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://directoryprocess.com/"><span>http://directoryprocess.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1233</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://listingsmaintained.com/"><span>http://listingsmaintained.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1234</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://naturalentries.com/"><span>http://naturalentries.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1235</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://newlylinkedinfo.com/"><span>http://newlylinkedinfo.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1236</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://onewayhigher.com/"><span>http://onewayhigher.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1237</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://pageranktarget.com/"><span>http://pageranktarget.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1238</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://processingwebsite.com/"><span>http://processingwebsite.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1239</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://realnetworkentries.com/"><span>http://realnetworkentries.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1240</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://topmostlists.com/"><span>http://topmostlists.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1241</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://increasemoretraffic.com/"><span>http://increasemoretraffic.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1242</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://businessdirectoryfort.com/"><span>http://businessdirectoryfort.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1243</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://businessmonarchy.com/"><span>http://businessmonarchy.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1244</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://listedbusinesses.net/"><span>http://listedbusinesses.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1245</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://worldofbiz.net/"><span>http://worldofbiz.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1246</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://scottfinanzdirectory.com/"><span>http://scottfinanzdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1247</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://smashmanagement.net/"><span>http://smashmanagement.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1248</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://freshentries.com/"><span>http://freshentries.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1249</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://freshentries.com/"><span>http://freshentries.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1250</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.mlinkdirectory.com/"><span>https://www.mlinkdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1251</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.smallbusinessdirectoryuk.co.uk/"><span>http://www.smallbusinessdirectoryuk.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1252</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.4114u.com/"><span>http://www.4114u.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1253</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.paid-directory.com/"><span>http://www.paid-directory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1254</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.securityclassifieds.co.uk/"><span>http://www.securityclassifieds.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1255</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.hugecandle.com/"><span>http://www.hugecandle.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1256</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.addallsites.com/"><span>http://www.addallsites.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1257</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.resonantdirectory.com/"><span>http://www.resonantdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1258</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://directorysitesubmit.com/"><span>http://directorysitesubmit.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1259</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.dir.us.com/"><span>https://www.dir.us.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1260</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://worldwebdirectory.com/"><span>worldwebdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1261</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://law.ibestlocal.com/"><span>https://law.ibestlocal.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1262</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.mlinkdirectory.com/"><span>https://www.mlinkdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1263</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.s-eo.net/"><span>https://www.s-eo.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1264</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.navstarter.com/"><span>http://www.navstarter.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1265</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.hugecandle.com/"><span>http://www.hugecandle.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1266</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ubublu.com/"><span>http://www.ubublu.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1267</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://tgv15.com/"><span>http://tgv15.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1268</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://internetdirectory1.org/"><span>internetdirectory1.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1269</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://onlinesociety.org/"><span>onlinesociety.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1270</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://lepsiasplatka.eu/"><span>lepsiasplatka.eu</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1271</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://latadirectory.com/"><span>latadirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1272</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://enriqueblancoweb.com/"><span>enriqueblancoweb.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1273</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://zendiva.com/"><span>zendiva.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1274</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.penangbusiness.my/"><span>https://www.penangbusiness.my</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1275</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.expresseek.com/"><span>http://www.expresseek.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1276</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.remsisdir.com/"><span>http://www.remsisdir.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1277</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://commercialapproved.com/"><span>http://commercialapproved.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1278</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://approvalchances.com/"><span>http://approvalchances.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1279</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.directoryedge.com/"><span>http://www.directoryedge.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1280</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.dirbenchmark.com/"><span>http://www.dirbenchmark.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1281</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.goinggreendirectory.org/"><span>http://www.goinggreendirectory.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1282</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://bizzdirectorylist.com/"><span>http://bizzdirectorylist.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1283</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://bestreportingservice.com/"><span>http://bestreportingservice.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1284</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://backlinkscompared.com/"><span>http://backlinkscompared.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1285</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://fantastichomepage.com/"><span>http://fantastichomepage.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1286</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://trackbackyoururl.com/"><span>http://trackbackyoururl.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1287</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://servicesupgraded.com/"><span>http://servicesupgraded.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1288</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://smartonlineforms.com/"><span>http://smartonlineforms.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1289</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://directoryusers.com/"><span>http://directoryusers.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1290</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://rankbasedlistings.com/"><span>http://rankbasedlistings.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1291</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://subscribedentries.com/"><span>http://subscribedentries.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1292</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://arenaofbusiness.com/"><span>http://arenaofbusiness.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1293</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://indexofbusinesses.com/"><span>http://indexofbusinesses.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1294</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://projectingbusinesses.com/"><span>http://projectingbusinesses.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1295</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://tangentbusinessdirectory.com/"><span>http://tangentbusinessdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1296</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://valuedbusinesslistings.com/"><span>http://valuedbusinesslistings.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1297</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://financedirectoryonline.com/"><span>http://financedirectoryonline.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1298</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://financehunt.net/"><span>http://financehunt.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1299</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://financestairway.com/"><span>http://financestairway.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1300</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://businesslandmark.com/"><span>http://businesslandmark.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1301</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://pointyourgoal.com/"><span>http://pointyourgoal.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1302</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://upgradedtactics.com/"><span>http://upgradedtactics.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1303</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://upgradedtactics.com/"><span>http://upgradedtactics.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1304</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.hunt.co.in/"><span>http://www.hunt.co.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1305</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://igaming.directory/"><span>http://igaming.directory</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1306</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://directory.traveltours.review/"><span>http://directory.traveltours.review</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1307</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://directory.org.vn/"><span>directory.org.vn</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1308</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://permanentrankings.com/"><span>permanentrankings.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1309</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://quickapprovaldirectory.com/"><span>quickapprovaldirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1310</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://polanddirectory.com/"><span>polanddirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1311</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://linkminded.com/"><span>linkminded.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1312</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://permanentrankings.com/"><span>http://permanentrankings.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1313</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://linkdirectory.info/"><span>http://linkdirectory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1314</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.indexgala.com/"><span>http://www.indexgala.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1315</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.linksvilla.com/"><span>http://www.linksvilla.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1316</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.searchengine-optimization.biz/"><span>http://www.searchengine-optimization.biz</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1317</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.dirroyal.com/"><span>http://www.dirroyal.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1318</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.dirhawk.com/"><span>http://www.dirhawk.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1319</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.directory-for-submission.com/"><span>http://www.directory-for-submission.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1320</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://usefultoeverybody.com/"><span>http://usefultoeverybody.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1321</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://nepsole.com/"><span>https://nepsole.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1322</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://businessbuildingblock.com/"><span>http://businessbuildingblock.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1323</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://businessreservoir.com/"><span>http://businessreservoir.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1324</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://identglobal.com/"><span>http://identglobal.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1325</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://judyhester.com/"><span>http://judyhester.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1326</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://listofbiz.com/"><span>http://listofbiz.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1327</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://paulstinson.com/"><span>http://paulstinson.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1328</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://qualitybussinesslistings.com/"><span>http://qualitybussinesslistings.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1329</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://shortlistedbiz.com/"><span>http://shortlistedbiz.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1330</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://skyscrapebusiness.com/"><span>http://skyscrapebusiness.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1331</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://financebreakthrough.com/"><span>http://financebreakthrough.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1332</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://financefairplay.com/"><span>http://financefairplay.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1333</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://financialreferences.com/"><span>http://financialreferences.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1334</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://finanzdirectory.com/"><span>http://finanzdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1335</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://leverageyourfinance.com/"><span>http://leverageyourfinance.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1336</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://multistoryedfinance.com/"><span>http://multistoryedfinance.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1337</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://financedmoz.com/"><span>http://financedmoz.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1338</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://businessposted.com/"><span>http://businessposted.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1339</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://playimportantrole.com/"><span>http://playimportantrole.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1340</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://buzzingwithpeople.com/"><span>http://buzzingwithpeople.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1341</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://playimportantrole.com/"><span>http://playimportantrole.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1342</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://buzzingwithpeople.com/"><span>http://buzzingwithpeople.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1343</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.searchengine-optimization.biz/"><span>http://www.searchengine-optimization.biz</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1344</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://directory.travelagent.win/"><span>http://directory.travelagent.win</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1345</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://bid.123directory.info/"><span>http://bid.123directory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1346</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.wikisavvy.com/"><span>http://www.wikisavvy.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1347</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://synergydirectory.com/"><span>synergydirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1348</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://thefreedirectory.co.uk/"><span>thefreedirectory.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1349</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.seo-index.com/"><span>http://www.seo-index.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1350</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.topsofweb.com/"><span>https://www.topsofweb.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1351</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.nowhoo.com/"><span>http://www.nowhoo.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1352</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.geoall.com/"><span>http://www.geoall.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1353</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.links4se.com/"><span>http://www.links4se.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1354</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://moderntopdirectory.org/"><span>moderntopdirectory.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>5</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1355</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://isyoursiteranked.com/"><span>isyoursiteranked.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>5</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1356</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://webgain.org/"><span>webgain.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>5</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1357</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.listbooth.com/"><span>http://www.listbooth.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>5</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1358</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.directorylotus.com/"><span>http://www.directorylotus.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>5</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1359</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://independentlyrated.com/"><span>http://independentlyrated.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>5</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1360</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://claimrecon.com/"><span>http://claimrecon.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>5</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1361</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://worldbigtops.com/"><span>http://worldbigtops.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>5</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1362</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://globalfinancedirectory.com/"><span>http://globalfinancedirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>5</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1363</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://landscapeyourfinance.com/"><span>http://landscapeyourfinance.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>5</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1364</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://88directory.info/"><span>http://88directory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>5</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1365</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://88directory.info/"><span>http://88directory.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>5</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1366</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.linkedlike.com/"><span>http://www.linkedlike.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>5</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1367</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.webgain.org/"><span>http://www.webgain.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>5</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1368</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.sbdir.com/"><span>http://www.sbdir.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>5</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1369</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.localbusinesslistings.org/"><span>http://www.localbusinesslistings.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>5</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1370</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.diamondjewelrydirectory.net/"><span>http://www.diamondjewelrydirectory.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>5</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1371</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://starlinkdirectory.com/"><span>http://starlinkdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>5</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1372</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.bigbluedirectory.com/"><span>http://www.bigbluedirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>5</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1373</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://networkeddirectory.org/"><span>networkeddirectory.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>4</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1374</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://networkeddirectory.org/"><span>http://networkeddirectory.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>4</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1375</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://feynd.com/"><span>http://feynd.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>4</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1376</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.bestbizseek.com/"><span>http://www.bestbizseek.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>4</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1377</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.linkcrocus.com/"><span>http://www.linkcrocus.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>4</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1378</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://freedirectory-listings.org/"><span>freedirectory-listings.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>3</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1379</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://latesttopdirectory.org/"><span>latesttopdirectory.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>3</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1380</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://mediadesk.org/"><span>mediadesk.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>3</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1381</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://mediadesk.org/"><span>http://mediadesk.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>3</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1382</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://taapslink.org/"><span>taapslink.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>2</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1383</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://sitepros.org/"><span>sitepros.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>2</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1384</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.taapslink.org/"><span>http://www.taapslink.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>2</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1385</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://financewhiteboard.com/"><span>http://financewhiteboard.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>2</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1386</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.pi96.info/"><span>http://www.pi96.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>2</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1387</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://spiffysearch.comdir/"><span>spiffysearch.comdir</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1388</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://cdon.info/"><span>http://cdon.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1389</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://istriebro.com"><span>istriebro.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1</span></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            <h1 className='text-[32px] font-medium my-3 heading mt-12'> Top 5 Things To Consider While Doing
                                Directory Submission</h1>
                            <p className='text-[18px] font-normal text-[#636363] '>When submitting your website to directories,
                                there are several guidelines that you must adhere to. You can adhere to These guidelines and
                                suggestions while submitting to directories.</p> <br />
                            <ul className='bg-[#f4dacd70] p-8'>
                                <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                    className='text-[#63E6BE] mr-2'><i
                                        class="fa-regular fa-square-check"></i></span></strong>It serves no purpose to
                                    be listed in a directory if it is not indexed. Thus, confirm that Google, Bing, and other
                                    major search engines have indexed and cached the directories.</li>
                                <br />
                                <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                    className='text-[#63E6BE] mr-2'><i
                                        class="fa-regular fa-square-check"></i></span></strong>In the titles and
                                    descriptions, use a variety of phrases to target, such as your brand name, primary keywords,
                                    exact match domain name, related keywords, main keyword synonyms, long-tail keywords, etc.
                                </li>
                                <br />
                                <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                    className='text-[#63E6BE] mr-2'><i
                                        class="fa-regular fa-square-check"></i></span></strong> Use a manual directory
                                    submission service provider or submit manually to each directory. Never utilize submission
                                    automation software that generates 1000 submissions in a minute.</li>
                                <br />
                                <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                    className='text-[#63E6BE] mr-2'><i
                                        class="fa-regular fa-square-check"></i></span></strong>
                                    Don't rely just on entries to directories. In addition to submitting directories, use other
                                    link-building strategies like infographics, guest blogging, social bookmarking, etc.</li>
                                <br />
                                <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                    className='text-[#63E6BE] mr-2'><i
                                        class="fa-regular fa-square-check"></i></span></strong>Send submissions to
                                    various directories, such as premium, industry-specific, nationwide, regional, specialized,
                                    and general directories.
                                </li>
                            </ul>

                            <div className="faqs mb-12">
                                <h1 className='text-[32px] font-medium my-3 heading'> FAQs
                                </h1>
                                <div id="accordion-collapse" data-accordion="collapse">
                                    <h2 id="accordion-collapse-heading-1">
                                        <button type="button"
                                            class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200  hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                            data-accordion-target="#accordion-collapse-body-1" aria-expanded="true"
                                            aria-controls="accordion-collapse-body-1">
                                            <span className='text-[20px] text-[#222] text-left'>What are Directory Submission
                                                Sites?</span>
                                            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                    strokeWidth="2" d="M9 5 5 1 1 5" />
                                            </svg>
                                        </button>
                                    </h2>
                                    <div id="accordion-collapse-body-1" class="hidden"
                                        aria-labelledby="accordion-collapse-heading-1">
                                        <div
                                            class="p-5 border border-b-0 border-gray-200 darks:border-gray-700 darks:bg-gray-900">
                                            <p className='text-[18px] font-normal text-[#636363] '>Website owners can submit
                                                their website's details, including its URL, description, and category, to
                                                directory submission sites and online directories to have their website listed
                                                in relevant categories.</p>
                                        </div>
                                    </div>
                                    <h2 id="accordion-collapse-heading-2">
                                        <button type="button"
                                            class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                            data-accordion-target="#accordion-collapse-body-2" aria-expanded="false"
                                            aria-controls="accordion-collapse-body-2">
                                            <span className='text-[20px] text-[#222] text-left'>What are the Benefits of
                                                Directory Submission Sites?</span>
                                            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                    strokeWidth="2" d="M9 5 5 1 1 5" />
                                            </svg>
                                        </button>
                                    </h2>
                                    <div id="accordion-collapse-body-2" class="hidden"
                                        aria-labelledby="accordion-collapse-heading-2">
                                        <div class="p-5 border border-b-0 border-gray-200 darks:border-gray-700">
                                            <p className='text-[18px] font-normal text-[#636363] '>Directory submissions offer
                                                better domain authority, more visibility, targeted traffic, enhanced search
                                                engine rankings, and cost-effectiveness.</p>
                                        </div>
                                    </div>
                                    <h2 id="accordion-collapse-heading-3">
                                        <button type="button"
                                            class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                            data-accordion-target="#accordion-collapse-body-3" aria-expanded="false"
                                            aria-controls="accordion-collapse-body-3">
                                            <span className='text-[20px] text-[#222] text-left'>Are All Directory Submission
                                                Sites Free?</span>
                                            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                    strokeWidth="2" d="M9 5 5 1 1 5" />
                                            </svg>
                                        </button>
                                    </h2>
                                    <div id="accordion-collapse-body-3" class="hidden"
                                        aria-labelledby="accordion-collapse-heading-3">
                                        <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                            <p className='text-[18px] font-normal text-[#636363] '>No, there are places where
                                                you can submit directories for free, and sometimes you must pay. Free
                                                directories are easier to use, but paid directories provide better targeting
                                                choices, greater visibility, and higher quality.</p>


                                        </div>
                                    </div>

                                    <h2 id="accordion-collapse-heading-4">
                                        <button type="button"
                                            class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                            data-accordion-target="#accordion-collapse-body-4" aria-expanded="false"
                                            aria-controls="accordion-collapse-body-4">
                                            <span className='text-[20px] text-[#222] text-left'>How Do I Choose The Right
                                                Directory Submission Site For my Website?</span>
                                            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                    strokeWidth="2" d="M9 5 5 1 1 5" />
                                            </svg>
                                        </button>
                                    </h2>
                                    <div id="accordion-collapse-body-4" class="hidden"
                                        aria-labelledby="accordion-collapse-heading-4">
                                        <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                            <p className='text-[18px] font-normal text-[#636363] '>Select a directory submission
                                                website with a strong reputation, high domain authority, and relevance to your
                                                niche. You must also carefully study and stick to the directory's standards to
                                                guarantee that your submission is accepted.</p>
                                        </div>
                                    </div>

                                    <h2 id="accordion-collapse-heading-5">
                                        <button type="button"
                                            class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                            data-accordion-target="#accordion-collapse-body-5" aria-expanded="false"
                                            aria-controls="accordion-collapse-body-5">
                                            <span className='text-[20px] text-[#222] text-left'>Should I Submit my Website to
                                                Several Directory Submission Sites?</span>
                                            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                    strokeWidth="2" d="M9 5 5 1 1 5" />
                                            </svg>
                                        </button>
                                    </h2>
                                    <div id="accordion-collapse-body-5" class="hidden"
                                        aria-labelledby="accordion-collapse-heading-5">
                                        <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                            <p className='text-[18px] font-normal text-[#636363] '>It's crucial to submit your
                                                website URL only to reputable directories. Prioritize quality over quantity and
                                                avoid submitting to unreliable or low-quality directories.</p>
                                        </div>
                                    </div>

                                    <h2 id="accordion-collapse-heading-6">
                                        <button type="button"
                                            class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                            data-accordion-target="#accordion-collapse-body-7" aria-expanded="false"
                                            aria-controls="accordion-collapse-body-7">
                                            <span className='text-[20px] text-[#222] text-left'>Can Submitting My Website to
                                                Directory Submission Sites Harm my Website's SEO?</span>
                                            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                    strokeWidth="2" d="M9 5 5 1 1 5" />
                                            </svg>
                                        </button>
                                    </h2>
                                    <div id="accordion-collapse-body-7" class="hidden"
                                        aria-labelledby="accordion-collapse-heading-6">
                                        <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                            <p className='text-[18px] font-normal text-[#636363] '>Indeed, adding your website
                                                to directories with a bad reputation or low quality can hurt its search engine
                                                optimization. Selecting reputable directories and adhering to their policies is
                                                crucial to prevent any harm to your website's online visibility.</p>
                                        </div>
                                    </div>


                                </div>

                            </div>
                        </div>
                        <div className="w-[30%] hidden lg:block relative">
                            <div className='box-shadow rounded-lg ml-5 p-8 sidebar sticky top-[-150px]'>
                                <div className="sidebar_banner">
                                    <img src="img/page_img_4.jpg" alt="" />
                                </div>

                                <div className="sidebar_tabs mt-5">
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/profile-creation-sites-list">
                                            <h1 className='text-[18px] font-semibold capitalize'>Top High Do Follow Profile Creation
                                                Sites List</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/image-submission-sites-list">
                                            <h1 className='text-[18px] font-semibold capitalize'>image-submission-sites-list</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/classified-ads-websites">
                                            <h1 className='text-[18px] font-semibold capitalize'>classified-ads-websites</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/instant-approval-article-submissions-sites-list">
                                            <h1 className='text-[18px] font-semibold capitalize'>instant-approval-article-submissions-sites-list</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/free-high-quality-directory-submission-sites">
                                            <h1 className='text-[18px] font-semibold capitalize'>free-high-quality-directory-submission-sites
                                                Sites List</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/blog-submission-sites">
                                            <h1 className='text-[18px] font-semibold capitalize'>blog-submission-sites</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/business-listing-sites-in-india">
                                            <h1 className='text-[18px] font-semibold capitalize'>business-listing-sites-in-india</h1>
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default FreeHighQualityDirectorySubmissionSites