import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { Link } from "react-router-dom";


function Wordpress() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{ backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)" }}>
                <div className="container mx-auto py-12">
                    <h1 className='text-center text-white text-[34px] md:text-[42px] lg:text-[56px] xl:text-[86px] font-bold heading uppercase'>WordPress</h1>
                </div>
            </div>

            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[70%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <img src="img/Word-Press.jpg" className='w-full' alt="" />

                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>WordPress</h1>
                                <p className='text-[18px] font-normal text-[#636363] '><strong>Work With Us To Build and Strengthen Your Brand.</strong></p><br />
                                <p className='text-[18px] font-normal text-[#636363] '>WordPress has many capabilities as a content management system (CMS) that let your company use creative vision to build strong functionality you would never have imagined.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>At Bonafide Technologies, we have worked with leading brands worldwide to build and maintain their WordPress websites and positively impact their business. Whether you want to create a blog or a fully functional website, Bonafide Technologies can provide you with the best WordPress developers for your project. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>We are a leading WordPress development company that excels in convenience, coding, and conceptualization. Our clients benefit from simple data administration and comprehension thanks to our integrated solutions and plugins. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>Our developers can provide value to your project by adding their experience to optimize your pages for voice search, create a video background, construct multi-purpose themes, or implement any other security or engagement features.</p>
                            </div>

                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'> What We Provide</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>With multiple plugin options and an open-source website creation platform, Bonafide Technologies offers extensive information on achieving WordPress sensations.</p><br />

                                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                                    <div className="flex flex-wrap justify-between">
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Custom Design </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>A well-designed custom website development that is search engine friendly helps your business attract more visitors looking for your products or services.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>WordPress Websites </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>For companies of all sizes, we create and design websites. Our websites satisfy your needs by offering quick loading times, security, and mobile friendliness. We also take care of regular website upgrades and maintenance so you can concentrate effectively on managing your company.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Custom Development </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Our skilled team of WordPress developers can provide custom solutions for your particular needs. Our staff works with you throughout the process to achieve the intended results.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>WooCommerce Development </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>The greatest option for online sales of goods and services is WooCommerce. We can help you start an online business or maintain an existing WooCommerce store. We provide WooCommerce development services to assist you with every process stage, including diagnosing issues, adding products and payment methods, and setting up your shop.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>WordPress Maintenance </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Among the maintenance services we offer is keeping your website safe, secure, and up to date. We ensure the newest security updates, plugins, and features are installed on your website.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Performance Optimization </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>One important component of website performance is speed. As a result, we provide performance optimization services that will enable your website to load quickly. After analyzing your website, we recommend enhancing its functionality and speed, like lowering file sizes and optimizing the code. We can also assist you with regular upgrades and optimization to maintain optimal performance.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>WordPress Security </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>To assist brands in protecting their websites from internet attacks, we provide a WordPress security solution. Our professionals implement security plugins, firewalls, two-factor authentication, and other procedures to protect your website.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="faqs my-12">
                                    <p className='text-[18px] font-normal text-[#636363] '><strong>Cheers To Your WordPress Website Development With Us!</strong></p>
                                    <h1 className='text-[32px] font-medium my-3 heading'> FAQs</h1>
                                    <div id="accordion-collapse" data-accordion="collapse">
                                        <h2 id="accordion-collapse-heading-1">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200  hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                <span className='text-[20px] text-[#222] text-left'>What Is WordPress?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-1" class="hidden" aria-labelledby="accordion-collapse-heading-1">
                                            <div class="p-5 border border-b-0 border-gray-200 darks:border-gray-700 darks:bg-gray-900">
                                                <p className='text-[18px] font-normal text-[#636363] '>WordPress is a free and open-source content management system (CMS) used to create websites and blogs. It provides users with a user-friendly interface for managing website content.</p>
                                            </div>
                                        </div>
                                        <h2 id="accordion-collapse-heading-2">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-2" aria-expanded="false" aria-controls="accordion-collapse-body-2">
                                                <span className='text-[20px] text-[#222] text-left'>How Much Does WordPress Cost?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-2" class="hidden" aria-labelledby="accordion-collapse-heading-2">
                                            <div class="p-5 border border-b-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>WordPress software is free to use. However, you may need to pay for web hosting, domain registration, premium themes, and plugins, depending on your needs.</p>
                                            </div>
                                        </div>
                                        <h2 id="accordion-collapse-heading-3">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-3" aria-expanded="false" aria-controls="accordion-collapse-body-3">
                                                <span className='text-[20px] text-[#222] text-left'>What Are WordPress Themes?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-3" class="hidden" aria-labelledby="accordion-collapse-heading-3">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>WordPress themes are templates that determine the appearance and layout of your website. They allow you to change the design of your site without altering its content.</p>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-4">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-4" aria-expanded="false" aria-controls="accordion-collapse-body-4">
                                                <span className='text-[20px] text-[#222] text-left'>What Are WordPress Plugins?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-4" class="hidden" aria-labelledby="accordion-collapse-heading-4">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>WordPress plugins are pieces of software that can be added to your site to extend its functionality. They allow you to add features such as contact forms, e-commerce, SEO optimization, and more.</p>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-5">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-5" aria-expanded="false" aria-controls="accordion-collapse-body-5">
                                                <span className='text-[20px] text-[#222] text-left'>Is WordPress SEO-Friendly?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-5" class="hidden" aria-labelledby="accordion-collapse-heading-5">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Yes, WordPress is inherently SEO-friendly. It provides features such as customizable permalinks, automatic sitemap generation, and plugins like Yoast SEO that help optimize your site for search engines.</p>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-6">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-6" aria-expanded="false" aria-controls="accordion-collapse-body-6">
                                                <span className='text-[20px] text-[#222] text-left'>Can I Monetize my Wordpress Website?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-6" class="hidden" aria-labelledby="accordion-collapse-heading-6">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Yes, you can monetize your WordPress website in various ways, such as displaying ads, selling products or services, affiliate marketing, sponsored content, and more.</p>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-7">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-7" aria-expanded="false" aria-controls="accordion-collapse-body-7">
                                                <span className='text-[20px] text-[#222] text-left'>Is WordPress Secure?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-7" class="hidden" aria-labelledby="accordion-collapse-heading-7">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>WordPress itself is secure, but like any software, it can be vulnerable if not properly maintained. To ensure security, keep WordPress and its plugins/themes updated, use strong passwords, install security plugins, and regularly back up your site.</p>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-8">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-8" aria-expanded="false" aria-controls="accordion-collapse-body-8">
                                                <span className='text-[20px] text-[#222] text-left'>Can You Provide Post-Maintenance Support?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-8" class="hidden" aria-labelledby="accordion-collapse-heading-8">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Yes, following the creation of the website, we offer full assistance and maintenance. We submit fixes for problems and suggest new features for the website to use. </p>
                                            </div>
                                        </div>
                                        <h2 id="accordion-collapse-heading-9">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-9" aria-expanded="false" aria-controls="accordion-collapse-body-9">
                                                <span className='text-[20px] text-[#222] text-left'>Do You Integrate Unique Plugins and APIs into the Website?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-9" class="hidden" aria-labelledby="accordion-collapse-heading-9">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Yes. Our staff integrates plugins and APIs in accordance with the needs of the company. We modify the essential plugins and functionality to make the website function better.</p>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className="w-[30%] hidden lg:block relative">
                            <div className='box-shadow rounded-lg ml-5 p-8 sidebar sticky top-[-150px]'>
                                <div className="sidebar_banner">
                                    <img src="img/page_img_4.jpg" alt="" />
                                </div>

                                <div className="sidebar_tabs mt-5">
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/wordpress">
                                            <h1 className='text-[18px] font-semibold capitalize'>Wordpress</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/wix">
                                            <h1 className='text-[18px] font-semibold capitalize'>wix</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/webflow">
                                            <h1 className='text-[18px] font-semibold capitalize'>webflow</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/figma">
                                            <h1 className='text-[18px] font-semibold capitalize'>figma</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/woocommerce">
                                            <h1 className='text-[18px] font-semibold capitalize'>Woo Commerce</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/shopify">
                                            <h1 className='text-[18px] font-semibold capitalize'>shopify</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/bubble">
                                            <h1 className='text-[18px] font-semibold capitalize'>Bubble.io</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/squarespace">
                                            <h1 className='text-[18px] font-semibold capitalize'>Squre Space</h1>
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Wordpress