import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Aurangabad() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{
                backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)" }}>
                <div className="container mx-auto py-12">
                    <h1
                        className='text-center text-white text-[32px] md:text-[52px]  font-semibold heading uppercase'>
                        SEO Company in Aurangabad
                    </h1>
                </div>
            </div>
        
            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[100%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>SEO Company in Aurangabad</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>An effective advertising campaign can hold the key to a company's success. Our work in digital marketing is transparent and honest, and it speaks for itself. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>Our goal is to increase internet leads, calls, and income for your company. Renowned for its accommodating and amiable style, Bonafide Technologies is one of the top SEO companies in Aurangabad. In this day and age of digitalization, every business must have a friendly online presence. However, many businesses are finding it difficult to establish this. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>And if you're one of them, we have the ideal SEO plan to help you improve your company website's position in search engine results (SERPs). After years of providing our services, we have managed to establish ourselves as a reliable company. Our clientele is composed primarily of happy and satisfied customers. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>From creating and overseeing AdWords accounts to organic SEO (search engine optimization), we have a customized SEO solution for every business. We are a team of seasoned professionals eager to meet the company's online requirements with the best possible results.</p>
                                <br />
                                <h1 className='text-[32px] font-semibold my-3 heading'>What is SEO and Why you need it</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Search Engine Optimization, or SEO, is getting your website to appear on search engine result pages. You must communicate with others and explain the benefits of your product if you want to succeed. Since organic traffic generates a higher return on investment than paid search traffic, investing in SEO is an intelligent strategy to strengthen the future of your business. Only the organic search results are viewed by the bulk of search traffic. Hence, you lose a significant portion of your online audience if your website does not appear on the first page with the most searched keywords.</p>
                            </div>
                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Why Choose Us</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>We have unrivaled experience working with clients from different backgrounds thanks to our team of highly qualified employees who are committed to turning business development ideas into reality by assigning scientific research & strategic planning. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>Bonafide Technologies is the top SEO service provider in Aurangabad. We offer our clients digital marketing, web development, and web design services in Aurangabad. </p>
                                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                                    <div className="flex flex-wrap justify-between">
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Experience
                                            </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>We enjoy talking about and planning new projects, and we bring years of expertise and experience to the table.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Passionate</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Nothing makes us happier than working on an amazing project with an amazing customer. We tend to work after hours to ensure that everything is "just right" since we genuinely care about our clients!</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Creative & Techincal</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Whether it's bespoke programming, system development, website or graphic design, or other services, we prefer to house everything under one roof to make things easier for our clients.</p>
                                        </div>
                                    </div>
                                </div>
                                <h1 className='text-[32px] font-semibold my-3 heading'>What Services We Provide</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>With the help of our services, your company can grow, reach the intended market, improve sales, and foster a sense of loyalty among clients. We take pleasure in our ability to satisfy customers and work hard to deliver the best possible SEO services in Aurangabad. </p>
                                <ul>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>Local Search Strategy</strong>
                                        It's standard procedure to combine local search tactics with other optimization methods. We will help you improve the local visibility of your website.</li>   
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>Maps SEO</strong>
                                        Businesses can use Maps SEO to optimize their website, local listing, and review sites to improve visibility and optimize search engine rankings.</li>   
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>Content & Link Building</strong>
                                        An effective digital marketing strategy must include both link-building and content marketing. We will take charge of your website content and building links.</li>   
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>Paid Search Advertising</strong>
                                        Increasing conversions and bringing in targeted visitors to your website can be accomplished with paid search advertising.</li>   
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>Custom Website Design</strong>
                                        The process of developing a website with an unparalleled appearance and design that is customized to the needs of the customer.</li>   
                                </ul> 
                                <h1 className='text-[32px] font-semibold my-3 heading'>Hire Us For Better Online Presence</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>We are a top SEO company in Aurangabad, and we can get your company to rank well on search engines. Over the years, we have developed our abilities to help our clients achieve the most satisfying results. In addition, you don't need to worry about breaking the bank while using our services because we provide specialized services at a very reasonable price. So what are you waiting for? Contact us to make your dreams come true.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        )
}

export default Aurangabad