import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { Link } from "react-router-dom";

function SocialEnginesMarketing() {
  return (
    <>
      <Header />
      <div className="container-fluid py-24 bg-cover bg-center" style={{ backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)" }}>
        <div className="container mx-auto py-12">
          <h1 className='text-center text-white text-[34px] md:text-[42px] lg:text-[56px] xl:text-[86px] font-bold heading uppercase'>social engines marketing</h1>
        </div>
      </div>

      <div className="container-fluid py-12">
        <div className="container mx-auto">
          <div className="flex flex-wrap md:flex-nowrap">
            <div className="w-full lg:w-[70%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
              <img src="img/SEM.jpg" className='w-full' alt="" />

              <div className='mt-8'>
                <h1 className='text-[32px] font-semibold my-3 heading'>Gain more customers Instantly.</h1>
                <p className='text-[18px] font-normal text-[#636363] '>Search engines are the most efficient way for businesses to connect with leads that are ready to buy. If your SEO plan is not delivering the desired results, you might need to give (SEM) search engine marketing a shoot. </p><br />
                <p className='text-[18px] font-normal text-[#636363] '>Searching for the right and relevant Search Engine Marketing agency that helps you reach out to your potential customers? Do you wish to promote a new product or service offer? Are you ready to grow your company efficiently? Bonafide Technologies, the Search Engine Marketing Company, looks for and provides chances to assist individuals in expanding their businesses. We offer a complete range of digital marketing services in India with 100% conversion rates. </p>
                <br />
                <p className='text-[18px] font-normal text-[#636363] '>SEM puts you in front of clients quickly. Our expert SEM team will help drive your business up to the first page of search results that will help you generate more leads.  </p><br />
                <p>Customers might look for your business in a variety of ways. Hundreds of keyword options are combined in an SEM campaign to make sure you are rising in all relevant searches.</p>
              </div>

              <div className='my-8'>
                <h1 className='text-[32px] font-semibold my-3 heading'> What We Provide</h1>
                <p className='text-[18px] font-normal text-[#636363] '>The flexibility to notice rapid changes and make modifications is a top quality of an SEM campaign. We track elements like call tracking, website traffic, and clicks on calls to action to report on campaign success and let you know, what is working.</p><br />

                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                  <div className="flex flex-wrap justify-between">
                    <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                      <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Paid Search</h1>
                      <p className='text-[18px] font-normal text-[#636363] '>Our dependable and powerful technology allows us to provide paid searches. We strive to offer a global audience with top-notch outcomes.</p>
                    </div>
                    <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                      <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Analytics Management  </h1>
                      <p className='text-[18px] font-normal text-[#636363] '>Make thoughtful strategic choices. We use knowledgeable, experienced analysts to assist you in making decisions. Hence, you should ensure that your choices are appropriate while keeping the wider picture in mind.</p>
                    </div>
                    <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                      <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Conversion Optimization </h1>
                      <p className='text-[18px] font-normal text-[#636363] '>Our goal is to improve engagements and complete ratings. We concentrate on an easy-to-apply and click-through strategy using an enticing approach. This ensures that your company is receiving the proper boost.</p>
                    </div>
                    <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                      <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Constant Improvement</h1>
                      <p className='text-[18px] font-normal text-[#636363] '>You do not spend money on pointless, basic techniques. You spend money on effective strategies that stabilize you to get the desired results. With a carefully considered plan, we strive to achieve outstanding results.</p>
                    </div>
                    <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                      <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Keywords Research </h1>
                      <p className='text-[18px] font-normal text-[#636363] '>Not only does our keyword research help us use the appropriate niche, but we also make extra efforts to make it work. We find out the right keywords and achieve the desired results. </p>
                    </div>
                    <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                      <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Tracking and Tools</h1>
                      <p className='text-[18px] font-normal text-[#636363] '>We continue to track the actions using our advanced new tools and technology. Investing in the appropriate technologies is crucial the ones that push you to the top of the internet marketing rankings.</p>
                    </div>
                  </div>
                </div>

                <div className="faqs my-12">
                  <h1 className='text-[32px] font-medium my-3 heading'> FAQs</h1>
                  <div id="accordion-collapse" data-accordion="collapse">
                    <h2 id="accordion-collapse-heading-1">
                      <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200  hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                        <span className='text-[20px] text-[#222] text-left'>What Do SEM Agencies Do?</span>
                        <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                        </svg>
                      </button>
                    </h2>
                    <div id="accordion-collapse-body-1" class="hidden" aria-labelledby="accordion-collapse-heading-1">
                      <div class="p-5 border border-b-0 border-gray-200 darks:border-gray-700 darks:bg-gray-900">
                        <p className='text-[18px] font-normal text-[#636363] '>With the help of search engines, particularly Google Search, a search engine marketing company provides targeted website visitors. The Google Ads campaign is usually managed by an SEM service provider, who also handles paid ad metrics reporting and initial keyword research. <br />
                          Search engine marketing positions your content right at the top of search engine results pages with the help of paid search advertising. Pay-per-click (PPC) advertising, also known as cost-per-click (CPC) marketing, is a form of search engine marketing in which the advertiser has to pay a fee each time a user clicks on the sponsored search result.
                        </p>
                      </div>
                    </div>
                    <h2 id="accordion-collapse-heading-2">
                      <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-2" aria-expanded="false" aria-controls="accordion-collapse-body-2">
                        <span className='text-[20px] text-[#222] text-left'>How Does SEM Work?</span>
                        <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                        </svg>
                      </button>
                    </h2>
                    <div id="accordion-collapse-body-2" class="hidden" aria-labelledby="accordion-collapse-heading-2">
                      <div class="p-5 border border-b-0 border-gray-200 darks:border-gray-700">
                        <p className='text-[18px] font-normal text-[#636363] '>Search Engine Marketing (SEM) is a digital marketing strategy aimed at increasing the visibility of websites in search engine results pages (SERPs) through paid advertising. <br /> Here's how SEM works:</p>
                        <ul>
                          <li>Keyword Research</li>
                          <li>Campaign Setup</li>
                          <li>Ad Creation</li>
                          <li>Bid Auction</li>
                          <li>Ad Rank</li>
                          <li>Ad Display</li>
                          <li>Clicks and Conversions</li>
                          <li>Campaign Optimization</li>
                          <li>Campaign Optimization</li>
                        </ul>
                      </div>
                    </div>
                    <h2 id="accordion-collapse-heading-3">
                      <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-3" aria-expanded="false" aria-controls="accordion-collapse-body-3">
                        <span className='text-[20px] text-[#222] text-left'>Why Use SEM?</span>
                        <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                        </svg>
                      </button>
                    </h2>
                    <div id="accordion-collapse-body-3" class="hidden" aria-labelledby="accordion-collapse-heading-3">
                      <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                        <p className='text-[18px] font-normal text-[#636363] '>The explosive growth of content marketing over the last 10 years indicates that paid advertising has become important in digital marketing. <br /> However, when used wisely as a more comprehensive marketing plan, SEM and other click advertising formats still have significant strategic value. SEM should be used in your digital marketing campaigns for several reasons, including:</p>
                        <ul>
                          <li>Better Click-through rates</li>
                          <li>Potential for Quick Results</li>
                          <li>Brand Awareness</li>
                          <li>Shortcuts to Product Pages</li>
                          <li>Promotion for Local Services</li>
                        </ul>
                      </div>
                    </div>

                    <h2 id="accordion-collapse-heading-4">
                      <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-4" aria-expanded="false" aria-controls="accordion-collapse-body-4">
                        <span className='text-[20px] text-[#222] text-left'>How Does SEM Differs from SEO?</span>
                        <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                        </svg>
                      </button>
                    </h2>
                    <div id="accordion-collapse-body-4" class="hidden" aria-labelledby="accordion-collapse-heading-4">
                      <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                        <p className='text-[18px] font-normal text-[#636363] '>Organic, free content is the foundation of search engine optimization, which raises site visibility. This could involve using technical SEO for on-page optimization and optimizing website copy to rank for particular keywords. Additionally, it could rely on off-page optimization using email marketing, social media marketing, backlink building, and other off-site techniques. <br />
                          SEM can wind up costing more if SEO is weak. This is a result of the significant influence on CPC that has a Quality Score. <br />
                          To reduce the expense of PPC techniques, it is crucial to optimize your website or landing page for specific keywords.
                        </p>
                      </div>
                    </div>

                    <h2 id="accordion-collapse-heading-5">
                      <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-5" aria-expanded="false" aria-controls="accordion-collapse-body-5">
                        <span className='text-[20px] text-[#222] text-left'>Which Social Media Platforms Support SEM?</span>
                        <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                        </svg>
                      </button>
                    </h2>
                    <div id="accordion-collapse-body-5" class="hidden" aria-labelledby="accordion-collapse-heading-5">
                      <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                        <p className='text-[18px] font-normal text-[#636363] '>Several social media platforms support SEM, including Facebook, Instagram, Twitter, LinkedIn, Pinterest, Snapchat, and TikTok. Each platform offers its advertising solutions and targeting options to help businesses reach their desired audience effectively.</p>
                      </div>
                    </div>

                    <h2 id="accordion-collapse-heading-6">
                      <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-6" aria-expanded="false" aria-controls="accordion-collapse-body-6">
                        <span className='text-[20px] text-[#222] text-left'>What Types Of Ads Can Be Created Through SEM?</span>
                        <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                        </svg>
                      </button>
                    </h2>
                    <div id="accordion-collapse-body-6" class="hidden" aria-labelledby="accordion-collapse-heading-6">
                      <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                        <p className='text-[18px] font-normal text-[#636363] '>SEM allows businesses to create various types of ads, including image ads, video ads, carousel ads, slideshow ads, lead generation ads, and sponsored content. The choice of ad format depends on the campaign objectives, target audience, and creative preferences.</p>
                      </div>
                    </div>

                    <h2 id="accordion-collapse-heading-7">
                      <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-7" aria-expanded="false" aria-controls="accordion-collapse-body-7">
                        <span className='text-[20px] text-[#222] text-left'>How Is The Success Of SEM Campaigns Measured?</span>
                        <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                        </svg>
                      </button>
                    </h2>
                    <div id="accordion-collapse-body-7" class="hidden" aria-labelledby="accordion-collapse-heading-7">
                      <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                        <p className='text-[18px] font-normal text-[#636363] '>The success of SEM campaigns is measured using key performance indicators (KPIs) such as impressions, clicks, click-through rate (CTR), conversions, conversion rate, return on ad spend (ROAS), cost per acquisition (CPA), and engagement metrics. These metrics help advertisers evaluate the effectiveness of their campaigns and optimize performance.</p>
                      </div>
                    </div>

                  </div>

                </div>
              </div>

            </div>
            <div className="w-[30%] hidden lg:block relative">
              <div className='box-shadow rounded-lg ml-5 p-8 sidebar sticky top-[-150px]'>
                <div className="sidebar_banner">
                  <img src="img/page_img_4.jpg" alt="" />
                </div>

                <div className="sidebar_tabs mt-5">
                  <div
                    className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                    <Link to="/social-media-marketing">
                      <h1 className='text-[18px] font-semibold capitalize'>Social Media Marketing - SMM</h1>
                    </Link>
                  </div>
                  <div
                    className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                    <Link to="/social-engines-marketing">
                      <h1 className='text-[18px] font-semibold capitalize'>Social Engines Marketing - SEM</h1>
                    </Link>
                  </div>
                  <div
                    className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                    <Link to="/guest-posting">
                      <h1 className='text-[18px] font-semibold capitalize'>Guest Posting</h1>
                    </Link>
                  </div>
                  <div
                    className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                    <Link to="/web-analytics">
                      <h1 className='text-[18px] font-semibold capitalize'>Web Analytics</h1>
                    </Link>
                  </div>
                  <div
                    className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                    <Link to="/local-seo">
                      <h1 className='text-[18px] font-semibold capitalize'>Local SEO</h1>
                    </Link>
                  </div>
                  <div
                    className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                    <Link to="/e-commerce-marketing">
                      <h1 className='text-[18px] font-semibold capitalize'>eCommerce Marketing</h1>
                    </Link>
                  </div>
                  <div
                    className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                    <Link to="/mobile-app-marketing">
                      <h1 className='text-[18px] font-semibold capitalize'>mobile-app-marketing</h1>
                    </Link>
                  </div>
                  <div
                    className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                    <Link to="/influencer-marketing">
                      <h1 className='text-[18px] font-semibold capitalize'>Influencer Marketing</h1>
                    </Link>
                  </div>
                  <div
                    className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                    <Link to="/affiliate-marketing">
                      <h1 className='text-[18px] font-semibold capitalize'>Affiliate Marketing</h1>
                    </Link>
                  </div>
                  <div
                    className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                    <Link to="/web-traffic">
                      <h1 className='text-[18px] font-semibold capitalize'>Web Traffic</h1>
                    </Link>
                  </div>
                  <div
                    className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                    <Link to="/ppc">
                      <h1 className='text-[18px] font-semibold capitalize'>PPC</h1>
                    </Link>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default SocialEnginesMarketing