import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from "react-router-dom";

function SeoServices() {
  const services = [
    {
      title: 'Keyword Research ',
      image: 'img/page_img_10.webp',
      link: '/',
    },
    {
      title: 'ON Page SEO',
      image: 'img/page_img_9.jpg',
      link: '/',
    },
    {
      title: 'Technical SEO',
      image: 'img/page_img_8.jpg',
      link: '/',
    },
    {
      title: 'OFF Page SEO',
      image: 'img/page_img_1.jpg',
      link: '/',
    },
    {
      title: 'Competitor Analysis',
      image: 'img/page_img_2.jpg',
      link: '/',
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 4, // Desktop
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1324, // Tablet
        settings: {
          slidesToShow: 3,
          centerMode: false,
        },
      },
      {
        breakpoint: 1024, // Tablet
        settings: {
          slidesToShow: 2,
          centerMode: false,
        },
      },
      {
        breakpoint: 600, // Mobile
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {services.map((service, index) => (
        <div key={index} className='p-4'>
          <Link to={service.link} className="">
            <div className="cursor-pointer hover:shadow-lg rounded-xl overflow-hidden   page_box ">
              <div className="main_box overflow-hidden relative rounded-xl">
                <img src={service.image} className='object-cover w-full h-[320px] lg:h-[400px] transition-all duration-500' alt="" />
                <div className="page_text px-3 py-6 relative">
                  <h1 className='text-[18px] md:text-[22px] font-medium text-white capitalize'>{service.title}</h1>
                </div>
              </div>
            </div>
          </Link>
        </div>
      ))}
    </Slider>
  )
}

export default SeoServices