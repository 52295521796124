import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


function CreateNewGig() {
    const navigate = useNavigate();

    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSubcategory, setSelectedSubcategory] = useState('');
    const [title, setTitle] = useState('');
    const [basicTitle, setBasicTitle] = useState('');
    const [standardTitle, setStandardTitle] = useState('');
    const [premiumTitle, setPremiumTitle] = useState('');
    const [basicDescription, setBasicDescription] = useState('');
    const [standardDescription, setStandardDescription] = useState('');
    const [premiumDescription, setPremiumDescription] = useState('');
    const [basicRevision, setBasicRevision] = useState('');
    const [standardRevision, setStandardRevision] = useState('');
    const [premiumRevision, setPremiumRevision] = useState('');
    const [basicDelivery, setBasicDelivery] = useState('');
    const [standardDelivery, setStandardDelivery] = useState('');
    const [premiumDelivery, setPremiumDelivery] = useState('');
    const [basicPrice, setBasicPrice] = useState('');
    const [standardPrice, setStandardPrice] = useState('');
    const [premiumPrice, setPremiumPrice] = useState('');
    const [description, setDescription] = useState('');
    const [selectedImageUrl, setSelectedImageUrl] = useState([]);
    const [selectedImageNames, setSelectedImageNames] = useState([]);


    const handleCategoryChange = (e) => {
      setSelectedCategory(e.target.value);
      setSelectedSubcategory('');
    };
    const handleBasicTitleChange = (e) => {
      setBasicTitle(e.target.value);
    };
    
    const handleStandardTitleChange = (e) => {
      setStandardTitle(e.target.value);
    };
    
    const handlePremiumTitleChange = (e) => {
      setPremiumTitle(e.target.value);
    };
    
    const handleBasicDescription = (e) => {
      setBasicDescription(e.target.value);
    };

    const handleStandardDescription = (e) => {
      setStandardDescription(e.target.value);
    };
    
    const handlePremiumDescription = (e) => {
      setPremiumDescription(e.target.value);
    };
    
    const handleBasicRevision = (e) => {
      setBasicRevision(e.target.value);
    };
    
    const handleStandardRevision = (e) => {
      setStandardRevision(e.target.value);
    };

    const handlePremiumRevision = (e) => {
      setPremiumRevision(e.target.value);
    };  
    
    const handleBasicDelivery = (e) => {
      setBasicDelivery(e.target.value);
    };
    
    const handleStandardDelivery = (e) => {
      setStandardDelivery(e.target.value);
    };
    
    const handlePremiumDelivery = (e) => {
      setPremiumDelivery(e.target.value);
    };
    
    const handleBasicPrice = (e) => {
      setBasicPrice(e.target.value);
    };
    
    const handleStandardPrice = (e) => {
      setStandardPrice(e.target.value);
    };
    
    const handlePremiumPrice = (e) => {
      setPremiumPrice(e.target.value);
    };
    const handleDescriptionChange = (e) => {
      setDescription(e.target.value);
    };
  
    const handleImageChange = async (e) => {
      const files = e.target.files;
      setSelectedImageUrl(e.target.files);

      const names = Array.from(files).map((file) => file.name);
      setSelectedImageNames(names);
     };

    useEffect(() => {
        console.log('Selected Image Names:', selectedImageNames);
        console.log('Base64 Images:', selectedImageUrl);
    }, [selectedImageNames, selectedImageUrl]);

    const subcategories = {
      digital_marketing: ['Sub-Category 1', 'Sub-Category 2'],
      seo: ['Sub-Category 3', 'Sub-Category 4'],
      web_design: ['Sub-Category 5', 'Sub-Category 6'],
      web_development: ['Sub-Category 7', 'Sub-Category 8'],
      content_writing: [], 
    };

    const [step, setStep] = useState(1);

    const nextStep = () => {
      setStep(step + 1);
    };

    const prevStep = () => {
      setStep(step - 1);
    };

    const handleTitle = (e) => {
        setTitle( e.target.value)
      }

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const category = selectedCategory;
        const subcategory = selectedSubcategory;
        if (!title || !selectedCategory || !basicTitle || !standardTitle || !premiumTitle || !basicDescription || !standardDescription || !premiumDescription || !basicRevision || !standardRevision || !premiumRevision || !basicDelivery || !standardDelivery || !premiumDelivery || !basicPrice || !standardPrice || !premiumPrice || !description ) {
        console.error('Please fill in all required fields');
        return;
    }
    
        console.log("Title:", title);
        console.log("Category:", category);
        console.log("Subcategory:", subcategory);
        console.log("Description:", description);
        console.log("selectedImageUrl:", selectedImageUrl);
        console.log("Basic Title:", basicTitle);
        console.log("Basic Description:", basicDescription);
        console.log("Basic Revision:", basicRevision);
        console.log("Basic Delivery:", basicDelivery);
        console.log("Basic Price:", basicPrice);
        
        console.log("Standard Title:", standardTitle);
        console.log("Standard Description:", standardDescription);
        console.log("Standard Revision:", standardRevision);
        console.log("Standard Delivery:", standardDelivery);
        console.log("Standard Price:", standardPrice);
        
        console.log("Premium Title:", premiumTitle);
        console.log("Premium Description:", premiumDescription);
        console.log("Premium Revision:", premiumRevision);
        console.log("Premium Delivery:", premiumDelivery);
        console.log("Premium Price:", premiumPrice);

        const gigData = new FormData();
        for (let i = 0; i < selectedImageUrl.length; i++) {
          gigData.append("image", selectedImageUrl[i]);
        }

        gigData.append('title', title);
        gigData.append('category', category);
        gigData.append('subcategory', subcategory);
        gigData.append('basicTitle', basicTitle);
        gigData.append('standardTitle', standardTitle);
        gigData.append('premiumTitle', premiumTitle);
        gigData.append('basicDescription', basicDescription);
        gigData.append('standardDescription', standardDescription);
        gigData.append('premiumDescription', premiumDescription);
        gigData.append('basicRevision', basicRevision);
        gigData.append('standardRevision', standardRevision);
        gigData.append('premiumRevision', premiumRevision);
        gigData.append('basicDelivery', basicDelivery);
        gigData.append('standardDelivery', standardDelivery);
        gigData.append('premiumDelivery', premiumDelivery);
        gigData.append('basicPrice', basicPrice);
        gigData.append('standardPrice', standardPrice);
        gigData.append('premiumPrice', premiumPrice);
        gigData.append('description', description);

    
        try {
          console.log("gigData",gigData);
            const response = await fetch('http://localhost:5007/api/createNewGig', {
                method: 'POST',
                body: gigData,
            });
            if (response.ok) {
                console.log('Gig data submitted successfully');
                 navigate(0);

            } else {
                console.error('Failed to submit gig data');
            }
            } catch (error) {
                console.error('Error during gig data submission:', error);
            }
        };
    

  return (
    <div className="container mx-auto py-4">
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        {step === 1 && (
          <div>
            <div className='space-y-4 md:space-y-6'>
            <div>
                <label htmlFor="name" className="block mb-2 text-md font-medium text-gray-900 darks:text-white"> Title <span className='text-red-600'> *</span></label>
                <textarea id="title" name='title' rows="3" class="bg-gray-50 border border-[#222] text-gray-900 sm:text-md rounded-lg focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500" placeholder="Write your title here..." onChange={handleTitle}></textarea>
            </div>

            <div>
                <label htmlFor="category" className="block mb-2 text-md font-medium text-gray-900 darks:text-white">
                    Category
                    <span className='text-red-600'> *</span>
                </label>
                <select
                    id="category"
                    className="bg-gray-50 border border-[#222] text-gray-900 sm:text-md rounded-lg focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500"
                    onChange={handleCategoryChange}
                    value={selectedCategory}
                >
                    <option value="" disabled>Select a Category</option>
                    <option value="digital_marketing">Digital Internet Marketing</option>
                    <option value="seo">SEO</option>
                    <option value="web_design">Web Design</option>
                    <option value="web_development">Web Development</option>
                    <option value="content_writing">Content Writing</option>
                </select>

                <div className="mt-4">
                    <label htmlFor="subcategory" className="block mb-2 text-md font-medium text-gray-900 darks:text-white">
                    Sub-Category
                    </label>
                    <select
                    id="subcategory"
                    className={`bg-gray-50 border border-[#222] text-gray-900 sm:text-md rounded-lg focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500 ${selectedCategory === 'content_writing' ? 'cursor-not-allowed' : ''}`}
                    value={selectedSubcategory}
                    onChange={(e) => setSelectedSubcategory(e.target.value)}
                    disabled={selectedCategory === 'content_writing'}
                    >
                    <option value="" disabled>Select a Sub-Category</option>
                    {subcategories[selectedCategory] &&
                        subcategories[selectedCategory].map((subcategory, index) => (
                        <option key={index} value={subcategory}>
                            {subcategory}
                        </option>
                        ))}
                    </select>
                </div>
            </div>
            </div>
          </div>
        )}

        {step === 2 && (
          <div>
               <div className="container mx-auto py-4"> 
                    <div class="relative overflow-x-auto shadow-md ">
                        <table class="w-full  text-left rtl:text-right text-gray-500 darks:text-gray-400">
                            <thead class="text-xs text-gray-700 uppercase bg-gray-50 darks:bg-gray-700 darks:text-gray-400">
                                <tr>
                                    <th scope="col" class="pl-2 pr-1 py-3">
                                    Packages
                                    </th>
                                    <th scope="col" class="px-1 py-2">
                                    BASIC
                                    </th>
                                    <th scope="col" class="px-1 py-2">
                                    STANDARD
                                    </th>
                                    <th scope="col" class="pl-1 pr-2 py-2">
                                    PREMIUM
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="bg-white darks:bg-gray-800 hover:bg-gray-50 darks:hover:bg-gray-600 px-2">
                                    <th scope="row" class="pl-2 pr-1 py-2 font-medium text-gray-900 whitespace-nowrap darks:text-white">
                                        Title
                                    </th>
                                    <td class="px-1 py-2">
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            className="bg-gray-50 border border-[#222] text-gray-900 sm:text-sm  focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500"
                                            placeholder="Title"
                                            required=""
                                            onChange={handleBasicTitleChange}
                                        />
                                    </td>
                                    <td class="px-1 py-2">
                                    <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            className="bg-gray-50 border border-[#222] text-gray-900 sm:text-sm  focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500"
                                            placeholder="Title"
                                            required=""
                                            onChange={handleStandardTitleChange}
                                        />
                                    </td>
                                    <td class="pl-1 pr-2 py-2">
                                    <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            className="bg-gray-50 border border-[#222] text-gray-900 sm:text-sm  focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500"
                                            placeholder="Title"
                                            required=""
                                            onChange={handlePremiumTitleChange}
                                        />
                                    </td>
                                </tr>
                                <tr class="bg-white darks:bg-gray-800 hover:bg-gray-50 darks:hover:bg-gray-600 px-2">
                                    <th scope="row" class="pl-2 pr-1 py-2 font-medium text-gray-900 whitespace-nowrap darks:text-white">
                                        Description
                                    </th>
                                    <td class="px-1 py-2">
                                         <textarea id="title" name='title' rows="2" class="bg-gray-50 border border-[#222] text-gray-900 sm:text-md focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500" placeholder="Description" onChange={handleBasicDescription}></textarea>
                                    </td>
                                    <td class="px-1 py-2">
                                        <textarea id="title" name='title' rows="2" class="bg-gray-50 border border-[#222] text-gray-900 sm:text-md focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500" placeholder="Description" onChange={handleStandardDescription}></textarea>
                                    </td>
                                    <td class="pl-1 pr-2 py-2">
                                        <textarea id="title" name='title' rows="2" class="bg-gray-50 border border-[#222] text-gray-900 sm:text-md focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500" placeholder="Description" onChange={handlePremiumDescription}></textarea>
                                    </td>
                                </tr>
                                <tr class="bg-white darks:bg-gray-800 hover:bg-gray-50 darks:hover:bg-gray-600 px-2">
                                    <th scope="row" class="pl-2 pr-1 py-2 font-medium text-gray-900 whitespace-nowrap darks:text-white">
                                        Revision
                                    </th>
                                    <td class="px-1 py-2">
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            className="bg-gray-50 border border-[#222] text-gray-900 sm:text-sm  focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500"
                                            placeholder="Revision"
                                            required=""
                                            onChange={handleBasicRevision}
                                        />
                                    </td>
                                    <td class="px-1 py-2">
                                    <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            className="bg-gray-50 border border-[#222] text-gray-900 sm:text-sm  focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500"
                                            placeholder="Revision"
                                            required=""
                                            onChange={handleStandardRevision}
                                        />
                                    </td>
                                    <td class="pl-1 pr-2 py-2">
                                    <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            className="bg-gray-50 border border-[#222] text-gray-900 sm:text-sm  focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500"
                                            placeholder="Revision"
                                            required=""
                                            onChange={handlePremiumRevision}
                                        />
                                    </td>
                                </tr>
                                <tr class="bg-white darks:bg-gray-800 hover:bg-gray-50 darks:hover:bg-gray-600 px-2">
                                    <th scope="row" class="pl-2 pr-1 py-2 font-medium text-gray-900 whitespace-nowrap darks:text-white">
                                        Delivery
                                    </th>
                                    <td class="px-1 py-2">
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            className="bg-gray-50 border border-[#222] text-gray-900 sm:text-sm  focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500"
                                            placeholder="Delivery"
                                            required=""
                                            onChange={handleBasicDelivery}
                                        />
                                    </td>
                                    <td class="px-1 py-2">
                                    <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            className="bg-gray-50 border border-[#222] text-gray-900 sm:text-sm  focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500"
                                            placeholder="Delivery"
                                            required=""
                                            onChange={handleStandardDelivery}
                                        />
                                    </td>
                                    <td class="pl-1 pr-2 py-2">
                                    <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            className="bg-gray-50 border border-[#222] text-gray-900 sm:text-sm  focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500"
                                            placeholder="Delivery"
                                            required=""
                                            onChange={handlePremiumDelivery}
                                        />
                                    </td>
                                </tr>
                                <tr class="bg-white darks:bg-gray-800 hover:bg-gray-50 darks:hover:bg-gray-600 px-2">
                                    <th scope="row" class="pl-2 pr-1 py-2 font-medium text-gray-900 whitespace-nowrap darks:text-white">
                                        Price
                                    </th>
                                    <td class="px-1 py-2">
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            className="bg-gray-50 border border-[#222] text-gray-900 sm:text-sm  focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500"
                                            placeholder="Price"
                                            required=""
                                            onChange={handleBasicPrice}
                                        />
                                    </td>
                                    <td class="px-1 py-2">
                                    <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            className="bg-gray-50 border border-[#222] text-gray-900 sm:text-sm  focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500"
                                            placeholder="Price"
                                            required=""
                                            onChange={handleStandardPrice}
                                        />
                                    </td>
                                    <td class="pl-1 pr-2 py-2">
                                    <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            className="bg-gray-50 border border-[#222] text-gray-900 sm:text-sm  focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500"
                                            placeholder="Price"
                                            required=""
                                            onChange={handlePremiumPrice}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
              </div>
          </div>
        )}

        {step === 3 && (
          <div className='space-y-4 md:space-y-6'>
            <div>
                <label htmlFor="name" className="block mb-2 text-md font-medium text-gray-900 darks:text-white">Descripition <span className='text-red-600'> *</span></label>
                <textarea id="message" rows="2" class="bg-gray-50 border border-[#222] text-gray-900 sm:text-md rounded-lg focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500" placeholder="Descripition" onChange={handleDescriptionChange}></textarea>
            </div>

            <div>
                <label htmlFor="name" className="block mb-2 text-md font-medium text-gray-900 darks:text-white">
                  Image 
                </label>
                <div className="flex items-center justify-center w-full">
                  <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-36 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 darks:hover:bg-bray-800 darks:bg-gray-700 hover:bg-gray-100 darks:border-gray-600 darks:hover:border-gray-500 darks:hover:bg-gray-600">
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                      <svg className="w-8 h-8 mb-4 text-gray-500 darks:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
                      </svg>
                      <p className="mb-2 text-sm text-gray-500 darks:text-gray-400">
                        <span className="font-semibold">Click to upload</span> or drag and drop
                      </p>
                      <div className="mt-2 nameOfImage">
                      {selectedImageNames.map((name, index) => (
                        <p key={index} className="text-sm text-gray-500 font-semibold">You Select : {name}</p>
                      ))}
                      </div>
                    </div>
                  </label>
                  <input id="dropzone-file" type="file" onChange={handleImageChange} className="hidden" />
                </div>
                
              </div>
          </div>
        )}
        
        <div className="mt-4 flex justify-between">
          {step > 1 && (
            <button type="button" onClick={prevStep} className="bg-[#222] text-white focus:outline-none font-medium rounded-lg text-md px-5 py-2.5 text-center ">
              Back
            </button>
          )}

          {step < 3 ? (
            <button type="button" onClick={nextStep} className="bg-[#222] text-white focus:outline-none font-medium rounded-lg text-md px-5 py-2.5 text-center ">
              Next
            </button>
          ) : (
            <button type="submit" className="bg-green-500 text-white focus:outline-none font-medium rounded-lg text-md px-5 py-2.5 text-center ">
              Submit
            </button>
          )}
        </div>
      </form>
    </div>
  );
};
export default CreateNewGig