import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Borivali() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{
                backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)" }}>
                <div className="container mx-auto py-12">
                    <h1 className='text-center text-white text-[32px] md:text-[52px]  font-semibold heading uppercase'>
                        SEO Company in Borivali
                    </h1>
                </div>
            </div>
        
            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[100%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>SEO Company in Borivali</h1>
                                <p className='text-[18px] font-normal text-[#636363] '><strong>Present Yourself To Your Intended Audience!</strong></p>
                                <p className='text-[18px] font-normal text-[#636363] '>Bonafide Technologies Will Assist You In Building The Greatest Online Image For Your Company, Expanding It, and Creating An Online Presence.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>Looking for the top Borivali SEO company? Hire Bonafide Technologies to bring countless leads to your business. Our Borivali SEO Company can help your business expand in addition to improving your website's ranking. For their online presence, a significant number of Borivali business owners take advantage of our SEO Services.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>Where unmatched success meets high-performance digital strategy, our results-oriented SEO company in Borivali is committed to helping Indian small and medium-sized businesses (SMBs) expand their online presence.</p>
                                <br />
        
        
        
                            </div>
                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>What We Offer in Our SEO Services in Borivali</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Users can be targeted according to their interests, attributes, and purchasing patterns in both broad and narrow categories. We provide unparalleled PPC and SEO services that significantly raise a website's paid and organic search score and help it rank at the top, even for extremely competitive keywords. </p>
                                <br />
                                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                                    <div className="flex flex-wrap justify-between">
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Keyword Targeting
                                            </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>The search terms that users enter into search engines are known as keywords. The degree to which your page is relevant to certain keywords determines your rankings.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Optimizing Map Search</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Google Maps Optimization is essential to any local marketing plan.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Content & Link Building</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Link Building is and will remain a crucial part of SEO in the years to come.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Advertisement Through Paid Search</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Using Google AdWords paid listings, you can reach a wider audience.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Custom Website Design</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Affordably priced, user-friendly e-commerce and visually beautiful site design are available.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Off-Page SEO Optimization</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>To get high-quality backlinks for your website, we engage in link building. These connections improve search engine visibility for your website. All off-page tasks, such as article submissions and backlinking, will be done by us.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Reporting</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>To keep you updated on any potential problems, you will receive regular reports on the status of your SEO strategy.</p>
                                        </div>
                                    </div>
                                </div>
                                <h1 className='text-[32px] font-semibold my-3 heading'>What Are All The Benefits To Hire Us</h1>
                                <p className='text-[18px] font-normal text-[#636363] '><strong>Increase Engagement, Brand Awareness, and Sales by Optimizing Performance!</strong></p>
        
                                <ul>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                                className='text-[#63E6BE] mr-2'><i
                                                    class="fa-regular fa-square-check">Geographical Targeting</i></span></strong>
                                                    Reach interested customers who live close to your business or any other target place by walking and promoting your brand or products globally. Our initial goal is to target a local audience and increase doorsteps to your store. Then, we further spread your business globally for more leads.</li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                                className='text-[#63E6BE] mr-2'><i
                                                    class="fa-regular fa-square-check">Intelligent Optimization</i></span></strong>
                                                    Maximize efficiency and cut costs on CTRs, CPCs, CPVs, CPLs, CACs, and CPAs by using an updated algorithm and AB testing.</li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                                className='text-[#63E6BE] mr-2'><i
                                                    class="fa-regular fa-square-check">Target Audience</i></span></strong>
                                                    In order to achieve the desired results and avoid wasting a lot of money on pointless advertising, we do market research on your target demographic prior to launching any marketing campaigns.
                                    </li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                                className='text-[#63E6BE] mr-2'><i
                                                    class="fa-regular fa-square-check">Provide Daily, Monthly, Weekly Reports</i></span></strong>
                                                    We also provide daily, weekly, or monthly reports to our clients as per their preferences. Get detailed reports about your campaign's performance and organic reach across all platforms to find out how your brand is doing. </li>
                                </ul>
                                <p className='text-[18px] font-normal text-[#636363] '>At Bonafide Technologies, we are experts in navigating the always-changing digital landscape. We combine creativity, innovation, and data-driven insights to develop effective marketing strategies that result in measurable results. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>So stop waiting now. Increase revenue and grow your company by twofold with Bonafide Technologies. Please do not hesitate to contact us with queries.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        )
}

export default Borivali