import React, { useState } from 'react';
import axios from 'axios';
import AdminLogin from './AdminLogin';

function AdminRegister() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: ''
    });

    const [showLogin, setShowLogin] = useState(false);
    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        // Clear errors when user types
        setErrors({ ...errors, [e.target.name]: undefined });
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        // Validate form fields
        const newErrors = {};
        if (!formData.name) {
            newErrors.name = 'Name is required';
        }
        if (!formData.email) {
            newErrors.email = 'Email is required';
        }
        if (!formData.password) {
            newErrors.password = 'Password is required';
        }

        if (Object.keys(newErrors).length > 0) {
            // If there are errors, set them and return
            setErrors(newErrors);
            return;
        }

        try {
            const response = await axios.post('http://localhost:5007/register', formData);
            console.log(response.data);
            window.location.href = '/admin';
        } catch (error) {
            console.error(error.response.data);
        }
    };

    const toggleLogin = () => {
        setShowLogin(!showLogin);
    };

    if (showLogin) {
        return <AdminLogin />;
    }

    return (
        <section className="">
            <div className="flex flex-col items-center justify-center p-6 py-8 mx-auto h-[80vh]">
                <div className="w-full bg-white rounded-lg shadow darks:border md:mt-0 sm:max-w-md xl:p-0 darks:bg-gray-800 darks:border-gray-700">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <form className="space-y-4 md:space-y-6" action="#" onSubmit={handleFormSubmit}>
                            <div>
                                <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 darks:text-white">Your Name</label>
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500"
                                    placeholder="Your Name"
                                    required=""
                                    onChange={handleInputChange}
                                />
                                <div className="text-red-500 text-sm">{errors.name}</div>
                            </div>

                            <div>
                                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 darks:text-white">Your Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500"
                                    placeholder="name@company.com"
                                    required=""
                                    onChange={handleInputChange}
                                />
                                <div className="text-red-500 text-sm">{errors.email}</div>
                            </div>
                            <div>
                                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 darks:text-white">Password</label>
                                <input
                                    type="password"
                                    name="password"
                                    id="password"
                                    placeholder="••••••••"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500"
                                    required=""
                                    onChange={handleInputChange}
                                />
                                <div className="text-red-500 text-sm">{errors.password}</div>
                            </div>
                            <div className="flex items-center justify-between">
                                <div className="flex items-start">
                                    <div className="flex items-center h-5">
                                        <input
                                            id="remember"
                                            aria-describedby="remember"
                                            type="checkbox"
                                            className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 darks:bg-gray-700 darks:border-gray-600 darks:focus:ring-primary-600 darks:ring-offset-gray-800"
                                            required=""
                                        />
                                    </div>
                                    <div className="ml-3 text-sm">
                                        <label htmlFor="remember" className="text-gray-500 darks:text-gray-300">Remember me</label>
                                    </div>
                                </div>
                            </div>
                            <button
                                type="submit"
                                className="w-full bg-gray-900 text-white focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center darks:bg-primary-600 darks:hover:bg-primary-700 darks:focus:ring-primary-800"
                            >
                                Register
                            </button>
                        </form>

                        <p>Already Registered? <span className='cursor-pointer font-medium text-primary-600 hover:underline darks:text-primary-500 cursor-pointer' onClick={toggleLogin}>Login</span> </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AdminRegister;
