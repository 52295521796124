import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Bandra() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{
                backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)" }}>
                <div className="container mx-auto py-12">
                    <h1 className='text-center text-white text-[32px] md:text-[52px]  font-semibold heading uppercase'>
                        Seo Company In Bandra Mumbai
                    </h1>
                </div>
            </div>
        
            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[100%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Seo Company In Bandra Mumbai</h1>
                                <p className='text-[18px] font-normal text-[#636363] '><strong>Promote Your Brand for Success
                                        Online!</strong></p>
                                <p className='text-[18px] font-normal text-[#636363] '>With our extensive, goal-specific digital
                                    marketing services, you can maximize the potential of your company. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>Bonafide Technologies is a reputable SEO
                                    company in Mumbai that crafts strategies to improve your search engine ranking and draw in
                                    plenty of traffic. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>Our passionate staff is committed to
                                    providing the best SEO solutions to boost your brand's overall growth.</p>
                                <br />
        
                                <h1 className='text-[32px] font-semibold my-3 heading'>Why Choose Us</h1>
                                <p className='text-[18px] font-normal text-[#636363] '><strong>Increase Your Organic Traffic
                                        with the Best SEO Company in Mumbai.</strong></p>
                                <p className='text-[18px] font-normal text-[#636363] '>You've likely done a Google or other
                                    search engine search, clicked on the link at the top of the results page, and then pondered,
                                    how did this one reach the top spot? The solution is as easy as it is effective SEO
                                    execution. You should get results that align with your interests when using various search
                                    engines to find information or services. Every business wants to be visible on the internet
                                    so that potential clients will find them.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>SEO is the process of applying various
                                    techniques to improve the readability of your website and increase the amount of hits it
                                    receives from search engines.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>Indeed, there has never been more
                                    competition than there is now, and firms that provide SEO services have a significant impact
                                    on how visible their brands are online, particularly when it comes to achieving a high
                                    search engine ranking. Getting the greatest deal on the service is just as crucial as
                                    ranking your website.</p>
                                <br />
        
                                <h1 className='text-[32px] font-semibold my-3 heading'>What Makes us the Top SEO Service Company
                                    in Bandra, Mumbai </h1>
                                <p className='text-[18px] font-normal text-[#636363] '>At Bonafide Technologies, we put a lot of
                                    effort into boosting the search engine visibility of your website and drawing in traffic
                                    that results in conversions. This calls for more approaches than usual, and our dedicated
                                    team of experts is full of innovative suggestions. By producing top-notch content and
                                    utilizing social signals on Facebook, Twitter, and Google+, we can enhance client results.
                                </p>
                                <p className='text-[18px] font-normal text-[#636363] '>As one of the best SEO companies in
                                    Mumbai, Bonafide Technologies will ensure that your campaigns are well-planned and that the
                                    steady results will speak for themselves.</p>
                                <br />
        
        
        
                            </div>
                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>What We Offer</h1>
                                <br />
                                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                                    <div className="flex flex-wrap justify-between">
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Link Building
                                            </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>One of the most crucial parts
                                                of SEO is link-building. Thus, we've created effective techniques that only
                                                serve the commercial objectives of our clients by drawing relevant prospects to
                                                your website.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Mobile SEO</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Over 60% of internet users
                                                access the internet via mobile devices. We develop your website with a
                                                responsive layout that is also mobile-friendly.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Organic Visibility</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Your brand will outperform
                                                your competitors with the aid of specialist tools and a few creative abilities
                                                since it will constantly rank highly, draw in the proper kind of traffic, and
                                                grow more visible naturally.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Keyword Planning</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>We optimize your website by
                                                conducting keyword research and including the most relevant keywords in your
                                                content. This increases the likelihood that search engine bots will rank your
                                                website higher and enhance its searchability.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>SEO Audit</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>In order to make your website
                                                more SEO-friendly, we treat it like our own and offer a comprehensive SEO audit
                                                that examines its advantages, disadvantages, and potential improvement areas.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Get an SEO Audit!</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Speak with one of our SEO Experts to
                                    learn more about how Bonafide Technologies can support your endeavors.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        )
}

export default Bandra