import React from 'react'
import Footer from './Footer'
import Header from './Header'
import { Link } from "react-router-dom";

function InstantApprovalArticleSubmissionSitesList() {
    return (
        <>
            <Header />
            <div className="container-fluid p-3 lg:p-0">
                <div className="container mx-auto py-12">
                    <h1 className='text-[28px] md:text-[42px] font-medium heading  mb-8 '> Instant Approval Article Submission
                        Sites List in 2024</h1>
                    <div className="flex flex-wrap md:flex-nowrap pt-12 border-t-2">
                        <div className="w-full md:w-[70%] p-10 box-shadow mr-10 rounded-lg">
                            <div className="text_contenr pt-3 pb-8">
                                <p className='text-[18px] font-normal text-[#636363] '>In an Off-page SEO strategy, submitting
                                    articles is one of the most crucial things. The best way to get a high-quality backlink to
                                    your website is through article submission. This blog has compiled a list of article
                                    submission sites in 2024 with rapid clearance, do-follow links, high DA, and high MOZ
                                    rankings. We have included all the sites where anyone can publish their valuable, original,
                                    handwritten articles to these article submission sites.</p><br />

                                <p className='text-[18px] font-normal text-[#636363] '>Some believe that the days of article
                                    submission are over. That's not true.</p> <br />
                                <p className='text-[18px] font-normal text-[#636363] '>Considering recent SEO changes, article
                                    submission is still an effective off-page SEO tactic to get high-quality backlinks to your
                                    website or blog. Article submission has many advantages and is an excellent way to acquire
                                    high-quality backlinks.</p> <br />

                                <p className='text-[18px] font-normal text-[#636363] '>This blog post will walk you through the
                                    numerous advantages of article submission to high-domain authority websites. We have
                                    compiled a list of some of the top high DA article submission sites for 2024 for our
                                    readers' convenience.
                                </p> <br />

                                <h1 className='text-[32px] font-medium my-3 heading'>What are Article Submission Sites?</h1>
                                <br />

                                <p className='text-[18px] font-normal text-[#636363] '>You write and post an informative piece
                                    on a third-party website as part of an Off-page SEO strategy. They are among the safest ways
                                    to create DoFollow backlinks pointing to your blog or website. Additionally, it enhances
                                    traffic and raises the domain authority value.</p> <br />

                                <p className='text-[18px] font-normal text-[#636363] '>However, one should submit original,
                                    high-quality content to article directories to get high authority and PageRank backlinks.
                                    You could rapidly get some backlinks for your blog by submitting the articles to article
                                    submission sites. </p> <br />

                                <p className='text-[18px] font-normal text-[#636363] '>Another way to think of article
                                    submission as a component of content marketing is to create articles about your company and
                                    submit them to reputable article submission websites. When submitting articles, writers
                                    typically write on topics related to the online product.</p> <br />

                                <h1 className='text-[32px] font-medium my-3 heading'>What is an Article in SEO?</h1><br />

                                <p className='text-[18px] font-normal text-[#636363] '>A piece of text that explains or supports
                                    a position is called an article. An article's length can range from 600 to 800 words,
                                    depending on SEO considerations, and it should have high-quality, user- and search
                                    engine-relevant information. </p> <br />

                                <p className='text-[18px] font-normal text-[#636363] '>Google guidelines state that an article
                                    should be informative rather than promotional. You can find article submission websites
                                    online that either approve your content instantly or can take some time to go live. </p>
                                <br />

                                <p className='text-[18px] font-normal text-[#636363] '>Sites that allow you to submit articles
                                    instantly are the best for SEO if you want your target keywords to rank quickly.</p> <br />

                                <h1 className='text-[32px] font-medium my-3 heading'>Who Can Write an Article?</h1><br />

                                <p className='text-[18px] font-normal text-[#636363] '>If one is experienced, anyone can write
                                    an article on any subject. You can also work with a professional content writer to get
                                    articles delivered. </p> <br />

                                <p className='text-[18px] font-normal text-[#636363] '>Remember that an article created by a
                                    content writer must be search engine optimization (SEO) friendly when hiring one. Writing
                                    content by hand is recommended, as content spinning is a black-hat SEO tactic.</p> <br />

                                <h1 className='text-[32px] font-medium my-3 heading'>Which Article Submission Sites Should We
                                    Use?</h1><br />

                                <ul className='bg-[#f4dacd70] p-8'>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span></strong> Site with continued
                                        good traffic </li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span></strong>The submission site
                                        has high DA and MOZ ranks.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span></strong>A site that offers
                                        Do-follow backlinks.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span></strong>Sites with faster
                                        indexing.</li>
                                    <br />
                                </ul>



                                <h1 className='text-[32px] font-medium my-3 heading'>Why are Article Submission Sites Important
                                    in 2024?
                                </h1><br />

                                <p className='text-[18px] font-normal text-[#636363] '>Businesses today adopt various strategies
                                    to boost website popularity and expand organic traffic. They use different strategies,
                                    including social media marketing, SEO, and emails. The strategies vary depending on the
                                    customer the business wants to draw in.

                                </p> <br />
                                <p className='text-[18px] font-normal text-[#636363] '>Content is the most crucial component of
                                    article site promotion, regardless of your chosen strategies. Because it is impossible to
                                    grab people's attention without compelling content. As a result, improving the blog's
                                    quality is crucial, and article submission services assist with that.


                                </p> <br />

                                <p className='text-[18px] font-normal text-[#636363] '><strong>These are a few other benefits of
                                    article submission websites:</strong>
                                </p> <br />

                                <ul className='bg-[#f4dacd70] p-8'>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                        className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check">1. Free
                                            Internet Marketing: </i></span></strong>You can include links pointing to
                                        blogs within the content of most article submission websites. In this manner, you can
                                        use free marketing content to advertise your products and services. It would also assist
                                        you in obtaining more credible and high-quality blog links.
                                    </li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check">2.
                                            Increases Traffic:</i></span></strong> The links in the author bio box
                                        increase your website's traffic. Your blog will receive high-quality conversion traffic
                                        once the website owner accepts the article. You can repost or share the content on other
                                        social media platforms to increase the number of visitors and customers.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check">3. Using
                                            the Right Category</i></span></strong>Article submission websites also allow
                                        you to provide a brief biography of your company and yourself in the author bio box at
                                        the bottom of the article's body. You can advertise your products by using the keywords
                                        in a precise description. You can also include a backlink to your blog in this
                                        field.<br /><br />

                                        Therefore, it increases the domain authority if you post your blog post or article on a
                                        third-party website and provide an author bio with an external link to your website. You
                                        would gain from this since it spreads awareness of your company.
                                    </li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check">4. Create
                                            Leads and Sales:</i></span></strong> You can quickly create blog leads and
                                        sales leads with article submission websites. </li>
                                    <br />
                                </ul>
                                <h1 className='text-[32px] font-medium my-3 heading'>Top Free Article Submission Sites</h1>
                            </div>
                            <div class="relative overflow-x-auto shadow-md sm:rounded-lg ">
                                <table class="w-full text-sm text-left rtl:text-right text-gray-500 darks:text-gray-400">
                                    <thead
                                        class="text-xs text-gray-700 uppercase bg-gray-50 darks:bg-gray-700 darks:text-gray-400">
                                        <tr>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                SNo.
                                            </th>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                Free Article Submission Sites
                                            </th>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                DA
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://blog.freeadstime.org/"><span>https://blog.freeadstime.org/</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>2</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://blog.yookalo.com/"><span>https://blog.yookalo.com/</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>3</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://story.wallclassifieds.com/"><span>https://story.wallclassifieds.com/</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>4</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://articles.h1ad.com/"><span>https://articles.h1ad.com/</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>5</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://article.classifiedsfactor.com/"><span>https://article.classifiedsfactor.com/</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.ologames.com/"><span>https://www.ologames.com/</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://blogs.findermaster.com/"><span>https://blogs.findermaster.com/</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://article.advertiseera.com/"><span>https://article.advertiseera.com/</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.bloggersroad.com/"><span>https://www.bloggersroad.com/</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.howcube.com/"><span>https://www.howcube.com/</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://blog.shopolop.com/"><span>https://blog.shopolop.com/</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h1 className='text-[32px] font-medium my-3 heading'>Free Instant Article Submission Sites 2024</h1>
                            <div class="relative overflow-x-auto shadow-md sm:rounded-lg ">
                                <table class="w-full text-sm text-left rtl:text-right text-gray-500 darks:text-gray-400">
                                    <thead
                                        class="text-xs text-gray-700 uppercase bg-gray-50 darks:bg-gray-700 darks:text-gray-400">
                                        <tr>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                SNo.
                                            </th>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                Article Submission Sites
                                            </th>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                DA
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.linkedin.com/"><span>https://www.linkedin.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>99</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>2</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://sites.google.com/"><span>https://sites.google.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>97</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>3</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.github.com/"><span>https://www.github.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>96</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>4</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.medium.com/"><span>https://www.medium.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>95</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>5</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.hatena.ne.jp/"><span>https://www.hatena.ne.jp</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>94</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.bloglovin.com/"><span>https://www.bloglovin.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>93</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.livejournal.com/"><span>https://www.livejournal.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>93</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://telegra.ph/"><span>https://telegra.ph</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>93</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://buzzfeed.com/"><span>buzzfeed.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>93</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.quora.com/"><span>https://www.quora.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>93</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.academia.edu/"><span>https://www.academia.edu</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>93</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.evernote.com/"><span>https://www.evernote.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>92</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.reddit.com/"><span>https://www.reddit.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>92</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.wattpad.com/"><span>https://www.wattpad.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>92</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://manage.wn.com/article"><span>https://manage.wn.com/article</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>91</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://justpaste.it/"><span>http://justpaste.it</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>91</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://gumroad.com/"><span>https://gumroad.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>91</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.seekingalpha.com/"><span>https://www.seekingalpha.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>91</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.boredpanda.com/"><span>https://www.boredpanda.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>91</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://squidoo.com/"><span>squidoo.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>90</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://zimbio.com/"><span>zimbio.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>90</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://storify.com/"><span>storify.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>90</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://ezinearticles.com/"><span>https://ezinearticles.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>87</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.tumblr.com/"><span>https://www.tumblr.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>86</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://examiner.com/"><span>examiner.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>85</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.dzone.com/"><span>https://www.dzone.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>84</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://webpronews.com/"><span>webpronews.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>82</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.thefreelibrary.com/"><span>https://www.thefreelibrary.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>81</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.apsense.com/"><span>http://www.apsense.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>80</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ezinemark.com/"><span>ezinemark.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>80</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://biggerpockets.com/"><span>biggerpockets.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>79</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.articlesbase.com/"><span>https://www.articlesbase.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>78</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.vingle.net/"><span>https://www.vingle.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>77</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>34</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://buzzle.com/"><span>buzzle.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>77</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.merchantcircle.com/"><span>https://www.merchantcircle.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>76</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>36</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://penzu.com/"><span>https://penzu.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>76</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>37</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://bcz.com/"><span>http://bcz.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>75</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://mytrendingstories.com/"><span>https://mytrendingstories.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>74</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>39</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://netarticleshack.com/"><span>netarticleshack.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>73</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>40</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://e27.co/"><span>https://e27.co</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>72</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>41</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://wakelet.com/"><span>https://wakelet.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>71</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>42</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://vocal.media/"><span>https://vocal.media</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>71</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>43</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.infobarrel.com/"><span>http://www.infobarrel.com/</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>69</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>44</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://write.as/"><span>https://write.as</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>69</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>45</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://brighthub.com/"><span>brighthub.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>68</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>46</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://webwire.com/"><span>webwire.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>67</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>47</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articlesdir.org/"><span>articlesdir.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>65</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>48</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.selfgrowth.com/"><span>https://www.selfgrowth.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>49</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.infobarrel.com/"><span>http://www.infobarrel.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>50</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://storeboard.com/"><span>https://storeboard.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>51</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://teletype.in/"><span>https://teletype.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>52</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.letsdiskuss.com/"><span>https://www.letsdiskuss.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>53</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://writeupcafe.com/community"><span>https://writeupcafe.com/community</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>54</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://evancarmichael.com/"><span>evancarmichael.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>60</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://guest-articles.com/"><span>guest-articles.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>60</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://foreverdoomed.com/"><span>foreverdoomed.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>59</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>57</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.uberant.com/"><span>https://www.uberant.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>58</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>58</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://learnloftblog.com/"><span>learnloftblog.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>58</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>59</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.inube.com/"><span>http://www.inube.com/</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>57</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>60</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://theomnibuzz.com/"><span>https://theomnibuzz.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>57</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://anotepad.com/"><span>https://anotepad.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>57</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://dreampirates.us/"><span>dreampirates.us</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>57</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>63</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://writeonwall.com/"><span>writeonwall.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>57</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://storeboard.com/"><span>https://storeboard.com/</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>65</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://teletype.in/"><span>https://teletype.in/</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>66</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://articles.abilogic.com/"><span>https://articles.abilogic.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>67</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articlesfactory.com/"><span>http://www.articlesfactory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>68</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.sooperarticles.com/"><span>https://www.sooperarticles.com/</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>69</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.inube.com/"><span>http://www.inube.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>70</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://craft.co/"><span>https://craft.co</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>71</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.article1.co.uk/"><span>https://www.article1.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>72</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://feedsfloor.com/"><span>feedsfloor.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>73</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articlesubmited.com/"><span>articlesubmited.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>74</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://howto-tips.com/"><span>howto-tips.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>75</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.pr3-articles.com/"><span>https://www.pr3-articles.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>54</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>76</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.herbaltricks.com/"><span>https://www.herbaltricks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>54</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>77</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ourblogpost.com/"><span>ourblogpost.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>54</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>78</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://seoforums.me.uk/"><span>seoforums.me.uk</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>54</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>79</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articles.mybikaner.com/"><span>http://www.articles.mybikaner.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>53</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>80</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.creativebloggingworld.com/"><span>https://www.creativebloggingworld.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>53</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>81</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://powerhomebiz.com/"><span>powerhomebiz.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>53</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>82</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://articlesneed.com/"><span>https://articlesneed.com/</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>52</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>83</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.uberant.com"><span>https://www.uberant.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>52</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>84</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://articlesneed.com/"><span>https://articlesneed.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>52</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>85</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.pr5-articles.com/"><span>https://www.pr5-articles.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>52</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>86</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articlealley.com/"><span>http://www.articlealley.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>52</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>87</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://topsitenet.com/"><span>topsitenet.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>52</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>88</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.techsite.io/"><span>https://www.techsite.io</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>51</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>89</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://anotepad.com/"><span>https://anotepad.com/</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>50</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>90</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://craft.co/"><span>https://craft.co/</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>50</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>91</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.articlecube.com/"><span>https://www.articlecube.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>50</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>92</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articlesnatch.com/"><span>articlesnatch.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>50</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>93</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.techsite.io/"><span>https://www.techsite.io/</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>49</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>94</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.amazines.com/"><span>https://www.amazines.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>49</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>95</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.hubpages.com/"><span>https://www.hubpages.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>49</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>96</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.promotionworld.com/"><span>https://www.promotionworld.com/</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>48</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>97</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://telescope.ac/"><span>https://telescope.ac</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>48</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>98</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://constant-content.com/"><span>constant-content.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>48</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>99</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://velog.io/"><span>velog.io</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>48</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>100</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://triond.com/"><span>triond.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>47</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>101</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://homment.com/"><span>homment.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>47</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>102</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://bilalarticles.com/"><span>bilalarticles.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>46</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>103</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://leasedadspace.com/"><span>leasedadspace.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>46</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>104</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.promotionworld.com/"><span>https://www.promotionworld.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>45</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>105</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://streetarticles.com/"><span>streetarticles.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>45</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>106</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://submityourarticle.com/"><span>submityourarticle.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>44</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>107</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articleted.com/"><span>articleted.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>44</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>108</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://fortunetelleroracle.com/"><span>https://fortunetelleroracle.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>43</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>109</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://threadwatch.org/"><span>threadwatch.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>42</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>110</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://presszoom.com/"><span>presszoom.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>42</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>111</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articlecity.com/"><span>articlecity.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>41</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>112</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://themanager.org/"><span>themanager.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>40</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>113</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articlerich.com/"><span>articlerich.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>40</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>114</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://123articleonline.com/"><span>123articleonline.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>40</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>115</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://knowpia.com/"><span>knowpia.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>40</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>116</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://articlexpress.co.uk/"><span>https://articlexpress.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>39</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>117</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://submitafreearticle.com/"><span>https://submitafreearticle.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>39</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>118</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://globalarticlefinder.com/"><span>https://globalarticlefinder.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>39</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>119</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://articlesjust4you.com/"><span>https://articlesjust4you.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>39</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>120</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://trickyenough.com/"><span>trickyenough.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>39</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>121</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.articlesreader.com/"><span>https://www.articlesreader.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>122</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.otherarticles.com/"><span>https://www.otherarticles.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>123</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://popularticles.com/"><span>https://popularticles.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>124</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://howtoadvice.com/"><span>howtoadvice.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>125</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://magportal.com/"><span>magportal.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>126</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articleant.com/"><span>articleant.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>127</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://top7business.com/"><span>top7business.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>128</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://earticlesonline.com/"><span>earticlesonline.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>37</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>129</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articleslash.net/"><span>articleslash.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>37</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>130</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articlesforwebsite.com/"><span>articlesforwebsite.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>36</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>131</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articlebliss.com/"><span>articlebliss.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>36</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>132</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://webproworld.com/"><span>webproworld.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>36</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>133</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articleblast.com/"><span>articleblast.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>36</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>134</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://andrey-cruz.livejournal.com/"><span>andrey-cruz.livejournal.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>36</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>135</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://go2article.com/"><span>go2article.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>36</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>136</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://upublish.info/"><span>upublish.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>137</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://libervis.com/"><span>libervis.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>138</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://idleexperts.com/"><span>idleexperts.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>139</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articlespromoter.com/"><span>articlespromoter.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>140</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://libervis.com/"><span>libervis.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>141</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://ezarticlesdb.com/"><span>https://ezarticlesdb.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>34</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>142</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://a1articles.com/"><span>a1articles.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>34</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>143</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articleclick.com/"><span>articleclick.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>34</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>144</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://a1articles.com/"><span>a1articles.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>34</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>145</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://upstartblogger.com/"><span>upstartblogger.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>34</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>146</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.yaarikut.com/"><span>http://www.yaarikut.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>147</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://articlerockstars.com/"><span>https://articlerockstars.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>148</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://uberarticles.com/"><span>http://uberarticles.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>149</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articlization.com/"><span>articlization.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>150</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://yooarticles.net/"><span>yooarticles.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>151</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://bpubs.com/"><span>bpubs.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>152</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.airtract.com/articles"><span>https://www.airtract.com/articles</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>153</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articlegeek.com/"><span>articlegeek.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>154</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://techmanik.com/"><span>techmanik.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>155</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://prolinkdirectory.com/"><span>prolinkdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>156</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://realestateproarticles.com/"><span>realestateproarticles.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>157</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articlemonkeys.com/"><span>articlemonkeys.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>158</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://umumble.com/"><span>umumble.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>159</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://articleweb55.com/"><span>https://articleweb55.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>160</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.insertarticles.info/"><span>http://www.insertarticles.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>161</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articleseen.com/"><span>http://www.articleseen.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>162</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articletrader.com/"><span>articletrader.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>163</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articlecell.com/"><span>articlecell.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>164</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://gruks.com/"><span>gruks.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>165</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://bestezines.com/"><span>bestezines.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>166</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://highrankdirectory.com/"><span>highrankdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>167</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articlesbd.com/"><span>http://www.articlesbd.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>168</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articles.mastercraftindia.com/"><span>http://www.articles.mastercraftindia.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>169</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://awebcity.com/"><span>awebcity.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>170</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://saching.com/"><span>saching.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>171</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://marketinginternetdirectory.com/"><span>marketinginternetdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>172</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://usalistingdirectory.com/"><span>usalistingdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>173</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://businessseek.biz/"><span>businessseek.biz</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>174</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.simplysearch4it.com/"><span>http://www.simplysearch4it.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>175</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articleswrap.com/"><span>articleswrap.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>176</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://sitepromotiondirectory.com/"><span>sitepromotiondirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>177</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://goodinfohome.com/"><span>goodinfohome.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>178</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://simplysearch4it.com/"><span>simplysearch4it.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>179</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.openarticles.com/"><span>http://www.openarticles.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>180</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articleuploads.com/"><span>articleuploads.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>181</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://edarticle.com/"><span>edarticle.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>182</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://weeno.com/"><span>weeno.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>183</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articlecompilation.com/"><span>articlecompilation.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>184</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://freesticky.com/"><span>freesticky.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>185</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://excellentguide.info/"><span>excellentguide.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>186</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ladypens.com/"><span>ladypens.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>187</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articlicious.com/"><span>http://articlicious.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>188</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://dime-co.com/"><span>dime-co.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>189</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://approvedarticles.com/"><span>approvedarticles.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>190</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://dime-co.com/"><span>dime-co.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>191</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://searcharticles.net/"><span>searcharticles.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>192</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ukinternetdirectory.net/"><span>ukinternetdirectory.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>193</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articles411.com/"><span>articles411.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>194</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articlesss.com/"><span>http://articlesss.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>195</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.myarticles.io/"><span>https://www.myarticles.io</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>196</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.weboworld.com/"><span>https://www.weboworld.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>197</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://traveltourismdirectory.net/"><span>traveltourismdirectory.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>198</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://easyarticles.com/"><span>easyarticles.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>199</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articlestars.com/"><span>articlestars.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>200</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://letsbefamous.com/"><span>letsbefamous.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>201</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.articletrunk.com/"><span>https://www.articletrunk.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>202</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://facearticle.com/"><span>facearticle.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>203</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://goarticles.info/"><span>goarticles.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>204</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://blog.giganticlist.com/"><span>blog.giganticlist.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>205</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://informationbible.com/"><span>informationbible.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>206</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://populararticles.com/"><span>populararticles.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>207</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://qwesz.com/"><span>qwesz.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>208</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://bysharing.com/"><span>bysharing.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>209</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://thecontentcorner.com/"><span>thecontentcorner.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>210</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://americanarticle.org/"><span>http://americanarticle.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>211</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articlemedia.co.uk/"><span>articlemedia.co.uk</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>212</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://24by7articles.com/"><span>24by7articles.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>213</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articlefree4all.com/"><span>articlefree4all.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>214</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.articledirectoryusa.com/"><span>https://www.articledirectoryusa.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>215</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.article-buzz.com/"><span>http://www.article-buzz.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>216</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.easy-articles.com/"><span>http://www.easy-articles.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>217</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articlecede.com/"><span>articlecede.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>218</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://webmasterslibrary.com/"><span>webmasterslibrary.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>219</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://new-list.com/"><span>new-list.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>220</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://yesarticles.com/"><span>yesarticles.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>221</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articleclick.info/"><span>articleclick.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>222</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ezinearticles.org.uk/"><span>ezinearticles.org.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>223</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articlet.com/"><span>articlet.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>224</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://expertarticles.com/"><span>expertarticles.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>225</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.warticles.com/"><span>https://www.warticles.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>226</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articleslist.net/"><span>articleslist.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>227</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articleavenue.com/"><span>articleavenue.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>228</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://thepr.com.au/"><span>thepr.com.au</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>229</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://altegen.com/"><span>altegen.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>230</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://thewhir.info/"><span>thewhir.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>231</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://freearticlestore.com/"><span>freearticlestore.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>232</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articlecatalog.com/"><span>http://articlecatalog.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>233</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.aniarticles.com/"><span>https://www.aniarticles.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>234</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://howitworks.net/"><span>howitworks.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>235</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://tongshu.net/"><span>tongshu.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>236</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://submitanarticle.info/"><span>submitanarticle.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>237</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ezine-articles-planet.com/"><span>ezine-articles-planet.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>238</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ezinehub.com/"><span>ezinehub.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>239</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articlepool.info/"><span>articlepool.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>240</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.srmarticles.com/"><span>https://www.srmarticles.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>241</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ezinepost.com/"><span>http://www.ezinepost.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>242</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://articlegift.com/"><span>https://articlegift.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>243</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.galoor.com/"><span>http://www.galoor.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>244</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://greatarticles.co.uk/"><span>https://greatarticles.co.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>245</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articledoctor.com/"><span>articledoctor.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>246</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articleside.com/"><span>articleside.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>247</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articledoctor.com/"><span>articledoctor.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>248</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://afreearticle.com/"><span>afreearticle.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>249</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ezinepost.com/"><span>ezinepost.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>250</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://zigyasu.com/"><span>zigyasu.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>251</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articlegold.com/"><span>articlegold.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>252</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://datasoftsystem.com/"><span>datasoftsystem.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>253</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articlesupport.com/"><span>articlesupport.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>254</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://readezarchive.com/"><span>readezarchive.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>255</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://adarticles.net/"><span>adarticles.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>256</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articledesk.info/"><span>articledesk.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>257</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://accessinfohub.com/"><span>accessinfohub.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>258</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://hubpages.info/"><span>hubpages.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>259</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://postarticles.com/"><span>postarticles.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>260</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://articlebeast.online/"><span>https://articlebeast.online</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>261</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.groundofsuccess.com/"><span>https://www.groundofsuccess.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>262</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://thefreelibrary.info/"><span>thefreelibrary.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>263</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://article-diary.com/"><span>article-diary.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>264</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://olmaweblinkdirectory.com/"><span>olmaweblinkdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>265</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://writerspenarticledirectory.com/"><span>writerspenarticledirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>266</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://smarticledirectory.com/"><span>smarticledirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>267</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://articles87.com/"><span>https://articles87.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>268</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://seosubmitarticle.com/"><span>seosubmitarticle.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>269</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articlecontentking.com/"><span>articlecontentking.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>270</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://myseoarticle.com/"><span>myseoarticle.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>271</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://marketmyarticle.com/"><span>marketmyarticle.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>272</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articlerealm.com/"><span>articlerealm.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>273</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://selfseo.info/"><span>selfseo.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>274</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://onlinearticlessubmission.com/"><span>onlinearticlessubmission.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>275</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://contentarticles.com/"><span>contentarticles.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>276</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://thehealthyvillage.com/"><span>thehealthyvillage.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>277</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://addmyarticles.com/"><span>addmyarticles.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>278</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://contentarticles.com/"><span>contentarticles.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>279</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://sdcouncil.com/"><span>sdcouncil.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>280</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://101articles.com/"><span>101articles.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>281</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://acme-articles.com/"><span>acme-articles.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>282</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://e-topic.com/"><span>e-topic.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>283</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.sharehealthtips.com/"><span>http://www.sharehealthtips.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>284</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articlekit.com/"><span>articlekit.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>285</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://loudpages.com/"><span>loudpages.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>286</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://jazzylook.com/"><span>jazzylook.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>287</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://wellnessarticlelibrary.com/"><span>wellnessarticlelibrary.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>288</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://guestsposting.com/"><span>guestsposting.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>289</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://myarticle.com/"><span>myarticle.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>290</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://thetexasjobsource.com/"><span>thetexasjobsource.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>291</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://textiledegree.com/"><span>textiledegree.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>292</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.jumparticles.com/"><span>http://www.jumparticles.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>293</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.netezinearticles.com/"><span>https://www.netezinearticles.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>294</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://nehoiu.org/"><span>nehoiu.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>295</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://mymedicenter.com/"><span>mymedicenter.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>296</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://mazines.com/"><span>mazines.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>297</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articlewale.com/"><span>articlewale.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>298</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://wall2wallarticles.com/"><span>wall2wallarticles.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>299</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articlefans.com/"><span>http://www.articlefans.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>300</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://linkcompose.com/"><span>linkcompose.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>301</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ghrankdirectory.com/"><span>ghrankdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>302</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://sheil.newsvine.com/"><span>sheil.newsvine.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>303</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://ganzok.com/"><span>ganzok.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>304</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://articleswarm.com/"><span>articleswarm.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>5</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>305</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://fivearticles.com/"><span>https://fivearticles.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>4</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>306</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://allinclusivelinks.com/"><span>allinclusivelinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>3</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h1 className='text-[32px] font-medium my-3 heading mt-12'> What are the Things to Keep in Mind
                                Before Posting an Article </h1>
                            <p className='text-[18px] font-normal text-[#636363] '>There are thousands of websites where people
                                can submit articles, each with different requirements. The following are some of the most
                                popular guidelines for submitting articles that you should be aware of:</p> <br />
                            <ul className='bg-[#f4dacd70] p-8'>
                                <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                    className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>1.
                                    The Title</strong>
                                    Each article must have a distinct title that accurately conveys the item's content. The
                                    title should be between 50-60 characters long.
                                </li>
                                <br />
                                <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                    className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>2.
                                    Summary</strong>
                                    Before publishing your article, you must enter a summary on most article submission
                                    websites. A brief overview of your article can be entered in the summary area. You can
                                    usually enter 150 to 200 characters in a summary box.
                                </li>
                                <br />
                                <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                    className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>3.
                                    Tags and Keywords</strong>
                                    Most article submission websites will ask you to fill out the Keywords and Tags area with
                                    the topic and target keyword for that particular post, respectively. Your post is ranked
                                    higher by using keywords, and it is categorized using tags.
                                </li>
                                <br />
                                <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                    className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span> 4.
                                    Add Signature </strong>
                                    You'll also see the signature or about us section when you submit an article. Include
                                    personal information about yourself or your business in these sections, such as your
                                    occupation.
                                </li>
                                <br />
                                <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                    className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>5.
                                    Article Body </strong>
                                    This is where you enter the textual and multimedia information for your article. Make sure
                                    that before you press the publish button, your article is original and up to date.

                                </li>
                            </ul>
                            <br />
                            <p className='text-[18px] font-normal text-[#636363] '>Your article may require moderator
                                approval before becoming live, depending on the website you submitted it to. If your article
                                satisfies the requirements listed on their website, the moderator will review it for
                                plagiarism and other issues and approve it in a few days, if not sooner.</p> <br />

                            <h1 className='text-[32px] font-medium my-3 heading'>Points that Make Article Submission Sites
                                Worth Using
                            </h1><br />
                            <p className='text-[18px] font-normal text-[#636363] '>Article submission sites are exceptional
                                for several reasons, including:
                            </p> <br />
                            <ul className='bg-[#f4dacd70] p-8'>
                                <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                    className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"> </i></span>1.
                                    Keywords:</strong>The core of any content is its usage of
                                    keywords, which can help your website reach a wider audience. Utilizing effective
                                    keywords helps increase the reach of your page.
                                </li>
                                <br />
                                <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                    className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check">
                                    </i></span>2. Matter:</strong>Make sure your writing is original and creative. People will
                                    wait for it and visit your website more often if your content is exciting and original.
                                </li>
                                <br />
                                <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                    className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>3.
                                    Publication Channel:</strong> This effort would be useless if you
                                    used excellent keywords and your content was equally compelling, but the distribution
                                    channel was incorrect. Choosing a top-notch publication page will guarantee that your
                                    blog reaches the correct audience and gains more recognition.<br /><br />
                                </li>
                                <br />
                            </ul>

                            <h1 className='text-[32px] font-medium my-3 heading'>How Does Article Submission Work?
                            </h1><br />
                            <p className='text-[18px] font-normal text-[#636363] '>Remember to abide by website terms and
                                conditions when submitting high-quality content to article submission sites. When submitting
                                your content to them, there are a few things you should consider, like:
                            </p> <br />
                            <ul className='bg-[#f4dacd70] p-8'>
                                <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                    className='text-[#63E6BE] mr-2'><i
                                        class="fa-regular fa-square-check"></i></span></strong>In the post, use
                                    high-resolution graphics or photographs.
                                </li>
                                <br />
                                <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                    className='text-[#63E6BE] mr-2'><i
                                        class="fa-regular fa-square-check"></i></span></strong>Follow the guidelines
                                    on this website.</li>
                                <br />
                                <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                    className='text-[#63E6BE] mr-2'><i
                                        class="fa-regular fa-square-check"></i></span></strong>Add a few backlinks
                                    to the article.<br /><br />
                                </li>
                                <br />
                                <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                    className='text-[#63E6BE] mr-2'><i
                                        class="fa-regular fa-square-check"></i></span></strong>Share original and
                                    fresh content.
                                </li>
                                <br />
                                <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                    className='text-[#63E6BE] mr-2'><i
                                        class="fa-regular fa-square-check"></i></span></strong>Choose the
                                    appropriate tags and category.</li>
                                <br />
                                <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                    className='text-[#63E6BE] mr-2'><i
                                        class="fa-regular fa-square-check"></i></span></strong>Write a page title
                                    and description that is optimized for search engines.<br /><br />
                                </li>
                                <br />
                            </ul>



                            <h1 className='text-[32px] font-medium my-3 heading'>How Do You Submit An Article To Article
                                Submission Sites?
                            </h1><br />
                            <p className='text-[18px] font-normal text-[#636363] '>TSubmitting an article on websites that
                                accept submissions is really simple. Visit the website, find the login section, and create
                                an account. Additionally, you can log in through a third party because some article
                                submission websites allow you to connect with Gmail, Twitter, Facebook, and other accounts.
                            </p> <br />
                            <p className='text-[18px] font-normal text-[#636363] '>You can then submit your article in the
                                appropriate category after registering.
                            </p> <br />





                            <div className="faqs mb-12">
                                <h1 className='text-[32px] font-medium my-3 heading'> FAQs
                                </h1>
                                <div id="accordion-collapse" data-accordion="collapse">
                                    <h2 id="accordion-collapse-heading-1">
                                        <button type="button"
                                            class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200  hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                            data-accordion-target="#accordion-collapse-body-1" aria-expanded="true"
                                            aria-controls="accordion-collapse-body-1">
                                            <span className='text-[20px] text-[#222] text-left'>What are DoFollow Article
                                                Submission Sites?</span>
                                            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                    strokeWidth="2" d="M9 5 5 1 1 5" />
                                            </svg>
                                        </button>
                                    </h2>
                                    <div id="accordion-collapse-body-1" class="hidden"
                                        aria-labelledby="accordion-collapse-heading-1">
                                        <div
                                            class="p-5 border border-b-0 border-gray-200 darks:border-gray-700 darks:bg-gray-900">
                                            <p className='text-[18px] font-normal text-[#636363] '>These websites allow
                                                search engines to crawl links from other websites and follow them to the
                                                same website that receives backlinks, much like a slow-moving search engine.
                                                <br /><br />
                                                Article submission websites also help improve your blog or website's
                                                exposure across search engines like Google, Bing, and Yahoo. You can use and
                                                benefit from various free High DA DoFollow article submission sites.
                                            </p>
                                        </div>
                                    </div>
                                    <h2 id="accordion-collapse-heading-2">
                                        <button type="button"
                                            class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                            data-accordion-target="#accordion-collapse-body-2" aria-expanded="false"
                                            aria-controls="accordion-collapse-body-2">
                                            <span className='text-[20px] text-[#222] text-left'>What are High DA Article
                                                Submission Sites?</span>
                                            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                    strokeWidth="2" d="M9 5 5 1 1 5" />
                                            </svg>
                                        </button>
                                    </h2>
                                    <div id="accordion-collapse-body-2" class="hidden"
                                        aria-labelledby="accordion-collapse-heading-2">
                                        <div class="p-5 border border-b-0 border-gray-200 darks:border-gray-700">
                                            <p className='text-[18px] font-normal text-[#636363] '>Based on security,
                                                traffic, backlinks, trust, age, and domain, Moz's algorithm has assigned
                                                these websites a high domain authority value. They significantly affect
                                                search ranking if you get a few backlinks from them.<br /><br />
                                                Your web pages will appear more prominently on search engine results pages
                                                if you link to high-domain authority domains. The worth of the domain's
                                                authority and ranking in search engine result pages will rise if you receive
                                                even one backlink from an article submission site with a high domain
                                                authority.
                                            </p>
                                        </div>
                                    </div>
                                    <h2 id="accordion-collapse-heading-3">
                                        <button type="button"
                                            class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                            data-accordion-target="#accordion-collapse-body-3" aria-expanded="false"
                                            aria-controls="accordion-collapse-body-3">
                                            <span className='text-[20px] text-[#222] text-left'>Are Article Submission Sites
                                                Free?</span>
                                            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                    strokeWidth="2" d="M9 5 5 1 1 5" />
                                            </svg>
                                        </button>
                                    </h2>
                                    <div id="accordion-collapse-body-3" class="hidden"
                                        aria-labelledby="accordion-collapse-heading-3">
                                        <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                            <p className='text-[18px] font-normal text-[#636363] '>While some article
                                                submission websites charge a fee for premium services or features, others
                                                are free</p>
                                        </div>
                                    </div>

                                    <h2 id="accordion-collapse-heading-4">
                                        <button type="button"
                                            class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                            data-accordion-target="#accordion-collapse-body-4" aria-expanded="false"
                                            aria-controls="accordion-collapse-body-4">
                                            <span className='text-[20px] text-[#222] text-left'>How Do I Select The Right
                                                Article Submission Site?</span>
                                            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                    strokeWidth="2" d="M9 5 5 1 1 5" />
                                            </svg>
                                        </button>
                                    </h2>
                                    <div id="accordion-collapse-body-4" class="hidden"
                                        aria-labelledby="accordion-collapse-heading-4">
                                        <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                            <p className='text-[18px] font-normal text-[#636363] '>The niche, authority,
                                                traffic, submission policies, user experience, social media integration, and
                                                statistics of websites are all essential considerations when choosing an
                                                article submission site.</p>
                                        </div>
                                    </div>

                                    <h2 id="accordion-collapse-heading-5">
                                        <button type="button"
                                            class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                            data-accordion-target="#accordion-collapse-body-5" aria-expanded="false"
                                            aria-controls="accordion-collapse-body-5">
                                            <span className='text-[20px] text-[#222] text-left'>What Types of Articles Can I
                                                Submit to Article Submission Sites?</span>
                                            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                    strokeWidth="2" d="M9 5 5 1 1 5" />
                                            </svg>
                                        </button>
                                    </h2>
                                    <div id="accordion-collapse-body-5" class="hidden"
                                        aria-labelledby="accordion-collapse-heading-5">
                                        <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                            <p className='text-[18px] font-normal text-[#636363] '>Most article submission
                                                websites allow submissions on various subjects, such as technology,
                                                lifestyle, business, health, etc. It's crucial to check the submission
                                                guidelines to make sure your work satisfies the site's requirements.</p>
                                        </div>
                                    </div>

                                    <h2 id="accordion-collapse-heading-6">
                                        <button type="button"
                                            class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                            data-accordion-target="#accordion-collapse-body-7" aria-expanded="false"
                                            aria-controls="accordion-collapse-body-6">
                                            <span className='text-[20px] text-[#222] text-left'>How Long Does Publishing an
                                                Article on An Article Submission Site Take?</span>
                                            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                    strokeWidth="2" d="M9 5 5 1 1 5" />
                                            </svg>
                                        </button>
                                    </h2>
                                    <div id="accordion-collapse-body-6" class="hidden"
                                        aria-labelledby="accordion-collapse-heading-6">
                                        <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                            <p className='text-[18px] font-normal text-[#636363] '>The duration of an
                                                article's publication on an article submission website differs based on the
                                                editorial procedure of the website. While some websites might examine and
                                                post articles in a matter of days, others would take several weeks.</p>
                                        </div>
                                    </div>

                                    <h2 id="accordion-collapse-heading-7">
                                        <button type="button"
                                            class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                            data-accordion-target="#accordion-collapse-body-7" aria-expanded="false"
                                            aria-controls="accordion-collapse-body-7">
                                            <span className='text-[20px] text-[#222] text-left'>Can I Republish My Article
                                                on Other Websites After It Has Been Published on an Article Submission
                                                Site?</span>
                                            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                    strokeWidth="2" d="M9 5 5 1 1 5" />
                                            </svg>
                                        </button>
                                    </h2>
                                    <div id="accordion-collapse-body-7" class="hidden"
                                        aria-labelledby="accordion-collapse-heading-7">
                                        <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                            <p className='text-[18px] font-normal text-[#636363] '>Depending on the
                                                guidelines set forth by the website where articles are submitted. While some
                                                websites can demand exclusive rights to the content, others might let you
                                                repost the article on other websites as long as you give due credit. Before
                                                submitting your content, it's crucial to read the terms and conditions of
                                                the website</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-[30%] hidden lg:block relative">
                            <div className='box-shadow rounded-lg ml-5 p-8 sidebar sticky top-[-150px]'>
                                <div className="sidebar_banner">
                                    <img src="img/page_img_4.jpg" alt="" />
                                </div>

                                <div className="sidebar_tabs mt-5">
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/profile-creation-sites-list">
                                            <h1 className='text-[18px] font-semibold capitalize'>Top High Do Follow Profile Creation
                                                Sites List</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/image-submission-sites-list">
                                            <h1 className='text-[18px] font-semibold capitalize'>image-submission-sites-list</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/classified-ads-websites">
                                            <h1 className='text-[18px] font-semibold capitalize'>classified-ads-websites</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/instant-approval-article-submissions-sites-list">
                                            <h1 className='text-[18px] font-semibold capitalize'>instant-approval-article-submissions-sites-list</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/free-high-quality-directory-submission-sites">
                                            <h1 className='text-[18px] font-semibold capitalize'>free-high-quality-directory-submission-sites
                                                Sites List</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/blog-submission-sites">
                                            <h1 className='text-[18px] font-semibold capitalize'>blog-submission-sites</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/business-listing-sites-in-india">
                                            <h1 className='text-[18px] font-semibold capitalize'>business-listing-sites-in-india</h1>
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default InstantApprovalArticleSubmissionSitesList