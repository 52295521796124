import React from 'react'
import ServiceSlider from './ServiceSlider'
import SeoServices from './SeoServices'
import DevSlider from './DevSlider'
import DigitalSlider from './DigitalSlider'
import Header from './Header'
import Footer from './Footer'

function OurWork() {
  return (

    <>
    <Header/>
     <div className="container-fluid py-12 lg:py-24 bg-cover bg-center" style={{ backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)"}}>
          <div className="container mx-auto py-12">
               <h1 className='text-center text-white text-[34px] md:text-[42px] lg:text-[56px] xl:text-[86px] font-bold heading uppercase'>OUR WORK</h1>
          </div>
     </div>
     <div className="container-fluid">
        <div className="container mx-auto pt-12">
          <p className='text-[18px] font-normal text-[#636363] text-center'>Bonafide Technologies is a progressive and innovative digital marketing company in India that provides a unique experience for its clients. We differ from traditional strategies of digital internet marketing by utilizing both advanced techniques and best practices to carve your dream into reality. We are ready for any challenge that comes our way.</p><br />

          <p className='text-[18px] font-normal text-[#636363] text-center'>We are a driven group of experienced digital marketers with a range of experiences, and we have the knowledge and expertise required for success. Our team of digital marketing specialists in India collaborates daily to provide quick, data-driven results that support the goals and ambitions of our clients and foster the growth of Indian businesses.</p><br />

          <p className='text-[18px] font-normal text-[#636363] text-center'>Our goal is to exceed your expectations regarding value, agility, and honesty. We value honest interaction and a commitment to providing the best services available. Our clients adore our work and take great pride in using our services.</p>
        </div>
   </div>

     <div className="container-fluid">
          <div className="container mx-auto py-12">
               <div className='mb-10'>
               <h1 className='text-[36px] font-bold heading text-center uppercase'>SEO</h1>
               <p className='text-[18px] font-normal text-[#636363] text-center '>Expand your chances of appearing in the search engine's top results. Search engine optimization, or SEO, helps your website appear at the top of search results. Produce outstanding leads and exceed drastically in sales.</p>
               </div>
               <SeoServices/>
          </div>
     </div>

   <div className="container-fluid bg-gray-50">
        <div className="container mx-auto py-12">
            <div className='mb-10'>
            <h1 className='text-[36px] font-bold heading text-center uppercase'>Web Designing</h1>
            <p className='text-[18px] font-normal text-[#636363] text-center '>Our talented team of website designers builds visually attractive and captivating websites that convey our client's beliefs and support the expansion of their businesses.</p>
            </div>
             <ServiceSlider/>
        </div>
   </div>
   
   <div className="container-fluid ">
        <div className="container mx-auto py-12">
            <div className='mb-10'>
            <h1 className='text-[36px] font-bold heading text-center'>DIGITAL INTERNET MARKETING</h1>
            <p className='text-[18px] font-normal text-[#636363] text-center '>As the top digital marketing company in India, we provide several services to help you reach your digital marketing objectives, including search engine optimization (SEO), social media marketing, pay-per-click (PPC) advertising, content marketing, email marketing, and more.</p>
            </div>
             <DigitalSlider/>
        </div>
   </div>

   <div className="container-fluid bg-gray-50">
        <div className="container mx-auto py-12">
            <div className='mb-10'>
            <h1 className='text-[36px] font-bold heading text-center uppercase'>Web Development</h1>
            <p className='text-[18px] font-normal text-[#636363] text-center '>At Bonafide Technologies, we strive to make every detail matter to provide the best web development services. Our expertise lies in creating software, mobile applications, and websites.</p>
            </div>
             <DevSlider/>
        </div>
   </div>


   <Footer/>
   </>
    
  )
}

export default OurWork