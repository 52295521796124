import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { Link } from "react-router-dom";


function PHP() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{
                backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)"
            }}>
                <div className="container mx-auto py-12">
                    <h1
                        className='text-center text-white text-[34px] md:text-[42px] lg:text-[56px] xl:text-[86px] font-bold heading uppercase'>
                        PHP</h1>
                </div>
            </div>

            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[70%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <img src="img/php.jpg" className='w-full' alt="" />

                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>PHP</h1>
                                <p className='text-[18px] font-normal text-[#636363] '><strong> Get Stunning and Effective PHP
                                    Solutions to Hasten the Growth of Your Company!</strong></p><br />
                                <p className='text-[18px] font-normal text-[#636363] '>A custom PHP web development company that
                                    can deliver large-scale transformation and push your business to new heights.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>PHP, an open-source server-side scripting
                                    language, is a free tool for developing dynamic and interactive online applications. Thanks
                                    to its strong coding architecture and modern principles, PHP web application development
                                    services are always evolving to provide the finest user experience through quick execution
                                    and customization.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>Bonafide Technologies, a top PHP web
                                    development company, provides comprehensive, sophisticated, and customized PHP development
                                    services for various business needs. To provide excellent PHP development services, our
                                    highly qualified PHP developers stay up to speed on the newest technologies, possess a
                                    variety of PHP frameworks, and are quite knowledgeable.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>We at Bonafide Technologies provide
                                    comprehensive PHP web development services and are aware of your business's needs. Our team
                                    has years of experience and knowledge, which helps us create dynamic, quick, and effective
                                    websites.</p>
                            </div>

                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>What We Provide</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>We create a website that is completely
                                    suited to the sector's demands and provides your clients with a unique experience. To
                                    provide optimal speed, we design PHP websites by properly aligning your requirements with
                                    industry standards.</p><br />

                                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                                    <div className="flex flex-wrap justify-between">
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>CMS Application Development
                                            </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Web CMS development is our
                                                area of expertise. You'll receive a personalized content management system (CMS)
                                                designed to meet your requirements and give your clients an outstanding user
                                                experience.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Web Application Development
                                            </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>We are a full-service PHP web
                                                development business that strives to offer the best solutions using the latest
                                                industry developments.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>PHP Migration and Upgrading
                                            </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>With the newest frameworks
                                                and technologies, we can help you improve your current codebase with our skilled
                                                PHP development services. Our thorough testing will also guarantee that your
                                                apps are operating at their best.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>API Development</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>We are skilled API
                                                developers, and we are ready to show you how we can support your unique business
                                                requirements with advanced and effective PHP apps.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>PHP Integrations</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Look no further if you're
                                                searching for an effortless connection between your PHP application and a
                                                content management system (CMS) such as Drupal or WordPress. We are cordial,
                                                incredibly reasonably-priced industry professionals.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Custom Web Portals</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>We are dedicated to providing
                                                complete web portal solutions because we recognize your needs. We create
                                                user-friendly, content-driven websites with innovative interfaces that
                                                successfully help clients achieve their objectives.</p>
                                        </div>

                                    </div>
                                </div>

                                <div className="faqs my-12">
                                    <h1 className='text-[32px] font-medium my-3 heading'>FAQs</h1>
                                    <div id="accordion-collapse" data-accordion="collapse">
                                        <h2 id="accordion-collapse-heading-1">
                                            <button type="button"
                                                class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200  hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                                data-accordion-target="#accordion-collapse-body-1" aria-expanded="true"
                                                aria-controls="accordion-collapse-body-1">
                                                <span className='text-[20px] text-[#222] text-left'>What Technology Areas of PHP
                                                    Do You Specialize In?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                        strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-1" class="hidden"
                                            aria-labelledby="accordion-collapse-heading-1">
                                            <div
                                                class="p-5 border border-b-0 border-gray-200 darks:border-gray-700 darks:bg-gray-900">
                                                <p className='text-[18px] font-normal text-[#636363] '>Bonafide Technologies has
                                                    been serving companies across different industries for more than 18 years.
                                                    We are experts in PHP development frameworks such as Laravel, CodeIgniter,
                                                    Cake PHP, and many more. In addition to developing WordPress websites and
                                                    eCommerce platforms, our PHP developers are experienced at developing mobile
                                                    applications, HTML and JavaScript websites, and mobile applications.
                                                </p>
                                            </div>
                                        </div>
                                        <h2 id="accordion-collapse-heading-2">
                                            <button type="button"
                                                class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                                data-accordion-target="#accordion-collapse-body-2" aria-expanded="false"
                                                aria-controls="accordion-collapse-body-2">
                                                <span className='text-[20px] text-[#222] text-left'>What Benefits Do PHP
                                                    Development Services Offer?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                        strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-2" class="hidden"
                                            aria-labelledby="accordion-collapse-heading-2">
                                            <div class="p-5 border border-b-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>We assist companies in
                                                    utilizing the expertise of our skilled PHP developers to deliver
                                                    ground-breaking PHP solutions. With PHP technology, we are able to code
                                                    unique frameworks, develop compelling business strategies, and produce
                                                    dynamic UI/UX solutions.</p>
                                            </div>
                                        </div>
                                        <h2 id="accordion-collapse-heading-3">
                                            <button type="button"
                                                class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                                data-accordion-target="#accordion-collapse-body-3" aria-expanded="false"
                                                aria-controls="accordion-collapse-body-3">
                                                <span className='text-[20px] text-[#222] text-left'>How Much Time Do You Need to
                                                    Complete a PHP Development Project?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                        strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-3" class="hidden"
                                            aria-labelledby="accordion-collapse-heading-3">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Being a trustworthy PHP
                                                    development company, we always stick to our deadlines and never make
                                                    exaggerated claims. Therefore, we set a delivery schedule at the beginning
                                                    of the project and stick to it. In the rare circumstance that we miss it, we
                                                    notify the client in advance of the delay. Generally, depending on the
                                                    application's complexity, three to six months is the optimal amount of time
                                                    for development.</p>
                                            </div>
                                        </div>


                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className="w-[30%] hidden lg:block relative">
                            <div className='box-shadow rounded-lg ml-5 p-8 sidebar sticky top-[-150px]'>
                                <div className="sidebar_banner">
                                    <img src="img/page_img_4.jpg" alt="" />
                                </div>

                                <div className="sidebar_tabs mt-5">
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/full-stack">
                                            <h1 className='text-[18px] font-semibold capitalize'>full stack</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/mern-stack">
                                            <h1 className='text-[18px] font-semibold capitalize'>mern stack</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/javascript">
                                            <h1 className='text-[18px] font-semibold capitalize'>java script</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/cake-php">
                                            <h1 className='text-[18px] font-semibold capitalize'>cake php</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/laravel">
                                            <h1 className='text-[18px] font-semibold capitalize'>laravel</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/reactjs">
                                            <h1 className='text-[18px] font-semibold capitalize'>react JS</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/php">
                                            <h1 className='text-[18px] font-semibold capitalize'>PHP</h1>
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default PHP
