import React from 'react'
import Header from './Header'
import Footer from './Footer'

function DelhiNCR() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{
                backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)" }}>
                <div className="container mx-auto py-12">
                    <h1
                        className='text-center text-white text-[32px] md:text-[52px]  font-semibold heading uppercase'>
                        SEO Company in Delhi NCR
                    </h1>
                </div>
            </div>
        
            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[100%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>SEO Company in Delhi NCR</h1>
                                <p className='text-[18px] font-normal text-[#636363] '><strong>AVOID THE WAVES OF DIGITAL MARKETING, AND LET US BE YOUR DIGITAL COMPASS!</strong></p>
                                <p className='text-[18px] font-normal text-[#636363] '>In the current digital era, an increasing number of organizations are expecting web solutions to build their presence where potential clients can find them with ease. You've come to the perfect spot if you're one of the business owners who finds it hard to build an internet presence. The most reputable SEO company in Delhi NCR, Bonafide Technologies, has been providing years of happy and contented clientele with its services. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>Since the local market is becoming more competitive online, having an online presence is crucial if you want to reap the benefits of your success. And our goal is to help you achieve those success-related fruits.</p>
                                <br />
                                <h1 className='text-[32px] font-semibold my-3 heading'>Why Choose Us</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>We are among India's top providers of digital marketing services. With enduring results, we have ranked numerous clients highly in search engines. As far as online businesses are concerned, SEO is currently a powerful instrument that offers the best return on investment. You can scale your organization to any level with our assistance if you're searching for the greatest Internet marketing company.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>Bonafide Technologies is one of the best digital marketing companies in India, with a strong focus on return on investment. With our extensive knowledge, we can comprehend the needs of our clients and adjust our work accordingly. We put a lot of effort into providing top-notch services because we cherish our clients. We provide our clients with cost-effective brand marketing services, all backed by our assured digital growth.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>For all branding and digital marketing needs, our digital marketing agency is the one-stop shop. By assisting in the redirection of organic traffic to your website, we can boost your company's online sales. Our team of experts assists in achieving company goals and objectives in addition to improving your website's search engine rating.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>Being the top SEO company in India, we are skilled at creating a unique campaign plan just for your website. Our technical and content teams will optimize your website using effective and tried-and-true SEO strategies that draw in the largest possible amount of organic traffic as well as the targeted audience. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>Search engines are constantly updating their algorithms, and as a knowledgeable SEO team, we are aware of these changes and know how to best optimize your website for them. Therefore, as soon as we build a plan, we test and retest it to ensure that everything can be improved and that we don't waste any time during the campaign. </p>
                            </div>
                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>What SEO Services We Provide</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>The key component to any business's success is marketing. You can rest assured you have the best online visibility by working with a reliable web development and SEO provider. Consider us as your partner in digital marketing.</p>
                                <br />
                                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                                    <div className="flex flex-wrap justify-between">
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Search Engine Optimization
                                            </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Earn more focused online traffic with your SEO strategies thanks to our expert SEO services in Delhi's knowledge of outreach, content production, keyword research, and other areas.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Local SEO Services</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>You only need to tap into your local audience. In today's technologically savvy society, the easiest approach to reach people is to be visible to them online. Our locally focused SEO services in Delhi NCR are a failsafe way to engage your local audience.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>E-commerce SEO Services</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Increase sales to boost your revenue. For specialized e-commerce SEO services in Delhi catered to your particular company needs, work with our E-commerce SEO professional. Rise among the ranks of search results.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Social Media Marketing</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>A sort of online marketing known as "social media marketing" involves a business creating and disseminating content on various social media platforms to raise its profile and advertise its goods, services, and brand.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Pay Per Click</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Pay-per-click advertising is among the most economical ways to sell your website. When using traditional marketing, you have to pay to have an advertisement shown in front of a big audience, most of whom are not going to be interested in the product you are attempting to sell.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Content Marketing</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>A marketing tactic called content marketing involves producing content by keeping the users or viewers in mind whom you wish to draw to your website. Content marketing includes tools, ebooks, blogs, social media platforms, and online material. While content marketing piques readers' curiosity, it does not immediately promote your company's brand.</p>
                                        </div>
                                    </div>
                                </div>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Hire Us For Your SEO Services in Delhi, NCR</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Even if every SEO company in India says they offer the greatest SEO services in the country, you can easily determine whether or not they are telling the truth. Don't just believe what we say. Run "SEO company in Delhi" and "SEO agency in Delhi" search on Google. With our highly competitive and targeted keywords, we rank.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>With our tested SEO services in Delhi, you can connect with your target audience and bring in new local clients and sales. So, what are you waiting for? Contact us right away with your queries.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        )
}

export default DelhiNCR