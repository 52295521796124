import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function AuditForm() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    url: '',
    comment: '',
  });

  const [formErrors, setFormErrors] = useState({
    name: '',
    email: '',
    phone: '',
    url: '',
    comment: '',
  });

  const validateForm = () => {
    let isValid = true;
    const errors = {};

    // Validation logic for each field
    if (!formData.name.trim()) {
      errors.name = 'Name is required';
      isValid = false;
    }

    if (!formData.email.trim()) {
      errors.email = 'Email is required';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Invalid email address';
      isValid = false;
    }

    if (!formData.phone.trim()) {
      errors.phone = 'Phone is required';
      isValid = false;
    } else if (!/^\d{10}$/.test(formData.phone)) {
      errors.phone = 'Invalid phone number (10 digits)';
      isValid = false;
    }

    if (!formData.url.trim()) {
      errors.url = 'URL is required';
      isValid = false;
    }

    if (!formData.comment.trim()) {
      errors.comment = 'Comment is required';
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const response = await fetch('http://localhost:5007/api/submitAuditForm', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });

        const data = await response.json();

        if (response.ok) {
          console.log('Audit form data submitted successfully');
          alert('Form submitted successfully!');
          navigate("/");
          // Reset the form after successful submission
          setFormData({
            name: '',
            email: '',
            phone: '',
            url: '',
            comment: '',
          });
          setFormErrors({
            name: '',
            email: '',
            phone: '',
            url: '',
            comment: '',
          });
        } else {
          console.error('Failed to submit audit form data:', data.message);
        }
      } catch (error) {
        console.error('Error submitting audit form:', error);
      }
    } else {
      console.log('Form validation failed');
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setFormErrors({ ...formErrors, [e.target.name]: '' }); // Clear error on input change
  };

  return (
    <div className='form'>
      <div className="text_form text-center">
        <h1 className='text-[22px] lg:text-[46px] font-bold heading'>Free Website Audit</h1>
        <p className='text-[16px] font-medium'>Please provide the following information about you and get connected with us.</p>
      </div>

      <section className="">
        <div className="mx-auto">
          <div className="w-full bg-white rounded-lg darks:border ">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 darks:text-white">Full Name <span className='text-red-600'> *</span></label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className={`bg-gray-50 border border-[#222] text-gray-900 sm:text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500 ${formErrors.name && 'border-red-500'}`}
                    placeholder="Your Name"
                    required=""
                  />
                  {formErrors.name && <p className="text-red-500 text-sm mt-1">{formErrors.name}</p>}
                </div>

                <div>
                  <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 darks:text-white">Email Address <span className='text-red-600'> *</span></label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className={`bg-gray-50 border border-[#222] text-gray-900 sm:text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500 ${formErrors.email && 'border-red-500'}`}
                    placeholder="name@company.com"
                    required=""
                  />
                  {formErrors.email && <p className="text-red-500 text-sm mt-1">{formErrors.email}</p>}
                </div>

                <div>
                  <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900 darks:text-white">Phone <span className='text-red-600'> *</span></label>
                  <input
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="1236547896"
                    className={`bg-gray-50 border border-[#222] text-gray-900 sm:text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500 ${formErrors.phone && 'border-red-500'}`}
                    required=""
                  />
                  {formErrors.phone && <p className="text-red-500 text-sm mt-1">{formErrors.phone}</p>}
                </div>

                <div>
                  <label htmlFor="url" className="block mb-2 text-sm font-medium text-gray-900 darks:text-white">URL<span className='text-red-600'> *</span></label>
                  <input
                    type="text"
                    name="url"
                    value={formData.url}
                    onChange={handleChange}
                    placeholder="https/...."
                    className={`bg-gray-50 border border-[#222] text-gray-900 sm:text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500 ${formErrors.url && 'border-red-500'}`}
                    required=""
                  />
                  {formErrors.url && <p className="text-red-500 text-sm mt-1">{formErrors.url}</p>}
                </div>

                <div>
                  <label htmlFor="comment" className="block mb-2 text-sm font-medium text-gray-900 darks:text-white">Comment <span className='text-red-600'> *</span></label>
                  <input
                    type="text"
                    name="comment"
                    value={formData.comment}
                    onChange={handleChange}
                    placeholder="Hi there, I would like to ..."
                    className={`bg-gray-50 border border-[#222] text-gray-900 sm:text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500 ${formErrors.comment && 'border-red-500'}`}
                    required=""
                  />
                  {formErrors.comment && <p className="text-red-500 text-sm mt-1">{formErrors.comment}</p>}
                </div>

                <button
                  type="submit"
                  className="w-full bg-[#222] text-white focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center darks:bg-black darks:hover:bg-primary-700 darks:focus:ring-primary-800"
                >
                  SEND NOW
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AuditForm;
