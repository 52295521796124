import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { Link } from "react-router-dom";


function InfluencerMarketing() {
  return (
    <>
      <Header />
      <div className="container-fluid py-24 bg-cover bg-center" style={{ backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)" }}>
        <div className="container mx-auto py-12">
          <h1 className='text-center text-white text-[34px] md:text-[42px] lg:text-[56px] xl:text-[86px] font-bold heading uppercase'>Influencer Marketing</h1>
        </div>
      </div>

      <div className="container-fluid py-12">
        <div className="container mx-auto">
          <div className="flex flex-wrap md:flex-nowrap">
            <div className="w-full lg:w-[70%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
              <img src="img/Influencer-Marketing.jpg" className='w-full' alt="" />

              <div className='mt-8'>
                <h1 className='text-[32px] font-semibold my-3 heading'>Influencer Marketing</h1>
                <p className='text-[18px] font-normal text-[#636363] '>Are you trying to find the top influencer marketing company in India?</p><br />
                <p className='text-[18px] font-normal text-[#636363] '>One of the best strategies to increase brand exposure, engagement, and conversions is to collaborate with influencers. One of the top influencer marketing companies in India, Bonafide Technologies has a wealth of expertise collaborating with a diverse clientele of companies in all sectors. Our extensive Indian influencer network is handpicked to guarantee that each member is an actual person who engages and converts.</p><br />
                <p className='text-[18px] font-normal text-[#636363] '>We have gained popularity for it and rightfully do so. Use our unique combination of certified influencers, knowledge from past campaigns, and in-house technology to your brand's favor. </p><br />
                <p className='text-[18px] font-normal text-[#636363] '>We concentrate on lead generation and sales methods. Our expertise lies in creating unique and impactful viral and influencer marketing initiatives that beat the competition. From creative concept, influencer sourcing, and onboarding, to content production, optimization, and boosting, we are professionals at every stage of campaign deployment. </p>
              </div>

              <div className='my-8'>
                <h1 className='text-[32px] font-semibold my-3 heading'> What We Provide</h1>
                <p className='text-[18px] font-normal text-[#636363] '>A deliberate process is necessary for influencer marketing campaigns to be effective. </p><br />

                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                  <div className="flex flex-wrap justify-between">
                    <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                      <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Data-Driven Strategy</h1>
                      <p className='text-[18px] font-normal text-[#636363] '>We strongly believe that the data is always truthful. Our approach is based on statistics and has been demonstrated to be effective in establishing strategies and selecting influencers.</p>
                    </div>
                    <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                      <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Choose Your Influencer </h1>
                      <p className='text-[18px] font-normal text-[#636363] '>We will provide you with the approval reports and a single, easily readable document with all the influencer information. We believe these influencers would be best for your brand and are currently active in your industry.</p>
                    </div>
                    <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                      <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Secure Your Influencer</h1>
                      <p className='text-[18px] font-normal text-[#636363] '>Securing your influencers is the next step we'll take after deciding on a plan and selecting the influencers. To quickly get your content up on social networking sites, we will take care of all the negotiations and conversations with the top influencers in digital marketing.</p>
                    </div>
                    <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                      <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Launch Your Influencer Content</h1>
                      <p className='text-[18px] font-normal text-[#636363] '>The influencer content will be posted after negotiations and agreements with the influencers. As a result of social media influencer marketing, you will notice that postings show up on Facebook, Instagram, YouTube, and Twitter. This generates interest in your company from potential customers.</p>
                    </div>
                    <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                      <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Track the Results of Your Campaign</h1>
                      <p className='text-[18px] font-normal text-[#636363] '>It is insufficient to just start an influencer campaign. It all comes down to how well the campaign executes and the results it produces. We'll keep a close eye on your influencer campaign to make sure it achieves the desired results.</p>
                    </div>
                    <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                      <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Share Your Results</h1>
                      <p className='text-[18px] font-normal text-[#636363] '>Our duties to your brand extend beyond just keeping an eye on the campaign's results. We will provide you with an in-depth report on the performance of your campaign online. Depending on the results, we are also ready to make adjustments and change the techniques.</p>
                    </div>

                  </div>
                </div>

                <div className="faqs my-12">
                  <h1 className='text-[32px] font-medium my-3 heading'> FAQs</h1>
                  <div id="accordion-collapse" data-accordion="collapse">
                    <h2 id="accordion-collapse-heading-1">
                      <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200  hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                        <span className='text-[20px] text-[#222] text-left'>What Is an Influencer?</span>
                        <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                        </svg>
                      </button>
                    </h2>
                    <div id="accordion-collapse-body-1" class="hidden" aria-labelledby="accordion-collapse-heading-1">
                      <div class="p-5 border border-b-0 border-gray-200 darks:border-gray-700 darks:bg-gray-900">
                        <p className='text-[18px] font-normal text-[#636363] '>A person with a vast fan following on social media is considered an influencer. Influencers come in many forms, from "macro influencers" with millions of followers to "nano influencers" with comparatively small yet devoted followers. Influencers often set trends or are thought leaders. </p>
                      </div>
                    </div>
                    <h2 id="accordion-collapse-heading-2">
                      <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-2" aria-expanded="false" aria-controls="accordion-collapse-body-2">
                        <span className='text-[20px] text-[#222] text-left'>What Is Influencer Marketing?</span>
                        <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                        </svg>
                      </button>
                    </h2>
                    <div id="accordion-collapse-body-2" class="hidden" aria-labelledby="accordion-collapse-heading-2">
                      <div class="p-5 border border-b-0 border-gray-200 darks:border-gray-700">
                        <p className='text-[18px] font-normal text-[#636363] '>Influencer marketing is like advertising that increases brand recognition and encourages conversions using the reach and power of opinion leaders, top-tier industry specialists, and social media content providers. <br />
                          Your marketing activities will have a greater effect now and down the road if you collaborate with influencers who share your work with their followers who get influenced by their suggestions and try your products and services.  <br />
                          Influencer marketing is a crucial part of making sure that the material on your website is viewed as relevant, as search engines are always striving to enhance the user experience by presenting more relevant results.<br />
                          The strongest brands are based on trust, which takes time to acquire. Your company's chances of becoming recognized as an industry leader increase significantly when you work with influencers and implement a solid content marketing strategy.
                        </p>
                      </div>
                    </div>
                    <h2 id="accordion-collapse-heading-3">
                      <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-3" aria-expanded="false" aria-controls="accordion-collapse-body-3">
                        <span className='text-[20px] text-[#222] text-left'>Is Influencer Marketing Effective?</span>
                        <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                        </svg>
                      </button>
                    </h2>
                    <div id="accordion-collapse-body-3" class="hidden" aria-labelledby="accordion-collapse-heading-3">
                      <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                        <p className='text-[18px] font-normal text-[#636363] '>Influencer marketing relies heavily on word-of-mouth referrals, which are quite successful. According to several studies, people are more inclined to believe recommendations from other customers, friends, and respected individuals than they are to believe conventional brand messaging from businesses. </p>
                      </div>
                    </div>

                    <h2 id="accordion-collapse-heading-4">
                      <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-4" aria-expanded="false" aria-controls="accordion-collapse-body-4">
                        <span className='text-[20px] text-[#222] text-left'>Do I Have Control Over What an Influencer Says About Us?</span>
                        <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                        </svg>
                      </button>
                    </h2>
                    <div id="accordion-collapse-body-4" class="hidden" aria-labelledby="accordion-collapse-heading-4">
                      <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                        <p className='text-[18px] font-normal text-[#636363] '>When influencer matching is done right, the selected influencer will draw attention to the highlights and emphasize the advantages of your brand, service, or product. We collaborate with influencers to produce captivating, highly engaging content that will improve rather than harm people's opinions of your brand. </p>
                      </div>
                    </div>

                    <h2 id="accordion-collapse-heading-5">
                      <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-5" aria-expanded="false" aria-controls="accordion-collapse-body-5">
                        <span className='text-[20px] text-[#222] text-left'>How Influencer Marketing Works</span>
                        <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                        </svg>
                      </button>
                    </h2>
                    <div id="accordion-collapse-body-5" class="hidden" aria-labelledby="accordion-collapse-heading-5">
                      <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                        <p className='text-[18px] font-normal text-[#636363] '>Social Networks The primary social media networks that influencers typically use include YouTube, Instagram, Snapchat, Twitter, and, more recently, TikTok. However, influencers frequently cross-promote their brands on other platforms, which can result in a slightly varied following base for them.<br />
                          They strive to produce material that is both authentic and appropriate for their business, which they can then share with their fans. A brand can improve its perception, traffic, and reach by doing this. Their sincere recommendation of a product, that serves as a type of digital PR, is what gives it value.  <br />
                          To succeed, content creators distribute their excellent work, which frequently consists of videos. As a result, they may provide a variety of content for your brand, including long-lasting postings and ephemeral content.<br />
                          Depending on the platform and influencer's reach, working with them can come at a different cost. Together with additional funding options, there are several partnership tiers and techniques. This can include commission-based plans, affiliate fees, and gifting.
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
            <div className="w-[30%] hidden lg:block relative">
              <div className='box-shadow rounded-lg ml-5 p-8 sidebar sticky top-[-150px]'>
                <div className="sidebar_banner">
                  <img src="img/page_img_4.jpg" alt="" />
                </div>

                <div className="sidebar_tabs mt-5">
                  <div
                    className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                    <Link to="/social-media-marketing">
                      <h1 className='text-[18px] font-semibold capitalize'>Social Media Marketing - SMM</h1>
                    </Link>
                  </div>
                  <div
                    className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                    <Link to="/social-engines-marketing">
                      <h1 className='text-[18px] font-semibold capitalize'>Social Engines Marketing - SEM</h1>
                    </Link>
                  </div>
                  <div
                    className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                    <Link to="/guest-posting">
                      <h1 className='text-[18px] font-semibold capitalize'>Guest Posting</h1>
                    </Link>
                  </div>
                  <div
                    className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                    <Link to="/web-analytics">
                      <h1 className='text-[18px] font-semibold capitalize'>Web Analytics</h1>
                    </Link>
                  </div>
                  <div
                    className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                    <Link to="/local-seo">
                      <h1 className='text-[18px] font-semibold capitalize'>Local SEO</h1>
                    </Link>
                  </div>
                  <div
                    className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                    <Link to="/e-commerce-marketing">
                      <h1 className='text-[18px] font-semibold capitalize'>eCommerce Marketing</h1>
                    </Link>
                  </div>
                  <div
                    className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                    <Link to="/mobile-app-marketing">
                      <h1 className='text-[18px] font-semibold capitalize'>mobile-app-marketing</h1>
                    </Link>
                  </div>
                  <div
                    className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                    <Link to="/influencer-marketing">
                      <h1 className='text-[18px] font-semibold capitalize'>Influencer Marketing</h1>
                    </Link>
                  </div>
                  <div
                    className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                    <Link to="/affiliate-marketing">
                      <h1 className='text-[18px] font-semibold capitalize'>Affiliate Marketing</h1>
                    </Link>
                  </div>
                  <div
                    className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                    <Link to="/web-traffic">
                      <h1 className='text-[18px] font-semibold capitalize'>Web Traffic</h1>
                    </Link>
                  </div>
                  <div
                    className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                    <Link to="/ppc">
                      <h1 className='text-[18px] font-semibold capitalize'>PPC</h1>
                    </Link>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default InfluencerMarketing