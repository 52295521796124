import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { Link } from "react-router-dom";

function BusinessListingSitesinIndia() {
    return (
        <>
            <Header />
            <div className="container-fluid p-3 lg:p-0">
                <div className="container mx-auto py-12">
                    <h1 className='text-[28px] md:text-[42px] font-medium heading  mb-8 '>Free Business Listing Sites in India in 2024
                    </h1>
                    <div className="flex flex-wrap md:flex-nowrap pt-12 border-t-2">
                        <div className="w-full lg:w-[70%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <div className="text_contenr pt-3 pb-8">
                                <p className='text-[18px] font-normal text-[#636363] '>We used to save business facts in a diary a few years before the Internet was invented. We have kept business information on business listing sites since the Internet entered our lives.</p><br />
                                <p className='text-[18px] font-normal text-[#636363] '>The days of looking up companies in notebooks or diaries are long gone. Business listing websites are publicly available to all users. Today, anyone can quickly and easily find information about a business by visiting a business directory website.</p> <br />
                                <p className='text-[18px] font-normal text-[#636363] '>People search on company directory websites these days. Don't worry if your company isn't included on these sites. You can list your business on more than 200 accessible business listing sites in India using the links in this article and tips on using them to gain better results.</p> <br />

                                <h1 className='text-[32px] font-medium my-3 heading'>What Are Business Listing Sites?</h1>
                                <br />
                                <p className='text-[18px] font-normal text-[#636363] '>Business listing is an online directory that lists firms and allows them to set up informational profiles like their name, address, phone number, website, and other information. These listings aim to improve a business's online visibility and local search exposure. </p> <br />
                                <p className='text-[18px] font-normal text-[#636363] '>However, you might be wondering how these listings can improve visibility. These high-PR company listing websites are now a standard, modern way for companies to advertise their goods and services. </p> <br />
                                <p className='text-[18px] font-normal text-[#636363] '>One can find just about any type of business or service on these local business listing websites. The best thing is that your listing will appear as the top result when someone searches for what you provide, considering all other listings. </p> <br />
                                <p className='text-[18px] font-normal text-[#636363] '>Giving your target audience this knowledge builds trust.</p> <br />

                                <h1 className='text-[32px] font-medium my-3 heading'>How to Do Business Listing?</h1>
                                <br />
                                <p className='text-[18px] font-normal text-[#636363] '>There are variations in the procedure for listing a business on various free business listing platforms. You can take a few different approaches based on your requirements and preferences for the specific website you wish to list with. </p> <br />
                                <p className='text-[18px] font-normal text-[#636363] '>We'll now review the primary methods to ensure a seamless submission process for your details to these free business directory sites in India.</p> <br />
                                <ul className='bg-[#f4dacd70] p-8'>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Sign Up</strong>Registering a business on a well-known local directory website, such as Google or Grotal, might seem intimidating as they have all the information about our firm (website, address, etc.). However, you must quickly work through their easy-to-use interface to proceed.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Verification</strong>After completing registration on any business listing website, you will most likely receive a confirmation email with a link. The email shouldn't take more than ten minutes to arrive in your inbox. Check your spam box and click the confirmation link if you do not see them in your inbox.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Fill in the Details</strong>You'll be taken to a page where you can enter all the information about your company, including its name, address, website, phone number, year of establishment, employee count, turnover, and other details, once you've completed the verification process on any of the business listing websites. Make sure you accurately enter all the information.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Link Social Accounts</strong>You may also need to link your social media accounts to many websites offering free company listings. This can be done via Facebook and Twitter to keep people informed about what's happening with you and your business and other things like promotions they might find interesting!</li>
                                    <br />
                                </ul>

                                <h1 className='text-[32px] font-medium my-3 heading'>Why Do FREE Business Listing Sites in India Have Importance in SEO?</h1><br />
                                <p className='text-[18px] font-normal text-[#636363] '>How often have you encountered a Google business listing devoid of reviews, photographs, or information? Would you feel confident enough to proceed further? Maybe not. Like an online business card, business listings provide local businesses with the necessary exposure. Let's explore its advantages below:</p> <br />
                                <ul className='bg-[#f4dacd70] p-8'>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Helps in Developing Local SEO:</strong> Search engines such as Google give weight to local results. Your company's online presence, particularly on sites like Google My Business, will increase awareness when nearby residents look for similar services. Therefore, having a local business listing increases the chances that people will find you locally.
                                    </li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Opportunities for Backlinks: </strong>A business listing that links to your website might produce high-quality backlinks, raising your SEO rating.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Evident NAP Data:</strong>Because search engines view consistent Name, Address, and Phone Number (NAP) across listings as legitimate information, it fosters confidence.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Customer feedback affects rankings: </strong>A detailed listing with the latest data, testimonials, and images attracts attention and converts visitors into paying clients.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Cost-effective marketing: </strong>Using an accessible business listing is an inexpensive marketing tactic that provides beneficial exposure without spending additional costs.
                                    </li>
                                    <br />
                                </ul>
                                <p className='text-[18px] font-normal text-[#636363] '>Local citations are revolutionary for small companies. Having your business appear at the top of local search results can bring in a sizable amount of business. It's a valuable strategy for increasing your exposure to your target market and attracting prospective clients constantly looking for products or services in your region.</p> <br />

                                <h1 className='text-[32px] font-medium my-3 heading'>How Do You Choose Business Listing Sites?
                                </h1><br />
                                <p className='text-[18px] font-normal text-[#636363] '>There are several things you should do before choosing digital business listing directories.</p> <br />
                                <ul className='bg-[#f4dacd70] p-8'>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>1.Check Spam Score:
                                    </strong>Let's say you are looking for information on a website. There are a lot of promotional adverts on the website. Your experience will be miserable as a result. Sometimes, it's impossible to find the information you require. You immediately jump to the next webpage. Don't register on these websites.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>2. Check the Domain Authority:
                                    </strong>Choose a website that has a high level of domain authority. Acquiring a backlink from a website that lists businesses will provide domain authority. With various tools, you can quickly assess the domain authority of any website. This article includes a list of company listing websites along with domain authority.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>3. Check the Link Type:
                                    </strong>Not every Indian free listing website offers linking capabilities. Thus, it's essential to balance Do-Follow and No-Follow links.</li>
                                    <br />
                                </ul>

                                <h1 className='text-[32px] font-medium my-3 heading'>List of the Top 300 Free Business Listing Sites in India</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>You need backlinks from high-DA websites to rank higher on Google. A list of free business listing websites with a high DA is provided below. Google will rank you higher if you register your business on these platforms.</p> <br />
                            </div>
                            <div class="relative overflow-x-auto shadow-md sm:rounded-lg ">
                                <table class="w-full text-sm text-left rtl:text-right text-gray-500 darks:text-gray-400">
                                    <thead
                                        class="text-xs text-gray-700 uppercase bg-gray-50 darks:bg-gray-700 darks:text-gray-400">
                                        <tr>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                SNo.
                                            </th>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                Business Listing Sites in India
                                            </th>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                DA
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>1</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.google.com/business"><span>https://www.google.com/business</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>100</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>2</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://sites.google.com/"><span>sites.google.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>97</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>3</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://trustpilot.com/"><span>trustpilot.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>93</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>4</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://foursquare.com/"><span>foursquare.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>92</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>5</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://crunchbase.com/"><span>crunchbase.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>91</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>6</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://mapquest.com/"><span>mapquest.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>89</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>7</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://angel.co/"><span>angel.co</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>88</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>8</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://profiles.yourstory.com/"><span>profiles.yourstory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>87</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>9</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://in.viadeo.com/"><span>in.viadeo.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>85</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>10</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://dev.to/"><span>dev.to</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>83</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>11</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://manta.com/"><span>manta.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>81</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>12</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://apsense.com/"><span>apsense.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>80</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>13</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://bizcommunity.com/"><span>bizcommunity.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>79</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>14</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://biz.prlog.org/"><span>biz.prlog.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>79</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>15</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://sulekha.com/"><span>sulekha.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>78</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>16</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://zoominfo.com/"><span>zoominfo.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>77</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>17</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://tradeindia.com/"><span>tradeindia.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>76</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>18</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://owler.com/"><span>owler.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>76</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>19</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://directory.justlanded.com/"><span>directory.justlanded.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>76</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>20</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://sitejabber.com/"><span>sitejabber.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>75</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>21</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://gust.com/"><span>https://gust.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>74</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>22</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://f6s.com/"><span>f6s.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>73</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>23</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://indiamart.com/"><span>indiamart.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>73</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>24</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://yell.com/"><span>yell.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>73</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>25</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://e27.co/"><span>e27.co</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>72</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>26</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://ijnet.org/en"><span>ijnet.org/en</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>72</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>27</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://afaqs.com/"><span>afaqs.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>71</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>28</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://enetget.com/"><span>enetget.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>69</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>29</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://yellowpages.webindia123.com/"><span>yellowpages.webindia123.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>69</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>30</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://vator.tv/"><span>vator.tv</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>67</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>31</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://dead.net/"><span>dead.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>67</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>32</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://thetoptens.com/"><span>thetoptens.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>66</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>33</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://mapsofindia.com/whitepages"><span>mapsofindia.com/whitepages</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>65</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>34</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://mouthshut.com/"><span>mouthshut.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>64</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>35</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://ec21.com/"><span>ec21.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>63</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>36</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://reviewcentre.com/"><span>reviewcentre.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>63</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>37</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://lacartes.com/"><span>lacartes.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>63</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>38</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://elocal.com/"><span>elocal.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>63</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>39</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://brownbook.net/"><span>brownbook.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>62</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>40</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://justdial.com/"><span>justdial.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>62</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>41</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://storeboard.com/"><span>storeboard.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>62</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>42</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://ezilon.com/"><span>ezilon.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>62</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>43</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://yext.com/"><span>yext.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>61</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>44</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://spoke.com/"><span>spoke.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>59</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>45</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://exportersindia.com/"><span>exportersindia.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>58</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>46</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://communitywalk.com/"><span>communitywalk.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>58</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>47</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://prsync.com/"><span>prsync.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>58</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>48</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://releasewire.com/"><span>releasewire.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>58</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>49</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://basenotes.net/"><span>basenotes.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>57</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>50</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://tradekey.com/"><span>tradekey.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>57</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>51</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.kenyanz.com/"><span>https://www.kenyanz.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>57</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>52</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://startupxplore.com/"><span>startupxplore.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>56</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>53</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://yellowbot.com/"><span>yellowbot.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>56</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>54</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://goodfirms.co/"><span>goodfirms.co</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>56</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>55</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://cybo.com/"><span>cybo.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>56</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>56</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://indiacom.com/"><span>indiacom.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>57</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://trepup.com/"><span>trepup.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>58</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://elcraz.com/"><span>elcraz.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>59</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://index.co/"><span>index.co</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>60</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://craft.co/"><span>craft.co</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>55</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>61</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://loclisting.com/"><span>loclisting.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>54</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>62</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://zumvu.com/"><span>zumvu.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>54</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>63</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://articles.seoforums.me.uk/"><span>articles.seoforums.me.uk</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>54</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>64</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://webestools.com/"><span>webestools.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>54</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>65</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://everplaces.com/"><span>everplaces.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>54</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>66</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://listography.com/"><span>listography.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>54</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>67</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.techdirectory.io/"><span>https://www.techdirectory.io</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>54</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>68</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://callupcontact.com/"><span>callupcontact.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>53</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>69</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://loclists.com/"><span>loclists.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>53</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>70</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://showmelocal.com/"><span>showmelocal.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>53</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>71</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://startus.cc/"><span>startus.cc</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>53</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>72</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://40billion.com/"><span>40billion.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>52</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>73</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://biz.burrp.com/"><span>biz.burrp.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>51</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>74</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://in.enrollbusiness.com/"><span>in.enrollbusiness.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>51</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>75</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://craftfoxes.com/"><span>craftfoxes.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>51</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>76</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://parkbench.com/"><span>https://parkbench.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>51</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>77</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://globalcatalog.com/"><span>https://globalcatalog.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>51</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>78</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://locbusiness.com/"><span>locbusiness.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>50</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>79</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.findit.com/"><span>https://www.findit.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>50</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>80</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.askmap.net/"><span>http://www.askmap.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>50</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>81</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://yoomark.com/index.php"><span>yoomark.com/index.php</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>50</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>82</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://kudzu.com/"><span>kudzu.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>49</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>83</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://freeadshare.com/"><span>freeadshare.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>49</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>84</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://yelloyello.com/"><span>yelloyello.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>48</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>85</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://clickindia.com/"><span>clickindia.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>48</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>86</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://briefingwire.com/"><span>briefingwire.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>48</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>87</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://hostsearch.com/"><span>hostsearch.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>48</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>88</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://2findlocal.com/"><span>2findlocal.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>47</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>89</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://traderscity.com/"><span>traderscity.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>47</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>90</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.place123.net/"><span>http://www.place123.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>47</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>91</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://appfutura.com/"><span>appfutura.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>46</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>92</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://csslight.com/"><span>csslight.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>46</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>93</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://citysquares.com/"><span>citysquares.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>46</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>94</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://expressbusinessdirectory.com/"><span>expressbusinessdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>46</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>95</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.cgmimm.com/"><span>https://www.cgmimm.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>45</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>96</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://zaubacorp.com/"><span>zaubacorp.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>44</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>97</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://find-us-here.com/"><span>find-us-here.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>44</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>98</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://foodpanda.in/"><span>foodpanda.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>43</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>99</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://bresdel.com/"><span>bresdel.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>43</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>100</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://businesslistingplus.com/"><span>https://businesslistingplus.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>43</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>101</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.anibookmark.com/"><span>https://www.anibookmark.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>43</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>102</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://indiaonline.in/"><span>indiaonline.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>42</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>103</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://siftery.com/"><span>siftery.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>42</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>104</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://asklaila.com/"><span>asklaila.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>42</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>105</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://surfindia.com/"><span>surfindia.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>42</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>106</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://linqto.me/"><span>linqto.me</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>42</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>107</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.consultants500.com/"><span>https://www.consultants500.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>42</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>108</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://bunity.com/"><span>bunity.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>41</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>109</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://twenty19.com/"><span>twenty19.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>41</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>110</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://completed.com/"><span>completed.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>41</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>111</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://gostartups.in/"><span>gostartups.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>41</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>112</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://coactivate.org/"><span>coactivate.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>41</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>113</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://hotfrog.in/"><span>hotfrog.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>41</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>114</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.go4worldbusiness.com/"><span>https://www.go4worldbusiness.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>41</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>115</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.croozi.com/"><span>https://www.croozi.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>41</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>116</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://tupalo.net/"><span>tupalo.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>40</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>117</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://igotbiz.com/"><span>igotbiz.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>40</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>118</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://thenewsfunnel.com/"><span>thenewsfunnel.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>40</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>119</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://business-startpage.com/"><span>business-startpage.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>40</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>120</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://dotnetfunda.com/"><span>dotnetfunda.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>40</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>121</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.nextbizthing.com/"><span>https://www.nextbizthing.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>40</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>122</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://grotal.com/"><span>grotal.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>39</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>123</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://fullhyderabad.com/"><span>fullhyderabad.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>39</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>124</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://qutee.com/"><span>qutee.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>39</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>125</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://tradekeyindia.com/"><span>tradekeyindia.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>39</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>126</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://directory.ac/"><span>directory.ac</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>38</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>127</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://macro.market/"><span>macro.market</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>38</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>128</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://adsansar.com/"><span>adsansar.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>38</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>129</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://knpbundles.com/"><span>knpbundles.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>38</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>130</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://wowcity.com/"><span>wowcity.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>37</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>131</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://tuugo.biz/"><span>tuugo.biz</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>37</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>132</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://dealerbaba.com/"><span>dealerbaba.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>37</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>133</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://freelistingindia.in/"><span>freelistingindia.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>37</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>134</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://creativematch.com/directory"><span>https://creativematch.com/directory</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>37</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>135</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.eliteservicesnetwork.com/"><span>https://www.eliteservicesnetwork.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>37</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>136</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.citybyapp.com/"><span>https://www.citybyapp.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>37</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>137</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://tuugo.co.uk/"><span>tuugo.co.uk</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>36</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>138</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://ogoing.com/"><span>ogoing.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>36</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>139</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://fundoodata.com/"><span>fundoodata.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>36</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>140</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://addonbiz.com/"><span>addonbiz.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>36</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>141</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.peeplocal.com/"><span>https://www.peeplocal.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>36</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>142</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://zordis.com/"><span>zordis.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>35</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>143</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://akama.com/"><span>akama.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>35</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>144</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://orapages.com/"><span>orapages.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>35</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>145</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://freeadstime.org/"><span>freeadstime.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>35</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>146</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://in.locanto.asia/"><span>in.locanto.asia</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>35</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>147</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://pitnit.com/"><span>pitnit.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>35</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>148</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://trueen.com/"><span>https://trueen.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>35</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>149</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://yalwa.in/"><span>yalwa.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>34</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>150</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://zizics.com/"><span>zizics.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>34</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>151</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://cityinsider.com/"><span>cityinsider.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>34</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>152</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://indianyellowpages.com/"><span>indianyellowpages.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>34</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>153</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://nicefirm.com/"><span>nicefirm.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>34</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>154</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://bizinfe.com/"><span>https://bizinfe.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>34</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>155</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://addyp.com/"><span>addyp.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>33</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>156</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.bizofit.com/"><span>https://www.bizofit.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>33</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>157</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://designfirms.org/"><span>designfirms.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>32</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>158</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.zipleaf.com/"><span>https://www.zipleaf.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>32</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>159</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.bizbangboom.com/"><span>https://www.bizbangboom.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>32</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>160</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://indiabook.com/"><span>indiabook.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>31</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>161</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://indiabizclub.com/"><span>indiabizclub.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>31</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>162</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://bizsheet.com/"><span>bizsheet.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>31</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>163</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://tuugo.in/"><span>tuugo.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>31</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>164</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.hrbusinesslive.com/"><span>https://www.hrbusinesslive.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>31</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>165</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://extract.co/"><span>extract.co</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>30</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>166</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://wadline.com/"><span>wadline.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>30</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>167</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://tucando.com/"><span>tucando.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>30</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>168</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://bizcyclone.com/"><span>bizcyclone.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>30</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>169</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.localhomeservicepros.com/"><span>http://www.localhomeservicepros.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>30</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>170</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://companylist.org/"><span>companylist.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>30</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>171</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://credibase.com/"><span>credibase.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>29</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>172</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://eindiabusiness.com/"><span>eindiabusiness.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>29</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>173</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://adfreeposting.com/"><span>adfreeposting.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>29</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>174</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://indiacatalog.com/"><span>indiacatalog.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>29</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>175</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://in.99nearby.com/"><span>in.99nearby.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>29</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>176</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.maharashtradirectory.com/"><span>https://www.maharashtradirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>29</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>177</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.directorytogoto.com/"><span>https://www.directorytogoto.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>29</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>178</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.businesssoftwarehelp.com/"><span>https://www.businesssoftwarehelp.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>29</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>179</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://zycon.com/"><span>zycon.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>180</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://cylex.in/"><span>cylex.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>181</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://localbiznetwork.com/"><span>localbiznetwork.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>182</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://ncrcities.com/"><span>ncrcities.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>183</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://indianindustry.com/"><span>indianindustry.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>184</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://jantareview.com/"><span>jantareview.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>185</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://routeandgo.net/"><span>routeandgo.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>186</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://startupfreak.com/"><span>startupfreak.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>187</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://localbiznetwork.com/india"><span>localbiznetwork.com/india</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>188</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.countrypwr.com/"><span>https://www.countrypwr.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>189</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://dialindia.com/"><span>dialindia.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>27</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>190</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://vandanayellowpages.com/"><span>vandanayellowpages.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>27</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>191</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://bigwigbiz.com/"><span>bigwigbiz.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>27</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>192</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://findinall.com/"><span>findinall.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>27</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>193</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://mysheriff.co.in/"><span>mysheriff.co.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>194</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://true2ourselves.com/"><span>true2ourselves.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>195</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://onefdh.com/"><span>onefdh.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>196</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://a4add.com/"><span>a4add.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>197</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://gujaratdirectory.com/"><span>gujaratdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>198</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://yelu.in/"><span>yelu.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>199</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://forglobal.com/"><span>https://forglobal.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>200</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://justfindus.in/"><span>justfindus.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>201</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://infoline.com/"><span>infoline.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>202</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.citymapia.com/"><span>https://www.citymapia.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>203</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://yellowpages.in/"><span>yellowpages.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>24</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>204</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://bizmartindia.com/"><span>bizmartindia.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>24</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>205</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://findbestseo.com/"><span>findbestseo.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>24</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>206</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://sholay.in/"><span>sholay.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>24</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>207</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://jimyellowpages.com/"><span>jimyellowpages.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>24</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>208</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://japanautopages.com/"><span>japanautopages.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>24</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>209</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://nextbizdoor.com/"><span>nextbizdoor.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>24</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>210</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.dueclix.com/"><span>https://www.dueclix.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>24</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>211</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://classifiedsfactor.com/"><span>classifiedsfactor.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>24</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>212</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://bgyellowpages.com/"><span>bgyellowpages.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>213</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://jsdirectory.com/"><span>jsdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>214</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://adoak.com/"><span>adoak.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>215</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://findermaster.com/"><span>findermaster.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>216</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://giganticlist.com/"><span>giganticlist.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>217</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://gidonline.com/"><span>gidonline.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>218</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://rajb2b.com/"><span>rajb2b.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>219</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://infoisinfo.co.in/"><span>infoisinfo.co.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>220</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://speedylocal.com/"><span>https://speedylocal.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>221</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://businesses.avidlocals.com/"><span>http://businesses.avidlocals.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>222</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.ilocatelocal.com/"><span>https://www.ilocatelocal.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>223</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://poweredindia.com/"><span>poweredindia.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>224</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://wallclassifieds.com/"><span>wallclassifieds.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>225</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://buckeyeads.com/"><span>buckeyeads.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>226</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://indyapages.com/"><span>indyapages.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>227</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://onestopb2b.com/"><span>onestopb2b.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>228</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://bharathlisting.com/"><span>bharathlisting.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>229</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://finest4.com/"><span>finest4.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>230</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://ourncr.com/"><span>ourncr.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>231</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://ncrpages.in/"><span>ncrpages.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>232</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.punnaka.com/"><span>https://www.punnaka.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>233</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.onlinebusinessoffice.com/"><span>https://www.onlinebusinessoffice.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>234</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://placereference.com/"><span>placereference.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>235</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://busylisting.com/"><span>busylisting.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>236</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://h1ad.com/"><span>h1ad.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>237</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://bizzduniya.com/"><span>bizzduniya.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>238</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://advertiseera.com/"><span>advertiseera.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>239</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.storycrews.com/"><span>https://www.storycrews.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>240</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.ecity.com/"><span>https://www.ecity.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>241</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://imvendor.com/"><span>imvendor.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>242</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://bananadirectories.com/"><span>bananadirectories.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>243</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://tradesights.com/"><span>tradesights.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>244</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://webbaniya.com/"><span>webbaniya.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>245</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://callme.co.in/"><span>callme.co.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>246</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://altaindia.com/"><span>altaindia.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>247</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://tubecities.com/"><span>tubecities.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>248</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://ceramicdirectory.com/"><span>ceramicdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>249</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://imclassified.com/"><span>imclassified.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>250</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://vcsdata.com/"><span>vcsdata.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>251</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.yplocal.com/"><span>https://www.yplocal.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>252</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://indisearch.com/"><span>indisearch.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>253</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://indiafinder.in/"><span>indiafinder.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>254</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://indialife.com/"><span>indialife.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>255</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://aaspaas.com/"><span>aaspaas.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>256</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://startlocal.in/"><span>startlocal.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>257</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://jetpack.pro/"><span>jetpack.pro</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>258</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://addsera.in/"><span>addsera.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>259</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://sharelocalbusiness.com/"><span>sharelocalbusiness.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>260</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://indiarubberdirectory.com/"><span>indiarubberdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>261</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.vanik.com/"><span>http://www.vanik.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>262</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.indocanadianbusinesspages.com/"><span>https://www.indocanadianbusinesspages.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>263</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://artmsg.com/"><span>artmsg.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>264</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://localdiaries.in/"><span>localdiaries.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>265</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://beautybythebatch.com/"><span>beautybythebatch.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>266</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://superadpost.com/"><span>superadpost.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>267</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://biz15.co.in/"><span>biz15.co.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>268</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://israel-india.info/"><span>israel-india.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>269</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://clickblue.in/"><span>clickblue.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>270</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.karosearch.com/"><span>https://www.karosearch.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>271</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.thelululist.com/"><span>https://www.thelululist.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>272</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://yellowpages.goodlinksindia.com/"><span>yellowpages.goodlinksindia.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>273</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://eximdata.com/"><span>eximdata.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>274</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://fairsearches.com/"><span>fairsearches.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>275</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://localfrog.in/"><span>localfrog.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>276</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://gurgaonbazaar.co.in/"><span>gurgaonbazaar.co.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>277</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://channeliser.com/"><span>channeliser.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>278</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://greaternoidadirectory.com/"><span>greaternoidadirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>279</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://findmetop.com/"><span>findmetop.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>280</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://lemonise.com/"><span>lemonise.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>281</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.housetohomepros.com/"><span>https://www.housetohomepros.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>282</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.242hub.com/"><span>https://www.242hub.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>283</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://findbazaar.in/"><span>findbazaar.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>284</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://clickedindia.net/"><span>clickedindia.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>285</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://infoindiaa.com/"><span>infoindiaa.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>286</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://bigstartups.in/"><span>bigstartups.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>287</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://yellez.com/"><span>yellez.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>288</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://thelocalbz.com/"><span>thelocalbz.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>289</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://indiaaclick.com/"><span>indiaaclick.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>290</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://tuffclicks.com/"><span>tuffclicks.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>291</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.cityyap.com/"><span>https://www.cityyap.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>292</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.helloteachers.in/"><span>https://www.helloteachers.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>293</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.bicycledirectory.com/"><span>https://www.bicycledirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>294</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://local.gocommercially.com/"><span>https://local.gocommercially.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>295</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://nettica.co/"><span>nettica.co</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>296</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://buysellcart.in/"><span>buysellcart.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>297</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://rectanglead.com/"><span>rectanglead.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>298</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://entireindia.com/"><span>entireindia.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>299</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://thelinkindia.com/"><span>thelinkindia.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>300</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://indiaexportnews.com/"><span>indiaexportnews.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>301</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://indianindustriesdirectory.com/"><span>indianindustriesdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>302</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://connectfirms.com/"><span>connectfirms.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>303</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://indiaontrade.com/"><span>indiaontrade.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>304</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://businesspkr.com/"><span>businesspkr.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>305</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://delhi-ncr.dialb2c.com/"><span>delhi-ncr.dialb2c.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>306</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://mozopages.com/"><span>mozopages.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>307</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.bookkeepersdirectory.com/"><span>http://www.bookkeepersdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>308</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.hmongsearch.com/"><span>http://www.hmongsearch.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>14</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>309</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://indiamoz.com/"><span>indiamoz.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>310</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://serviceindia.com/"><span>serviceindia.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>311</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://az-india.com/"><span>az-india.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>312</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://allindiayellowpage.com/"><span>allindiayellowpage.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>313</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://directoryxp.com/"><span>directoryxp.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>314</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://indiabusinessenquiry.com/"><span>indiabusinessenquiry.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>315</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://bestdamndirectory.com/"><span>bestdamndirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>316</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.directory.best/"><span>http://www.directory.best</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>317</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.blacklinkus.com/"><span>https://www.blacklinkus.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>318</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.campingiq.com/"><span>https://www.campingiq.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>319</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://businessplaces.in/"><span>businessplaces.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>320</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://daccanomics.com/"><span>daccanomics.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>321</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://yellowpages99.com/"><span>yellowpages99.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>322</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://indiabizdir.com/"><span>indiabizdir.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>323</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://lemonhunt.com/"><span>lemonhunt.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>324</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://directory.mandsauronline.com/"><span>directory.mandsauronline.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>325</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://mrrichman.com/"><span>mrrichman.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>326</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://primebacklinks.com/"><span>primebacklinks.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>327</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://maharashtrabusinesspages.com/"><span>maharashtrabusinesspages.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>328</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://discoverthepages.com/"><span>discoverthepages.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>329</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://searchrunners.com/"><span>searchrunners.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>330</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://edustudy.org/"><span>edustudy.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>331</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://locpages.com/"><span>locpages.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>332</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://exportersindia.net/"><span>exportersindia.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>333</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://dizylocal.com/"><span>dizylocal.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>334</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.breaie.com/"><span>https://www.breaie.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>335</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.askjaynee.com/"><span>http://www.askjaynee.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>336</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.solartize.com/"><span>https://www.solartize.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>337</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.qavendors.com/"><span>https://www.qavendors.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>338</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.edypa.in/"><span>http://www.edypa.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>12</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>339</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://indiabusinessindex.com/"><span>indiabusinessindex.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>340</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://cheapwb.com/"><span>cheapwb.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>341</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://attamarketonline.com/"><span>attamarketonline.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>342</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://toplocal.in/"><span>toplocal.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>343</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://linkcenter.com/"><span>linkcenter.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>344</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.indocanonline.com/"><span>https://www.indocanonline.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>345</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.capecoddigest.com/"><span>https://www.capecoddigest.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>346</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://businessmention.in/"><span>businessmention.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>347</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://addbizinfo.com/"><span>addbizinfo.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>11</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>348</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://opendi.in/"><span>opendi.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>349</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://a1bizdirectory.com/"><span>a1bizdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>350</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://goodlinksindia.in/"><span>goodlinksindia.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>351</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://getdoortags.com/"><span>getdoortags.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>352</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://searchinfo.in/"><span>searchinfo.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>353</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://mybusinesslive.in/"><span>mybusinesslive.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>354</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.mycleaningbid.com/india"><span>http://www.mycleaningbid.com/india</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>355</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://in.fairface.com/"><span>https://in.fairface.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>356</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.dobusinesslocal.com/"><span>https://www.dobusinesslocal.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>357</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.plrtherapy.com/"><span>http://www.plrtherapy.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>358</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.truthinx.com/"><span>http://www.truthinx.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>10</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>359</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://checkinfo.in/"><span>checkinfo.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>360</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://adroitdirectory.com/"><span>adroitdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>361</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://callfor.co.in/business-directory"><span>callfor.co.in/business-directory</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>362</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://adnify.com/"><span>adnify.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>363</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://indiaranking.com/"><span>indiaranking.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>364</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://mybusinesslisting.in/"><span>mybusinesslisting.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>365</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://zocal.in/"><span>zocal.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>366</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://lungidude.com/"><span>lungidude.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>367</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://excluu.com/"><span>excluu.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>368</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.dentalbusinessdirectory.com/"><span>https://www.dentalbusinessdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>369</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.massagefinders.com/"><span>https://www.massagefinders.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>370</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.igottime.net/"><span>http://www.igottime.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>371</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.auslankabridge.com/"><span>http://www.auslankabridge.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>372</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.southeastvalley.com/"><span>http://www.southeastvalley.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>373</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.directory.international/"><span>https://www.directory.international</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>374</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://bindia.com/"><span>bindia.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>375</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://healthcare.lonestarmedicalsolutions.com/"><span>https://healthcare.lonestarmedicalsolutions.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>376</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.yohsocialmedia.com/"><span>https://www.yohsocialmedia.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>377</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://indiasewa.com/"><span>indiasewa.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>378</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://bestofbazaar.com/"><span>bestofbazaar.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>379</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.gbusinesslisting.com/"><span>https://www.gbusinesslisting.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>5</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>380</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://companiesindelhi.com/"><span>companiesindelhi.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>4</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>381</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://kezaa.in/"><span>kezaa.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>4</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>382</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://gurgaononcall.com/"><span>gurgaononcall.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>4</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>383</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.costaricalocations.com/"><span>https://www.costaricalocations.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>3</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>384</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://bestincity.online/"><span>https://bestincity.online</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>3</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>385</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://indiabusinesslink.com/"><span>indiabusinesslink.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>2</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>386</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://kivirose.com/"><span>kivirose.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>1</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <h1 className='text-[32px] font-medium my-3 heading mt-12'>Why You Should Start Using Business Listing Sites</h1>
                            <p className='text-[18px] font-normal text-[#636363] '>The SEO community has noticed a change in the importance of business listing sites. Although they were once quite valuable, their significance has gradually decreased. However, they still have some advantages, particularly in certain situations like local SEO.</p> <br />
                            <p className='text-[18px] font-normal text-[#636363] '>These platforms are still used, especially by Indian business listing sites, to obtain free backlinks. While artificial backlinks might not be as valuable as genuine ones, they can raise SERP ranks. Using Indian business listing sites can improve internet visibility, draw in local clients, and raise brand awareness—all of which can support the expansion of a global company.</p> <br />
                            <p className='text-[18px] font-normal text-[#636363] '>The allure of business listing websites is their capacity to provide free advertising, enabling companies to advertise without worrying about breaking the bank. </p> <br />
                            <p className='text-[18px] font-normal text-[#636363] '>Businesses can access prospective traffic sources and increase their visibility in local citations by listing on these platforms, which will help them attract more local customers.</p> <br />

                            <h1 className='text-[32px] font-medium my-3 heading'>Conclusion</h1><br />
                            <p className='text-[18px] font-normal text-[#636363] '>Without question, one of the best strategies for drawing traffic and high-quality backlinks to your website is to use business listing sites. Take precaution to avoid sharing too much, as doing so could result in penalties. For the best results, make thoughtful use of the platforms mentioned above! Share your preferred platforms for content distribution as well. We hope this information is helpful to you. </p>

                            <div className="faqs mb-12">
                                <h1 className='text-[32px] font-medium my-3 heading'>FAQs</h1>
                                <div id="accordion-collapse" data-accordion="collapse">
                                    <h2 id="accordion-collapse-heading-1">
                                        <button type="button"
                                            class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200  hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                            data-accordion-target="#accordion-collapse-body-1" aria-expanded="true"
                                            aria-controls="accordion-collapse-body-1">
                                            <span className='text-[20px] text-[#222] text-left'>What is Local Citation?</span>
                                            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                    strokeWidth="2" d="M9 5 5 1 1 5" />
                                            </svg>
                                        </button>
                                    </h2>
                                    <div id="accordion-collapse-body-1" class="hidden"
                                        aria-labelledby="accordion-collapse-heading-1">
                                        <div
                                            class="p-5 border border-b-0 border-gray-200 darks:border-gray-700 darks:bg-gray-900">
                                            <p className='text-[18px] font-normal text-[#636363] '>When your company is mentioned online in a business magazine, business listing website, or business directory submission website, it is known as a local citation. A brand can reach out to potential customers with local citations. Advertising your company locally and appearing in local search results when you use local citations is simple.<br />
                                                Numerous websites allow for local citations. With a high domain authority, most Indian citation sites accept do-follow backlinks.<br />
                                                In local search results, local SEO can improve a company's ranking. Listing your website or company on local citation sites is the best approach to increasing your local SEO.<br />
                                                Local citations were formerly a tried-and-true SEO tactic, but they are no longer a significant SEO component.<br />
                                                Backlinks from local citations might boost your website's domain authority (DA) and local search engine optimization (SEO).
                                            </p>
                                        </div>
                                    </div>
                                    <h2 id="accordion-collapse-heading-2">
                                        <button type="button"
                                            class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                            data-accordion-target="#accordion-collapse-body-2" aria-expanded="false"
                                            aria-controls="accordion-collapse-body-2">
                                            <span className='text-[20px] text-[#222] text-left'>Is The Google My Business Listing Free?</span>
                                            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                    strokeWidth="2" d="M9 5 5 1 1 5" />
                                            </svg>
                                        </button>
                                    </h2>
                                    <div id="accordion-collapse-body-2" class="hidden"
                                        aria-labelledby="accordion-collapse-heading-2">
                                        <div class="p-5 border border-b-0 border-gray-200 darks:border-gray-700">
                                            <p className='text-[18px] font-normal text-[#636363] '>Of course! Your golden ticket to the internet world is the "Google My Business" listing. The best thing is that it's free! You may include your company's information, put eye-catching images up, and even engage with clients by having them leave reviews. Therefore, it's free and essential for any business looking to build an online reputation.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="w-[30%] hidden lg:block relative">
                            <div className='box-shadow rounded-lg ml-5 p-8 sidebar sticky top-[-150px]'>
                                <div className="sidebar_banner">
                                    <img src="img/page_img_4.jpg" alt="" />
                                </div>

                                <div className="sidebar_tabs mt-5">
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/profile-creation-sites-list">
                                            <h1 className='text-[18px] font-semibold capitalize'>Top High Do Follow Profile Creation
                                                Sites List</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/image-submission-sites-list">
                                            <h1 className='text-[18px] font-semibold capitalize'>image-submission-sites-list</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/classified-ads-websites">
                                            <h1 className='text-[18px] font-semibold capitalize'>classified-ads-websites</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/instant-approval-article-submissions-sites-list">
                                            <h1 className='text-[18px] font-semibold capitalize'>instant-approval-article-submissions-sites-list</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/free-high-quality-directory-submission-sites">
                                            <h1 className='text-[18px] font-semibold capitalize'>free-high-quality-directory-submission-sites
                                                Sites List</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/blog-submission-sites">
                                            <h1 className='text-[18px] font-semibold capitalize'>blog-submission-sites</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/business-listing-sites-in-india">
                                            <h1 className='text-[18px] font-semibold capitalize'>business-listing-sites-in-india</h1>
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default BusinessListingSitesinIndia