import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { Link } from "react-router-dom";

function InstantApprovalDirectorySubmissionSitesList() {
    return (
        <>
            <Header />
            <div className="container-fluid p-3 lg:p-0">
                <div className="container mx-auto py-12">
                    <h1 className='text-[28px] md:text-[42px] font-medium heading  mb-8 '>Instant Approval Directory Submission Sites List 2024</h1>
                    <div className="flex flex-wrap md:flex-nowrap pt-12 border-t-2">
                        <div className="w-full md:w-[70%] p-10 box-shadow mr-10 rounded-lg">
                            <div className="text_contenr pt-3 pb-8">
                                <p className='text-[18px] font-normal text-[#636363] '>Do you want to increase the search engine rankings of your website? Do you want to take your business to the next level and improve your SEO strategy? You should use the directory submission sites with instant approval. These websites will significantly increase your online presence and draw in your target audience, benefiting your business.</p><br />

                                <p className='text-[18px] font-normal text-[#636363] '>There are various approaches to a company's marketing strategy. They include conventional, online, and other forms of marketing. A few key actions will help you make these techniques more effective. The Instant Approval Dofollow Directory Submission List is one such technique.</p><br />

                                <p className='text-[18px] font-normal text-[#636363] '>No one can achieve the most outstanding results from their business by sticking to a single promotion strategy. You won't see the expected financial growth from your company until you can expand its customer base. Lists of directory submission sites can be helpful here!</p><br />

                                <p className='text-[18px] font-normal text-[#636363] '>Let's discuss how and why you should address instant directory submission in your SEO strategy. We'll ensure you get the idea and can apply it to advance your professional SEO strategies.</p><br />


                                <h1 className='text-[28px] md:text-[42px] font-medium heading  mb-8 '>What are Instant Approval Directory Submission Sites?</h1>

                                <p className='text-[18px] font-normal text-[#636363] '>A free instant approval directory submission list is one of the many techniques online firms use to grow their business. Companies use it to boost their website's online visibility.
                                </p><br />

                                <p className='text-[18px] font-normal text-[#636363] '>The procedure includes a website or URL to a web directory and gets it authorized immediately. Directory submission is the least expensive method of creating following links in SEO, which is why do-follow links are so important.</p><br />

                                <p className='text-[18px] font-normal text-[#636363] '>Directory Submission sites with instant approval can help you quickly obtain high-quality, do-follow links. This method can improve the website's reputation and link significantly and be applied to businesses for the intended results.</p><br />

                                <p className='text-[18px] font-normal text-[#636363] '>These backlinks help websites rank higher in search results. Having more backlinks will have a significant positive impact on your website's search engine rating. To maximize your business's potential, you must acquire increasing backlinks.
                                </p><br />

                                <p className='text-[18px] font-normal text-[#636363] '>We're not suggesting that your website won't have any competition from a reputable search engine if it doesn't have the instant approval to follow the directory submission list. Still, the directory submission sites will significantly expand your reach.
                                </p><br />
                            </div>

                            <h1 className='text-[32px] font-medium my-3 heading'>HIGH DA DIRECTORY SUBMISSION SITES LIST</h1>

                            <p className='text-[18px] font-normal text-[#636363] '>Finding the top directory submission websites doesn't require you to go further. We have put together a list for you. We did quite a lot of research before compiling the list since we value your business as much as you do. You must use these websites to push your business to new heights.
                            </p><br />

                            <div class="relative overflow-x-auto shadow-md sm:rounded-lg ">
                                <table class="w-full text-sm text-left rtl:text-right text-gray-500 darks:text-gray-400">
                                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 darks:bg-gray-700 darks:text-gray-400">
                                        <tr>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                SNo.
                                            </th>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                Instant Approval Directory Submission Sites List
                                            </th>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                DA
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>1</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://viesearch.com/"><span>https://viesearch.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>46</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>2</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.operationuplink.org/"><span>http://www.operationuplink.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>38</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>3</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.joeant.com/"><span>https://www.joeant.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>37</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>4</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.activdirectory.net/"><span>https://www.activdirectory.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>36</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>5</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.directory6.org/"><span>https://www.directory6.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>36</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>6</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.acedirectory.org/"><span>https://www.acedirectory.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>36</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>7</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.angelsdirectory.com/"><span>https://www.angelsdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>35</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>8</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.free-weblink.com/"><span>https://www.free-weblink.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>35</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>9</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.01webdirectory.com/"><span>https://www.01webdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>35</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>10</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.royaldirectory.biz/"><span>http://www.royaldirectory.biz</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>34</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>11</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.goguides.org/"><span>http://www.goguides.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>34</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>12</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.ad-links.org/"><span>https://www.ad-links.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>33</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>13</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.beegdirectory.com/"><span>https://www.beegdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>32</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>14</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.getwebprice.org/"><span>http://www.getwebprice.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>32</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>15</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.domainnamesseo.com/"><span>https://www.domainnamesseo.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>32</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>16</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.adbritedirectory.com/"><span>http://www.adbritedirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>31</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>17</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.directory10.biz/"><span>https://www.directory10.biz</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>31</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>18</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.addgoodsites.com/"><span>https://www.addgoodsites.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>31</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>19</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://businessfreedirectory.biz/"><span>http://businessfreedirectory.biz</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>31</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>20</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.trafficdirectory.org/"><span>http://www.trafficdirectory.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>31</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>21</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.1abc.org/"><span>http://www.1abc.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>30</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>22</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.aweblist.org/"><span>http://www.aweblist.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>30</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>23</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.harddirectory.net/"><span>http://www.harddirectory.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>30</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>24</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.fire-directory.com/"><span>https://www.fire-directory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>30</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>25</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.1abc.org/"><span>http://www.1abc.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>30</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>26</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://targetlink.biz/"><span>https://targetlink.biz</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>30</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>27</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.target-directory.com/"><span>http://www.target-directory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>30</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>28</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.cipinet.com/"><span>https://www.cipinet.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>30</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>29</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.highrankdirectory.com/"><span>https://www.highrankdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>30</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>30</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.usalistingdirectory.com/"><span>https://www.usalistingdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>29</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>31</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.abstractdirectory.net/"><span>https://www.abstractdirectory.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>29</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>32</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.toptendir.net/"><span>http://www.toptendir.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>29</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>33</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.classdirectory.org/"><span>http://www.classdirectory.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>29</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>34</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.justdirectory.org/"><span>http://www.justdirectory.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>29</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>35</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.unique-listing.com/"><span>http://www.unique-listing.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>29</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>36</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.one-sublime-directory.com/"><span>https://www.one-sublime-directory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>29</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>37</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.usalistingdirectory.com/"><span>http://www.usalistingdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>29</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>38</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://ellysdirectory.com/"><span>https://ellysdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>29</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>39</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.huludirectory.com/"><span>https://www.huludirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>29</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>40</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.sublimedir.net/"><span>http://www.sublimedir.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>29</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>41</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.facebook-list.com/"><span>http://www.facebook-list.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>29</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>42</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://ellysdirectory.com/"><span>https://ellysdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>29</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>43</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.usalistingdirectory.com/"><span>https://www.usalistingdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>29</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>44</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.marketinginternetdirectory.com/"><span>https://www.marketinginternetdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>29</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>45</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.populardirectory.biz/"><span>https://www.populardirectory.biz</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>46</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.ask-dir.org/"><span>http://www.ask-dir.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>47</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.directory3.org/"><span>http://www.directory3.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>48</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.homedirectory.biz/"><span>https://www.homedirectory.biz</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>49</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.alivelink.org/"><span>http://www.alivelink.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>50</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.efdir.com/"><span>http://www.efdir.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>51</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.linkedin-directory.com/"><span>http://www.linkedin-directory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>52</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.freeweblink.org/"><span>https://www.freeweblink.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>53</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.asklink.org/"><span>http://www.asklink.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>54</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.alivelinks.org/"><span>http://www.alivelinks.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>55</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.411freedirectory.com/"><span>https://www.411freedirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>56</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.reddit-directory.com/"><span>https://www.reddit-directory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>57</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://hotlinks.biz/"><span>https://hotlinks.biz</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>58</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.globaldir.org/"><span>http://www.globaldir.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>59</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.link-boy.org/"><span>http://www.link-boy.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>60</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.alive-directory.com/"><span>http://www.alive-directory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>61</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.poordirectory.com/"><span>http://www.poordirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>62</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.submissionwebdirectory.com/"><span>https://www.submissionwebdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>63</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.sitepromotiondirectory.com/"><span>https://www.sitepromotiondirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>28</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>64</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.relevantdirectory.biz/"><span>http://www.relevantdirectory.biz</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>27</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>65</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.craigslistdir.org/"><span>http://www.craigslistdir.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>27</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>66</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.jet-links.com/"><span>http://www.jet-links.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>27</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>67</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.relevantdirectories.com/"><span>https://www.relevantdirectories.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>27</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>68</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.prolink-directory.com/"><span>http://www.prolink-directory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>27</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>69</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://relateddirectory.org/"><span>http://relateddirectory.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>27</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>70</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.addirectory.org/"><span>https://www.addirectory.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>27</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>71</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.lemon-directory.com/"><span>http://www.lemon-directory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>27</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>72</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.upsdirectory.com/"><span>https://www.upsdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>27</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>73</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.nicedir.net/"><span>http://www.nicedir.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>27</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>74</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.link-man.org/"><span>http://www.link-man.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>27</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>75</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.craigslistdirectory.net/"><span>http://www.craigslistdirectory.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>27</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>76</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.interesting-dir.com/"><span>http://www.interesting-dir.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>27</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>77</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.afunnydir.com/"><span>http://www.afunnydir.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>27</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>78</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.ecodir.net/"><span>http://www.ecodir.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>27</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>79</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.ask-directory.com/"><span>https://www.ask-directory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>80</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.backpagedir.com/"><span>https://www.backpagedir.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>81</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.a2place.com/"><span>https://www.a2place.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>82</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.bedirectory.com/"><span>https://www.bedirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>83</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.hotdirectory.net/"><span>https://www.hotdirectory.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>84</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.directory4.org/"><span>https://www.directory4.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>85</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.directory5.org/"><span>https://www.directory5.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>86</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.steeldirectory.net/"><span>http://www.steeldirectory.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>87</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.seooptimizationdirectory.com/"><span>https://www.seooptimizationdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>88</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.advancedseodirectory.com/"><span>https://www.advancedseodirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>89</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.exampledir.com/"><span>https://www.exampledir.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>90</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.elitesitesdirectory.com/"><span>http://www.elitesitesdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>91</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.ecobluedirectory.com/"><span>https://www.ecobluedirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>92</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.gainweb.org/"><span>http://www.gainweb.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>93</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.britainbusinessdirectory.com/"><span>https://www.britainbusinessdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>94</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.botid.org/"><span>https://www.botid.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>26</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>95</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.piratedirectory.org/"><span>http://www.piratedirectory.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>96</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.searchdomainhere.com/"><span>http://www.searchdomainhere.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>97</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.a1webdirectory.org/"><span>http://www.a1webdirectory.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>98</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.freeseolink.org/"><span>https://www.freeseolink.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>99</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.sublimelink.org/"><span>https://www.sublimelink.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>100</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.bestdirectory4you.com/"><span>http://www.bestdirectory4you.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>101</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.sercm.org/"><span>http://www.sercm.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>102</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.gowwwlist.com/"><span>https://www.gowwwlist.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>103</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.onecooldir.com/"><span>https://www.onecooldir.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>104</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.populardirectory.org/"><span>http://www.populardirectory.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>105</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.directorydirect.net/"><span>http://www.directorydirect.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>106</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.bing-directory.com/"><span>https://www.bing-directory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>107</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://directory8.org/"><span>https://directory8.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>108</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.directory9.biz/"><span>http://www.directory9.biz</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>109</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.aquarius-dir.com/"><span>http://www.aquarius-dir.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>110</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.apeopledirectory.com/"><span>http://www.apeopledirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>111</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.usawebsitesdirectory.com/"><span>https://www.usawebsitesdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>25</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>112</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.247webdirectory.com/"><span>https://www.247webdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>24</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>113</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.smartdir.org/"><span>http://www.smartdir.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>24</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>114</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.fruity-directory.com/"><span>https://www.fruity-directory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>24</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>115</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.1directory.org/"><span>https://www.1directory.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>24</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>116</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.johnnylist.org/"><span>https://www.johnnylist.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>24</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>117</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.smartseolink.org/"><span>https://www.smartseolink.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>24</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>118</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.bluebook-directory.com/"><span>https://www.bluebook-directory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>24</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>119</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.directoryanalytic.com/"><span>http://www.directoryanalytic.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>24</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>120</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://webguiding.net/"><span>https://webguiding.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>24</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>121</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.businessfreedirectory.com/"><span>http://www.businessfreedirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>24</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>122</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.siteswebdirectory.com/"><span>https://www.siteswebdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>24</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>123</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.directory10.org/"><span>http://www.directory10.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>124</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://web-directory-sites.org/"><span>https://web-directory-sites.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>125</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.directdirectory.org/"><span>http://www.directdirectory.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>126</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.alive2directory.com/"><span>https://www.alive2directory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>127</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.ifidir.com/"><span>http://www.ifidir.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>128</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.greenydirectory.com/"><span>https://www.greenydirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>129</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.dbsdirectory.com/"><span>https://www.dbsdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>130</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.dicedirectory.com/"><span>https://www.dicedirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>131</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.expansiondirectory.com/"><span>https://www.expansiondirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>132</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.colorblossomdirectory.com/"><span>http://www.colorblossomdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>133</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.darksschemedirectory.com/"><span>http://www.darksschemedirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>134</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.blackandbluedirectory.com/"><span>https://www.blackandbluedirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>135</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.clicksordirectory.com/"><span>http://www.clicksordirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>136</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.ebay-dir.com/"><span>http://www.ebay-dir.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>137</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.traveltourismdirectory.info/"><span>https://www.traveltourismdirectory.info</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>23</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>138</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.bluesparkledirectory.com/"><span>https://www.bluesparkledirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>139</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.aurora-directory.com/"><span>https://www.aurora-directory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>140</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.azure-directory.com/"><span>https://www.azure-directory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>141</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.bizz-directory.com/"><span>https://www.bizz-directory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>142</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.direct-directory.com/"><span>https://www.direct-directory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>143</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.celestialdirectory.com/"><span>http://www.celestialdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>144</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.bestbuydir.com/"><span>http://www.bestbuydir.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>145</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.the-web-directory.co.uk/"><span>https://www.the-web-directory.co.uk</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>146</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.promotebusinessdirectory.com/"><span>https://www.promotebusinessdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>22</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>147</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.brownedgedirectory.com/"><span>https://www.brownedgedirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>148</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.arcticdirectory.com/"><span>https://www.arcticdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>149</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.groovy-directory.com/"><span>https://www.groovy-directory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>150</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.deepbluedirectory.com/"><span>https://www.deepbluedirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>151</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.thedailysubmit.com/"><span>http://www.thedailysubmit.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>152</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.earthlydirectory.com/"><span>https://www.earthlydirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>153</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.blackgreendirectory.com/"><span>https://www.blackgreendirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>154</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.familydir.com/"><span>http://www.familydir.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>155</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.qualityinternetdirectory.com/"><span>https://www.qualityinternetdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>21</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>156</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.cleangreendirectory.com/"><span>http://www.cleangreendirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>157</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.linkdir4u.com/"><span>http://www.linkdir4u.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>158</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.coles-directory.com/"><span>http://www.coles-directory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>159</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.propellerdir.com/"><span>https://www.propellerdir.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>20</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>160</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.poozl.com/"><span>http://www.poozl.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>161</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://dmoz.org.in/"><span>http://dmoz.org.in</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>162</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.seodirectoryonline.org/"><span>http://www.seodirectoryonline.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>163</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://thedirectorylistings.org/"><span>https://thedirectorylistings.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>164</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.allstatesusadirectory.com/"><span>https://www.allstatesusadirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>165</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.webdirectoryhealth.com/"><span>https://www.webdirectoryhealth.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>166</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.directory-free.com/"><span>https://www.directory-free.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>19</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>167</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://ampledirectory.com/"><span>http://ampledirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>168</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.digabusiness.com/"><span>https://www.digabusiness.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>169</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.directoryseo.biz/"><span>http://www.directoryseo.biz</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>170</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.tfwd.org/"><span>http://www.tfwd.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>171</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.mediafiredirectlink.com/"><span>https://www.mediafiredirectlink.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>172</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.medicalhealthsites.com/"><span>https://www.medicalhealthsites.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>173</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.splashdirectory.com/"><span>https://www.splashdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>18</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>174</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.directory-listingsnow.org/"><span>https://www.directory-listingsnow.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>175</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.fearinc.org/"><span>http://www.fearinc.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>176</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.generalshoppingdirectory.com/"><span>http://www.generalshoppingdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>177</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.directorybusinesssite.org/"><span>https://www.directorybusinesssite.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>17</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>178</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.obln.org/"><span>http://www.obln.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>179</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.abstractdirectory.org/"><span>http://www.abstractdirectory.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>180</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://res-atlas.org/"><span>http://res-atlas.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>181</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.ourss.org/"><span>http://www.ourss.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>182</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.freewebsitedirectories.com/"><span>http://www.freewebsitedirectories.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>183</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.authorizeddir.com/"><span>https://www.authorizeddir.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>16</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>184</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.dataspear.com/"><span>http://www.dataspear.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>15</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>185</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.directorylistingsonline.org/"><span>https://www.directorylistingsonline.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>13</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>186</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="https://www.wmdirectory.com/"><span>https://www.wmdirectory.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>9</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>187</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.directoryhost.org/"><span>http://www.directoryhost.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>188</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.linkdirectorylistings.org/"><span>http://www.linkdirectorylistings.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>189</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.generaldirectorylistings.org/"><span>http://www.generaldirectorylistings.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>8</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>190</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.onlinesociety.org/"><span>http://www.onlinesociety.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>191</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.yawd.org/"><span>http://www.yawd.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>7</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>192</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.directory.org.vn/"><span>http://www.directory.org.vn</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>6</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>193</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://networkeddirectory.org/"><span>http://networkeddirectory.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>4</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>194</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.freedirectory-listings.org/"><span>http://www.freedirectory-listings.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>3</span></td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>195</span></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a href="http://www.latesttopdirectory.org/"><span>http://www.latesttopdirectory.org</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><span>3</span></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                            <h1 className='text-[32px] font-medium my-3 heading'>Why Do People Prefer Directory Submission for High-Quality Baclinks?</h1><br />

                            <p className='text-[18px] font-normal text-[#636363] '>People are now primarily curious as to why you should choose this. You can expect plenty of advantages from instant directory submission sites, which we will discuss below.
                            </p> <br />

                            <ul className='bg-[#f4dacd70] p-8'>
                                <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i
                                    class="fa-regular fa-square-check">1. Help in Increasing Ranking</i></span></strong>Adding your website to several directories will help it become more visible and grow on search engine results pages. This is so because directory submissions are a common way for search engines to assess the authority and relevancy of a website.
                                </li>
                                <br />
                                <li className='text-[18px] font-normal text-[#636363] '><strong> <span className='text-[#63E6BE] mr-2'><i
                                    class="fa-regular fa-square-check">2. Boost Online Visitor Volume</i></span></strong> Instant directory submission can help increase website traffic, mainly if the directories are relevant to the business or niche of the website. It could be because users frequently use directories to find websites and information on particular subjects.</li>
                                <br />
                                <li className='text-[18px] font-normal text-[#636363] '><strong> <span className='text-[#63E6BE] mr-2'><i
                                    class="fa-regular fa-square-check">3. Cost Effective</i></span></strong>Who wouldn't like to discover an economical solution? Free instant approval directory submission sites are cost-effective since they don't charge website owners for directory submissions.<br /><br />

                                    Small businesses and startups that require additional funding for paid directory entries or other marketing initiatives can benefit from this.
                                </li>
                                <br />
                                <li className='text-[18px] font-normal text-[#636363] '><strong> <span className='text-[#63E6BE] mr-2'><i
                                    class="fa-regular fa-square-check">4. Create Backlinks</i></span></strong>Backlinks, or links from other websites to one's own website, are something that directory submissions can assist website owners with growing. Backlinks can raise a website's authority and reliability and are crucial for search engine results.</li>
                                <br />

                            </ul>
                            <div className="faqs mb-12">
                                <h1 className='text-[32px] font-medium my-3 heading'> FAQs</h1>
                                <div id="accordion-collapse" data-accordion="collapse">
                                    <h2 id="accordion-collapse-heading-1">
                                        <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200  hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                            <span className='text-[20px] text-[#222] text-left'>What is the Directory Submission?</span>
                                            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                            </svg>
                                        </button>
                                    </h2>
                                    <div id="accordion-collapse-body-1" class="hidden" aria-labelledby="accordion-collapse-heading-1">
                                        <div class="p-5 border border-b-0 border-gray-200 darks:border-gray-700 darks:bg-gray-900">
                                            <p className='text-[18px] font-normal text-[#636363] '>Directory submission is a powerful SEO link-building strategy that improves search engine rankings and produces high-quality backlinks. You can publish the website's URL and company details to internet directories using directory submission sites.</p>
                                        </div>
                                    </div>
                                    <h2 id="accordion-collapse-heading-2">
                                        <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-2" aria-expanded="false" aria-controls="accordion-collapse-body-2">
                                            <span className='text-[20px] text-[#222] text-left'>Why is Instant Directory Submission Important for SEO?</span>
                                            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                            </svg>
                                        </button>
                                    </h2>
                                    <div id="accordion-collapse-body-2" class="hidden" aria-labelledby="accordion-collapse-heading-2">
                                        <div class="p-5 border border-b-0 border-gray-200 darks:border-gray-700">
                                            <p className='text-[18px] font-normal text-[#636363] '>Instant directory submission can help you secure a desirable place on Google and build backlinks to your website. Additionally, it strengthens domain authority, which has a promising impact on search engine rankings.</p>
                                        </div>
                                    </div>
                                    <h2 id="accordion-collapse-heading-3">
                                        <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-3" aria-expanded="false" aria-controls="accordion-collapse-body-3">
                                            <span className='text-[20px] text-[#222] text-left'>How Do I Find Relevant Directories for Submission in SEO?</span>
                                            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                            </svg>
                                        </button>
                                    </h2>
                                    <div id="accordion-collapse-body-3" class="hidden" aria-labelledby="accordion-collapse-heading-3">
                                        <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                            <p className='text-[18px] font-normal text-[#636363] '>You will need to look through directories about the niche of your website. The top directory submission sites appropriate to your website domain can be located using search keywords. Google prioritizes hyperlinks that are relevant to a given niche. </p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="w-[30%] hidden lg:block relative">
                            <div className='box-shadow rounded-lg ml-5 p-8 sidebar sticky top-[-150px]'>
                                <div className="sidebar_banner">
                                    <img src="img/page_img_4.jpg" alt="" />
                                </div>

                                <div className="sidebar_tabs mt-5">
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/profile-creation-sites-list">
                                            <h1 className='text-[18px] font-semibold capitalize'>Top High Do Follow Profile Creation
                                                Sites List</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/image-submission-sites-list">
                                            <h1 className='text-[18px] font-semibold capitalize'>image-submission-sites-list</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/classified-ads-websites">
                                            <h1 className='text-[18px] font-semibold capitalize'>classified-ads-websites</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/instant-approval-article-submissions-sites-list">
                                            <h1 className='text-[18px] font-semibold capitalize'>instant-approval-article-submissions-sites-list</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/free-high-quality-directory-submission-sites">
                                            <h1 className='text-[18px] font-semibold capitalize'>free-high-quality-directory-submission-sites
                                                Sites List</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/blog-submission-sites">
                                            <h1 className='text-[18px] font-semibold capitalize'>blog-submission-sites</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/business-listing-sites-in-india">
                                            <h1 className='text-[18px] font-semibold capitalize'>business-listing-sites-in-india</h1>
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default InstantApprovalDirectorySubmissionSitesList