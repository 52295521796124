import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Nashik() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{
                backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)" }}>
                <div className="container mx-auto py-12">
                    <h1 className='text-center text-white text-[32px] md:text-[52px]  font-semibold heading uppercase'>
                        SEO Company in Nashik
                    </h1>
                </div>
            </div>
        
            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[100%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>SEO Company in Nashik</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>As a creative and innovative partner, we create revolutionary digital solutions for a changing future. Bonafide Technologies is a reputable and reliable digital marketing company in Nashik and Pune.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>A prestigious marketing company with a large and diverse clientele, with a focus on developing brands for almost all industries. We provide services and solutions to a wide range of companies, from A to Z.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>Our data-driven, goal-oriented approach has allowed us to serve more than 1000 satisfied and prosperous clients.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>We are ready to handle all of your marketing needs thanks to our 15 years of experience and performance-enhancing techniques. We will work beyond your anticipations to make your dream into life.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>Here at Bonafide Technologies, we work together as a cohesive digital marketing company in Nashik, Pune. Our language is innovation, our culture is hard work, intelligence drives us, and success is our compass.</p>
                                <br />
                            </div>
                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>What Services We Offer</h1>
                                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                                    <div className="flex flex-wrap justify-between">
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>SEO
                                            </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>From website SEO to local SEO, App Store, and E-commerce SEO, we use proven strategies to enhance your online visibility and rankings.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Google Adwords</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>As experts in PPC Management, Lead Generation, Google Shopping Ads, and Content Marketing, we optimize your paid campaigns for maximum ROI.​</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Branding</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Harness the power of social media with our LinkedIn, YouTube, Facebook, and Instagram marketing strategies designed to boost your brand's recognition.
                                            </p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Website Designing</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Whether it's CMS, mobile, or static websites, our team delivers seamless design solutions. We also offer PSD to WordPress or HTML5 conversions and complete website maintenance services. </p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Website Development</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>With our expertise in E-commerce, open-source, custom app, and portal development, we build solutions tailored to your business needs.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Account Management</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>We offer complete seller account management services for Amazon, Meesho, Flipkart, Alibaba, and JioMart, covering all aspects from product listing to customer interactions, ensuring optimal business growth on these e-commerce platforms.</p>
                                        </div>
                                    </div>
                                </div>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Why Choose Us</h1>
        
                                <p className='text-[18px] font-normal text-[#636363] '><strong>Complete Commitment:</strong> After we set up a meeting, we guarantee to perform all necessary duties and make sure we offer digital support customized to your unique requirements.</p>
                                <p className='text-[18px] font-normal text-[#636363] '><strong>Customer-Priority:</strong> At the Bonafide Technologies, client happiness is our priority. We put their needs first so that they have a successful and happy experience.</p>
                                <p className='text-[18px] font-normal text-[#636363] '><strong>Excellence with Resolution:</strong> You can ensure that when you hire us, we'll not only finish all the duties at hand but also provide custom digital assistance tailored to your specific needs.</p>
                                <p className='text-[18px] font-normal text-[#636363] '><strong>Data-Driven Insights:</strong> At Bonafide Technologies, we use data to inform options that advance your company. Our group gathers and analyzes data from multiple sources to produce insightful analysis that gives you a strategic advantage.</p>
                                <p className='text-[18px] font-normal text-[#636363] '><strong>Customized Approach:</strong> We adjust our strategically customized approach in accordance with the company's unique needs and goals. Our strategy is specially designed to satisfy your needs, guaranteeing top performance and a high return on investment (ROI). </p>
                                <p className='text-[18px] font-normal text-[#636363] '><strong>Collaborative Process:</strong> Our relationship goes beyond providing services. It also encourages communication and partnership between your team and ours. We work closely at every level of conception, implementation, and optimization, creating synergies that produce excellent results.</p>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Hire a Reliable SEO Company in Nashik</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Hiring Bonafide Technologies guarantees a significant return on investment. No matter the industry you work in, internet marketing can be quite helpful to you. Your company will likely receive more leads if it has a strong online presence. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>So, what are you waiting for? Hire Bonafide Technologies to make your online competitors face serious competition. Please do not hesitate to contact us with any questions. We would be delighted to help you with this. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        )
}

export default Nashik
