import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from "react-router-dom";

const ServiceSlider = () => {
  const services = [
    {
      title: 'Wordpress ',
      image: 'img/w-press_sml.jpg',
      link: '/wordpress',
    },
    {
      title: 'Wix',
      image: 'img/wix_small.jpg',
      link: '/wix',
    },
    {
      title: 'Webflow',
      image: 'img/w-flow_smll.jpg',
      link: '/webflow',
    },
    {
      title: 'Figma',
      image: 'img/figma_smlll.jpg',
      link: '/figma',
    },
    {
      title: 'Woo Commerce',
      image: 'img/w_c_smll.jpg',
      link: '/woocommerce',
    },
    {
      title: 'Shopify',
      image: 'img/shopify_small (1).jpg',
      link: '/shopify',
    },
    {
      title: 'Bubble.io',
      image: 'img/bbl_small.jpg',
      link: '/bubble',
    },
    {
      title: 'Squre Space',
      image: 'img/squarespace_small.jpg',
      link: '/squarespace',
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 4, // Desktop
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1324, // Tablet
        settings: {
          slidesToShow: 3,
          centerMode: false,
        },
      },
      {
        breakpoint: 1024, // Tablet
        settings: {
          slidesToShow: 2,
          centerMode: false,
        },
      },
      {
        breakpoint: 600, // Mobile
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {services.map((service, index) => (
        <div key={index} className='p-4'>
          <Link to={service.link} className="">
            <div className="cursor-pointer hover:shadow-lg rounded-xl overflow-hidden   page_box ">
              <div className="main_box overflow-hidden relative rounded-xl">
                <img src={service.image} className='object-cover w-full h-[320px] lg:h-[400px] transition-all duration-500' alt="" />
                <div className="page_text px-3 py-6 relative">
                  <h1 className='text-[18px] md:text-[22px] font-medium text-white capitalize'>{service.title}</h1>
                </div>
              </div>
            </div>
          </Link>
        </div>
      ))}
    </Slider>
  );
};

export default ServiceSlider;
