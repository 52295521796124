import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Pages from './Components/Pages';
import BookmarkingSitesLists from './Components/BookmarkingSitesLists';
import Home from './Components/Home';
import Proposal from './Components/Proposal';
import BlogSubmissionSites from './Components/BlogSubmissionSites';
import OurWork from './Components/OurWork';
import Wordpress from './Components/Wordpress';
import AboutUs from './Components/AboutUs';
import ContactUs from './Components/ContactUs';
import Admin from './Components/Admin';
import Dashbord from './Components/Dashbord';
import PrivateComponent from './Components/PrivateComponent';
import Error from './Components/Error';
import ClassifiedAdsWebsites from './Components/ClassifiedAdsWebsites';
import FreeWeb20SitesList from './Components/FreeWeb20SitesList';
import FreeHighQualityDirectorySubmissionSites from './Components/FreeHighQualityDirectorySubmissionSites';
import SingleGig from './Components/SingleGig';
import ImageSubmissionSitesList from './Components/ImageSubmissionSitesList';
import InstantApprovalArticleSubmissionSitesList from './Components/InstantApprovalArticleSubmissionSitesList';
import Gigs from './Components/Gigs';
import SEO from './Components/SEO';
import SocialMediaMarketing from './Components/SocialMediaMarketing';
import SocialEnginesMarketing from './Components/SocialEnginesMarketing';
import GigForm from './Components/GigForm';
import GuestPosting from './Components/GuestPosting';
import WebAnalytics from './Components/WebAnalytics';
import FullStack from './Components/FullStack';
import LocalSeo from './Components/LocalSeo';
import Bubble from './Components/Bubble';
import MobileAppMarketing from './Components/MobileAppMarketing';
import InfluencerMarketing from './Components/InfluencerMarketing';
import EcommerceMarketing from './Components/EcommerceMarketing';
import InstantApprovalDirectorySubmissionSitesList from './Components/InstantApprovalDirectorySubmissionSitesList';
import VideoSubmissionSitesList from './Components/VideoSubmissionSitesList';
import ProfileCreationSitesList from './Components/ProfileCreationSitesList';
import BusinessListingSitesinIndia from './Components/BusinessListingSitesinIndia';
import WebTraffic from './Components/WebTraffic';
import AffiliateMarketing from './Components/AffiliateMarketing';
import PPC from './Components/PPC';
import Shopify from './Components/Shopify';
import Webflow from './Components/Webflow';
import Figma from './Components/Figma';
import Squarespace from './Components/Squarespace';
import Laravel from './Components/Laravel';
import Wix from './Components/Wix';
import CakePHP from './Components/CakePHP';
import WooCommerce from './Components/WooCommerce';
import Javascript from './Components/Javascript';
import ReactJS from './Components/ReactJS';
import MernStack from './Components/MernStack';
import Mumbai from './Components/Mumbai';
import Jaipur from './Components/Jaipur';
import Chennai from './Components/Chennai';
import Kolkata from './Components/Kolkata';
import Vashi from './Components/Vashi';
import Dombivli from './Components/Dombivli';
import Thane from './Components/Thane';
import Nashik from './Components/Nashik';
import Bangaloree from './Components/Bangaloree';
import Goregaon from './Components/Goregaon';
import Noida from './Components/Noida';
import Andheri from './Components/Andheri';
import PHP from './Components/PHP';
import DelhiNCR from './Components/DelhiNCR';
import Gwalior from './Components/Gwalior';
import Dehradun from './Components/Dehradun';
import Allahabad from './Components/Allahabad';
import Aurangabad from './Components/Aurangabad';
import Pune from './Components/Pune';
import Chandigarh from './Components/Chandigarh';
import Houston from './Components/Houston';
import Indore from './Components/Indore';
import Ahmedabad from './Components/Ahmedabad';
import NaviMumbai from './Components/NaviMumbai';
import Vadodara from './Components/Vadodara';
import Surat from './Components/Surat';
import Hyderabad from './Components/Hyderabad';
import Gurgaon from './Components/Gurgaon';
import Borivali from './Components/Borivali';
import Bandra from './Components/Bandra';
import Kalyan from './Components/Kalyan';
import Udaipur from './Components/Udaipur';
import Ghaziabad from './Components/Ghaziabad';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
function App() {
  return (

    <Router>
      <div className="app">
        {/* <Header/> */}
        <ScrollToTop />
        <Routes >
          <Route element={<PrivateComponent />}>
            <Route path="/dashbord" element={<Dashbord />} />
          </Route>
          <Route path="/" element={<Home />} />
          <Route path="/resources" element={<Pages />} />
          <Route path="/gigs" element={<Gigs />} />
          <Route path="/gig-details/:singleGigId/" element={<GigForm />} />
          <Route path="/top-free-social-bookmarking-sites-lists" element={<BookmarkingSitesLists />} />
          <Route path="/blog-submission-sites" element={<BlogSubmissionSites />} />
          <Route path="/proposal" element={<Proposal />} />
          <Route path="/our-work" element={<OurWork />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/dashboard" element={<Dashbord />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/wordpress" element={<Wordpress />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/error" element={<Error />} />
          <Route path="/classified-ads-websites" element={<ClassifiedAdsWebsites />} />
          <Route path="/free-web-2.0-sites-list" element={<FreeWeb20SitesList />} />
          <Route path="/free-high-quality-directory-submission-sites" element={<FreeHighQualityDirectorySubmissionSites />} />
          <Route path="/image-submission-sites-list" element={<ImageSubmissionSitesList />} />
          <Route path="/instant-approval-article-submissions-sites-list" element={<InstantApprovalArticleSubmissionSitesList />} />
          <Route path="/single-gig/:gigID" element={<SingleGig />} />
          <Route path="/seo" element={<SEO />} />
          <Route path="/social-media-marketing" element={<SocialMediaMarketing />} />
          <Route path="/social-engines-marketing" element={<SocialEnginesMarketing />} />
          <Route path="/guest-posting" element={<GuestPosting />} />
          <Route path="/web-analytics" element={<WebAnalytics />} />
          <Route path="/full-stack" element={<FullStack />} />
          <Route path="/woocommerce" element={<WooCommerce />} />
          <Route path="/local-seo" element={<LocalSeo />} />
          <Route path="/bubble" element={<Bubble />} />
          <Route path="/mobile-app-marketing" element={<MobileAppMarketing />} />
          <Route path="/influencer-marketing" element={<InfluencerMarketing />} />
          <Route path="/e-commerce-marketing" element={<EcommerceMarketing />} />
          <Route path="/instant-approval-directory-submission-sites-list" element={<InstantApprovalDirectorySubmissionSitesList />} />
          <Route path="/video-submission-sites-list" element={<VideoSubmissionSitesList />} />
          <Route path="/profile-creation-sites-list" element={<ProfileCreationSitesList />} />
          <Route path="/business-listing-sites-in-india" element={<BusinessListingSitesinIndia />} />
          <Route path="/web-traffic" element={<WebTraffic />} />
          <Route path="/affiliate-marketing" element={<AffiliateMarketing />} />
          <Route path="/ppc" element={<PPC />} />
          <Route path="/shopify" element={<Shopify />} />
          <Route path="/webflow" element={<Webflow />} />
          <Route path="/figma" element={<Figma />} />
          <Route path="/squarespace" element={<Squarespace />} />
          <Route path="/laravel" element={<Laravel />} />
          <Route path="/wix" element={<Wix />} />
          <Route path="/cake-php" element={<CakePHP />} />
          <Route path="/woocommerce" element={<WooCommerce />} />
          <Route path="/javascript" element={<Javascript />} />
          <Route path="/php" element={<PHP />} />
          <Route path="/reactjs" element={<ReactJS />} />
          <Route path="/mern-stack" element={<MernStack />} />
          <Route path="/mumbai" element={<Mumbai />} />
          <Route path="/jaipur" element={<Jaipur />} />
          <Route path="/chennai" element={<Chennai />} />
          <Route path="/kolkata" element={<Kolkata />} />
          <Route path="/vashi" element={<Vashi />} />
          <Route path="/dombivli" element={<Dombivli />} />
          <Route path="/thane" element={<Thane />} />
          <Route path="/nashik" element={<Nashik />} />
          <Route path="/goregaon" element={< Goregaon />} />
          <Route path="/noida" element={< Noida />} />
          <Route path="/andheri" element={< Andheri />} />
          <Route path="/delhincr" element={< DelhiNCR />} />
        <Route path="/gwalior" element={< Gwalior />} />
        <Route path="/dehradun" element={< Dehradun />} />
        <Route path="/allahabad" element={< Allahabad />} />
        <Route path="/aurangabad" element={< Aurangabad />} />
        <Route path="/pune" element={< Pune />} />
        <Route path="/chandigarh" element={< Chandigarh />} />
        <Route path="/houston" element={< Houston/>} />
        <Route path="/indore" element={< Indore/>} />
        <Route path="/ahmedabad" element={< Ahmedabad/>} />
        <Route path="/navimumbai" element={< NaviMumbai/>} />
        <Route path="/vadodara" element={< Vadodara/>} />
        <Route path="/surat" element={< Surat/>} />
        <Route path="/hyderabad" element={< Hyderabad/>} />
        <Route path="/gurugram" element={< Gurgaon/>} />
        <Route path="/borivali" element={< Borivali/>} />
        <Route path="/bandra" element={< Bandra/>} />
        <Route path="/kalyan" element={< Kalyan/>} />
        <Route path="/udaipur" element={< Udaipur/>} />
        <Route path="/ghaziabad" element={< Ghaziabad/>} />
        <Route path="/bangalore" element={< Bangaloree/>} />
        </Routes >
        {/* <Footer/>     */}
      </div>
    </Router>
  );
}

export default App;
