import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Allahabad() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{
                backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)" }}>
                <div className="container mx-auto py-12">
                    <h1
                        className='text-center text-white text-[32px] md:text-[52px]  font-semibold heading uppercase'>
                        SEO Company in Allahabad
                    </h1>
                </div>
            </div>
        
            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[100%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>SEO Company in Allahabad</h1>
                                <p className='text-[18px] font-normal text-[#636363] '><strong>Discover the Potential of Your Company with the Top Digital Marketing Company in Allahabad.</strong></p>
                                <p className='text-[18px] font-normal text-[#636363] '><strong>Our Expertise Is Assisting Companies Of All Sizes To Reach Their Internet Marketing Objectives.</strong></p>
                                <p className='text-[18px] font-normal text-[#636363] '>Bonafide Technologies is dedicated to creating innovative IT solutions, including digital marketing, website design, and development. In order to increase business, Bonafide focuses on developing strong client relationships and tactics.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>In the areas of website design and development, search engine optimization, search engine marketing, social media optimization, content creation, graphic design, email marketing, and digital marketing, we have a committed team focused on providing flawless solutions. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>We are the top and most reliable SEO company in Allahabad, thanks to our large clientele of happy customers. And we value their trust as much as we do, which means we have a responsibility to uphold it by providing services that are worth investing in. To that end, we understand each client's unique needs and tailor our solutions accordingly.</p>
                                <br />
                                <h1 className='text-[32px] font-semibold my-3 heading'>What is SEO and Why you need it</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Search Engine Optimization, or SEO, is getting your website to appear on search engine result pages. You must communicate with others and explain the benefits of your product if you want to succeed. Since organic traffic generates a higher return on investment than paid search traffic, investing in SEO is an intelligent strategy to strengthen the future of your business. Only the organic search results are viewed by the bulk of search traffic. Hence, you lose a significant portion of your online audience if your website does not appear on the first page with the most searched keywords.</p>
                            </div>
                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Why Choose Us</h1>
                                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                                    <div className="flex flex-wrap justify-between">
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Customized Solutions
                                            </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>We know that each company is different. For this reason, we reject the concept of universally applicable solutions. With the help of our highly customized approach, you can ensure that our methods will completely match your unique requirements and goals.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Proven Results</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Our previous performance is quite evident. Our digital marketing services have enabled many businesses in India and around the globe to experience incredible growth and success. We gauge our success based on how well we do.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Supreme Technology</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>We use the latest technologies and strategies in digital marketing to provide your company an edge over competitors. We guarantee that you will remain at the top of the digital world thanks to our dedication to innovation.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Knowledge And Experience</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Our more than 15 years of experience in the field have given us the knowledge and skills necessary to negotiate the constantly shifting digital landscape successfully. Our team of experienced professionals stays updated on emerging trends and technology to guarantee that your company maintains its competitive edge.</p>
                                        </div>
                                    </div>
                                </div>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Our Services </h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Unleash All Services with Bonafide Technologies: Your Best Digital Marketing Agency in Allahabad!</p>
                                <ul>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>Online Marketing Solutions </strong>
                                        Since web-based solutions have replaced traditional marketing strategies, we provide online marketing solutions that are customized to meet your company's needs.</li>   
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>Website Design And Development </strong>
                                        Our team of web designers and developers will constantly make sure that the website design we provide is visually beautiful, captivating, and appealing all at once.</li>   
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>Search Engine Optimization </strong>
                                        For years, we have been providing exceptional SEO results by creating original content, doing in-depth keyword research, and designing well-organized websites to improve your site's user experience.</li>   
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>Content Creation </strong>
                                        Nothing is more captivating than relevant website content. Because of our team's expertise and experience in creating relevant content for your website, we are regarded as the best SEO Company in Allahabad. </li>     
                                </ul> 
                                <h1 className='text-[32px] font-semibold my-3 heading'>Want To Know About Bonafide Technologies, India?</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>You've come to the right spot if you're seeking the top SEO company in Prayagraj, Allahabad. We are at your service to boost the growth of your company. We increase your brand's visibility, sales, engagement, and appeal by using our best digital marketing strategy. Contact us right away and take your business to new heights!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        )
}

export default Allahabad