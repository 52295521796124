import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Indore() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{
                backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)" }}>
                <div className="container mx-auto py-12">
                    <h1
                        className='text-center text-white text-[32px] md:text-[52px]  font-semibold heading uppercase'>
                            SEO Company in Indore
                    </h1>
                </div>
            </div>
        
            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[100%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>SEO Company in Indore</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>In the current digital era, businesses must have a strong online presence in order to connect with their target market and expand their brand. Still, it can be difficult to differentiate yourself from the competition with the millions of websites available on the internet. Search engine optimization, or SEO, can help with that.</p>
                                <br />
                                <p className='text-[18px] font-normal text-[#636363] '>SEO is the process of making your website and online presence more optimized so that it appears higher in search engine results pages (SERPs) and attracts more natural visitors. Your company may expand and draw in more qualified leads by increasing conversions and optimizing your website for search engines.</p>
                                <br />
                                <p className='text-[18px] font-normal text-[#636363] '>Your online marketing campaigns will not be successful until you select the proper SEO company. It might be challenging to know where to turn with so many businesses claiming to provide the best SEO services. Bonafide Technologies can help with that. </p>
                                <br />
                                <h1 className='text-[32px] font-semibold my-3 heading'>Why Choose Us</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>In a society where the internet is becoming more and more important, your online presence can make or break your company. Bonafide Technologies, a top digital marketing agency in Indore, is here to help and is your trusted partner. Our passion is helping businesses succeed in the digital era, and we are here to support you as you navigate the always-evolving landscape of Internet marketing to secure your growth and success. </p>
                                <br />
                                <p className='text-[18px] font-normal text-[#636363] '>As a top provider of web design, development, and brand marketing services, we use a variety of cutting-edge technology to build websites and offer personalized web and digital marketing services. We can provide the best digital marketing solutions at a reasonable price because we have a great deal of experience in every facet of the industry.</p>
                                <br />
                                <p className='text-[18px] font-normal text-[#636363] '>Bonafide Technologies is one of the best digital marketing companies in India, offering solutions for all your needs. With six years of experience and a team of knowledgeable digital marketing specialists, Bonafide Technologies provides all businesses with individualized digital marketing services. Thanks to our extensive experience and subject specialization, we are able to offer our clients from more than 20 countries the best digital marketing services available. We provide web, app, PPC, SEO, and SMM services. </p>
                                <br />
                            </div>
                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>What We Offer</h1>
                                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                                    <div className="flex flex-wrap justify-between">
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>SMM 
                                            </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>As one of the best social media marketing companies in India, Bonafide Technologies develops social media plans while taking the most recent market trends into account. By using our SMM services, you may build a strong brand identity and improve consumer interactions in addition to obtaining conversions straight from social media.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>PPC </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>PPC tactics necessitate giving significant thought to every business objective. To attain high ROl, Bonafide Technologies takes into account all business factors and makes the best use of the available cash. You can beat the competition and maximize the return on your digital marketing investment by working with our PPC specialists.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>SEO</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>We conduct in-depth keyword research and examine the competition, market trends, and more elements. We provide a list of optimized keywords for your website based on this.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Content Strategy</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Our speciality is offering the best SEO services in India, which we achieve by combining paid and organic search engine optimization techniques to meet the specific requirements of your company. Our SEO services can increase your company's position on the search engine results page and increase natural traffic to your website.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Content Marketing</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Relevant and useful content is the lifeblood of any internet business. Bonafide provides planning, creation, and implementation services for content marketing.</p>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <h1 className='text-[32px] font-semibold my-3 heading'>Contact Us Right Now And Rank Top On Google </h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Clear interaction is important to us to make it simple for future clients to contact Bonafide Technologies. We invite you to get in contact with us if you have any queries concerning our offerings or costs or are prepared to talk about your particular requirements. To speak with us, please complete the contact form on our website. We will try our best to meet your demands and will reply to your request as soon as possible. </p> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        )
}

export default Indore