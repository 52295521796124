import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from "react-router-dom";

function DigitalSlider() {
  const services = [
    {
      title: 'Social Media Marketing - SMM',
      image: 'img/SMM.jpg',
      link: '/social-media-marketing',
    },
    {
      title: 'Social Engines Marketing - SEM',
      image: 'img/SEM.jpg',
      link: '/social-engines-marketing',
    },
    {
      title: 'Guest Posting',
      image: 'img/Guest-Posting.jpg',
      link: '/guest-posting',
    },
    {
      title: 'Web Analytics',
      image: 'img/Web-analytics.jpg',
      link: '/web-analytics',
    },
    {
      title: 'Local SEO',
      image: 'img/seo.jpg',
      link: '/local-seo',
    },
    {
      title: 'eCommerce Marketing',
      image: 'img/eCommerce-Marketing.jpg',
      link: '/e-commerce-marketing',
    },
    {
      title: 'Mobile App Marketing',
      image: 'img/Mobile-App-Marketing.jpg',
      link: '/mobile-app-marketing',
    },
    {
      title: 'Influencer Marketing',
      image: 'img/Influencer-Marketing.jpg',
      link: '/influencer-marketing',
    },
    {
      title: 'Affiliate Marketing',
      image: 'img/Affiliate-Marketing.jpg',
      link: '/affiliate-marketing',
    },
    {
      title: 'Web Traffic',
      image: 'img/Web-Traffic.jpg',
      link: '/web-traffic',
    },
    {
      title: 'PPC',
      image: 'img/PPC.jpg',
      link: '/ppc',
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 4, // Desktop
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1324, // Tablet
        settings: {
          slidesToShow: 3,
          centerMode: false,
        },
      },
      {
        breakpoint: 1024, // Tablet
        settings: {
          slidesToShow: 2,
          centerMode: false,
        },
      },
      {
        breakpoint: 600, // Mobile
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {services.map((service, index) => (
        <div key={index} className='p-4'>
          <Link to={service.link} className="">
            <div className="cursor-pointer hover:shadow-lg rounded-xl overflow-hidden   page_box ">
              <div className="main_box overflow-hidden relative rounded-xl">
                <img src={service.image} className='object-cover w-full h-[320px] lg:h-[400px] transition-all duration-500' alt="" />
                <div className="page_text px-3 py-6 relative">
                  <h1 className='text-[18px] md:text-[22px] font-medium text-white capitalize'>{service.title}</h1>
                </div>
              </div>
            </div>
          </Link>
        </div>
      ))}
    </Slider>
  )
}

export default DigitalSlider