import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { Link } from "react-router-dom";


function Wix() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{ backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)" }}>
                <div className="container mx-auto py-12">
                    <h1 className='text-center text-white text-[34px] md:text-[42px] lg:text-[56px] xl:text-[86px] font-bold heading uppercase'>Wix</h1>
                </div>
            </div>

            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[70%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <img src="img/Wix.jpg" className='w-full' alt="" />

                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Wix</h1>
                                <p className='text-[18px] font-normal text-[#636363] '><strong>You Take Care of Your Business. We'll Take Care of Your Wix Web Design.</strong></p><br />
                                <p className='text-[18px] font-normal text-[#636363] '>We can help with everything from importing and exporting products to building, updating, and managing your website.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>With years of experience in Wix design and development, our team is skilled at building attractive, useful websites tailored to your unique requirements. We can help you in making the most of your recently deployed website.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>Your website powers your business. Therefore, you need a team of Wix specialists who can work as fast as you can. We take great satisfaction in providing professional service, excellent craftsmanship, and quick turnaround times.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>Working with Bondafide Technologies gives you access to our unparalleled web development experience. We can assist you with any Wix-related queries, updates, or problems. We have highly qualified staff members to assist you with queries regarding your business or website.</p>
                            </div>

                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'> What We Provide</h1>
                                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                                    <div className="flex flex-wrap justify-between">
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Wix Website Design</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>We are expert web designers focusing on using Wix to create and design websites. Since we thoroughly understand how Wix operates, we can assist you in developing a website or eCommerce store that fits your unique requirements and objectives.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Wix Website Development</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>We have a group of incredibly talented and experienced developers familiar with the Wix platform. Furthermore, we use the newest technology and tools to guarantee that your website is updated and complies with the most recent requirements.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Wix SEO</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>As a top Wix web development firm, we go above and beyond creating your website by offering Wix SEO services that open up your e-commerce store to various target markets.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Wix Plugin & Module Development</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Our developers for Wix website development services are experts in creating unique, error-free extensions and plugins that enhance the functionality of your Wix store by creating custom modules.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Wix Website Maintenance</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>One service that keeps your website current and functional is website maintenance. It covers tasks like backing up your webpage data, upgrading your plugins and software, and updating the content of your webpage.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Wix Theme Development</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Improve the appearance and feel of your eCommerce store to draw in clients. Our Wix development company creates unique Wix themes precisely created with your customers in mind.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Wix Migration Solutions</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Our Wix developers work in tandem with our leading Wix website development business in India to assist you in smoothly and safely migrating to Wix without any data loss.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Wix Payment Gateway Integration</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Our Wix website development business in India enables you to integrate well-known payment channels like PayPal, Google Checkout, and many more to give your consumers a seamless and enjoyable shopping experience.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="faqs my-12">
                                    <p className='text-[18px] font-normal text-[#636363] '><strong>Are You Ready To Talk To Us About Your Dream Project?<br />
                                        Reach Out To Make It Happen
                                    </strong></p><br />
                                    <h1 className='text-[32px] font-medium my-3 heading'> FAQs</h1>
                                    <div id="accordion-collapse" data-accordion="collapse">
                                        <h2 id="accordion-collapse-heading-1">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200  hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                <span className='text-[20px] text-[#222] text-left'>What Is Wix?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-1" class="hidden" aria-labelledby="accordion-collapse-heading-1">
                                            <div class="p-5 border border-b-0 border-gray-200 darks:border-gray-700 darks:bg-gray-900">
                                                <p className='text-[18px] font-normal text-[#636363] '>Wix is a cloud-based platform that allows users to create and manage websites without needing to know how to code. It offers a variety of customizable templates and features to suit different needs.</p>
                                            </div>
                                        </div>
                                        <h2 id="accordion-collapse-heading-2">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-2" aria-expanded="false" aria-controls="accordion-collapse-body-2">
                                                <span className='text-[20px] text-[#222] text-left'>How Much Does Wix Cost?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-2" class="hidden" aria-labelledby="accordion-collapse-heading-2">
                                            <div class="p-5 border border-b-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Wix offers a range of pricing plans, including free and premium options. The cost depends on the features you need and whether you want to use a custom domain. </p>
                                            </div>
                                        </div>
                                        <h2 id="accordion-collapse-heading-3">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-3" aria-expanded="false" aria-controls="accordion-collapse-body-3">
                                                <span className='text-[20px] text-[#222] text-left'>Can I Use my Own Domain With a Wix Website?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-3" class="hidden" aria-labelledby="accordion-collapse-heading-3">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Yes, you can connect your own domain to your Wix website. Wix offers domain registration services, or you can use a domain you already own by connecting it to your Wix site.</p>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-4">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-4" aria-expanded="false" aria-controls="accordion-collapse-body-4">
                                                <span className='text-[20px] text-[#222] text-left'>Is Wix SEO-Friendly?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-4" class="hidden" aria-labelledby="accordion-collapse-heading-4">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Yes, Wix provides tools and features to help optimize your website for search engines. You can customize meta tags, titles, and descriptions, as well as add alt text to images, among other SEO-friendly practices.</p>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-5">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-5" aria-expanded="false" aria-controls="accordion-collapse-body-5">
                                                <span className='text-[20px] text-[#222] text-left'>Can I Sell Products on my Wix Website?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-5" class="hidden" aria-labelledby="accordion-collapse-heading-5">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Yes, Wix offers e-commerce capabilities that allow you to sell products and accept payments online. You can set up an online store, manage inventory, and process orders directly through your Wix website.</p>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-6">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-6" aria-expanded="false" aria-controls="accordion-collapse-body-6">
                                                <span className='text-[20px] text-[#222] text-left'>Is Wix Secure?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-6" class="hidden" aria-labelledby="accordion-collapse-heading-6">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Wix takes security seriously and offers SSL encryption for all Wix websites to ensure that data transmitted between the site and its visitors is secure. Additionally, Wix regularly updates its platform to protect against security threats.</p>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-7">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-7" aria-expanded="false" aria-controls="accordion-collapse-body-7">
                                                <span className='text-[20px] text-[#222] text-left'>Can I Add Third-Party Apps to My Wix Website?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-7" class="hidden" aria-labelledby="accordion-collapse-heading-7">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Yes, Wix has an App Market where you can find a variety of third-party apps to enhance the functionality of your website. From social media integrations to marketing tools, there are many apps available to customize your Wix site.</p>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-8">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-8" aria-expanded="false" aria-controls="accordion-collapse-body-8">
                                                <span className='text-[20px] text-[#222] text-left'>What Kind of Customer Support Does Wix Offer?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-8" class="hidden" aria-labelledby="accordion-collapse-heading-8">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Wix provides customer support through its Help Center, which includes articles, tutorials, and FAQs to assist users with common questions and issues. Additionally, premium plan users have access to priority support via phone and email.</p>
                                            </div>
                                        </div>
                                        <h2 id="accordion-collapse-heading-9">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-9" aria-expanded="false" aria-controls="accordion-collapse-body-9">
                                                <span className='text-[20px] text-[#222] text-left'>Can I Change Templates After I've Started Building my Wix Website?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-9" class="hidden" aria-labelledby="accordion-collapse-heading-9">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Yes, you can change templates at any time without losing your content. Wix allows you to switch templates and customize the design of your website as often as you like.</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className="w-[30%] hidden lg:block relative">
                            <div className='box-shadow rounded-lg ml-5 p-8 sidebar sticky top-[-150px]'>
                                <div className="sidebar_banner">
                                    <img src="img/page_img_4.jpg" alt="" />
                                </div>

                                <div className="sidebar_tabs mt-5">
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/wordpress">
                                            <h1 className='text-[18px] font-semibold capitalize'>Wordpress</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/wix">
                                            <h1 className='text-[18px] font-semibold capitalize'>wix</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/webflow">
                                            <h1 className='text-[18px] font-semibold capitalize'>webflow</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/figma">
                                            <h1 className='text-[18px] font-semibold capitalize'>figma</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/woocommerce">
                                            <h1 className='text-[18px] font-semibold capitalize'>Woo Commerce</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/shopify">
                                            <h1 className='text-[18px] font-semibold capitalize'>shopify</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/bubble">
                                            <h1 className='text-[18px] font-semibold capitalize'>Bubble.io</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/squarespace">
                                            <h1 className='text-[18px] font-semibold capitalize'>Squre Space</h1>
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Wix