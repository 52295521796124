import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { Link } from "react-router-dom";

function VideoSubmissionSitesList() {
    return (
        <>
            <Header />
            <div className="container-fluid p-3 lg:p-0">
                <div className="container mx-auto py-12">
                    <h1 className='text-[28px] md:text-[42px] font-medium heading  mb-8 '>Top Video Submission Sites List 2024
                    </h1>
                    <div className="flex flex-wrap md:flex-nowrap pt-12 border-t-2">
                        <div className="w-full lg:w-[70%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <div className="text_contenr pt-3 pb-8">
                                <p className='text-[18px] font-normal text-[#636363] '>Searching for websites with a high DA for
                                    video submissions to promote your business? You can trust BulkSEOSite! We have compiled a
                                    list of the top video submission sites because we appreciate your business just as much as
                                    you do and want to help you grow it.</p><br />
                                <p className='text-[18px] font-normal text-[#636363] '>Every single owner of a website wants it
                                    to appear among Google's top searches. However, search engine optimization can help you
                                    achieve this goal and increase website traffic and engagement. </p> <br />
                                <p className='text-[18px] font-normal text-[#636363] '>Are you interested in using the lists of
                                    video-sharing and submission sites to increase the rating of your Google page? Videos
                                    provide credibility, organic traffic, backlinks, and visibility and can generate more leads
                                    than a standard post.</p> <br />
                                <p className='text-[18px] font-normal text-[#636363] '>When you're ready to start promoting your
                                    website online through digital marketing, start with the lists of websites that accept video
                                    submissions and those that share videos, and you'll get excellent results. </p> <br />
                                <p className='text-[18px] font-normal text-[#636363] '>Due to their popularity and well-known
                                    brands, several free video-sharing websites and video submission portals also let you rank
                                    your page.</p> <br />

                                <h1 className='text-[32px] font-medium my-3 heading'>What is Video Submission or Sharing?</h1>
                                <br />
                                <p className='text-[18px] font-normal text-[#636363] '>To obtain high-quality backlinks, one
                                    might post videos to video submission websites. Additionally, video websites work wonders
                                    for advertising and raising brand recognition.</p> <br />
                                <p className='text-[18px] font-normal text-[#636363] '>Video sharing sites is one of the
                                    Off-page SEO strategy where the submission or sharing of videos take space along with the
                                    website URL. Videos provide credibility, organic traffic, backlinks, and visibility and can
                                    generate more leads than a standard post.</p> <br />
                                <p className='text-[18px] font-normal text-[#636363] '>Video submission is one of the most
                                    popular backlink-building tools for executing your SEO plans.</p> <br />

                                <h1 className='text-[32px] font-medium my-3 heading'>Importance of Video submission sites</h1>
                                <br />
                                <p className='text-[18px] font-normal text-[#636363] '>People constantly have little time in
                                    this digital age. Therefore, digesting information in photographs or papers or carefully
                                    reading words can be challenging! Thus, a good, informative video consistently draws in more
                                    viewers than any picture, graphic, document, or audio file. Thus, submitting videos to
                                    high-authority video submission sites has gained much traction and is now a widely used
                                    strategy for increasing backlinks, increasing traffic, and improving a site's ranking in
                                    Google search engine results.</p> <br />
                                <p className='text-[18px] font-normal text-[#636363] '>The benefit of video uploads for SEO, or
                                    its value, cannot be overstated. Here are a few explanations:</p> <br />
                                <ul className='bg-[#f4dacd70] p-8'>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span></strong>It helps with
                                        internet brand promotion. Sharing information via a video is preferable and very
                                        effective if you own a business service or product and want to market to get the
                                        audience aware of it or consume it.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span></strong>It benefits getting a
                                        sizable number of people to visit business websites.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span></strong>You can use the video
                                        to display the details of the products or services you are selling and ask people to
                                        click on your link or visit your website.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span></strong>Aids in obtaining
                                        backlinks of the highest caliber for your website.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span></strong>It aids in raising a
                                        website's Google SERP ranking.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span></strong>Adding a video to a
                                        high-ranking video submission website can also raise your website's DA, PA, PR, and
                                        Alexa rankings.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span></strong>Get authoritative,
                                        high-quality backlinks for your website.</li>
                                    <br />
                                </ul>

                                <h1 className='text-[32px] font-medium my-3 heading'>Why Video Submission Sites?</h1><br />
                                <p className='text-[18px] font-normal text-[#636363] '>As previously noted, uploading
                                    high-quality videos to sites that allow free or paid submission can significantly increase
                                    website traffic. However, one should also keep in mind that submitting anything and
                                    everything in an attempt to obtain an increasing number of backlinks may be detrimental.
                                    Additionally, there's a reasonable probability of spam. Thus, choosing a video submission
                                    website carefully and methodically is essential.</p> <br />
                                <p className='text-[18px] font-normal text-[#636363] '>The benefit of video uploads for SEO, or
                                    its value, cannot be overstated. Here are a few explanations:</p> <br />
                                <ul className='bg-[#f4dacd70] p-8'>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>1. User Preference for
                                        Videos:</strong>Reading lengthy articles or posts might be tiresome for some people.
                                        Videos, on the other hand, provide a simpler and more entertaining approach to absorbing
                                        knowledge. Videos are more likely to be viewed and shared due to this preference.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>2. Compatibility Across
                                        Devices:</strong>Videos are adaptable and simple to watch on various gadgets,
                                        including computers, tablets, and smartphones. A wider audience can view your material
                                        thanks to its broad compatibility.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>3. High Density of
                                        Information:</strong>A single film can say as much as one hundred thousand words.
                                        The high information density makes you communicate more effectively and efficiently than
                                        text.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>4. Social Sharing
                                        Tendency:</strong>People are likelier to post videos on social media platforms and
                                        share them with friends and family. This propensity increases the likelihood of your
                                        work becoming viral and gaining more visibility.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>5. Powerful Promotional
                                        Tool:</strong>Using videos to advertise your company can be successful. They are an
                                        effective marketing technique since they may draw in customers fast and create a lasting
                                        impression.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>6. The trend of High-Quality
                                        Videos:</strong>High-quality videos are becoming increasingly popular. Exciting and
                                        high-quality videos have a higher chance of getting views, shares, and likes, making
                                        them more powerful marketing tools.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>7. Comfort and
                                        Relaxation:</strong>Watching videos is often more comfortable and soothing than
                                        reading text. Extended engagement periods with your material may result from this ease
                                        of consumption.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>8. Engagement and
                                        Interest:</strong>Regarding engagement and interest, videos frequently outperform
                                        text. They can employ visual and audio components to give the audience a more engaging
                                        and dynamic experience.</li>
                                    <br />
                                </ul>

                                <h1 className='text-[32px] font-medium my-3 heading'>How Video Submission Works</h1><br />
                                <ul className='bg-[#f4dacd70] p-8'>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Producing and Posting Videos:
                                    </strong>Initially, make a captivating and pertinent video. This might be a tutorial, an
                                        article about your brand, or anything else that benefits your readers. You post your
                                        finished film to several websites that allow users to submit videos. Well-known websites
                                        like Dailymotion, Vimeo, and YouTube are excellent starting points.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Acquiring Backlinks:
                                    </strong>The video description usually contains a link to your website when you upload a
                                        video. This links back to your website. Backlinks are essential to SEO since they tell
                                        search engines like Google that your website is a significant resource to improve your
                                        site's search ranks.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Brand Promotion and Awareness:
                                    </strong>Videos are a great way to spread the word about your brand. They have the power
                                        to communicate your message successfully, captivate the audience visually, and make an
                                        impression. You may reach a larger audience and build a more substantial online presence
                                        by posting films about your company or goods.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Social Media Video Sharing:
                                    </strong>Part of the plan includes submitting your video to video websites and posting
                                        the link on social media. Your video's reach is increased when you share it on social
                                        media platforms like Facebook, Twitter, and LinkedIn. Higher engagement rates and more
                                        significant website traffic may result from this enhanced visibility.</li>
                                    <br />
                                </ul>

                                <h1 className='text-[32px] font-medium my-3 heading'>How To Submit Videos To Video Submission
                                    Sites</h1><br />
                                <p className='text-[18px] font-normal text-[#636363] '>The steps involved in submitting a video
                                    are as follows. These are easy methods that anyone may take to develop links for websites,
                                    online advertising, and online marketing:</p> <br />
                                <ul className='bg-[#f4dacd70] p-8'>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Step 1: </strong>First and
                                        foremost, you must make a movie covering every aspect of your program, services, and
                                        goods you wish to highlight. The film might include the owner's introduction, members,
                                        and other information about your company. The video file type may be MKV, FLV, AVI, WMV,
                                        or MP4. The MP4 format is most frequently utilized for submitting videos for link
                                        building.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Step 2: </strong>The second
                                        step is converting your produced videos into your chosen format and pixel count.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Step 3: </strong>Make the
                                        video visually appealing. The video needs to be visually appealing to grab viewers'
                                        attention. You can utilize pictures that accurately represent your company website as
                                        snapshots at strategic locations to create a visually appealing video.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Step 4: </strong>Make the
                                        video brief, accessible, and intelligible. Five to ten minutes is the recommended time
                                        for videos to be uploaded to a video submission website.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Step 5: </strong>Choose a few
                                        high DA video submission websites when your video is prepared to be submitted. You have
                                        a choice from the websites listed above. The majority of the websites listed below have
                                        high DA and PA. Always choose well-known websites with a sizable user base and high
                                        authority.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Step 6: </strong>Before
                                        posting a video, register on their website. Thus, register necessary fields and give
                                        thoughtful and sincere information.</li>
                                    <br />
                                    <li className='text-[18px] font-normal text-[#636363] '><strong> <span
                                        className='text-[#63E6BE] mr-2'><i
                                            class="fa-regular fa-square-check"></i></span>Step 7: </strong>After
                                        registration, click on the buttons labeled "share your video," "upload your video,"
                                        "submit your video," "submit a video file," "post your video," and so on to start the
                                        submission process.</li>
                                    <br />
                                </ul>

                                <h1 className='text-[32px] font-medium my-3 heading'>Top 10 Video Submission Sites for 2024</h1>
                            </div>
                            <div class="relative overflow-x-auto shadow-md sm:rounded-lg ">
                                <table class="w-full text-sm text-left rtl:text-right text-gray-500 darks:text-gray-400">
                                    <thead
                                        class="text-xs text-gray-700 uppercase bg-gray-50 darks:bg-gray-700 darks:text-gray-400">
                                        <tr>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                SNo.
                                            </th>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                Top Video Submission Websites
                                            </th>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                DA
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.youtube.com/"><span>https://www.youtube.com/</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>100</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>2</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.vimeo.com/"><span>https://www.vimeo.com/</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>97</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>3</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.dailymotion.com/"><span>https://www.dailymotion.com/</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>96</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>4</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.facebook.com/"><span>https://www.facebook.com/</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>96</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>5</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.myspace.com/"><span>https://www.myspace.com/</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>95</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.twitter.com/"><span>https://www.twitter.com/</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>93</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.instagram.com/"><span>https://www.instagram.com/</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>93</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.twitch.tv/"><span>https://www.twitch.tv/</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>93</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.snapchat.com/"><span>https://www.snapchat.com/</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>92</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.tumblr.com/"><span>https://www.tumblr.com/</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>85</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h1 className='text-[32px] font-medium my-3 heading mt-12'>High DA Video Submission Sites List For
                                2024</h1>
                            <p className='text-[18px] font-normal text-[#636363] '>Here are several lists of high domain
                                authority video submission websites where you can upload a video and obtain valuable backlinks
                                to strengthen your website's authority.</p> <br />

                            <div class="relative overflow-x-auto shadow-md sm:rounded-lg ">
                                <table class="w-full text-sm text-left rtl:text-right text-gray-500 darks:text-gray-400">
                                    <thead
                                        class="text-xs text-gray-700 uppercase bg-gray-50 darks:bg-gray-700 darks:text-gray-400">
                                        <tr>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                SNo.
                                            </th>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                Video Submission Sites
                                            </th>
                                            <th scope="col" class="px-8 py-6 text-black text-[16px]">
                                                DA
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>1</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.dailymotion.com/in"><span>https://www.dailymotion.com/in</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>95</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>2</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.dropbox.com/?landing=dbv2"><span>https://www.dropbox.com/?landing=dbv2</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>95</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>3</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.4shared.com/"><span>https://www.4shared.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>94</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>4</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.archive.org/"><span>https://www.archive.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>94</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>5</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.mediafire.com/"><span>http://www.mediafire.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>94</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>6</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.sapo.pt/"><span>https://www.sapo.pt</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>93</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>7</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.zippyshare.com/"><span>https://www.zippyshare.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>93</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>8</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.livejournal.com/"><span>https://www.livejournal.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>93</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>9</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.photobucket.com/"><span>https://www.photobucket.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>93</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>10</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.evernote.com/"><span>https://www.evernote.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>92</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>11</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.imageshack.com/"><span>https://www.imageshack.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>92</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>12</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.flickr.com/"><span>https://www.flickr.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>92</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>13</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.rediff.com/"><span>https://www.rediff.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>92</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.reddit.com/"><span>https://www.reddit.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>92</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.uploaded.net/"><span>http://www.uploaded.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>91</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ustream.tv/"><span>http://www.ustream.tv</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>91</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>17</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.flipboard.com/"><span>https://www.flipboard.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>91</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.hulu.com/welcome"><span>https://www.hulu.com/welcome</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>90</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://youku.com/"><span>http://youku.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>90</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.soup.io/"><span>https://www.soup.io</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>89</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://vine.co/"><span>https://vine.co</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>89</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.brightcove.com/en"><span>https://www.brightcove.com/en</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>88</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.coub.com/"><span>https://www.coub.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>88</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.prosieben.de/video"><span>https://www.prosieben.de/video</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>88</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.metacafe.com/"><span>https://www.metacafe.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>86</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.minds.com/"><span>https://www.minds.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>86</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.visual.ly/"><span>https://www.visual.ly</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>86</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.bitchute.com/"><span>https://www.bitchute.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>86</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.mensjournal.com/"><span>https://www.mensjournal.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>85</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.filedropper.com/"><span>http://www.filedropper.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>85</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.funnyordie.com/"><span>https://www.funnyordie.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>84</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.thinglink.com/"><span>https://www.thinglink.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>84</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.docdroid.net/"><span>https://www.docdroid.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>83</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>34</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.filefactory.com/"><span>http://www.filefactory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>83</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.buzznet.com/?chrome=1"><span>https://www.buzznet.com/?chrome=1</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>82</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>36</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.break.com/"><span>https://www.break.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>82</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>37</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.wistia.com/"><span>https://www.wistia.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>82</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.mobypicture.com/"><span>http://www.mobypicture.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>82</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>39</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.younow.com/"><span>https://www.younow.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>80</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>40</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.veoh.com/"><span>https://www.veoh.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>79</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>41</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.viddler.com/"><span>https://www.viddler.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>77</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>42</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.fark.com/"><span>https://www.fark.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>76</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>43</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.mix.com/"><span>https://www.mix.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>75</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>44</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.fotki.com/"><span>https://www.fotki.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>75</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>45</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://funnyjunk.com/"><span>https://funnyjunk.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>74</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>46</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.teachertube.com/"><span>https://www.teachertube.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>73</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>47</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.jibjab.com/"><span>https://www.jibjab.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>71</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>48</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.getmiro.com/"><span>http://www.getmiro.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>70</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>49</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.slideserve.com/"><span>https://www.slideserve.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>69</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>50</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.godtube.com/"><span>https://www.godtube.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>68</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>51</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.izlesene.com/"><span>https://www.izlesene.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>67</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>52</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.webwire.com/"><span>http://www.webwire.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>66</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>53</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.directory.r-tt.com/"><span>http://www.directory.r-tt.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>65</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>54</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://blinkx.com/"><span>https://blinkx.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>65</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.inspire.com/"><span>http://www.inspire.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.usalistingdirectory.com/"><span>http://www.usalistingdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>57</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.apsense.com/"><span>http://www.apsense.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>58</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.evancarmichael.com/"><span>http://www.evancarmichael.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>59</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articledashboard.com/"><span>http://www.articledashboard.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>60</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.prleap.com/"><span>http://www.prleap.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.dacast.com/"><span>https://www.dacast.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>62</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articlesfactory.com/"><span>http://www.articlesfactory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>63</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.isnare.com/"><span>http://www.isnare.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>64</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.dropmark.com/"><span>https://www.dropmark.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>61</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>65</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.pusha.se/"><span>http://www.pusha.se</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>60</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>66</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.sitepromotiondirectory.com/"><span>http://www.sitepromotiondirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>59</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>67</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articlecity.com/"><span>http://www.articlecity.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>58</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>68</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.bubblews.com/"><span>http://www.bubblews.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>58</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>69</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articlealley.com/"><span>http://www.articlealley.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>57</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>70</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articlesnatch.com/"><span>http://www.articlesnatch.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>71</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.blogsome.com/"><span>http://www.blogsome.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>72</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ideamarketers.com/"><span>http://www.ideamarketers.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>73</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://stupidvideos.com/"><span>http://stupidvideos.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>74</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.vidivodo.com/"><span>https://www.vidivodo.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>56</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>75</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.infobarrel.com/"><span>http://www.infobarrel.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>76</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.highrankdirectory.com/"><span>http://www.highrankdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>77</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.web-source.net/"><span>http://www.web-source.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>78</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.magportal.com/"><span>http://www.magportal.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>79</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.transferbigfiles.com/"><span>https://www.transferbigfiles.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>55</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>80</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.marketinginternetdirectory.com/"><span>http://www.marketinginternetdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>54</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>81</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.businessseek.biz/"><span>http://www.businessseek.biz</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>54</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>82</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.bukisa.com/"><span>http://www.bukisa.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>54</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>83</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.webproworld.com/"><span>http://www.webproworld.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>54</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>84</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.storeboard.com/"><span>http://www.storeboard.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>53</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>85</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.allthewebsites.org/"><span>http://www.allthewebsites.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>53</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>86</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ukinternetdirectory.net/"><span>http://www.ukinternetdirectory.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>52</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>87</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.threadwatch.org/"><span>http://www.threadwatch.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>52</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>88</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://expotv.com/"><span>http://expotv.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>52</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>89</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.amazines.com/"><span>http://www.amazines.com/</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>51</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>90</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.powerhomebiz.com/"><span>https://www.powerhomebiz.com/</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>51</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>91</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.sooperarticles.com/"><span>http://www.sooperarticles.com/</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>50</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>92</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.constant-content.com/"><span>http://www.constant-content.com/</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>50</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>93</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.traveltourismdirectory.net/"><span>http://www.traveltourismdirectory.net/</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>50</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>94</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articletrader.com/"><span>http://www.articletrader.com/</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>50</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>95</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.prolinkdirectory.com/"><span>http://www.prolinkdirectory.com/</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>49</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>96</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articles.submityourarticle.com/"><span>http://www.articles.submityourarticle.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>49</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>97</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.selfseo.com/"><span>http://www.selfseo.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>49</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>98</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articlecube.com/"><span>http://www.articlecube.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>48</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>99</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.work911.com/"><span>http://www.work911.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>48</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>100</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://die-erklaervideo-agentur.com/"><span>https://die-erklaervideo-agentur.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>48</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>101</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.bpubs.com/"><span>http://www.bpubs.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>47</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>102</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articlerich.com/"><span>http://www.articlerich.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>47</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>103</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.top7business.com/"><span>http://www.top7business.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>46</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>104</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articles.pubarticles.com/"><span>http://www.articles.pubarticles.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>45</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>105</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.triond.com/"><span>http://www.triond.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>45</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>106</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articles.org/"><span>http://www.articles.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>44</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>107</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articlebiz.com/"><span>http://www.articlebiz.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>44</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>108</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.cgidir.com/"><span>http://www.cgidir.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>44</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>109</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.rlrouse.com/"><span>http://www.rlrouse.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>44</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>110</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ex-designz.net/"><span>http://www.ex-designz.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>44</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>111</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.artipot.com/"><span>http://www.artipot.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>43</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>112</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.homebiztools.com/"><span>http://www.homebiztools.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>43</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>113</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articlecirculation.com/"><span>http://www.articlecirculation.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>42</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>114</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="https://www.indiavideo.org/"><span>https://www.indiavideo.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>42</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>115</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.a1articles.com/"><span>http://www.a1articles.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>41</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>116</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.upublish.info/"><span>http://www.upublish.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>41</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>117</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.presszoom.com/"><span>http://www.presszoom.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>41</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>118</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articleclick.com/"><span>http://www.articleclick.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>40</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>119</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.themanager.org/"><span>http://www.themanager.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>40</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>120</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articlesphere.com/"><span>http://www.articlesphere.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>39</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>121</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.streetarticles.com/"><span>http://www.streetarticles.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>39</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>122</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.uberarticles.com/"><span>http://www.uberarticles.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>39</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>123</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.content-articles.com/"><span>http://www.content-articles.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>39</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>124</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.populararticles.com/"><span>http://www.populararticles.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>39</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>125</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.simplysearch4it.com/"><span>http://www.simplysearch4it.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>126</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.libervis.com/"><span>http://www.libervis.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>127</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.easyarticles.com/"><span>http://www.easyarticles.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>38</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>128</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articleslash.net/"><span>http://www.articleslash.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>37</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>129</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.howtoadvice.com/"><span>http://www.howtoadvice.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>37</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>130</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.dime-co.com/"><span>http://www.dime-co.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>37</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>131</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.datasoftsystem.com/"><span>http://www.datasoftsystem.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>37</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>132</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.earticlesonline.com/"><span>http://www.earticlesonline.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>36</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>133</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articlesxpert.com/"><span>http://www.articlesxpert.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>36</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>134</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.edarticle.com/"><span>http://www.edarticle.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>36</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>135</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.informationbible.com/"><span>http://www.informationbible.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>36</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>136</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articles.studio9xb.com/"><span>http://www.articles.studio9xb.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>137</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articles.gappoo.com/"><span>http://www.articles.gappoo.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>138</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.top-affiliate.com/"><span>http://www.top-affiliate.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>139</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articledunia.com/"><span>http://www.articledunia.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>140</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.clipmoon.com/"><span>http://www.clipmoon.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>35</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>141</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.webmasterslibrary.com/"><span>http://www.webmasterslibrary.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>34</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>142</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.free-articles-zone.com/"><span>http://www.free-articles-zone.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>34</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>143</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.myarticle.com/"><span>http://www.myarticle.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>144</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articlestars.com/"><span>http://www.articlestars.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>145</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.approvedarticles.com/"><span>http://www.approvedarticles.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>146</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articledoctor.com/"><span>http://www.articledoctor.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>147</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articlecell.com/"><span>http://www.articlecell.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>148</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.expertarticles.com/"><span>http://www.expertarticles.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>149</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ladypens.com/"><span>http://www.ladypens.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>33</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>150</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.123articleonline.com/"><span>http://www.123articleonline.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>151</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articlicious.com/"><span>http://www.articlicious.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>152</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articlecompilation.com/"><span>http://www.articlecompilation.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>153</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articleseen.com/"><span>http://www.articleseen.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>154</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articlejoe.com/"><span>http://www.articlejoe.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>32</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>155</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.idleexperts.com/"><span>http://www.idleexperts.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>156</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articlesbd.com/"><span>http://www.articlesbd.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>157</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.yooarticles.net/"><span>http://www.yooarticles.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>158</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articles411.com/"><span>http://www.articles411.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>31</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>159</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articles.seoforums.me.uk/"><span>http://www.articles.seoforums.me.uk</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>160</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articlegold.com/"><span>http://www.articlegold.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>161</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.article-hut.com/"><span>http://www.article-hut.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>162</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.tongshu.net/"><span>http://www.tongshu.net</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>30</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>163</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.easy-articles.com/"><span>http://www.easy-articles.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>164</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.otherarticles.com/"><span>http://www.otherarticles.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>165</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.readezarchive.com/"><span>http://www.readezarchive.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>166</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articleant.com/"><span>http://www.articleant.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>29</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>167</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.thetexasjobsource.com/"><span>http://www.thetexasjobsource.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>168</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articlepdq.com/"><span>http://www.articlepdq.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>169</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ezine-articles-planet.com/"><span>http://www.ezine-articles-planet.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>28</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>170</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.pr4-articles.com/"><span>http://www.pr4-articles.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>171</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articlet.com/"><span>http://www.articlet.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>172</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.adarticles.net/"><span>http://www.adarticles.net</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>173</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articlemonkeys.com/"><span>http://www.articlemonkeys.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>174</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.qwesz.com/"><span>http://www.qwesz.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>27</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>175</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articlecatalog.com/"><span>http://www.articlecatalog.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>176</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.openarticles.com/"><span>http://www.openarticles.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>177</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articleswrap.com/"><span>http://www.articleswrap.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>178</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.yesarticles.com/"><span>http://www.yesarticles.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>179</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.gruks.com/"><span>http://www.gruks.com</span></a></td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>26</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>180</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articleside.com/"><span>http://www.articleside.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>181</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.postarticles.com/"><span>http://www.postarticles.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>182</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.101articles.com/"><span>http://www.101articles.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>183</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articlefree4all.com/"><span>http://www.articlefree4all.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>25</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>184</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articlecontentking.com/"><span>http://www.articlecontentking.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>185</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.afreearticle.com/"><span>http://www.afreearticle.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>186</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.submitanarticle.info/"><span>http://www.submitanarticle.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>187</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articlerealm.com/"><span>http://www.articlerealm.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>24</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>188</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.ezinepost.com/"><span>http://www.ezinepost.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>189</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.thehealthyvillage.com/"><span>http://www.thehealthyvillage.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>23</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>190</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.bysharing.com/"><span>http://www.bysharing.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>191</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.olmaweblinkdirectory.com/"><span>http://www.olmaweblinkdirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>192</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.allinclusivelinks.com/"><span>http://www.allinclusivelinks.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>22</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>193</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.thepr.com.au/"><span>http://www.thepr.com.au</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>194</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.mymedicenter.com/"><span>http://www.mymedicenter.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>21</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>195</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articlesdir.org/"><span>http://www.articlesdir.org</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>196</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.writerspenarticledirectory.com/"><span>http://www.writerspenarticledirectory.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>20</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>197</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.jazzylook.com/"><span>http://www.jazzylook.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>198</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.textiledegree.com/"><span>http://www.textiledegree.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>19</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>199</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articlekit.com/"><span>http://www.articlekit.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>18</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>200</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articleswale.com/"><span>http://www.articleswale.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>201</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.wall2wallarticles.com/"><span>http://www.wall2wallarticles.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>16</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>202</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.seosubmitarticle.com/"><span>http://www.seosubmitarticle.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>203</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.articlesforwebsite.com/"><span>http://www.articlesforwebsite.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>204</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.sdcouncil.com/"><span>http://www.sdcouncil.com</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>15</span>
                                            </td>
                                        </tr>
                                        <tr class="odd:bg-white even:bg-gray-50 border-b darks:border-gray-700">
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>205</span>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]"><a
                                                href="http://www.thewhir.info/"><span>http://www.thewhir.info</span></a>
                                            </td>
                                            <td class="px-8 py-6 font-medium text-gray-900 whitespace-nowrap text-[18px]">
                                                <span>14</span>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            <h1 className='text-[32px] font-medium my-3 heading mt-12'>So, Are You Ready For Video Submission
                                Sites?</h1>
                            <p className='text-[18px] font-normal text-[#636363] '>Ranking well in Google search results and
                                drawing visitors to websites have become more competitive due to the growing number of websites,
                                online businesses, e-commerce sites, worldwide communication channels, and easily accessible
                                internet. However, it's important to remember that in many situations, quality does matter more
                                than rank in Google. Thus, besides having more backlinks pointing to your website, you must
                                concentrate on backlinking from high-quality websites. With the help of the knowledge above on
                                video upload for SEO, you may raise your site's search engine rating and rapidly increase
                                visitors.</p> <br />

                            <div className="faqs mb-12">
                                <h1 className='text-[32px] font-medium my-3 heading'>FAQs</h1>
                                <div id="accordion-collapse" data-accordion="collapse">
                                    <h2 id="accordion-collapse-heading-1">
                                        <button type="button"
                                            class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200  hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                            data-accordion-target="#accordion-collapse-body-1" aria-expanded="true"
                                            aria-controls="accordion-collapse-body-1">
                                            <span className='text-[20px] text-[#222] text-left'>What are Video Sharing
                                                Sites?</span>
                                            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                    strokeWidth="2" d="M9 5 5 1 1 5" />
                                            </svg>
                                        </button>
                                    </h2>
                                    <div id="accordion-collapse-body-1" class="hidden"
                                        aria-labelledby="accordion-collapse-heading-1">
                                        <div
                                            class="p-5 border border-b-0 border-gray-200 darks:border-gray-700 darks:bg-gray-900">
                                            <p className='text-[18px] font-normal text-[#636363] '>You can publish videos to
                                                video-sharing websites to obtain high-quality backlinks. These channels are
                                                excellent for marketing and raising brand awareness.</p>
                                        </div>
                                    </div>
                                    <h2 id="accordion-collapse-heading-2">
                                        <button type="button"
                                            class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                            data-accordion-target="#accordion-collapse-body-2" aria-expanded="false"
                                            aria-controls="accordion-collapse-body-2">
                                            <span className='text-[20px] text-[#222] text-left'>Why are Videos Important For
                                                Promotion?</span>
                                            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                    strokeWidth="2" d="M9 5 5 1 1 5" />
                                            </svg>
                                        </button>
                                    </h2>
                                    <div id="accordion-collapse-body-2" class="hidden"
                                        aria-labelledby="accordion-collapse-heading-2">
                                        <div class="p-5 border border-b-0 border-gray-200 darks:border-gray-700">
                                            <p className='text-[18px] font-normal text-[#636363] '>One effective technique to
                                                increase the visibility of your website is to upload videos. Increased website
                                                traffic and backlinks can be achieved quickly using reputable video submission
                                                websites.</p>
                                        </div>
                                    </div>
                                    <h2 id="accordion-collapse-heading-3">
                                        <button type="button"
                                            class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                            data-accordion-target="#accordion-collapse-body-3" aria-expanded="false"
                                            aria-controls="accordion-collapse-body-3">
                                            <span className='text-[20px] text-[#222] text-left'>Why is Video Submission Key in
                                                SEO?</span>
                                            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                    strokeWidth="2" d="M9 5 5 1 1 5" />
                                            </svg>
                                        </button>
                                    </h2>
                                    <div id="accordion-collapse-body-3" class="hidden"
                                        aria-labelledby="accordion-collapse-heading-3">
                                        <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                            <p className='text-[18px] font-normal text-[#636363] '>Video posting is one of the
                                                most essential SEO tactics for raising website traffic and ranking. The sites
                                                used for video submission are crucial to its efficacy; they should rank well on
                                                Moz, Alexa, PA, DA, and DA. There are several SEO benefits to using this
                                                approach.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-[30%] hidden lg:block relative">
                            <div className='box-shadow rounded-lg ml-5 p-8 sidebar sticky top-[-150px]'>
                                <div className="sidebar_banner">
                                    <img src="img/page_img_4.jpg" alt="" />
                                </div>

                                <div className="sidebar_tabs mt-5">
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/profile-creation-sites-list">
                                            <h1 className='text-[18px] font-semibold capitalize'>Top High Do Follow Profile Creation
                                                Sites List</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/image-submission-sites-list">
                                            <h1 className='text-[18px] font-semibold capitalize'>image-submission-sites-list</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/classified-ads-websites">
                                            <h1 className='text-[18px] font-semibold capitalize'>classified-ads-websites</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/instant-approval-article-submissions-sites-list">
                                            <h1 className='text-[18px] font-semibold capitalize'>instant-approval-article-submissions-sites-list</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/free-high-quality-directory-submission-sites">
                                            <h1 className='text-[18px] font-semibold capitalize'>free-high-quality-directory-submission-sites
                                                Sites List</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/blog-submission-sites">
                                            <h1 className='text-[18px] font-semibold capitalize'>blog-submission-sites</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/business-listing-sites-in-india">
                                            <h1 className='text-[18px] font-semibold capitalize'>business-listing-sites-in-india</h1>
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default VideoSubmissionSitesList