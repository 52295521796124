import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { Link } from "react-router-dom";

function Laravel() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{ backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)" }}>
                <div className="container mx-auto py-12">
                    <h1 className='text-center text-white text-[34px] md:text-[42px] lg:text-[56px] xl:text-[86px] font-bold heading uppercase'>Laravel</h1>
                </div>
            </div>

            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[70%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <img src="img/laravel.jpg" className='w-full' alt="" />

                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Laravel</h1>
                                <p className='text-[18px] font-normal text-[#636363] '><strong>Bonafide Technologies Offers Sturdy Laravel Web Development Solutions!</strong></p><br />
                                <p className='text-[18px] font-normal text-[#636363] '>One of the most popular PHP MVC frameworks, Laravel provides the greatest online application development in a simple yet effective manner. Bonafide Technologies provides a complete range of first-class, tailored Laravel development services. We collaborate directly with our clients to create scalable web apps, leveraging Laravel to improve your company's online presence.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>Being one of the top Laravel web development companies, giving our clients a competitive advantage in the market requires a variety of actions, one of which is timely and profitable web development. Our skilled Laravel developers have practical expertise in creating innovative online apps for several business sectors. Whether creating a feature-rich web application or a sturdy company website, we provide our clients with timely, bespoke web development solutions.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>A popular PHP framework for web applications, Laravel provides a number of web development options. Web applications, RESTful APIs, e-commerce websites, content management systems, social networking platforms, and real-time applications can all be created using it. You can use Laravel to develop scalable and reliable web applications that satisfy your business requirements.</p>
                            </div>

                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'> What We Provide</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>With Laravel, you can streamline your development process and begin building your next web application right now.</p><br />

                                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                                    <div className="flex flex-wrap justify-between">
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Project Planning And Scoping</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>This step involves the first consultation, during which our project managers talk with you to figure out the goals and requirements of your project. After that, our team will look into end-user requirements and determine both front- and backend roles, including identifying the initial endpoints. Based on the study, we will modify your project plan to make it more practical and in line with your growth objectives.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Database Setup</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>For your web application, our developers will establish a database and specify each database connection. This also specifies the particular connection that needs to be used by default. Additionally, we will set up the database based on the environment variable settings of the new web application.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Application Development</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>On the basis of the project planning, we will create the application from beginning to end. This covers the creation of every backend functionality outlined in the project schedule. At Bonafide Technologies, we use the appropriate infrastructure, technology, and systems to finish the job quickly.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Quality Assurance and Testing</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Our QA team will carefully review the program after it has been constructed to make sure that all of the features, functionalities, and interfaces function as expected. If there are any problems with the application's functionality, they will let you know. To guarantee the software functions flawlessly, our testing team will examine every part of it, find any flaws, and submit them to our development team.</p>
                                        </div>

                                    </div>
                                </div>

                                <div className="faqs my-12">
                                    <h1 className='text-[32px] font-medium my-3 heading'>FAQs</h1>
                                    <div id="accordion-collapse" data-accordion="collapse">
                                        <h2 id="accordion-collapse-heading-1">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200  hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                <span className='text-[20px] text-[#222] text-left'>What Is Laravel?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-1" class="hidden" aria-labelledby="accordion-collapse-heading-1">
                                            <div class="p-5 border border-b-0 border-gray-200 darks:border-gray-700 darks:bg-gray-900">
                                                <p className='text-[18px] font-normal text-[#636363] '>Laravel is a powerful PHP framework renowned for its elegant syntax, developer-friendly approach, and extensive feature set. It simplifies the process of web application development by providing tools and libraries for common tasks, such as routing, authentication, and database management.</p>
                                            </div>
                                        </div>
                                        <h2 id="accordion-collapse-heading-2">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-2" aria-expanded="false" aria-controls="accordion-collapse-body-2">
                                                <span className='text-[20px] text-[#222] text-left'>Why Choose Laravel for Web Development?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-2" class="hidden" aria-labelledby="accordion-collapse-heading-2">
                                            <div class="p-5 border border-b-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Laravel offers numerous advantages for web development, including a clean and expressive syntax, built-in features like Eloquent ORM and Blade templating engine, robust security mechanisms, seamless database migration, and a vibrant ecosystem of extensions and packages.</p>
                                            </div>
                                        </div>
                                        <h2 id="accordion-collapse-heading-3">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-3" aria-expanded="false" aria-controls="accordion-collapse-body-3">
                                                <span className='text-[20px] text-[#222] text-left'>What Are the Key Features of Laravel?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-3" class="hidden" aria-labelledby="accordion-collapse-heading-3">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Some key features of Laravel include:</p>
                                                <ul>
                                                    <li className='text-[18px] font-normal text-[#636363] '>Eloquent ORM for database management</li>
                                                    <li className='text-[18px] font-normal text-[#636363] '>Blade templating engine for intuitive views</li>
                                                    <li className='text-[18px] font-normal text-[#636363] '>Artisan command-line interface for automating tasks</li>
                                                    <li className='text-[18px] font-normal text-[#636363] '>Middleware for handling HTTP requests</li>
                                                    <li className='text-[18px] font-normal text-[#636363] '>Authentication and authorization systems</li>
                                                    <li className='text-[18px] font-normal text-[#636363] '>Testing support with PHP Unit</li>
                                                    <li className='text-[18px] font-normal text-[#636363] '>Laravel Mix for asset compilation and optimization</li>
                                                </ul>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-4">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-4" aria-expanded="false" aria-controls="accordion-collapse-body-4">
                                                <span className='text-[20px] text-[#222] text-left'>How Does Laravel Compare to Other PHP Frameworks?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-4" class="hidden" aria-labelledby="accordion-collapse-heading-4">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Laravel stands out among PHP frameworks due to its simplicity, elegance, and complete feature set. Compared to other frameworks like CodeIgniter or Symfony, Laravel offers more built-in functionalities, a smoother learning curve, and a more expressive syntax.</p>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-5">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-5" aria-expanded="false" aria-controls="accordion-collapse-body-5">
                                                <span className='text-[20px] text-[#222] text-left'>What Kind of Projects Can Be Built With Laravel?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-5" class="hidden" aria-labelledby="accordion-collapse-heading-5">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Laravel is versatile and can be used to build a wide range of web applications, including:</p>
                                                <ul>
                                                    <li className='text-[18px] font-normal text-[#636363] '>E-commerce platforms</li>
                                                    <li className='text-[18px] font-normal text-[#636363] '>Content management systems (CMS)</li>
                                                    <li className='text-[18px] font-normal text-[#636363] '>Customer relationship management (CRM) systems</li>
                                                    <li className='text-[18px] font-normal text-[#636363] '>Enterprise resource planning (ERP) software</li>
                                                    <li className='text-[18px] font-normal text-[#636363] '>Social networking platforms</li>
                                                    <li className='text-[18px] font-normal text-[#636363] '>Online marketplaces</li>
                                                </ul>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-6">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-6" aria-expanded="false" aria-controls="accordion-collapse-body-6">
                                                <span className='text-[20px] text-[#222] text-left'>Is Laravel Suitable for Large-Scale Applications?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-6" class="hidden" aria-labelledby="accordion-collapse-heading-6">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Yes, Laravel is well-suited for large-scale applications. Its modular structure, robust architecture, and scalability features make it ideal for handling complex projects with high traffic and extensive functionality. Laravel's built-in tools for caching, queue management, and database optimization further enhance its suitability for enterprise-level applications.</p>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-7">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-7" aria-expanded="false" aria-controls="accordion-collapse-body-7">
                                                <span className='text-[20px] text-[#222] text-left'>What Makes Bonafide Technologies a Prominent Name for Laravel Development?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-7" class="hidden" aria-labelledby="accordion-collapse-heading-7">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Bonafide Technologies is a well-known brand when it comes to providing high-calibre Laravel services in India. Our skilled team of seasoned Laravel developers can assist you in developing solutions of various sizes for a variety of business sectors. You can be confident that our developers and industry specialists will stay up to date on the most recent advancements in the industry to provide you with the finest possible solution.</p>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-8">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-8" aria-expanded="false" aria-controls="accordion-collapse-body-8">
                                                <span className='text-[20px] text-[#222] text-left'>Can You Migrate My Existing Framework to the Laravel Framework?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-8" class="hidden" aria-labelledby="accordion-collapse-heading-8">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Yes. For improved performance, we can quickly switch from your current framework to the Laravel framework. Bonafide is well-known for its Laravel Migration Services, which are our speciality.</p>
                                            </div>
                                        </div>
                                        <h2 id="accordion-collapse-heading-9">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-9" aria-expanded="false" aria-controls="accordion-collapse-body-9">
                                                <span className='text-[20px] text-[#222] text-left'>How Does Your Team of Developers Manage the Code?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-9" class="hidden" aria-labelledby="accordion-collapse-heading-9">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>To handle source code, we make use of the newest technologies. You can be confident that our Laravel developers will periodically share the code with you, giving you access to the source codes at all times.</p>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className="w-[30%] hidden lg:block relative">
                            <div className='box-shadow rounded-lg ml-5 p-8 sidebar sticky top-[-150px]'>
                                <div className="sidebar_banner">
                                    <img src="img/page_img_4.jpg" alt="" />
                                </div>

                                <div className="sidebar_tabs mt-5">
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/full-stack">
                                            <h1 className='text-[18px] font-semibold capitalize'>full stack</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/mern-stack">
                                            <h1 className='text-[18px] font-semibold capitalize'>mern stack</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/javascript">
                                            <h1 className='text-[18px] font-semibold capitalize'>java script</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/cake-php">
                                            <h1 className='text-[18px] font-semibold capitalize'>cake php</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/laravel">
                                            <h1 className='text-[18px] font-semibold capitalize'>laravel</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/reactjs">
                                            <h1 className='text-[18px] font-semibold capitalize'>react JS</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/php">
                                            <h1 className='text-[18px] font-semibold capitalize'>PHP</h1>
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Laravel