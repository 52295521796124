import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Mumbai() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{
                backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)" }}>
                <div className="container mx-auto py-12">
                    <h1
                        className='text-center text-white text-[32px] md:text-[42px] font-semibold heading uppercase'>
                            The Best Digital Marketing Agency in Mumbai
                    </h1>
                </div>
            </div>
        
            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[100%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>The Best Digital Marketing Agency in
                                    Mumbai</h1>
                                <p className='text-[18px] font-normal text-[#636363] '><strong>A multilingual advanced digital
                                        marketing agency helps brands grow and manage their business activities.</strong></p>
                                <br />
                                <p className='text-[18px] font-normal text-[#636363] '>Social media has incredible power
                                    nowadays. Many tiny startups have quickly gained popularity due to their outstanding
                                    promotion across all social media platforms. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>A digital marketing agency is the most
                                    promising bet for business startups to connect with clients, as these businesses need to
                                    grab as much attention from the public as possible to leave an unforgettable impression in
                                    the market.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>For example, a customer orders something,
                                    and within a few days, he receives the product. The transporter bridges the gap between the
                                    maker and the customer.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>Similarly, the carriers in this case are
                                    the digital marketing agency in Mumbai that closes the gap between the manufacturers and the
                                    customer. They can easily access their products and services when they are widely dispersed
                                    among the public.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>Enhancing the online visibility of the
                                    business, integrating it with various platforms, and conducting market research about
                                    competitors' allies are the main goals of digital marketing.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>Possessing digital marketing expertise
                                    can profoundly impact one's life, particularly in the current digital era.<strong>Bonafide
                                        Technologies</strong> provides a variety of revenue streams, including PPC and SEO. You
                                    can enroll in any of the many digital marketing courses in Mumbai to gain practical digital
                                    marketing skills.</p>
                            </div>
                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Top digital marketing agency in Mumbai!
                                    We
                                    design, develop, and execute.</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>If someone declared that Mumbai is the
                                    Indian
                                    advertising capital, it would be hard to deny them. Over the past few years, there has been
                                    a
                                    shift in the status quo. However, Mumbai is home to one of the top digital marketing firms.
                                    Bonafide Technologies, particularly, has an abundance of talented people in the area.</p>
                                <br />
                                <p className='text-[18px] font-normal text-[#636363] '>Bonafide Technologies, a well-known
                                    digital marketing firm, was established to meet the marketing demand and create a digital
                                    presence for brands. There was a significant increase in internet and social media users
                                    starting in 2010. </p><br />
                                <p className='text-[18px] font-normal text-[#636363] '>However, as technology developed in the
                                    digital sphere, management had to keep marketing opportunities, strategies, and tactics
                                    up-to-date. This is why we bring knowledgeable members of the digital marketing community,
                                    such as SEO specialists and social media marketers, to expand our team.</p><br />
                                <p className='text-[18px] font-normal text-[#636363] '>As one of Mumbai's top digital marketing
                                    agencies, we have offered digital marketing services to clients from various industries,
                                    including rubber manufacturing, for the past six years. </p><br />
                                <p className='text-[18px] font-normal text-[#636363] '>Furniture, fashion, sports, and a lot
                                    more! Many companies, including some of our clients, have encountered various difficulties
                                    with digital marketing. Let's examine them down below.</p><br />
        
                                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                                    <div className="flex flex-wrap justify-between">
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Global Creative Operations
                                            </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '><strong> Bonafide
                                                    Technologies</strong> is a leading creative and innovative digital marketing
                                                agency in Mumbai. We provide outstanding global digital and internet marketing
                                                services in 22 countries.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>High Performance</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Our digital marketing experts
                                                will help you to lead and grow your brand globally. We will recruit and oversee
                                                local experts to enhance your marketing initiatives when necessary.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Data-Driven</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>As the top digital marketing
                                                agency in Mumbai, we provide our clients with daily, weekly, and monthly
                                                performance reports to ensure they can access updated data.</p>
                                        </div>
                                    </div>
                                </div>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Contact Us To Get Started With Your Project!</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Our work will start when you choose our digital marketing services. Eventually, we will have to research all about you and your business. You can trust our digital marketing strategies by checking our previous work projects and reviews by our clients.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>We are organized and using tried-and-tested tactics because we value producing high-quality performance. Because of these factors, we are the top pick in Mumbai.</p> 
                                <p className='text-[18px] font-normal text-[#636363] '>Give us a call or send us an email so we can get in touch with you right away.</p>  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        )
}

export default Mumbai