import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { Link } from "react-router-dom";


function AffiliateMarketing() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{
                backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)"
            }}>
                <div className="container mx-auto py-12">
                    <h1
                        className='text-center text-white text-[34px] md:text-[42px] lg:text-[56px] xl:text-[86px] font-bold heading uppercase'>
                        Affiliate Marketing</h1>
                </div>
            </div>
            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[70%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <img src="img/Affiliate-Marketing.jpg" className='w-full' alt="" />

                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Affiliate Marketing</h1>
                                <p className='text-[18px] font-normal text-[#636363] '><strong>We link affiliates to brands and
                                    brands to customers. </strong></p><br />
                                <p className='text-[18px] font-normal text-[#636363] '>Are you an affiliate marketer in India
                                    who is trying to maximize your profits with limited resources? </p>
                                <p className='text-[18px] font-normal text-[#636363] '>Even the most promising affiliate
                                    marketing campaigns can sometimes fail due to volatile client tastes, intense competition,
                                    and unpredictable industry trends. It could be difficult for you to drive significant
                                    traffic to the websites of your partners, or the traffic you are bringing in doesn't convert
                                    into profitable leads. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>Creating an affiliate marketing program
                                    that is directed to an objective and data-centric is the key to getting beyond these
                                    obstacles. But this requires a level of knowledge and resources that you may not have
                                    available to you.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>This is where Bonafide Technologies
                                    becomes useful. Even in India's dynamic and cutthroat environment, we create custom
                                    affiliate marketing plans that drive revenue growth and lead conversion by utilizing data
                                    analytics, creative solutions, and our wealth of experience. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>The affiliate marketing experts at
                                    Bonafide Technologies carry your company forward with profit-oriented tactics, resolving
                                    partner management issues, and producing steady revenue growth.</p>
                            </div>


                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'> What We Provide</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>We can provide complete plans that are
                                    customized to meet your needs because of our experience in the affiliate sector. Expect the
                                    following when working with us: </p><br />

                                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                                    <div className="flex flex-wrap justify-between">
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Advanced Reporting</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>With multitouch attribution,
                                                you can analyze your affiliate marketing using our data-driven methods. Reports
                                                that are customizable display affiliate campaign results in relation to your
                                                KPIs. Our skilled team of affiliate marketers assesses results and provides
                                                customized, useful insights.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Affiliate Activity Audit</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Complete data analysis of the
                                                effectiveness of affiliate marketing campaigns and opportunity detection.
                                                Complete analysis of the affiliate marketing industry's competitors, customized
                                                to your campaign's goals. Actionable, data-driven suggestions to direct your
                                                future affiliate marketing plan. Affiliate campaigns can be seamlessly
                                                integrated into your whole channel mix with combined invoicing.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Affiliate Management</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Use our cooperative
                                                connections with all of our main affiliate partners. Our group handles all rate
                                                negotiations, ensuring that you receive the best returns on your investment. We
                                                manage the delivery of any self-serve platform and suggest partners and tactics
                                                that are best for you.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="faqs my-12">
                                    <h1 className='text-[32px] font-medium my-3 heading'> FAQs</h1>
                                    <div id="accordion-collapse" data-accordion="collapse">
                                        <h2 id="accordion-collapse-heading-1">
                                            <button type="button"
                                                class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200  hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                                data-accordion-target="#accordion-collapse-body-1" aria-expanded="true"
                                                aria-controls="accordion-collapse-body-1">
                                                <span className='text-[20px] text-[#222] text-left'>What Distinguishes Your
                                                    Affiliate Marketing Company From The Competition? </span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                        strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-1" class="hidden"
                                            aria-labelledby="accordion-collapse-heading-1">
                                            <div
                                                class="p-5 border border-b-0 border-gray-200 darks:border-gray-700 darks:bg-gray-900">
                                                <p className='text-[18px] font-normal text-[#636363] '>Our staff has extensive
                                                    knowledge of the complex workings of the affiliate marketing industry,
                                                    making us much more than simply another affiliate marketing company. Here in
                                                    India, we work with businesses from a wide range of industries. Our strategy
                                                    is specifically customized and relies on our extensive industry knowledge,
                                                    evidence-based tactics, and keen sense of creativity. We always stay up to
                                                    date on the latest developments in affiliate marketing because we are
                                                    located in the thriving hub of the digital marketing universe.</p>
                                            </div>
                                        </div>
                                        <h2 id="accordion-collapse-heading-2">
                                            <button type="button"
                                                class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                                data-accordion-target="#accordion-collapse-body-2" aria-expanded="false"
                                                aria-controls="accordion-collapse-body-2">
                                                <span className='text-[20px] text-[#222] text-left'>How Does Affiliate Marketing
                                                    Differ From Traditional Marketing Efforts?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                        strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-2" class="hidden"
                                            aria-labelledby="accordion-collapse-heading-2">
                                            <div class="p-5 border border-b-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>The traditional marketing
                                                    strategy of investing heavily upfront and hoping for a return on investment
                                                    is being taken over in India's dynamic business environment. Affiliate
                                                    marketing is a new player in town. It follows a model that is
                                                    performance-based. This creative strategy makes sure that your investment is
                                                    only needed when a final result is reached, such as a sale, a lead
                                                    generation, or a click. This strategy dramatically reduces risk while
                                                    increasing return on investment, which makes it an appealing option for
                                                    companies looking to succeed in the market.</p>
                                            </div>
                                        </div>
                                        <h2 id="accordion-collapse-heading-3">
                                            <button type="button"
                                                class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                                data-accordion-target="#accordion-collapse-body-3" aria-expanded="false"
                                                aria-controls="accordion-collapse-body-3">
                                                <span className='text-[20px] text-[#222] text-left'>Which Sectors Stand To Gain
                                                    The Most From An Affiliate Marketing Firm? </span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                        strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-3" class="hidden"
                                            aria-labelledby="accordion-collapse-heading-3">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Indeed, there isn't a
                                                    single, complete answer for affiliate marketing. The success of these
                                                    strategies depends on how well your product, target market, and chosen
                                                    affiliates work together. Our team has expertise in finding the best fit for
                                                    your business, using a combination of historical performance data and
                                                    current market intelligence to generate significant revenue growth.</p>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-4">
                                            <button type="button"
                                                class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                                data-accordion-target="#accordion-collapse-body-4" aria-expanded="false"
                                                aria-controls="accordion-collapse-body-4">
                                                <span className='text-[20px] text-[#222] text-left'>What Is Affiliate
                                                    Marketing?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                        strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-4" class="hidden"
                                            aria-labelledby="accordion-collapse-heading-4">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>The process of promoting
                                                    your goods or services through links to websites that your target audience
                                                    is inclined to visit is known as affiliate marketing. These websites are the
                                                    affiliates that enlist in affiliate marketing schemes in order to get paid a
                                                    commission when referrals generate purchases. Affiliates seeking to monetize
                                                    their websites have additional options due to this performance-based
                                                    commission model, which also provides retailers with stronger returns on
                                                    investment.</p>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-5">
                                            <button type="button"
                                                class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                                data-accordion-target="#accordion-collapse-body-5" aria-expanded="false"
                                                aria-controls="accordion-collapse-body-5">
                                                <span className='text-[20px] text-[#222] text-left'>What Are Some Common Types
                                                    Of Affiliate Marketing Commission Structures?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                        strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-5" class="hidden"
                                            aria-labelledby="accordion-collapse-heading-5">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Common structures include
                                                    Pay Per Sale (PPS), Pay Per Click (PPC), and Pay Per Lead (PPL). We help
                                                    clients identify the best commission model aligned with their business
                                                    goals.</p>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-6">
                                            <button type="button"
                                                class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                                data-accordion-target="#accordion-collapse-body-6" aria-expanded="false"
                                                aria-controls="accordion-collapse-body-6">
                                                <span className='text-[20px] text-[#222] text-left'>How Do I Choose The Right
                                                    Affiliate Products Or Services To Promote? </span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                        strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-6" class="hidden"
                                            aria-labelledby="accordion-collapse-heading-6">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Select products and
                                                    services that fit the requirements and interests of your target market. The
                                                    team at Bonafide helps affiliates choose the best products by providing them
                                                    with advice and recommendations.</p>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-7">
                                            <button type="button"
                                                class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                                data-accordion-target="#accordion-collapse-body-7" aria-expanded="false"
                                                aria-controls="accordion-collapse-body-7">
                                                <span className='text-[20px] text-[#222] text-left'>Are There Any Legal Or
                                                    Ethical Guidelines I Should Follow As An Affiliate Marketer?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                        strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-7" class="hidden"
                                            aria-labelledby="accordion-collapse-heading-7">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Absolutely, affiliates
                                                    should avoid misleading promotions and inform their audience of their
                                                    affiliate relationships. We respect the highest ethical standards and give
                                                    affiliates clear guidance on how to keep transparency and trust.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="w-[30%] hidden lg:block relative">
                            <div className='box-shadow rounded-lg ml-5 p-8 sidebar sticky top-[-150px]'>
                                <div className="sidebar_banner">
                                    <img src="img/page_img_4.jpg" alt="" />
                                </div>

                                <div className="sidebar_tabs mt-5">
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/social-media-marketing">
                                            <h1 className='text-[18px] font-semibold capitalize'>Social Media Marketing - SMM</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/social-engines-marketing">
                                            <h1 className='text-[18px] font-semibold capitalize'>Social Engines Marketing - SEM</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/guest-posting">
                                            <h1 className='text-[18px] font-semibold capitalize'>Guest Posting</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/web-analytics">
                                            <h1 className='text-[18px] font-semibold capitalize'>Web Analytics</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/local-seo">
                                            <h1 className='text-[18px] font-semibold capitalize'>Local SEO</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/e-commerce-marketing">
                                            <h1 className='text-[18px] font-semibold capitalize'>eCommerce Marketing</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/mobile-app-marketing">
                                            <h1 className='text-[18px] font-semibold capitalize'>mobile-app-marketing</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/influencer-marketing">
                                            <h1 className='text-[18px] font-semibold capitalize'>Influencer Marketing</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/affiliate-marketing">
                                            <h1 className='text-[18px] font-semibold capitalize'>Affiliate Marketing</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/web-traffic">
                                            <h1 className='text-[18px] font-semibold capitalize'>Web Traffic</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/ppc">
                                            <h1 className='text-[18px] font-semibold capitalize'>PPC</h1>
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default AffiliateMarketing