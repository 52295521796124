import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Gwalior() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{
                backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)" }}>
                <div className="container mx-auto py-12">
                    <h1
                        className='text-center text-white text-[32px] md:text-[52px]  font-semibold heading uppercase'>
                        SEO Company in Gwalior
                    </h1>
                </div>
            </div>
        
            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[100%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>SEO Company in Gwalior</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>At Bonafide Technologies, we're more than just a Digital service provider. We're here to support the online growth of your company! Allow us to grow your company with our unparalleled SEO services meant to achieve remarkable success. This is where your journey to digital marketing starts.</p>
                                <br />
                                <h1 className='text-[32px] font-semibold my-3 heading'>Why Choose Us</h1>
                                <p className='text-[18px] font-normal text-[#636363] '><strong>Improves Traffic to Your Website!</strong></p>
                                <p className='text-[18px] font-normal text-[#636363] '>We take great pride in the work we do. To create captivating and intuitive website designs that will set your company apart, our designers remain on top of trends. Our SEO experts are dedicated to upholding the strictest online standards to ensure that your website stands throughout time. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>With years of experience in SEO, Bonafide Technologies is a leading SEO company located in Gwalior, Madhya Pradesh. Being one of the top SEO companies in Gwalior, India, we can help with everything from complete search engine optimization to website audits. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>The company takes pride in assisting companies, brands, and organizations who want to get the most out of their online presence to engage potential clients at the appropriate time and location. We can help your business produce leads, buyers, and customers by positioning you as an expert.</p>
                            </div>
                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>What We Offer</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Custom link building, on-page and off-page SEO, e-commerce SEO, technical SEO, and content optimization are some of our primary SEO services. Let's talk about other digital marketing services we offer.</p>
                                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                                    <div className="flex flex-wrap justify-between">
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>SEO
                                            </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Our SEO services are ready to significantly increase your company's visibility in less than a week. </p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>SSM</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Top Social Media Marketing Strategies to Generate Online Buzz for Your Company. </p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>PPC</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Top PPC Management to Help Your Company Succeed in the Internet Market.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Lead Generation</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>We are aware that generating leads is essential to any business's success. We'll assist you in producing leads that are excited about your services.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Content Marketing</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Content is the king of digital marketing since the most appropriate information is necessary to draw in business. We will help you create and spread your content online on all platforms.</p>
                                        </div>
                                    </div>
                                </div>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Let's Serve Your Company With The Care It Deserves.</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>At Bonafide Technologies, we're more than just a supplier of services—we're your allies in conquering commercial obstacles. To better understand your specific needs and offer solutions that are suited to them, we conduct consultations with our clients. We can help you with anything from improving your web appearance to optimizing strategy and overcoming digital obstacles. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>Let us help you navigate the options that have the power to revolutionize your company. The route to success is a collaborative effort rather than just a consultation with Bonafide Technologies. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        )
}

export default Gwalior