import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Noida() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{
                backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)" }}>
                <div className="container mx-auto py-12">
                    <h1 className='text-center text-white text-[32px] md:text-[52px]  font-semibold heading uppercase'>
                        SEO Company in Noida
                    </h1>
                </div>
            </div>
        
            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[100%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>SEO Company in Noida</h1>
                                <p className='text-[18px] font-normal text-[#636363] '><strong>CLIMB NEW HEIGHTS IN SEARCH ENGINE RESULT PAGES WITH SEO!</strong></p>
                                <p className='text-[18px] font-normal text-[#636363] '>There is no end to SEO. It served as the foundation for websites and still does. Thus, there's no need to go anymore if you're seeking a top SEO agency in Noida. Bonafide Technologies's SEO team has years of experience with search engine marketing and raising clients' rankings. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>With our SEO services in Noida, you can anticipate real business growth. Not only increased traffic and ranks but also increased leads and sales! We want to be your growth partners. Thus, we will advise, execute, and manage your complete SEO plan.</p>
                                <br />
        
                                <h1 className='text-[32px] font-semibold my-3 heading'>Improve Online Business Growth in Noida Through Smart SEO </h1>
                                <p className='text-[18px] font-normal text-[#636363] '>In addition to being home to numerous software companies and IT parks, Noida City is a significant commercial hub.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>Bonafide Technologies is the top SEO company in Noida. We've assisted companies in acquiring their web marketing goals for over 20 years. To provide you with the best SEO services in Noida, we offer On-page SEO, technical SEO, Off-page SEO, and content marketing.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>Our SEO experts are committed to providing high-quality SEO services in Noida to boost your company's performance. With more than two decades of experience in the field, we have successfully ranked our Noida clients for some of the most competitive keywords on Google's first page.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>While other marketing strategies can help increase sales, our SEO services in Noida are essential to the long-term prosperity of any company. These businesses employ SEO specialists focusing on social media (Facebook, Twitter, LinkedIn) and Google AdWords (PPC).</p>
        
                                <h1 className='text-[32px] font-semibold my-3 heading'>Delivering Organic Growth with Smart SEO in Noida</h1>
                                        <ul>
                                            <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span></strong>
                                                Best Integrated Search (SEO & PPC) Companies in India</li>   
                                            <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span></strong>
                                                1000+ Happy Clients in Various Regions</li>   
                                            <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span></strong>
                                                Growth-oriented, tailored SEO strategies for each and every company</li>   
                                            <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span></strong>
                                                Unparalleled Client and Brand Portfolio from Noida</li>      
                                        </ul> 
                                        <h1 className='text-[32px] font-semibold my-3 heading'>WHY DO YOU NEED SEO?</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Being a successful business owner in Noida requires more than just having a website. If you want people to be able to access the internet and discover what it has to offer, you must invest in SEO services. An optimized website with keyword-relevant content that is simple to look up using Google or Bing searches will be more visible on those platforms and receive more traffic, directly translating into more sales. </p>
                            </div>
                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Why Choose Us</h1>
                                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                                    <div className="flex flex-wrap justify-between">
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Complete In-depth Analysis
                                            </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>At Bonafide Technologies, we will thoroughly analyze every aspect of your website, including its content, competitors, and even the keywords you should focus on since we understand user search patterns and behavior and are recognized as the top SEO company in Noida. We will create a strategy incorporating both on-page and off-page optimization.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Taking Care of Your Campaign</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Since 2006, we have been practicing SEO and have seen several changes to the field. Our SEO services will help you utilize innovative methodology and provide a complete understanding of the plans and methods for achieving them. We will put much effort into assisting you in winning SERPs, from online reputation management to an efficient content strategy. We know that it is essential to hold your brand values to start generating organic growth and a higher return on investment.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Website Development Partners</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Bonafide Technologies is considered as the top digital marketing agency in Noida because we are the partners behind our clients' success. Our clients come to us to increase leads, sales, and traffic growth. Because we are an ethical business, we guarantee that the growth and ranking we bring for you won't ever be compromised, no matter how quickly search trends or algorithms change.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Social Media Marketing </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Using our results-driven social media marketing techniques, you can engage, expand, and connect with your audience.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>PPC</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Draw attention to your product or service from your target market to increase quality leads, sales, and leads.</p>
                                        </div>
                                    </div>
                                </div>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Book Our SEO Services in Noida</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Are you excited to see how effective your website is for you? We thoroughly analyze your website's visitors, their interests, and what you can do to improve and make it more user-friendly. For success, having this information visible online is essential.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>To learn more about our services, look at our SEO Packages. You can also fill out the form to contact one of our SEO specialists.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>Browse our SEO case studies or reviews to see what our clients say about us if you want proof that we can live up to our claims.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        )
}

export default Noida
