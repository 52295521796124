import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Dehradun() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{
                backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)" }}>
                <div className="container mx-auto py-12">
                    <h1
                        className='text-center text-white text-[32px] md:text-[52px]  font-semibold heading uppercase'>
                        SEO Company in Dehradun
                    </h1>
                </div>
            </div>
        
            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[100%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>SEO Company in Dehradun</h1>
                                <p className='text-[18px] font-normal text-[#636363] '><strong>We Deliver a Ton of Leads Without Any Extra Charges!</strong></p>
                                <p className='text-[18px] font-normal text-[#636363] '>Improving your website to get a higher search engine ranking is known as SEO (Search Engine Optimization). Optimizing a website is an essential part of a digital marketing strategy. Numerous SEO companies provide SEO services in Dehradun. However, not every SEO company is successful. For this reason, you should pick a business that has a lot of experience and a history of working with Dehradun's small enterprises. For more than 15 years, Bonafide Technologies has produced exceptional results. </p>
                                <br />
                                <h1 className='text-[32px] font-semibold my-3 heading'>Transform Your Company With Our Tried-And-True SEO Approach. </h1>
                                <p className='text-[18px] font-normal text-[#636363] '>With our tried-and-true SEO tactics, your company can achieve sustained growth without spending money on costly PPC advertising. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>To stay ahead of the competition in the local search market, you need to have a strong SEO plan. At Bonafide Technologies, we know that the most effective SEO strategies are those that assist you in ranking for keywords that will increase website traffic and support your company goals. We have a track record of delivering Dehradun and the surrounding areas with top-notch SEO services. In the past, we have assisted over 2,000 companies in improving their search engine rankings on platforms like Google and Bing. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>Creating engaging and user-focused experiences that connect with your audience, encourage interaction, and build enduring relationships is our primary goal. To secure your success in today's cutthroat market, we prioritize innovation, usability, boosting sales, drawing in local clients, staying ahead of the competition, and scalability from conception to execution. Hire us to achieve your brand's full potential and start your digital transformation journey.</p>
                            </div>
                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>What We Offer</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>We can handle every aspect of your needs related to digital marketing. </p><br />
                                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                                    <div className="flex flex-wrap justify-between">
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Search Engine Optimization
                                            </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Achieve Top Page Rankings with morally sound & efficient SEO techniques. Whether you run a local, global, or e-commerce company, our SEO services are tailored to fit your requirements. </p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Social Media Marketing</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Social media networks are excellent tools for business promotion. We can assist you in building a powerful social media presence.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Pay Per Click</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>We handle your search engine campaigns to ensure you get the most out of the search engine’s pay-per-click services.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>E-commerce Websites</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Because they need to store a lot of data and have robust administration systems, e-commerce, and shopping websites are among the most difficult types of websites. We provide you access to first-rate purchasing websites and a round-the-clock help desk.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Local Search Engine Optimization </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>You can target your local market with the help of local search engine optimization, and our package provides you with the best results possible.</p>
                                        </div>
                                    </div>
                                </div>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Ideal Solution for All Your SEO and Online Marketing Needs in Dehradun!</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>We take great pride in offering innovative digital marketing solutions. Most of our clients can contact and engage with their customers most effectively with our winning SEO solutions in Dehradun.</p> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        )
}

export default Dehradun