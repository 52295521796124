import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const testimonialsData = [
  {
    id: 1,
    quote: 'The team of Bonafide Technologies is simply phenomenal. They provide wise advice on what would work in my favor and pay careful attention to detail. Over the past 12 months, they have significantly improved our website and online presence, which has had excellent results for our company. We would like to thank Bonafide for all their hard work.',
    image: 'img/testimonials_img.png',
    name:'John Miller',
  },
  {
    id: 2,
    quote: 'I highly recommend Bonafide Technologies. The team has been managing my SEO and Google Ads campaign for 3 years now, and I am very happy with the service provided at that time. Leads and website traffic have increased considerably and I have no hesitation in recommending this company.',
    image: 'img/testimonials_img.png',
    name:'Asmi',
  },
  {
    id: 3,
    quote: 'We highly recommend the services of Bonafide Technologies currently manages our Google Ad campaign for our Common tasks. The bonafide team is very professional to deal with and provides regular monthly updates on progress and results. Our Google Ads have improved traffic to our website and increased our inquiries for different services.',
    image: 'img/testimonials_img.png',
    name:'Daniel',
  },
  {
    id: 4,
    quote: 'The Bonafide team has been great to work with in terms of helping us improve our SEO performance and Google Advertising campaigns. Their assigned virtual assistant is always on hand to answer our questions, no matter how small or silly they may seem. They are always ready to provide advice when required and show us new and innovative ways to continue improving our online presence. Thanks guys!',
    image: 'img/testimonials_img.png',
    name:'Tina',
  },
  {
    id: 5,
    quote: 'To anyone searching for a digital marketing solution and assistance with SEO, Paid Search, or Increasing Online Search Visibility for their Business, I happily recommend Bonafide Technologies.',
    image: 'img/testimonials_img.png',
    name:'Jackson',
  },
];

const TestimonialsSlider = () => {
    const settings = {
      dots: true,
      centerMode: true, 
      infinite: true,
      speed: 500,
      slidesToShow: 3, // Desktop
      slidesToScroll: 1,
      autoplay: true, 
      autoplaySpeed: 2000, 
      responsive: [
        {
          breakpoint: 1024, // Tablet
          settings: {
            slidesToShow: 2,
            centerMode: false, 
          },
        },
        {
          breakpoint: 600, // Mobile
          settings: {
            slidesToShow: 1,
            centerMode: false, 
          },
        },
      ],
    };
  
    return (
      <Slider {...settings}>
        {testimonialsData.map((testimonial) => (
          <div key={testimonial.id} className="p-4">
            <div className="bg-white p-6 rounded-lg shadow grid items-center text-center cursor-pointer border hover:border-[#e3b299] md:h-[460px]">
              <img className='w-[24%] mx-auto rounded-full' src={testimonial.image} alt="" />
              <p className="text-gray-700 text-[18px] font-normal mt-1">{testimonial.quote}</p>
              <p className=" font-medium text-[16px] text-[#e3b299] font-normal mt-1">{testimonial.name}</p>
            </div>
          </div>
        ))}
      </Slider>
    );
  };

export default TestimonialsSlider;
