import React from 'react'
import TestimonialsSlider from './TestimonialsSlider'
import { Link } from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
function Home() {
    return (

        <>
            <Header />
            <div className="container-fluid p-3 py-10 lg:py-24 lg:px-8">
                <div className="container mx-auto">
                    <div className="flex flex-wrap lg:flex-nowrap items-center">
                        <div className="w-full lg:w-[50%]">
                            <h1 className='text-[34px] md:text-[42px] lg:text-[56px] xl:text-[76px] font-bold heading  leading-tight'>We are an Innovative  <span className='text-[#e3b299] heading'>Digital Marketing  </span> Agency</h1>
                            <p className='text-[18px] md:text-[20px] font-medium text-[#222] mt-3'>We assist globally aspirational clients who require more assurance in a digital landscape that is evolving quickly. Through multi-channel digital marketing strategies across your owned, paid, and earned media, we promote steady, measured growth.</p>

                            <div className=" mt-8 inline-block relative main_btnn_div cursor-pointer ml-2">
                                <Link to="/about-us">  <span className='px-8 py-3 text-white uppercase font-bold text-[18px] bg-[#222] main_btnn inline-block'> DISCOVER MORE</span></Link>
                            </div>
                        </div>
                        <div className=" w-full lg:w-[50%] mt-12 lg:mt-0">

                            <img src="img/bg01.png" className='w-full lg:w-[75%] float-right home_hero_img' alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid p-3 py-10 lg:py-24 lg:px-8 bg-gray-50">
                <div className="container mx-auto">
                    <div className="flex flex-wrap lg:flex-nowrap items-center">
                        <div className=" w-full lg:w-[50%] ">
                            <div className="about_hero_img flex flex flex-wrap md:flex-nowrap ">
                                <div className="hidden md:block w-full md:w-[50%] overflow-hidden md:mr-12 mb-12 md:mb-[60px] rounded-3xl h-[420px] bg-cover bg-center" style={{
                                    backgroundImage: "url(img/about_sec_1.jpg)"
                                }}> </div>
                                <div className="w-full md:w-[50%] overflow-hidden md:mt-[60px] rounded-3xl h-[420px] bg-cover bg-center" style={{
                                    backgroundImage: "url(img/about_sec_2.jpg)"
                                }}> </div>

                            </div>
                        </div>
                        <div className="w-full lg:w-[50%] mb-[40px] mt-12 lg:ml-12 lg:mt-0">
                            <h1 className='text-[34px] md:text-[42px] lg:text-[48px] xl:text-[56px] font-bold heading '> Crafting Digital Marketing Strategies That Work Well</h1>
                            <p className='text-[18px] md:text-[20px] font-medium text-[#222] mt-3'>Even though we don't always stay in our lane, we never take our hands off the wheel. We're specialists, not generalists. Our hands in creating new records in digital world makes us the best SEO company in India.
                                <br /><br />
                                Our sole focus is developing extensive digital marketing plans for each and every customer we serve! Our devoted team invest all their time and effort to take your company to new heights!
                            </p>

                            <div className=" mt-8 inline-block relative main_btnn_div cursor-pointer ml-2">
                                <Link to="/proposal">
                                    <span className='px-8 py-3 text-white uppercase font-bold text-[18px] bg-[#222] main_btnn inline-block'> SEND US A PROPOSAL</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container-fluid p-3 py-10 lg:py-24 lg:px-8">
                <div className="container mx-auto">
                    <h1 className='text-[28px] md:text-[42px] lg:text-[42px] font-bold heading text-center xl:mx-28'>Speed up Your Business Growth With The Best Digital Marketing Agency in India.</h1>
                    <p className='text-[18px] md:text-[20px] font-medium text-[#222] mt-3 text-center xl:mx-36'>Looking for a one-stop shop for various digital marketing services? Look no further! We provide completely customized, responsive websites and top-notch solutions for our respected clients. Enroll with us right now!</p>
                    <div className="flex flex-wrap  mt-16 items-center justify-between">
                        <div className='w-full md:w-[48%] lg:w-[32%] cursor-pointer border border-[#e3b299] px-5 py-8  text-center rounded-xl hover:shadow-lg group mt-8'>
                            <img src="img/service_3.webp" className='mx-auto  h-[250px] lg:h-[280px]' alt="" />
                            <h1 className='text-[28px] font-bold heading'>Digital Marketing</h1>
                            <p className='text-[18px] font-medium text-[#222] mt-3'>Looking for help with SEO, social media, blogging, email marketing, or Google My Business? This is where it all begins! Explore the services we provide and contact us to set up a free consultation.</p>

                            {/* <div className=" my-8 inline-block relative main_btnn_div cursor-pointer ml-2">
                                <span className='px-6 py-2 text-white uppercase font-bold text-[16px] bg-[#222] main_btnn inline-block'> view more</span>
                            </div> */}
                        </div>

                        <div className='w-full md:w-[48%] lg:w-[32%] cursor-pointer border border-[#e3b299] px-5 py-8  text-center rounded-xl hover:shadow-lg group mt-8'>
                            <img src="img/service_1.webp" className='mx-auto  h-[250px] lg:h-[280px]' alt="" />
                            <h1 className='text-[28px] font-bold heading'>Web Development</h1>
                            <p className='text-[18px] font-medium text-[#222] mt-3'>We are experts in PHP, Laravel, Cake PHP, AJAX, jQuery & JavaScript, and customization of WordPress Plugin and modify your existing site to suit your business goals. </p>

                            {/* <div className=" my-8 inline-block relative main_btnn_div cursor-pointer ml-2">
                                <span className='px-6 py-2 text-white uppercase font-bold text-[16px] bg-[#222] main_btnn inline-block'> view more</span>
                            </div> */}
                        </div>

                        <div className='w-full md:w-[48%] lg:w-[32%] cursor-pointer border border-[#e3b299] px-5 py-8  text-center rounded-xl hover:shadow-lg group mt-8'>
                            <img src="img/service_2.webp" className='mx-auto  h-[250px] lg:h-[280px]' alt="" />
                            <h1 className='text-[28px] font-bold heading'> Web Design</h1>
                            <p className='text-[18px] font-medium text-[#222] mt-3'>Your website is your first impression among visitors. It is time to make an everlasting impression. Get a winning website with integrated SEO, better performance, usability, and accessibility. </p>

                            {/* <div className=" my-8 inline-block relative main_btnn_div cursor-pointer ml-2">
                                <span className='px-6 py-2 text-white uppercase font-bold text-[16px] bg-[#222] main_btnn inline-block'> view more</span>
                            </div> */}
                        </div>



                        <div className='w-full md:w-[48%] lg:w-[32%] cursor-pointer border border-[#e3b299] px-5 py-8  text-center rounded-xl hover:shadow-lg group mt-8'>
                            <img src="img/service_4.webp" className='mx-auto  h-[250px] lg:h-[280px]' alt="" />
                            <h1 className='text-[28px] font-bold heading'> Content Writing</h1>
                            <p className='text-[18px] font-medium text-[#222] mt-3'>Are you proud of what your brand says to the people who really matter? We strive to provide fresh and diverting content to pique your clients' attention and drive business leads and conversions. </p>

                            {/* <div className=" my-8 inline-block relative main_btnn_div cursor-pointer ml-2">
                                <span className='px-6 py-2 text-white uppercase font-bold text-[16px] bg-[#222] main_btnn inline-block'> view more</span>
                            </div> */}
                        </div>

                        <div className='w-full md:w-[48%] lg:w-[32%] cursor-pointer border border-[#e3b299] px-5 py-8  text-center rounded-xl hover:shadow-lg group mt-8'>
                            <img src="img/service_5.webp" className='mx-auto  h-[250px] lg:h-[280px]' alt="" />
                            <h1 className='text-[28px] font-bold heading'> SEO</h1>
                            <p className='text-[18px] font-medium text-[#222] mt-3'>Being a business without SEO is like being one without a sign. SEO helps your clients find you by strategically placing search terms on your website. </p>

                            {/* <div className=" my-8 inline-block relative main_btnn_div cursor-pointer ml-2">
                                <span className='px-6 py-2 text-white uppercase font-bold text-[16px] bg-[#222] main_btnn inline-block'> view more</span>
                            </div> */}
                        </div>

                        <div className='w-full md:w-[48%] lg:w-[32%] cursor-pointer border border-[#e3b299] px-5 py-8  text-center rounded-xl hover:shadow-lg group mt-8'>
                            <img src="img/service_6.webp" className='mx-auto  h-[250px] lg:h-[280px]' alt="" />
                            <h1 className='text-[28px] font-bold heading'> Resources</h1>
                            <p className='text-[18px] font-medium text-[#222] mt-3'>If you’re searching for a reliable list of all backlink websites to create link building in high authority websites, we have lists of pages having sites for profile creation, image sharing, web 2.0, etc.</p>

                            {/* <div className=" my-8 inline-block relative main_btnn_div cursor-pointer ml-2">
                                <span className='px-6 py-2 text-white uppercase font-bold text-[16px] bg-[#222] main_btnn inline-block'> view more</span>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>


            <div className="container-fluid p-3 py-10 lg:py-24 lg:px-8 bg-gray-50">
                <div className="container mx-auto">
                    <h1 className='text-[28px] md:text-[42px] lg:text-[42px] font-bold heading text-center xl:mx-28'>What Our Clients Say</h1>
                    <p className='text-[18px] md:text-[20px] font-medium text-[#222] mt-3 text-center xl:mx-36 mb-12'>Our clients have experienced remarkable growth because of our tried and tested, industry-leading results, which range from top eCommerce retail brands to globally listed businesses. Look what our happy clients say about our work.</p>
                    <TestimonialsSlider />
                </div>
            </div>


            <div className="container-fluid p-3 py-10 lg:py-24 lg:px-8 ">
                <div className="container mx-auto">
                    <div >
                        <section class="">
                            <div class="mx-auto max-w-screen-xl px-4">
                                <h2 class="text-[28px] md:text-[42px] lg:text-[42px] font-bold heading text-center xl:mx-28">Our Patners</h2>
                                <div class="grid grid-cols-2 gap-8 text-gray-500 sm:gap-12 md:grid-cols-3 lg:grid-cols-6  mt-8">
                                    <Link to="/" >
                                        <img className="grayscale hover:grayscale-0" src="img/client_1.webp" alt="" />
                                    </Link>
                                    <Link to="/" class="">
                                        <img src="img/client_2.webp" alt="" />
                                    </Link>
                                    <Link to="/" >
                                        <img className="grayscale hover:grayscale-0" src="img/client_3.webp" alt="" />
                                    </Link>

                                    <Link to="/" >
                                        <img className="grayscale hover:grayscale-0" src="img/client_4.webp" alt="" />
                                    </Link>
                                    <Link to="/" >
                                        <img className="grayscale hover:grayscale-0" src="img/client_5.webp" alt="" />
                                    </Link>
                                    <Link to="/" >
                                        <img className="grayscale hover:grayscale-0" src="img/client_6.webp" alt="" />
                                    </Link>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>


            <div className="container-fluid p-3 py-10 md:py-24 lg:pb-56 lg:pt-24 bg-cover bg-center" style={{ backgroundImage: "linear-gradient(rgb(0 0 0 / 63%), rgb(0 0 0 / 63%)), url(img/home_banner.jpg)" }} >
                <div className="container mx-auto " >
                    <h1 className='text-[28px] md:text-[42px] lg:text-[48px] font-bold heading text-white text-center'>We Help Your Business
                        To Become Stronger</h1>
                    <p className='text-[18px] md:text-[20px] font-medium text-[#222] mt-3 text-center xl:mx-36 text-white text-center'>Staying at the top position of “The Best SEO Comapny in India” demands ongoing analysis, devotion, and improvement. So that you can focus on what you do best, let us concentrate on what we do best.
                        With Bonafide Technologies, your traffic will spike, your growth will take off, and your annual sales report will be truly incredible.
                    </p>
                </div>
            </div>

            <div className="container-fluid lg:mt-[-170px] lg:mb-[-200px] relative z-2">
                <div className="lg:container mx-auto bg-[#e3b299] shadow px-4 lg:px-10 py-10 lg:py-24 lg:rounded-xl bg-cover bg-center" style={{ backgroundImage: "url(img/home-cta.png)" }}>
                    <div className="flex flex-wrap lg:flex-nowrap items-center">
                        <div className="w-full lg:w-[48%] p-3">
                            <h1 className='text-[28px] md:text-[36px] lg:text-[36px] font-bold heading  '>We Focus The First Place For You</h1>
                            <div class="text-[18px] font-medium text-[#222] mt-3 lg:mr-12">Do you want to become a prominent player in the rapidly growing digital market? If so, this is your chance! You can make your dream of ruling all search engines into a reality by hiring us! Take action right now!</div>
                        </div>

                        <div className="w-full lg:w-[48%] p-3">
                            <form action="#" className='lg:px-3'>
                                <h1 className='text-[24px] md:text-[32px]  font-bold heading mb-3 md:mb-5'>  Subscribe Our Newsletter</h1>
                                <div class="items-center mb-3 space-y-4 max-w-screen-sm sm:flex sm:space-y-0">
                                    <div class="relative w-full">
                                        <label for="email" class="hidden mb-2 text-sm font-medium text-gray-900 darks:text-gray-300">Email address</label>
                                        <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                            <svg class="w-5 h-5 text-gray-500 darks:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path><path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path></svg>
                                        </div>
                                        <input class="block p-3 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:rounded-none sm:rounded-l-lg focus:ring-black focus:border-black darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-black darks:focus:border-black" placeholder="Enter your email" type="email" id="email" required="" />
                                    </div>
                                    <div>
                                        <button type="submit" class="py-3 px-5 w-full text-sm font-medium text-center rounded-lg border-2
                                hover:bg-black cursor-pointer bg-primary-700 border-black sm:rounded-none sm:rounded-r-lg hover:bg-primary-800 focus:ring-4 focus:ring-transparent darks:bg-black darks:hover:bg-primary-700 darks:focus:ring-primary-800 text-black hover:text-white">Subscribe</button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>

                </div>
            </div>

            <div className="container-fluid p-3 py-26 lg:pt-56 lg:pb-24 bg-gray-50">
                <div className="container mx-auto " >
                    <h1 className='text-[28px] md:text-[42px] lg:text-[42px] font-bold heading  xl:mx-28 text-center'> Our Latest Blogs</h1>

                    <div class="grid gap-8 lg:grid-cols-2 xl:grid-cols-3 mt-8">
                        <article class="p-6 bg-white rounded-lg border border-gray-200 shadow-md darks:bg-gray-800 darks:border-gray-700">
                            <h2 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 darks:text-white"><a href="#">How to quickly deploy a static website</a></h2>
                            <p class="mb-5 font-light text-gray-500 darks:text-gray-400">Static websites are now used to bootstrap lots of websites and are becoming the basis for a variety of tools that even influence both web designers and developers influence both web designers and developers.</p>
                            <div class="flex justify-between items-center">
                                <div class="flex items-center space-x-4">
                                    <img class="w-7 h-7 rounded-full" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/jese-leos.png" alt="Jese Leos avatar" />
                                    <span class="font-medium darks:text-white">
                                        Jese Leos
                                    </span>
                                </div>
                                <a href="#" class="inline-flex items-center font-medium text-primary-600 darks:text-primary-500 hover:underline">
                                    Read more
                                    <svg class="ml-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                </a>
                            </div>
                        </article>

                        <article class="p-6 bg-white rounded-lg border border-gray-200 shadow-md darks:bg-gray-800 darks:border-gray-700">
                            <h2 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 darks:text-white"><a href="#">Our first project with React</a></h2>
                            <p class="mb-5 font-light text-gray-500 darks:text-gray-400">Static websites are now used to bootstrap lots of websites and are becoming the basis for a variety of tools that even influence both web designers and developers influence both web designers and developers.</p>
                            <div class="flex justify-between items-center">
                                <div class="flex items-center space-x-4">
                                    <img class="w-7 h-7 rounded-full" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png" alt="Bonnie Green avatar" />
                                    <span class="font-medium darks:text-white">
                                        Bonnie Green
                                    </span>
                                </div>
                                <a href="#" class="inline-flex items-center font-medium text-primary-600 darks:text-primary-500 hover:underline">
                                    Read more
                                    <svg class="ml-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                </a>
                            </div>
                        </article>

                        <article class="p-6 bg-white rounded-lg border border-gray-200 shadow-md darks:bg-gray-800 darks:border-gray-700">
                            <h2 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 darks:text-white"><a href="#">Our first project with React</a></h2>
                            <p class="mb-5 font-light text-gray-500 darks:text-gray-400">Static websites are now used to bootstrap lots of websites and are becoming the basis for a variety of tools that even influence both web designers and developers influence both web designers and developers.</p>
                            <div class="flex justify-between items-center">
                                <div class="flex items-center space-x-4">
                                    <img class="w-7 h-7 rounded-full" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png" alt="Bonnie Green avatar" />
                                    <span class="font-medium darks:text-white">
                                        Bonnie Green
                                    </span>
                                </div>
                                <a href="#" class="inline-flex items-center font-medium text-primary-600 darks:text-primary-500 hover:underline">
                                    Read more
                                    <svg class="ml-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                </a>
                            </div>
                        </article>
                    </div>
                </div>


            </div>

            <Footer />
        </>

    )
}

export default Home