import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Surat() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{
                backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)" }}>
                <div className="container mx-auto py-12">
                    <h1 className='text-center text-white text-[32px] md:text-[52px]  font-semibold heading uppercase'>
                        SEO Company in Surat
                    </h1>
                </div>
            </div>
        
            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[100%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>SEO Company in Surat</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>If you want to raise your website's
                                    position in Google search results, Bonafide Technologies is a top SEO services provider in
                                    India.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>Are you looking for the "Best SEO Company
                                    in Surat"? Entice more people to visit your website. You should then get in touch with
                                    Bonafide Technologies Digital Marketing Agency. From this platform, with the assistance of
                                    our passionate and imaginative team of knowledgeable SEO enthusiasts, we offer the best SEO
                                    services in Surat.</p>
                                <br />
                                <h1 className='text-[32px] font-semibold my-3 heading'>Why Do You Need SEO?</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>It is believed that Search Engine
                                    Optimization (SEO) is the foundation of Digital Marketing Services. It facilitates clients'
                                    natural online interactions with your product or service, saving you money.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>Using the best and long tail keywords,
                                    our team of SEO specialists uses the latest SEO services to improve your online presence
                                    organically, rank better in Google, and grow business from your website.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>If your company works online, you know
                                    the need for a substantial SEO plan. A website that does not rank high in search engines
                                    will not be able to generate enough leads in the present competitive market. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>When you work with an SEO Company in
                                    Surat, you have a team of professionals working tirelessly to ensure your business succeeds
                                    online.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>We guarantee the best outcome in the
                                    shortest amount of time. Before beginning any work, our staff meets clients briefly to learn
                                    about their goals and requirements. Clients receive reports of assured weekly growth to
                                    maintain total transparency and confidence.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>Therefore, whatever your objective, it
                                    might be increasing leads, traffic, brand awareness, or authority and trust. We promise
                                    unparalleled outcomes within a predetermined time frame.</p>
                            </div>
                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>How Do We Do It?</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>We know that your website represents your
                                    company and requires maintenance. We treat your website with the same consideration that we
                                    do for our own. Unlike many other SEO firms, we don't use black hat SEO techniques. We
                                    engage in ethical SEO. </p>
                                <br />
                                <p className='text-[18px] font-normal text-[#636363] '>We perform all necessary manual work and
                                    use tools to guarantee the best possible SEO result. Every SEO project is carefully examined
                                    to ensure it meets our requirements. We conduct a thorough examination to determine the
                                    areas on your website that require development. We alter websites and create a unique SEO
                                    plan based on your requirements.</p><br />
                                <p className='text-[18px] font-normal text-[#636363] '>Investing in expert SEO services tailored
                                    to your company's requirements will help you rank higher in search engines.</p><br />
                                <p className='text-[18px] font-normal text-[#636363] '>Bonafide Technologies is an SEO firm
                                    focused on its clients. Together with the clients, our SEO specialists create specialized
                                    SEO strategies that promote long-term financial success.</p><br />
                                <p className='text-[18px] font-normal text-[#636363] '>Our organization creates a strong
                                    campaign using a seven-step process to boost your internet visibility and improve the worth
                                    of your company. The following procedures are involved in the Bonafide Technologies SEO
                                    plan:</p><br />
                                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                                    <div className="flex flex-wrap justify-between">
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Find out
                                            </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>As your SEO firm, we are
                                                familiar with your industry and can pinpoint your Key Performance Indicators
                                                (KPIs), which gives us a complete knowledge of the functioning of your business.
                                            </p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>SEO Audit</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>In this section, we gather
                                                business information from you, such as traffic trends and backlinks from
                                                competitors, and search for factors that could influence your online appearance.
                                            </p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Strategize</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Following data analysis, our
                                                team of professionals develops a plan to enhance your internet visibility.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Execute</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>We carry out the expertly
                                                crafted plan in this phase. We improve your website's structure, add the
                                                best-performing keywords to your webpage and blog posts, etc.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Measure</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Following the implementation
                                                phase, we begin monitoring our success and determining how the SEO strategy
                                                affects the company.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Report</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>We compile a weekly progress
                                                report after assessing progress. The report is given to the client to
                                                demonstrate the result.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Adjust</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>To present even better
                                                outcomes, the SEO team last examines the report from an alternative angle and
                                                makes incremental modifications.</p>
                                        </div>
                                    </div>
                                </div>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Our Work Vision</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>As the top SEO company in Surat, we help
                                    you discover your objectives as they align with yours.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>Our team of professionals constantly
                                    creates campaigns that deliver measurable and unique results. Also, to guarantee a good
                                    return on investment, we choose the most cost-effective keyword for the company.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>Our constant goal is for your website to
                                    sell more. We help your company stand out by offering the most distinctive technologies.</p>
        
                                <h1 className='text-[32px] font-semibold my-3 heading'>What SEO Services Do We Offer to Our
                                    Clients? </h1>
                                <p className='text-[18px] font-normal text-[#636363] '>We have about 15 years of experience
                                    offering SEO services in Surat and worldwide, and we maintain a solid working relationship
                                    with all our clients. For all these years, we have constantly ensured our clients a good
                                    return on investment by providing them with excellent results at a reasonable investment
                                    cost.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>We constantly monitor the time it takes
                                    to complete the task and consistently give our clients the greatest outcomes in the shortest
                                    amount of time compared to other service providers.</p>
                                <p className='text-[18px] font-normal text-[#636363] '><strong>We offer services in numerous SEO
                                        sectors.</strong></p>
        
                                <ul>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                                className='text-[#63E6BE] mr-2'><i
                                                    class="fa-regular fa-square-check"></i></span></strong>
                                        On-page SEO</li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                                className='text-[#63E6BE] mr-2'><i
                                                    class="fa-regular fa-square-check"></i></span></strong>
                                        Off-page SEO</li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                                className='text-[#63E6BE] mr-2'><i
                                                    class="fa-regular fa-square-check"></i></span></strong>
                                        Local SEO</li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                                className='text-[#63E6BE] mr-2'><i
                                                    class="fa-regular fa-square-check"></i></span></strong>
                                        Competitive Research</li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                                className='text-[#63E6BE] mr-2'><i
                                                    class="fa-regular fa-square-check"></i></span></strong>
                                        Keyword Research</li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                                className='text-[#63E6BE] mr-2'><i
                                                    class="fa-regular fa-square-check"></i></span></strong>
                                        Content Creation</li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                                className='text-[#63E6BE] mr-2'><i
                                                    class="fa-regular fa-square-check"></i></span></strong>
                                        Conversion Optimization</li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                                className='text-[#63E6BE] mr-2'><i
                                                    class="fa-regular fa-square-check"></i></span></strong>
                                        Link Building</li>
                                </ul>
                                <p className='text-[18px] font-normal text-[#636363] '>In addition to the services above, we
                                    accept requests for e-commerce SEO services.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        )
}

export default Surat