import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Gurgaon() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{
                backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)" }}>
                <div className="container mx-auto py-12">
                    <h1 className='text-center text-white text-[32px] md:text-[52px]  font-semibold heading uppercase'>
                        SEO Company in Gurgaon
                    </h1>
                </div>
            </div>
        
            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[100%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>SEO Company in Gurgaon</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Do you want search engines to rank your
                                    website high on search engines so that people in your target audience can find you quickly?
                                    Then Bonafide Technologies is where you should go. You can count on our skilled and
                                    knowledgeable SEO specialists to deliver enduring outcomes and create websites that users
                                    and search engines enjoy visiting.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>While hundreds of companies provide SEO
                                    services, they need help matching the quality of services Bonafide Technologies provides. We
                                    seek to improve our client's search engine rankings while increasing their conversion rates,
                                    income, and profits without charging more. Much relies on how companies promote their brands
                                    and adjust to shifting trends in a crowded and fiercely competitive industry. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>Businesses should use SEO (Search Engine
                                    Optimization) to present their value proposal to the public. Over 75% of individuals spend
                                    their time online, and many use social media for business. Bonafide Technologies is known as
                                    the best SEO company in Gurgaon. We are experienced in SEO marketing and know the steps
                                    necessary to create a successful, scalable brand. That will support your business's internet
                                    launch and help it establish a substantial online presence.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>You can get real business progress by
                                    employing our SEO services in Gurgaon. Leads and sales are rising along with rankings and
                                    traffic! As your growth partners, we will advise, execute, and manage your SEO strategy
                                    plan.</p>
                                <br />
        
        
        
                            </div>
                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Why Choose Bonafide Technologies</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>In Gurgaon, Bonafide Technologies SEO
                                    Services is among the top SEO companies. It's a qualified and well-equipped business that
                                    offers a range of SEO services in Gurgaon. Our professionals have access to the latest
                                    technologies and methods and an updated knowledge of Gurgaon marketing and search engine
                                    optimization. We provide a broad selection of Search engine optimization services in Gurgaon
                                    that are assured and compelling.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>The experts at Bonafide Technologies SEO
                                    Services Gurgaon have years of expertise in online marketing and search engine optimization
                                    and come from various Internet marketing firms. Our team deeply understands search engine
                                    algorithms due to their extensive expertise. Since this industry depends on recent
                                    information, we constantly upgrade and maintain our knowledge.</p>
                                <br />
                                <ul>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                                className='text-[#63E6BE] mr-2'><i
                                                    class="fa-regular fa-square-check"></i></span></strong>
                                        Regular and targeted visitors to your website </li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                                className='text-[#63E6BE] mr-2'><i
                                                    class="fa-regular fa-square-check"></i></span></strong>
                                        A consistent flow to rival competitors</li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                                className='text-[#63E6BE] mr-2'><i
                                                    class="fa-regular fa-square-check"></i></span></strong>
                                        More profits through digital channels</li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                                className='text-[#63E6BE] mr-2'><i
                                                    class="fa-regular fa-square-check"></i></span></strong>
                                        Enhanced brand awareness and visibility </li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                                className='text-[#63E6BE] mr-2'><i
                                                    class="fa-regular fa-square-check"></i></span></strong>
                                        Enhanced visitor trust</li>
                                </ul>
                                <h1 className='text-[32px] font-semibold my-3 heading'>How Bonafide Technologies Can Help You To
                                    Scale Your Business In The Market</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Tell us about your needs and query, and
                                    we'll schedule a call so you can speak with one of our specialists! Provide you with an
                                    organic SEO solution that will enable you to increase revenue while staying within your
                                    budget. Get free project advice from our SEO services experts right now.</p>
        
                                <ul>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                                className='text-[#63E6BE] mr-2'><i
                                                    class="fa-regular fa-square-check"></i></span></strong>
                                        We perform detailed research on which keywords are suitable for your company. The ideal
                                        choice of keywords helps optimize audience reach, product description, and website
                                        content organization and find unknown advantages to ensure a top search engine position
                                        for businesses.</li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                                className='text-[#63E6BE] mr-2'><i
                                                    class="fa-regular fa-square-check"></i></span></strong>
                                        We find technical problems with the website since technically sound and optimized
                                        websites make it easy for crawlers to visit your pages and properly index them.</li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                                className='text-[#63E6BE] mr-2'><i
                                                    class="fa-regular fa-square-check"></i></span></strong>
                                        Your website needs better and more thoroughly examined content, so we are here to
                                        provide you with relevant content. Better website content fosters positive user
                                        interactions with your website in addition to helping you rank higher in search results.
                                    </li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                                className='text-[#63E6BE] mr-2'><i
                                                    class="fa-regular fa-square-check"></i></span></strong>
                                        Link building is an excellent strategy to rank your website well and have the search
                                        engines acknowledge your existence.</li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                                className='text-[#63E6BE] mr-2'><i
                                                    class="fa-regular fa-square-check"></i></span></strong>
                                        We develop a strategy specifically for your company so that you can benefit fully from
                                        it in the future. </li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                                className='text-[#63E6BE] mr-2'><i
                                                    class="fa-regular fa-square-check"></i></span></strong>
                                        We don't bother you with the hefty marketing charges because we respect your financial
                                        constraints. You can reinvest your savings back into other aspects of your company.</li>
                                </ul>
                                <h1 className='text-[32px] font-semibold my-3 heading'>What We Offer </h1>
                                <p className='text-[18px] font-normal text-[#636363] '>After speaking with you, our specialists
                                    will understand your company's objectives and which keywords must be targeted. At Bonafide
                                    Technologies, search engine optimization (SEO) entails optimizing HTML Meta tags, content,
                                    and navigation structure. You will undoubtedly raise the website's ranking with these
                                    strategies. With the selected relevant keywords and key phrases in mind, the website is
                                    optimized. Services for Search Engine Optimization include:</p>
                                <ul>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                                className='text-[#63E6BE] mr-2'><i
                                                    class="fa-regular fa-square-check"></i></span></strong>
                                        Local SEO</li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                                className='text-[#63E6BE] mr-2'><i
                                                    class="fa-regular fa-square-check"></i></span></strong>
                                        Link Building</li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                                className='text-[#63E6BE] mr-2'><i
                                                    class="fa-regular fa-square-check"></i></span></strong>
                                        On-page SEO Optimization
                                    </li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                                className='text-[#63E6BE] mr-2'><i
                                                    class="fa-regular fa-square-check"></i></span></strong>
                                        Off-page SEO Optimization</li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                                className='text-[#63E6BE] mr-2'><i
                                                    class="fa-regular fa-square-check"></i></span></strong>
                                        Content Writing</li>
                                </ul>
                                <p className='text-[18px] font-normal text-[#636363] '>Since our staff has mastered the art and
                                    science of White Hat SEO, they have combined the most effective ways to raise your website's
                                    search engine rating. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>Book our services for website
                                    optimization, and you'll rank well in search engines. Contact us to get our SEO services in
                                    Gurgaon. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        )
}

export default Gurgaon