import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Ghaziabad() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{
                backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)" }}>
                <div className="container mx-auto py-12">
                    <h1
                        className='text-center text-white text-[32px] md:text-[52px]  font-semibold heading uppercase'>
                        SEO Company in Ghaziabad
                    </h1>
                </div>
            </div>
        
            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[100%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>SEO Company in Ghaziabad</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Are you looking to get the most out of your web marketing campaigns? Our thorough and well-coordinated online advertising campaigns have shown to be effective in helping small, medium, and large companies achieve their goals. </p>
                                <br />
                                <h1 className='text-[32px] font-semibold my-3 heading'>Empower Your Online Business With The Best Marketing Technologies</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Bonafide Technologies is a progressive digital agency situated in Ghaziabad, Delhi NCR, India. Our enthusiasm for supporting medium-sized and small enterprises. Our goal is always to give our clients the best possible services. We have more than 15+ years of expertise with a proven track record in producing results-based digital marketing strategies. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>We will assist with the design, research, and effective promotion of your goods and services as a creative digital marketing professional and driven entrepreneur in the field of developing technologies. This will enable business owners to grow their clientele, enhance sales, and maximize profits.</p>
                            </div>
                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Work with Us to Increase Business Productivity</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>When it comes to the same, we take the same approach to branding. We can also begin by listening to our clients as they go through the shape-designing process, ensuring that every step goes well.</p>
                                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                                    <div className="flex flex-wrap justify-between">
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Search Engine Optimization
                                            </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>If you're seeking SEO solutions, we offer one of the better possibilities in relation to SEO services that satisfy specifications and fall within budget.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Social Media Optimization</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Use social media to your advantage to attract more followers, recognition, and customers! We are one of the best social media marketing companies.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Pay Per Click</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Are you ready to increase traffic and a business boom? If so, we can achieve a better return on advertising spending than ever before at a cheaper cost by using all available advertising channels.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Website Analysis</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>We use a variety of web analysis technologies to guarantee that you receive an in-depth report on the functionality of your website. Additionally, you are welcome to ask for advice, and we would be happy to offer you suggestions on how to make your website look and feel better. </p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Link Building</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>To get long-term results, we employ a range of link-building techniques and manual outreach to develop backlinks. Our staff of expert link builders has a lot of expertise in building links on a regular basis. Our group will build natural, high-quality links that will aid in the organic search engine rankings of your website.</p>
                                        </div>
                                    </div>
                                </div>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Why Choose Us As Your Companion for SEO in Ghaziabad?</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>For the past ten years, we have been providing clients in and around Ghaziabad with excellent Search Engine Optimization services. Our offerings are both scalable and reasonably priced. In addition, we guarantee the delivery of excellent results that will elevate your company's position in search engine rankings. Many in the area trust us because of our track record of successfully providing results for clients. As a company, we are also always learning and developing, and we have assisted numerous clients in raising their search engine ranks. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>So, without wasting more time, contact us to start with your SEO work!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        )
}

export default Ghaziabad