import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { Link } from "react-router-dom";


function WooCommerce() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{
                backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)"
            }}>
                <div className="container mx-auto py-12">
                    <h1
                        className='text-center text-white text-[34px] md:text-[42px] lg:text-[56px] xl:text-[86px] font-bold heading uppercase'>
                        WooCommerce</h1>
                </div>
            </div>

            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[70%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <img src="img/WooCommerce.jpg" className='w-full' alt="" />

                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>WooCommerce</h1>
                                <p className='text-[18px] font-normal text-[#636363] '><strong>Complete WooCommerce Development
                                    Services for Feature-Rich and Influential E-commerce Solutions!</strong></p><br />
                                <p className='text-[18px] font-normal text-[#636363] '>For companies in India, Bonafide
                                    Technologies offers premium WooCommerce development services. Built on WordPress,
                                    WooCommerce is an open-source, completely customizable e-commerce platform that enables
                                    companies to establish incredibly successful online business stores. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>With precise WooCommerce customization,
                                    create pages that take advantage of hidden consumer trends and triggers to efficiently
                                    convert page visits into purchases. But to fully utilize this platform, you'll need skilled
                                    developers who know how to take full advantage of all of WooCommerce's amazing capabilities.
                                </p>
                                <p className='text-[18px] font-normal text-[#636363] '>Fortunately, Bonafide Technologies is
                                    available to assist online businesses in achieving WooCommerce's full potential and creating
                                    e-commerce websites that generate revenue.
                                </p>
                                <p className='text-[18px] font-normal text-[#636363] '>Take advantage of our WooCommerce
                                    development services to get a fully functional, SEO-optimized, responsive WooCommerce store
                                    with dynamic themes, an easy-to-use interface, and strong extensions. </p>
                            </div>

                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>What We Provide</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Make customers hit the "buy" button with
                                    captivating and incredibly attractive e-stores created by utilizing years of carefully
                                    chosen experience that serve a range of distinct clients. </p><br />

                                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                                    <div className="flex flex-wrap justify-between">
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>WooCommerce Customization
                                            </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Every online store has unique
                                                requirements that must be satisfied. Our team knows this and strives to present
                                                your store in the best possible light. Our goal and area of expertise is
                                                tailoring the store to meet your specific demands.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>WooCommerce Store Development
                                            </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>The team makes sure to take
                                                into account every important goal. Introducing the possibilities for deals and
                                                discounts is essential. Integration of appropriate payments is also important.
                                                We work diligently to establish it online to have a full store.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>WooCommerce Theme Development
                                            </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Potential customers are first
                                                attracted by the themes and designs of your website. It's essential to get them
                                                to scroll through several products and offers. We strive to create captivating
                                                themes with the help of an experienced panel. themes that are appropriate for
                                                the category of the store.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>WooCommerce Technical &
                                                Security Audit</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Considering secure payment
                                                methods is essential for any online retailer. It goes beyond just remembering to
                                                make the payment. We need to take care of a lot of things, such as the store's
                                                ranking and loading speed. This is precisely what our team strives for.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>WooCommerce Plugin
                                                Development</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>A website's ease of use is
                                                facilitated by its features. It is not essential to use every feature or plugin
                                                for it to be used correctly. With a vast collection of plugins, our expert panel
                                                just utilizes the most effective plugins that enhance the functionality of the
                                                website. </p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>WooCommerce Speed
                                                Optimization</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Loading speed is one of the
                                                most critical aspects of any website, particularly when it comes to online
                                                retailers. This helps visitors get through your website pretty quickly. We use
                                                the appropriate technique to maintain the speed of your website.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Migration to WooCommerce</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>A smooth and seamless
                                                migration is attainable. We maintain security so that the current database is
                                                not in danger. Our designers securely deliver the database. They also finish the
                                                customizing process, adding your personal touch to the website.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>WooCommerce Maintenance and
                                                Support</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Installing recent updates and
                                                doing maintenance is not a huge or challenging task. Our team can help you in
                                                the ideal manner!</p>
                                        </div>

                                    </div>
                                </div>

                                <div className="faqs my-12">
                                    <h1 className='text-[32px] font-medium my-3 heading'>FAQs</h1>
                                    <div id="accordion-collapse" data-accordion="collapse">
                                        <h2 id="accordion-collapse-heading-1">
                                            <button type="button"
                                                class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200  hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                                data-accordion-target="#accordion-collapse-body-1" aria-expanded="true"
                                                aria-controls="accordion-collapse-body-1">
                                                <span className='text-[20px] text-[#222] text-left'>What Does a WooCommerce
                                                    Developer Do?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                        strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-1" class="hidden"
                                            aria-labelledby="accordion-collapse-heading-1">
                                            <div
                                                class="p-5 border border-b-0 border-gray-200 darks:border-gray-700 darks:bg-gray-900">
                                                <p className='text-[18px] font-normal text-[#636363] '>A specialized web
                                                    developer with an extensive understanding of the strong framework of the
                                                    platform, a WooCommerce developer can create an online store that generates
                                                    revenue for your company. Bonafide Technologies will create a successful
                                                    online store for you because we have vast coding expertise, fluency in
                                                    computer and numeracy languages, exceptional problem-solving abilities, the
                                                    capacity to handle and communicate technical solutions understandably, and a
                                                    creative eye.</p>
                                            </div>
                                        </div>
                                        <h2 id="accordion-collapse-heading-2">
                                            <button type="button"
                                                class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                                data-accordion-target="#accordion-collapse-body-2" aria-expanded="false"
                                                aria-controls="accordion-collapse-body-2">
                                                <span className='text-[20px] text-[#222] text-left'>What Are The Benefits of
                                                    WooCommerce?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                        strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-2" class="hidden"
                                            aria-labelledby="accordion-collapse-heading-2">
                                            <div class="p-5 border border-b-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>WooCommerce, a popular
                                                    e-commerce platform built as an extension of WordPress, offers several
                                                    benefits for businesses looking to establish and grow their online presence.
                                                    Some of the key benefits of WooCommerce include:</p>
                                                <ul>
                                                    <li className='text-[18px] font-normal text-[#636363] '>
                                                        <strong>1.Flexibility:</strong>WooCommerce is highly flexible and
                                                        customizable, allowing businesses to create online stores tailored to
                                                        their specific needs. With numerous themes and plugins available, users
                                                        can design and extend their stores to match their brand identity and
                                                        meet their unique requirements.</li>
                                                    <li className='text-[18px] font-normal text-[#636363] '>
                                                        <strong>2.Integration with WordPress:</strong>As an extension of
                                                        WordPress, WooCommerce seamlessly integrates with the WordPress content
                                                        management system (CMS). This integration provides users with access to
                                                        a wide range of WordPress features and functionalities, including
                                                        blogging capabilities, SEO tools, and user management options.</li>
                                                    <li className='text-[18px] font-normal text-[#636363] '>
                                                        <strong>3.Scalability:</strong>WooCommerce is designed to accommodate
                                                        businesses of all sizes, from small startups to large enterprises. As
                                                        businesses grow and expand, WooCommerce can scale alongside them,
                                                        offering support for additional products, customers, and transactions
                                                        without compromising performance or stability.</li>
                                                    <li className='text-[18px] font-normal text-[#636363] '><strong>4.Wide Range
                                                        of Extensions:</strong>WooCommerce offers a vast ecosystem of
                                                        extensions and plugins that extend its functionality. Users can enhance
                                                        their stores with features such as advanced payment gateways, shipping
                                                        calculators, product bundles, subscription services, and more, allowing
                                                        them to create rich and immersive shopping experiences for their
                                                        customers.</li>
                                                    <li className='text-[18px] font-normal text-[#636363] '><strong>5.Community
                                                        Support:</strong>With a large and active community of developers,
                                                        designers, and users, WooCommerce benefits from ongoing support,
                                                        updates, and improvements. Users can tap into community forums,
                                                        documentation, tutorials, and other resources to troubleshoot issues,
                                                        learn best practices, and stay informed about the latest developments in
                                                        e-commerce technology.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <h2 id="accordion-collapse-heading-3">
                                            <button type="button"
                                                class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                                data-accordion-target="#accordion-collapse-body-3" aria-expanded="false"
                                                aria-controls="accordion-collapse-body-3">
                                                <span className='text-[20px] text-[#222] text-left'>How Do WooCommerce and
                                                    Shopify Differ From One Another?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                        strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-3" class="hidden"
                                            aria-labelledby="accordion-collapse-heading-3">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Shopify is an all-in-one
                                                    e-commerce platform that is boundlessly scalable and covers every aspect of
                                                    building a reliable and strong online store. A powerful online store can be
                                                    created for your company with the help of WooCommerce, an open-source
                                                    WordPress plugin that comes with several extensions and plugins. <br />
                                                    Bonafide Technologies has the development experience to work efficiently on
                                                    either solution, and both offer benefits. Get in touch with our team so that
                                                    we can offer our professional advice if you would like to discuss whether
                                                    Shopify or WooCommerce would be the ideal option for your company.</p>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-4">
                                            <button type="button"
                                                class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                                data-accordion-target="#accordion-collapse-body-4" aria-expanded="false"
                                                aria-controls="accordion-collapse-body-4">
                                                <span className='text-[20px] text-[#222] text-left'>How Many Products Can
                                                    WooCommerce List at Once?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                        strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-4" class="hidden"
                                            aria-labelledby="accordion-collapse-heading-4">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>The number of products
                                                    that WooCommerce can list is virtually limitless because there isn't a set
                                                    restriction. However, many estimations claim that WooCommerce is limited to
                                                    smaller stores.<br />
                                                    These two interpretations are both correct. Everything is based on how
                                                    quickly and effectively your website loads. WooCommerce can withstand far
                                                    higher volume if your website speed is properly maintained and cared for.
                                                    The functionality of the website can also be enhanced by plugins and traffic
                                                    management.<br />
                                                    Your WooCommerce site won't be able to manage a lot of products if you don't
                                                    take care of these things.<br />
                                                    We often discover that companies with sizable product categories could find
                                                    that the Magento platform is a better fit for their online store. If you're
                                                    doubtful, we would be pleased to go over the available choices with you and
                                                    offer our expert judgment on which platform will work best for you.</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className="w-[30%] hidden lg:block relative">
                            <div className='box-shadow rounded-lg ml-5 p-8 sidebar sticky top-[-150px]'>
                                <div className="sidebar_banner">
                                    <img src="img/page_img_4.jpg" alt="" />
                                </div>

                                <div className="sidebar_tabs mt-5">
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/wordpress">
                                            <h1 className='text-[18px] font-semibold capitalize'>Wordpress</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/wix">
                                            <h1 className='text-[18px] font-semibold capitalize'>wix</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/webflow">
                                            <h1 className='text-[18px] font-semibold capitalize'>webflow</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/figma">
                                            <h1 className='text-[18px] font-semibold capitalize'>figma</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/woocommerce">
                                            <h1 className='text-[18px] font-semibold capitalize'>Woo Commerce</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/shopify">
                                            <h1 className='text-[18px] font-semibold capitalize'>shopify</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/bubble">
                                            <h1 className='text-[18px] font-semibold capitalize'>Bubble.io</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/squarespace">
                                            <h1 className='text-[18px] font-semibold capitalize'>Squre Space</h1>
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default WooCommerce