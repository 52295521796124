import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Chandigarh() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{
                backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)" }}>
                <div className="container mx-auto py-12">
                    <h1
                        className='text-center text-white text-[32px] md:text-[52px]  font-semibold heading uppercase'>
                        SEO Company in Chandigarh
                    </h1>
                </div>
            </div>
        
            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[100%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>SEO Company in Chandigarh</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Our team of SEO Experts in Chandigarh can assist your company in boosting its digital presence and maintaining a competitive edge over your rivals. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>In order to reach as many clients as possible, having an active online presence is crucial, and SEO plays a big part in that. Hiring the best SEO company in Chandigarh is the first step towards having a strong online presence. At Bonafide Technologies, our SEO specialists are well-versed in the strategies that must be used to achieve the desired results.  </p>
                                <p className='text-[18px] font-normal text-[#636363] '>Additionally, our SEO experts are knowledgeable about the latest updates to search engine algorithms and possess an in-depth understanding of the top search engine algorithms. </p>
                                <br />
                                <h1 className='text-[32px] font-semibold my-3 heading'>Why You Need Search Engine Optimization</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>You might be familiar with the phrase SEO, which stands for search engine optimization. One of the greatest marketing techniques for getting your website to appear in search results is SEO. Your company has the chance to reach new clients by having the homepage of your website appear in Google's natural search results. Our SEO company in Chandigarh can assist you in accomplishing that with ease.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>The page that appears first in search results is the one that has the highest click-through rate. Even if you post your company's webpage online, it can show up on the fourth or fifth page when people search for terms associated with products and services related to your business. Hence, you shouldn't expect to interact with the user. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>Similar businesses and firms compete in a city like Chandigarh. All want to grow and become the best in their respective fields. It is imperative to adopt proactive measures and leverage online marketing technology, such as SEO.</p>
                            </div>
                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Why Choose Us</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Bonafide Technologies is your helping hand in achieving a high search engine position. Our SEO services include keyword research, content production, on-page and off-page SEO, online site audits, and page speed optimization. In order to provide top search engine rankings and maintenance, our team of SEO specialists invests in timely, intelligent, and optimized efforts. </p>
                                <br />
                                <p className='text-[18px] font-normal text-[#636363] '>Our best practice, search engine optimization, is carried out by adhering to the highest ethical standards to get excellent results. Since we produce SEO results according to professional standards, people consider us to be the best SEO company in Chandigarh. </p><br />
                                <ul>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span></strong>
                                        We provide the best SEO services both locally and globally.</li>   
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span></strong>
                                        We assist you in boosting your website's search engine rating through with the help of our advanced SEO strategies.</li>   
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span></strong>
                                        We provide services with a guarantee because we want our clients to be happy with their website rankings.</li>   
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span></strong>
                                        Our SEO experts are well-known in the industry and are required to provide analysis reports along with effective advertising strategies that have been demonstrated.</li>   
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span></strong>
                                        To ensure online visibility, we turn your website's weakest aspect into a strength.</li>
                                        <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span></strong>
                                            For all types of businesses, we offer packages of SEO services at reasonable pricing.</li>   
                                </ul> 
                                <h1 className='text-[32px] font-semibold my-3 heading'>Our SEO Services in Chandigarh</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Depending on the needs and nature of the business, we provide several kinds of SEO services. </p>
                                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                                    <div className="flex flex-wrap justify-between">
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Competition Analysis
                                            </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Competition analysis is essential if you want to understand the advantages and disadvantages of your competitors. Our SEO experts will thoroughly examine your website by seizing this study as a chance. It will look for keywords that your rivals are using and find the most effective ones to keep you ahead of them.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Keyword Research</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Undoubtedly, the effective use of relevant keywords improves your search engine rankings. With the help of our advanced research tools, you can find the most searched keywords and improve your SEO rankings. We guarantee that the keywords we pick will improve the ranking of your website by sticking to Google standards.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Website Audits</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>We will be able to identify your website's flaws by conducting audits. To improve website ranking, we thus endeavor to fix the errors as soon as possible. Our SEO specialists will improve the website's usability, speed up page loads, and work on SEO tagging. As a result, it aids in producing more beneficial results for your website.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Boost Traffic</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>As the top Chandigarh SEO company, we have the marketing resources to drive a lot of traffic to your website. Our areas of expertise include social media marketing, local SEO, and mobile SEO.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Content Creation</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Google adores content! Hence, your website cannot rank well on the most popular search engines without content. We provide complete content writing services for your website. The proper keywords will be used in content optimization by our team of writers and SEO specialists to raise your website's position on the first page of search engine results. You will receive rich content that uses compelling language to raise your rating.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>SEO Reporting</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Our SEO services go beyond just content marketing and traffic building. However, we also take note of SEO reporting, which is a helpful feature. Evaluate the effectiveness of an SEO strategy with the use of this beneficial SEO technique. </p>
                                        </div>
                                    </div>
                                </div>
                                <h1 className='text-[32px] font-semibold my-3 heading'>To Rule Online Marketing Success, Count On The Best Seo Company In Chandigarh!</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>At Bonafide Technologies, outstanding companies should be given proper credit. Hence, you can easily use our most useful SEO techniques to help your business rank highly. We constantly strive to maintain your SEO rankings, which helps you stand out in the industry. Additionally, your website will receive timely updates from our unique approach to business improvement. This gives you a significant online branding advantage by igniting your search engine optimization.</p>
                                <p className='text-[18px] font-normal text-[#636363] '><strong>Hire Us To Take Your Online Business To New Heights!</strong></p> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        )
}

export default Chandigarh