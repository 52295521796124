import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Ahmedabad() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{
                backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)" }}>
                <div className="container mx-auto py-12">
                    <h1
                        className='text-center text-white text-[32px] md:text-[52px]  font-semibold heading uppercase'>
                            SEO Company in Ahmedabad 
                    </h1>
                </div>
            </div>
        
            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[100%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>SEO Company in Ahmedabad </h1>
                                <p className='text-[18px] font-normal text-[#636363] '><strong>Increase Traffic to Your Website with Our SEO Services!</strong></p>
                                <br />
                                <p className='text-[18px] font-normal text-[#636363] '>Low traffic and search engine ranking point that you need to work with an SEO agency in Ahmedabad that can identify and attract your target market to your website. </p>
                                <br />
                                <p className='text-[18px] font-normal text-[#636363] '>Bonafide Technologies, the best SEO company in Ahmedabad, looks after your online visibility on Google and other search engines. These days, SEO is essential for business owners who want to be seen online. </p>
                                <br />
                                <p className='text-[18px] font-normal text-[#636363] '>Among all the SEO agencies in Ahmedabad, Bonafide Technologies leads the industry when it comes to implementing a strategy that is focused on achieving results. Our internet marketing services have been tested and are utilized on the right platform. </p>
                                <br />
                                <p className='text-[18px] font-normal text-[#636363] '>If a company hires us right away, there's no need for them to keep an eye on what's happening and how it's being done. Whether you already have a website or not, when you get in touch with us, we offer guidance from the start and help you in creating a website. </p>
                                <br />
                                <p className='text-[18px] font-normal text-[#636363] '>We are the masters of fully managed SEO, so forget about the others! Our value-driven strategy positions us as the top option for companies both inside and outside of India. See it for yourself to see why Bonafide Technologies is the Best SEO Company. </p>
                                <br />
                            </div>
                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Why Choose Us</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Being the most reputable SEO company in Ahmedabad, we have a wealth of expertise in creating websites and applications for businesses, brands, and organizations operating locally, nationally, and beyond. </p>
                                <br />
                                <p className='text-[18px] font-normal text-[#636363] '>Our SEO company in Ahmedabad is perfectly equipped to lure clients toward your products and services, engaging in the triumph of commercial businesses throughout all domains and spheres. To build a custom, responsive, and user-friendly website that converts, we use the best design and web development techniques that help with SEO altogether. </p>
                                <br />
                                <p className='text-[18px] font-normal text-[#636363] '>Put your trust in us for all of your digital media needs, and let us assist you with better business marketing through insightful content. We are here to provide you with affordable service. Use the best technologies available to transform your business. Hire the top SEO company in Ahmedabad, then monitor your progress.</p>
                                <br />
                                <h1 className='text-[32px] font-semibold my-3 heading'>What We Offer</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>With the help of our SEO service, you can quickly grow your business and boost web traffic.</p>
                                <br />
                                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                                    <div className="flex flex-wrap justify-between">
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Local SEO</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>To assist you in dominating local search engine rankings, attracting the attention of your target market, and increasing foot traffic to your website or place of business.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Global SEO</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Beat your rivals more quickly. Enjoy the countless advantages of our national and international SEO services, and observe as more clients come your way.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>E-commerce SEO</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Say goodbye to hidden product pages and welcome elevated visibility, improved search engine rankings, and focused visitors by unlocking the potential of your virtual store.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Technical SEO</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Watch your company grow as we improve your website for optimal and reliable search engine performance—no more crawl problems or broken links.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Social Media Marketing</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Let's Make your brand visible on all screens to attract more customers. You stay ahead of the competition with our social media marketing.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Pay Per Click</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Greetings from focused, economical advertising that truly encourages conversions! With our expertise and resources, you can increase your return on investment and online presence dramatically.</p>
                                        </div>
                                    </div>
                                </div>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Contact Us Right Away</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Since Bonafide Technologies provides the best services within your budget, we are Ahmedabad's go-to SEO company. Therefore, get in touch with us right now, and we'll listen to your needs and deliver the results you want. Act quickly to beat your rival in the online industry. </p>
                                <br /> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        )
}

export default Ahmedabad