import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from "react-router-dom";

function DevSlider() {
  const services = [
    {
      title: 'Full Stack ',
      image: 'img/fullstack_smll.jpg',
      link: '/full-stack',
    },
    {
      title: 'Mern Stack',
      image: 'img/mern_smll.jpg',
      link: '/mern-stack',
    },
    {
      title: 'Java Script',
      image: 'img/js_smll.jpg',
      link: '/javascript',
    },
    {
      title: 'Cake PHP',
      image: 'img/cakephp_smll.jpg',
      link: '/cake-php',
    },
    {
      title: 'Laravel',
      image: 'img/laravel_smll.jpg',
      link: '/laravel',
    },
    {
      title: 'React',
      image: 'img/react_smll.jpg',
      link: '/reactjs',
    },
    {
      title: 'PHP',
      image: 'img/php_smll.jpg',
      link: '/php',
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 4, // Desktop
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1324, // Tablet
        settings: {
          slidesToShow: 3,
          centerMode: false,
        },
      },
      {
        breakpoint: 1024, // Tablet
        settings: {
          slidesToShow: 2,
          centerMode: false,
        },
      },
      {
        breakpoint: 600, // Mobile
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {services.map((service, index) => (
        <div key={index} className='p-4'>
          <Link to={service.link} className="">
            <div className="cursor-pointer hover:shadow-lg rounded-xl overflow-hidden   page_box ">
              <div className="main_box overflow-hidden relative rounded-xl">
                <img src={service.image} className='object-cover w-full h-[320px] lg:h-[400px] transition-all duration-500' alt="" />
                <div className="page_text px-3 py-6 relative">
                  <h1 className='text-[18px] md:text-[22px] font-medium text-white capitalize'>{service.title}</h1>
                </div>
              </div>
            </div>
          </Link>
        </div>
      ))}
    </Slider>
  )
}

export default DevSlider