import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { Link } from "react-router-dom";


function WebTraffic() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{
                backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)"
            }}>
                <div className="container mx-auto py-12">
                    <h1
                        className='text-center text-white text-[34px] md:text-[42px] lg:text-[56px] xl:text-[86px] font-bold heading uppercase'>
                        Web Traffic</h1>
                </div>
            </div>

            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[70%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <img src="img/Web-Traffic.jpg" className='w-full' alt="" />

                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>DESIGN AND EXPAND YOUR WEBSITE TRAFFIC ON
                                    THE INTERNET.</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>We take great pleasure in offering web
                                    traffic services weaving with website design, development, SEO, & digital marketing. At
                                    Bonafide Technologies, we push our boundaries to increase performance through our devoted
                                    digital marketing strategies. Our SEO Results are guaranteed to improve your domain's
                                    ranking in search engines in 60–90 business days. </p><br />
                            </div>
                            <h1 className='text-[32px] font-semibold my-3 heading'>We Help you Build your Powerful Online
                                Presence.</h1>
                            <p className='text-[18px] font-normal text-[#636363] '>Bonafide Technologies provides expert web
                                traffic digital marketing solutions to help you increase your online visibility and revenue. We
                                help businesses all around India establish themselves online, bringing in high-quality leads and
                                boosting revenue. You are invisible if your rivals appear on the first page instead of you. So,
                                investing in a reliable digital marketing company is a win-win to take your web traffic to the
                                top.</p><br />


                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'> What We Provide</h1>

                                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                                    <div className="flex flex-wrap justify-between">
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Domain & Web Hosting</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Are you looking to host your
                                                website and domain on a server located in India? Welcome to Bonafide
                                                Technologies, the Leading Domain & Hosting Company offering Web Traffic service.
                                            </p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Websites Design</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '><strong>Making you look
                                                incredible online:</strong> Since most clients expect to be able to find
                                                your business online, first impressions matter, especially when it comes to the
                                                internet!</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Digital Marketing And Seo
                                            </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>It is obvious that you are
                                                interested in learning "what is SEO" if you are reading this page. Or "What
                                                makes SEO services necessary? You may be wondering, "How can SEO benefit your
                                                website?" or "What should I pay for SEO services."</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="faqs my-12">
                                    <h1 className='text-[32px] font-medium my-3 heading'> FAQs</h1>
                                    <div id="accordion-collapse" data-accordion="collapse">
                                        <h2 id="accordion-collapse-heading-1">
                                            <button type="button"
                                                class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200  hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                                data-accordion-target="#accordion-collapse-body-1" aria-expanded="true"
                                                aria-controls="accordion-collapse-body-1">
                                                <span className='text-[20px] text-[#222] text-left'>What Is Web Traffic?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                        strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-1" class="hidden"
                                            aria-labelledby="accordion-collapse-heading-1">
                                            <div
                                                class="p-5 border border-b-0 border-gray-200 darks:border-gray-700 darks:bg-gray-900">
                                                <p className='text-[18px] font-normal text-[#636363] '>The data sent and
                                                    received by website visitors is referred to as web traffic. It is the volume
                                                    of information shared over a given period between a website and its users.
                                                </p>
                                            </div>
                                        </div>
                                        <h2 id="accordion-collapse-heading-2">
                                            <button type="button"
                                                class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                                data-accordion-target="#accordion-collapse-body-2" aria-expanded="false"
                                                aria-controls="accordion-collapse-body-2">
                                                <span className='text-[20px] text-[#222] text-left'>Why Is Web Traffic
                                                    Important?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                        strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-2" class="hidden"
                                            aria-labelledby="accordion-collapse-heading-2">
                                            <div class="p-5 border border-b-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Because it shows a
                                                    website's popularity and visibility, web traffic is significant. Increased
                                                    brand exposure, more prospective clients, and more revenue prospects are
                                                    frequently correlated with higher web traffic.</p>
                                            </div>
                                        </div>
                                        <h2 id="accordion-collapse-heading-3">
                                            <button type="button"
                                                class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                                data-accordion-target="#accordion-collapse-body-3" aria-expanded="false"
                                                aria-controls="accordion-collapse-body-3">
                                                <span className='text-[20px] text-[#222] text-left'>Is The Traffic Real?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                        strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-3" class="hidden"
                                            aria-labelledby="accordion-collapse-heading-3">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>The Traffic is automated.
                                                    We build it with genuine web browsers using automation, so the Traffic
                                                    appears to be real human Traffic even though it isn't making transactions.
                                                    We promise that your Google Analytics dashboard will display every visitor
                                                    we deliver to your website.</p>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-4">
                                            <button type="button"
                                                class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                                data-accordion-target="#accordion-collapse-body-4" aria-expanded="false"
                                                aria-controls="accordion-collapse-body-4">
                                                <span className='text-[20px] text-[#222] text-left'>Will The Traffic Help Me
                                                    With SEO, Ranking, Etc.?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                        strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-4" class="hidden"
                                            aria-labelledby="accordion-collapse-heading-4">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>There are various types
                                                    of Traffic here. We advise using our Search Console Traffic if you aim to
                                                    raise your Google ranks. By doing this, you can be confident that your
                                                    target keywords will appear alongside all of the Traffic in your Google
                                                    Search Console.</p>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-5">
                                            <button type="button"
                                                class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                                data-accordion-target="#accordion-collapse-body-5" aria-expanded="false"
                                                aria-controls="accordion-collapse-body-5">
                                                <span className='text-[20px] text-[#222] text-left'>What Are Your Services
                                                    For?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                        strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-5" class="hidden"
                                            aria-labelledby="accordion-collapse-heading-5">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>The majority of our
                                                    clients use our services to boost KPIs related to website Traffic. Your
                                                    session duration, bounce rate, and return rate can all be improved. Along
                                                    with improving practically every other element of your website Traffic, we
                                                    can also assist in increasing the volume of organic and social visitors to
                                                    your site.</p>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-6">
                                            <button type="button"
                                                class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                                data-accordion-target="#accordion-collapse-body-6" aria-expanded="false"
                                                aria-controls="accordion-collapse-body-6">
                                                <span className='text-[20px] text-[#222] text-left'>What Are The Different
                                                    Sources Of Web Traffic?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                        strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-6" class="hidden"
                                            aria-labelledby="accordion-collapse-heading-6">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>There are other ways that
                                                    people find websites, such as through</p>
                                                <ul>
                                                    <li className='text-[18px] font-normal text-[#636363] '><strong>Organic
                                                        Search:</strong>Traffic from search engines like Google.</li>
                                                    <li className='text-[18px] font-normal text-[#636363] '><strong>Direct
                                                        Traffic:</strong>Visitors who type your URL into their browser
                                                        directly are known as direct Traffic.</li>
                                                    <li className='text-[18px] font-normal text-[#636363] '><strong>Referral
                                                        Traffic:</strong>Users that find your website through links to other
                                                        websites.</li>
                                                    <li className='text-[18px] font-normal text-[#636363] '><strong> Social
                                                        Media Traffic:</strong>Traffic from social media networks or
                                                        visitors from these platforms.</li>
                                                    <li className='text-[18px] font-normal text-[#636363] '><strong>Paid
                                                        Traffic: </strong>Users referred by sponsored advertisements.</li>
                                                </ul>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-7">
                                            <button type="button"
                                                class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                                data-accordion-target="#accordion-collapse-body-7" aria-expanded="false"
                                                aria-controls="accordion-collapse-body-7">
                                                <span className='text-[20px] text-[#222] text-left'>How Is Web Traffic
                                                    Measured?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                        strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-7" class="hidden"
                                            aria-labelledby="accordion-collapse-heading-7">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Web analytics programs
                                                    like Google Analytics, Adobe Analytics, or other website monitoring software
                                                    are commonly used to measure web traffic. These tools offer information on a
                                                    number of parameters, including bounce rate, session duration, page views,
                                                    and unique visitors.</p>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-8">
                                            <button type="button"
                                                class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                                data-accordion-target="#accordion-collapse-body-8" aria-expanded="false"
                                                aria-controls="accordion-collapse-body-8">
                                                <span className='text-[20px] text-[#222] text-left'>How Can I Increase Web
                                                    Traffic to my Website?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                        strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-8" class="hidden"
                                            aria-labelledby="accordion-collapse-heading-8">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Yes, we offer behavioral
                                                    insights about users based on their psychographics (behaviors, hobbies) and
                                                    demographics (age, gender). With the help of tools in Google Analytics, you
                                                    can segment your audience according to demographic information and learn
                                                    more about the traits of people who visit your website. </p>
                                                <ul>
                                                    <li className='text-[18px] font-normal text-[#636363] '>Among the many
                                                        methods to boost online Traffic is Search Engine Optimization (SEO),
                                                        which raises a website's organic search results.</li>
                                                    <li className='text-[18px] font-normal text-[#636363] '>Producing excellent,
                                                        relevant content that draws users in.</li>
                                                    <li className='text-[18px] font-normal text-[#636363] '>Utilizing social
                                                        media platforms to advertise your website.</li>
                                                    <li className='text-[18px] font-normal text-[#636363] '>Executing internet
                                                        marketing campaigns, such as Facebook or Google Ads.</li>
                                                    <li className='text-[18px] font-normal text-[#636363] '>Generating backlinks
                                                        from reliable online sources.</li>
                                                    <li className='text-[18px] font-normal text-[#636363] '>Interacting with
                                                        your readers via newsletters and email marketing.</li>
                                                    <li className='text-[18px] font-normal text-[#636363] '>Improving the user
                                                        experience and speed of websites.</li>
                                                </ul>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-9">
                                            <button type="button"
                                                class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                                data-accordion-target="#accordion-collapse-body-9" aria-expanded="false"
                                                aria-controls="accordion-collapse-body-9">
                                                <span className='text-[20px] text-[#222] text-left'>What Is The Bounce
                                                    Rate?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                        strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-9" class="hidden"
                                            aria-labelledby="accordion-collapse-heading-9">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>The percentage of
                                                    visitors to your website that leave after only reading one page is called
                                                    the "bounce rate." A high bounce rate can be a sign that the user experience
                                                    or content on your website needs improvement.</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className="w-[30%] hidden lg:block relative">
                            <div className='box-shadow rounded-lg ml-5 p-8 sidebar sticky top-[-150px]'>
                                <div className="sidebar_banner">
                                    <img src="img/page_img_4.jpg" alt="" />
                                </div>

                                <div className="sidebar_tabs mt-5">
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/social-media-marketing">
                                            <h1 className='text-[18px] font-semibold capitalize'>Social Media Marketing - SMM</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/social-engines-marketing">
                                            <h1 className='text-[18px] font-semibold capitalize'>Social Engines Marketing - SEM</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/guest-posting">
                                            <h1 className='text-[18px] font-semibold capitalize'>Guest Posting</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/web-analytics">
                                            <h1 className='text-[18px] font-semibold capitalize'>Web Analytics</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/local-seo">
                                            <h1 className='text-[18px] font-semibold capitalize'>Local SEO</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/e-commerce-marketing">
                                            <h1 className='text-[18px] font-semibold capitalize'>eCommerce Marketing</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/mobile-app-marketing">
                                            <h1 className='text-[18px] font-semibold capitalize'>mobile-app-marketing</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/influencer-marketing">
                                            <h1 className='text-[18px] font-semibold capitalize'>Influencer Marketing</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/affiliate-marketing">
                                            <h1 className='text-[18px] font-semibold capitalize'>Affiliate Marketing</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/web-traffic">
                                            <h1 className='text-[18px] font-semibold capitalize'>Web Traffic</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/ppc">
                                            <h1 className='text-[18px] font-semibold capitalize'>PPC</h1>
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default WebTraffic