import React from 'react'
import Header from './Header'
import Footer from './Footer'
import AdminLogin from './AdminLogin'

function Admin() {
  return (
   <>
   <Header/>
    <AdminLogin/>
   <Footer/>
   </>
  )
}

export default Admin