import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { Link } from "react-router-dom";

function ReactJS() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{
                backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)"
            }}>
                <div className="container mx-auto py-12">
                    <h1
                        className='text-center text-white text-[34px] md:text-[42px] lg:text-[56px] xl:text-[86px] font-bold heading uppercase'>
                        ReactJS</h1>
                </div>
            </div>

            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[70%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <img src="img/react.jpg" className='w-full' alt="" />

                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>ReactJS</h1>
                                <p className='text-[18px] font-normal text-[#636363] '><strong>Leverage specialized ReactJS
                                    development services to create highly engaging and feature-rich websites, plugins, and
                                    applications.</strong></p><br />
                                <p className='text-[18px] font-normal text-[#636363] '>Over the past few years, ReactJS has
                                    gained popularity in online application development. This is so that developers can more
                                    easily design reusable components and user interfaces with the help of the JavaScript
                                    framework ReactJS. Stated differently, it improves the organization and efficiency of the
                                    development process.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>Work with Bonafide Technologies to offer
                                    your clients the best experience possible and use ReactJS development to boost the immense
                                    growth of your company. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>With a staff of highly skilled ReactJS
                                    developers and several hundred completed projects under its belt, Bonafide Technologies is
                                    one of the top ReactJS development companies in India. Our development methodologies and
                                    experience provide innovative and customized solutions, whether you're looking for web
                                    applications, mobile apps, or something else. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>To meet our clients' expectations, we
                                    explore and create novel apps. We pledge to work more closely with you to provide
                                    specialized, safe, and user-friendly platforms at very short notice. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>We are pleased to provide our services
                                    following your needs, whether you would rather have us design and develop the full project,
                                    would rather have advice and technical support, or would like hourly service for your
                                    project.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>We can handle any aspect of project
                                    development, from project conception to launch and ongoing maintenance, whether your
                                    preference is to create a simple application or a refined commercial website. We will
                                    provide you with dynamic UI and UX that flawlessly synchronize with your business theme to
                                    offer a great user experience. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>Get in touch with us to create plugins
                                    and native apps in addition to web applications and iOS/Android app development. We will
                                    conduct complete testing and quality checks to guarantee that your application works
                                    perfectly in each client environment.</p>
                            </div>

                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'> What We Provide</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Since we provide end-to-end full-stack
                                    ReactJS development, you can choose Bonafide Technologies as your ReactJS development
                                    company. Our years of experience working with clients from various industries and
                                    enterprises have contributed to our expertise. Here are what comes under our services:</p>
                                <br />

                                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                                    <div className="flex flex-wrap justify-between">
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>ReactJS Application
                                                Development</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Create a mobile application
                                                using the ReactJS ecosystem with dynamic features, then quickly launch it. It is
                                                possible to create cross-platform programs without compromising security or
                                                performance.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>ReactJS Support And
                                                Maintenance </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Does your app have problems
                                                that make it difficult for users, such as bugs, performance issues, or
                                                functional concerns? Get in touch with our ReactJS developers for quick ReactJS
                                                maintenance and assistance.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>ReactJS QA And Testing </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Our skilled QA and testing
                                                team can test and find bugs, performance difficulties, and other issues with
                                                your web and mobile applications. For your apps to run smoothly, we'll handle
                                                the performance and functionality problems and the bugs.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>ReactJS UI Development </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Every native user interface
                                                element in ReactJS is defined as a component. Most importantly, latency is not a
                                                problem while updating and changing them with Virtual DOM. Our ReactJS
                                                developers can create incredibly captivating and eye-catching user interfaces
                                                (UIs) for your apps and websites since they are proficient in ReactJS UI
                                                features and components.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Plugin Development</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Our ReactJS developers can
                                                create unique plugins from the ground up to provide your app with more features
                                                and power so you can accomplish your business objectives. The platform is a
                                                great choice for plugin development because of libraries' (like React's) ability
                                                to manage user interactions with ease and access data.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>React Migration Services
                                            </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Convert your outdated project
                                                to ReactJS to make it more advanced and user-engagement-focused. With no data
                                                loss, our ReactJS team can quickly move your project and fine-tune it according
                                                to your company objectives and industry standards.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Social Networking Solutions
                                            </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Use ReactJS to create
                                                incredibly dynamic, captivating, and user-friendly social network apps and
                                                solutions. Using ReactJS, we will develop feature-rich, personalized social
                                                networking apps and make sure they are optimized for end users.</p>
                                        </div>

                                    </div>
                                </div>

                                <div className="faqs my-12">
                                    <h1 className='text-[32px] font-medium my-3 heading'>FAQs</h1>
                                    <div id="accordion-collapse" data-accordion="collapse">
                                        <h2 id="accordion-collapse-heading-1">
                                            <button type="button"
                                                class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200  hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                                data-accordion-target="#accordion-collapse-body-1" aria-expanded="true"
                                                aria-controls="accordion-collapse-body-1">
                                                <span className='text-[20px] text-[#222] text-left'>What Does ReactJS Excel at
                                                    Providing? </span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                        strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-1" class="hidden"
                                            aria-labelledby="accordion-collapse-heading-1">
                                            <div
                                                class="p-5 border border-b-0 border-gray-200 darks:border-gray-700 darks:bg-gray-900">
                                                <p className='text-[18px] font-normal text-[#636363] '>An open-source JavaScript
                                                    library called React is used to create front-end user interfaces and
                                                    applications. With React, creating complex business applications is easy and
                                                    doesn't require any hassles like reloading pages or modifying codes.
                                                    Applications developed with React are more responsive, scalable, and
                                                    high-performing.</p>
                                            </div>
                                        </div>
                                        <h2 id="accordion-collapse-heading-2">
                                            <button type="button"
                                                class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                                data-accordion-target="#accordion-collapse-body-2" aria-expanded="false"
                                                aria-controls="accordion-collapse-body-2">
                                                <span className='text-[20px] text-[#222] text-left'>Is It Possible to Create
                                                    Mobile Applications With React.JS? </span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                        strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-2" class="hidden"
                                            aria-labelledby="accordion-collapse-heading-2">
                                            <div class="p-5 border border-b-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Indeed, we can. Even
                                                    though React JS is typically used for web development, React Native may also
                                                    be used to create mobile applications. Additionally, we have provided React
                                                    Native programming services, especially for needs related to mobile
                                                    development.</p>
                                            </div>
                                        </div>
                                        <h2 id="accordion-collapse-heading-3">
                                            <button type="button"
                                                class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                                data-accordion-target="#accordion-collapse-body-3" aria-expanded="false"
                                                aria-controls="accordion-collapse-body-3">
                                                <span className='text-[20px] text-[#222] text-left'>Why Prefer Reactjs For
                                                    Creating an E-Commerce Website? </span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                        strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-3" class="hidden"
                                            aria-labelledby="accordion-collapse-heading-3">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>When it comes to creating
                                                    an eCommerce application, React JS performs well. Additionally, the dynamic
                                                    features can be used in conjunction with HTML elements, CSS, and JavaScript
                                                    libraries. As a result, creating a multi-featured website or web application
                                                    with React JS is relatively hassle-free.</p>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-4">
                                            <button type="button"
                                                class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                                data-accordion-target="#accordion-collapse-body-4" aria-expanded="false"
                                                aria-controls="accordion-collapse-body-4">
                                                <span className='text-[20px] text-[#222] text-left'>Why Choose Bonafide
                                                    Technologies For React App Development? </span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                        strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-4" class="hidden"
                                            aria-labelledby="accordion-collapse-heading-4">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '><strong> Bonafide
                                                    Technologies</strong> can be a good fit for your company if you're
                                                    searching for highly qualified and seasoned developers who manage procedures
                                                    transparently and thoroughly understand React JS. To deliver strong support
                                                    and prompt communication that satisfies clients to a larger degree, we
                                                    adhere to international standards.</p>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-5">
                                            <button type="button"
                                                class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                                data-accordion-target="#accordion-collapse-body-5" aria-expanded="false"
                                                aria-controls="accordion-collapse-body-5">
                                                <span className='text-[20px] text-[#222] text-left'>Can I Migrate My Current
                                                    Applications Into a React Application? </span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                        strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-5" class="hidden"
                                            aria-labelledby="accordion-collapse-heading-5">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Yes! You can convert your
                                                    present applications into the latest React applications. It will be easier
                                                    to complete this hassle-free method than to create an application from the
                                                    ground up.</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className="w-[30%] hidden lg:block relative">
                            <div className='box-shadow rounded-lg ml-5 p-8 sidebar sticky top-[-150px]'>
                                <div className="sidebar_banner">
                                    <img src="img/page_img_4.jpg" alt="" />
                                </div>

                                <div className="sidebar_tabs mt-5">
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/full-stack">
                                            <h1 className='text-[18px] font-semibold capitalize'>full stack</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/mern-stack">
                                            <h1 className='text-[18px] font-semibold capitalize'>mern stack</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/javascript">
                                            <h1 className='text-[18px] font-semibold capitalize'>java script</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/cake-php">
                                            <h1 className='text-[18px] font-semibold capitalize'>cake php</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/laravel">
                                            <h1 className='text-[18px] font-semibold capitalize'>laravel</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/reactjs">
                                            <h1 className='text-[18px] font-semibold capitalize'>react JS</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/php">
                                            <h1 className='text-[18px] font-semibold capitalize'>PHP</h1>
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ReactJS