import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Jaipur() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{
                backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)"
            }}>
                <div className="container mx-auto py-12">
                    <h1
                        className='text-center text-white text-[32px] md:text-[42px] font-semibold heading uppercase'>SEO Company in Jaipur
                    </h1>
                </div>
            </div>

            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[100%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>SEO Company in Jaipur</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Setting up your web platform to draw attention and capture interaction. Creating a strong online presence and empowering brand authority to drive long-term returns on investment. With the help of tried and tested digital tricks, we can boost your website with the local audience. Set up your digital marketing objectives with the top SEO company in Jaipur.</p>
                                <br />
                            </div>
                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Increase Leads and Website Traffic With Skilled SEO Services</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>A goal-oriented SEO company in Jaipur, Bonafide Technologies offers various data-driven digital marketing solutions. Our primary goal is to help businesses become more visible on search engine results pages while improving their online presence. </p>
                                <br />
                                <p className='text-[18px] font-normal text-[#636363] '>Our team of expert website developers, SEO specialists, and project managers provides revolutionary, widely used, and organic SEO solutions. To help your business succeed and expand, we can also effortlessly manage any web development, web design, and SEO issue at any moment.</p><br />
                                <p className='text-[18px] font-normal text-[#636363] '>With over 15 years of experience, the organization is well-known for offering top-notch services. We have experience working with clients in different sectors, including banking, real estate, manufacturing, healthcare, and sports. Our professionals combine modern technology and their ingenuity to help your company achieve the highest level of profitability.</p><br />
                                <h1 className='text-[32px] font-semibold my-3 heading'>What We Offer</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>We have a broad perspective and expertise across all major technological platforms to provide you with a complete range of services.</p>
                                <br />
                                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                                    <div className="flex flex-wrap justify-between">
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Local SEO
                                            </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '><strong>Drifting through the native charts. Using geographical, regionally relevant business keywords, you can reach your audience's search queries!</strong></p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>PPC Services</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Managing your pay-per-click advertising involves selecting the right audiences, crafting persuasive copy, and launching impactful sponsored advertisements.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Content Marketing</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Content that piques your interest and moves your thoughts. Making use of the most profitable inbound channels to increase brand awareness!</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Social Media Optimization </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Become well-known on social media sites by dedicating yourself to creating digital content that draws in, influences, and involves your audience.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Digital Marketing</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Utilizing the most incredible strategies and techniques for digital marketing to make the most of online movements. A complete digital marketing solution is the best bet for any business to leverage cutting-edge digital chances to expand your company. </p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Content Writing</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Our content writing agency in India has a reputation for producing high-caliber, Google guidelines-compliant content that is optimized for search engines.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Guest Post Service</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Jaipur's Guest Posting Service With the outstanding guest posting service provided by Bonafide Technologies in Jaipur, buyers and sellers may emphasize their content and increase their reach.</p>
                                        </div>
                                    </div>
                                </div>
                                <h1 className='text-[32px] font-semibold my-3 heading'>How Do We Do It?</h1>
                                <p className='text-[18px] font-normal text-[#636363] '><strong>Global SEO Expertise With Local SEO Services!</strong></p>
                                <p className='text-[18px] font-normal text-[#636363] '>SEO is your long game. Getting a long-lasting spot on search results pages and ad networks takes consistent, ongoing work. Being the top SEO firm in Jaipur, we are aware of what it takes to launch a successful long-term SEO campaign that builds a meaningful online presence for your organization. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>We prioritize long-term results while creating a unique business plan to maintain your top search engine ranking. Our skilled team of qualified SEO specialists employs business-aware tactics to apply the best methods and concepts that provide the most significant outcomes in the shortest amount of time.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>We achieve this by combining the appropriate resources to perform in-depth keyword research, audience network planning, online case analysis, and strategic positioning to prepare a fully functional SEO stage that will get you started. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>These are executed correctly thanks to a targeted tactical strategy and reliable implementation techniques. This is supported further by effective performance monitoring and thorough reporting tasks to get consistently higher performance ratings. </p>

                                <h3 className='text-[32px] font-semibold my-3 heading'>Why Do You Need SEO in Jaipur?</h3>
                                <p className='text-[18px] font-normal text-[#636363] '>The business scene in Jaipur is congested. You may build trust and authority online with the aid of SEO. By continuously producing excellent content that is relevant to your industry and target audience, you establish credibility with prospective clients and show your knowledge. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>You can improve the visibility of your website in local search results by putting local SEO tactics into practice. This includes adding geo-targeted keywords, getting good online reviews, and improving your Google My Business listing. Travelers looking for experiences in Jaipur are more likely to find you if you have a stronger local SEO focus. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>Imagine potential customers looking for "traditional Rajasthani dinner" or "Jaipur souvenirs" in Jaipur. Your website may rank well on search engine results pages (SERPs) with efficient SEO, ensuring that users find you rather than your rivals. By focusing on pertinent keywords that residents are using to look for, SEO helps bring in targeted, high-quality traffic to your website.</p>

                                <h1 className='text-[32px] font-semibold my-3 heading'>What Makes us a Reliable SEO Company in Jaipur</h1>
                                <ul>
                                    <li className='text-[18px] font-normal text-[#636363] '><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>Decades of proven experience in the industry </li>
                                    <li className='text-[18px] font-normal text-[#636363] '><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>Top-ranked business on major networks </li>
                                    <li className='text-[18px] font-normal text-[#636363] '><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>Complete knowledge of SEO and digital marketing </li>
                                    <li className='text-[18px] font-normal text-[#636363] '><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>Helped businesses across the world </li>
                                    <li className='text-[18px] font-normal text-[#636363] '><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>Professionals in SEO certification with a track record of success </li>
                                    <li className='text-[18px] font-normal text-[#636363] '><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>Frequent growth and training programs for SEO </li>
                                    <li className='text-[18px] font-normal text-[#636363] '><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>Use tried and tested strategies based on the guidelines </li>
                                    <li className='text-[18px] font-normal text-[#636363] '><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>Business-focused approach supported by customized services</li>
                                </ul>
                                <p className='text-[18px] font-normal text-[#636363] '>Boost your website's SEO to the next level. We send you a huge amount of leads and revenue. You need to work with us if you want higher rankings.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Jaipur