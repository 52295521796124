import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { Link } from "react-router-dom";


function PPC() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{ backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)" }}>
                <div className="container mx-auto py-12">
                    <h1 className='text-center text-white text-[34px] md:text-[42px] lg:text-[56px] xl:text-[86px] font-bold heading uppercase'>PPC</h1>
                </div>
            </div>

            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[70%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <img src="img/PPC.jpg" className='w-full' alt="" />

                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>PPC</h1>
                                <p className='text-[18px] font-normal text-[#636363] '><strong>Join Hands With Bonafide, A PPC Services Provider That Creates Winning Marketing Campaigns!</strong></p><br />
                                <p className='text-[18px] font-normal text-[#636363] '>Bonafide Technologies is a committed PPC specialist who can help with any task, including maximizing brand exposure, boosting sales, or producing high-quality leads. Our PPC specialists have expertise in managing online marketing campaigns through social media channels like Facebook, Instagram, LinkedIn, Pinterest, Spotify, and TikTok, as well as niche channels. They have also run campaigns via Google Ads (formerly known as Google Adwords) using Search, Display, Shopping, and YouTube ads. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>We create engaging and creative ads, closely monitor market trends, thoroughly optimize campaigns, and closely evaluate PPC ad success on behalf of our clients. In the end, we help you in maximizing the marketing results of your PPC advertising. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>PPC marketing is the catalyst that powers the expansion of your company. Our methods are driven by our hunger to see an immediate increase in leads, sales, and profits for your company.</p>
                            </div>

                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'> What We Provide</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Being a market-leading PPC company in India, we boost results and provide your brand with a competitive edge with conversion-focused messaging and reliable statistics. Hundreds of businesses have benefited from our PPC marketing services in terms of results. </p><br />

                                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                                    <div className="flex flex-wrap justify-between">
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Search Ads</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Use paid search advertisements to target your most valued customers with the correct message at the right moment through local map listings and search results. Using Bing Ads or Google Search, our proactive PPC team will make sure you produce leads and sales at a reasonable cost.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Display Ads </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Use eye-catching online display banners to advertise your goods and services. These banners are made especially to raise brand awareness and generate high-quality website traffic. The Paid Media team at Bonafide will launch action-inspiring, low-cost per-click advertising campaigns. </p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Shopping Ads</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>By presenting your products to the ideal demographic who is ready to buy, your Google Shopping ad campaigns will help you reach your eCommerce sales goals. You can achieve an outstanding return on ad spend with the help of our PPC experts.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Paid Social Ads</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>With creative social media advertising on Facebook, LinkedIn, Instagram, TikTok, Pinterest, or Spotify, you can go beyond Paid Search. With our PPC services in India, you can target extremely relevant audiences and encourage clicks to your landing sites.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>YouTube Ads</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>With more than a billion active viewers in India, YouTube is the best platform for companies looking to expand their customer base. With Bonafide as your YouTube advertising agency, you can use compelling video ads that are specifically targeted to convert viewers into buyers.</p>
                                        </div>

                                    </div>
                                </div>

                                <div className="faqs my-12">
                                    <h1 className='text-[32px] font-medium my-3 heading'> FAQs</h1>
                                    <div id="accordion-collapse" data-accordion="collapse">
                                        <h2 id="accordion-collapse-heading-1">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200  hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                <span className='text-[20px] text-[#222] text-left'>What Is PPC Marketing?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-1" class="hidden" aria-labelledby="accordion-collapse-heading-1">
                                            <div class="p-5 border border-b-0 border-gray-200 darks:border-gray-700 darks:bg-gray-900">
                                                <p className='text-[18px] font-normal text-[#636363] '>PPC marketing is a form of online advertising where advertisers pay a fee each time their ad is clicked. It's a way of buying visits to your site, rather than attempting to "earn" those visits organically.</p>
                                            </div>
                                        </div>
                                        <h2 id="accordion-collapse-heading-2">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-2" aria-expanded="false" aria-controls="accordion-collapse-body-2">
                                                <span className='text-[20px] text-[#222] text-left'>How Does PPC Advertising Work?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-2" class="hidden" aria-labelledby="accordion-collapse-heading-2">
                                            <div class="p-5 border border-b-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>PPC advertising works through platforms like Google Ads, Bing Ads, and social media advertising networks. Advertisers bid on keywords relevant to their target audience. When users search for those keywords, the ads appear at the top or bottom of search engine results pages (SERPs) or on other websites or platforms.</p>
                                            </div>
                                        </div>
                                        <h2 id="accordion-collapse-heading-3">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-3" aria-expanded="false" aria-controls="accordion-collapse-body-3">
                                                <span className='text-[20px] text-[#222] text-left'>What Are the Benefits of PPC Marketing?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-3" class="hidden" aria-labelledby="accordion-collapse-heading-3">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <ul>
                                                    <li className='text-[18px] font-normal text-[#636363] '><strong>Immediate visibility: </strong>Your ads can appear at the top of search results almost instantly.</li>
                                                    <li className='text-[18px] font-normal text-[#636363] '><strong>Targeted advertising: </strong>You can target specific demographics, locations, and interests.</li>
                                                    <li className='text-[18px] font-normal text-[#636363] '><strong>Measurable results: </strong>PPC platforms provide detailed analytics, allowing you to track the performance of your campaigns in real time.</li>
                                                    <li className='text-[18px] font-normal text-[#636363] '><strong>Control over budget: </strong>You can set a daily or monthly budget and adjust bids as needed.</li>
                                                </ul>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-4">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-4" aria-expanded="false" aria-controls="accordion-collapse-body-4">
                                                <span className='text-[20px] text-[#222] text-left'>What Platforms Can I Use for PPC Advertising?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-4" class="hidden" aria-labelledby="accordion-collapse-heading-4">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Some popular PPC advertising platforms include Google Ads, Bing Ads, Facebook Ads, Twitter Ads, LinkedIn Ads, and Instagram Ads.</p>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-5">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-5" aria-expanded="false" aria-controls="accordion-collapse-body-5">
                                                <span className='text-[20px] text-[#222] text-left'>How Do I Choose The Right Keywords for My PPC Campaigns?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-5" class="hidden" aria-labelledby="accordion-collapse-heading-5">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Perform keyword research to identify relevant keywords that align with your business, products, or services. Consider factors such as search volume, competition, and relevance to your target audience.</p>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-6">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-6" aria-expanded="false" aria-controls="accordion-collapse-body-6">
                                                <span className='text-[20px] text-[#222] text-left'>How Much Does PPC Advertising Cost?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-6" class="hidden" aria-labelledby="accordion-collapse-heading-6">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>The cost of PPC advertising varies based on factors such as competition for keywords, quality score, and industry. You can set a budget based on your goals and adjust bids accordingly.</p>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-7">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-7" aria-expanded="false" aria-controls="accordion-collapse-body-7">
                                                <span className='text-[20px] text-[#222] text-left'>What Is a Quality Score In PPC Marketing?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-7" class="hidden" aria-labelledby="accordion-collapse-heading-7">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Quality score is a metric used by PPC platforms to calculate the quality and relevance of your ads, keywords, and landing pages. It affects your ad position, ad rank, and cost-per-click (CPC).</p>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-8">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-8" aria-expanded="false" aria-controls="accordion-collapse-body-8">
                                                <span className='text-[20px] text-[#222] text-left'>How Can I Optimize My PPC Campaigns for Better Performance?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-8" class="hidden" aria-labelledby="accordion-collapse-heading-8">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <ul>
                                                    <li className='text-[18px] font-normal text-[#636363] '>Continuously monitor and analyze campaign performance.</li>
                                                    <li className='text-[18px] font-normal text-[#636363] '>Test different ad copies, keywords, and targeting options.</li>
                                                    <li className='text-[18px] font-normal text-[#636363] '>Optimize landing pages for better conversion rates.</li>
                                                    <li className='text-[18px] font-normal text-[#636363] '>Use ad extensions to provide additional information and improve ad visibility.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <h2 id="accordion-collapse-heading-9">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-9" aria-expanded="false" aria-controls="accordion-collapse-body-9">
                                                <span className='text-[20px] text-[#222] text-left'>Is PPC Marketing Suitable for All Businesses?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-9" class="hidden" aria-labelledby="accordion-collapse-heading-9">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>While PPC marketing can be effective for many businesses, its practicality depends on factors such as budget, goals, target audience, and competition. It's essential to consider your specific needs and objectives before investing in PPC advertising.</p>
                                            </div>
                                        </div>
                                        <h2 id="accordion-collapse-heading-10">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-10" aria-expanded="false" aria-controls="accordion-collapse-body-10">
                                                <span className='text-[20px] text-[#222] text-left'>Do I Need an Expert to Run My PPC Ad Campaigns?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-10" class="hidden" aria-labelledby="accordion-collapse-heading-10">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>You must have a broad understanding of ad platforms such as Google Ads and Bing ads, Google Analytics, landing pages, site design, tracking, and more to effectively manage PPC ad campaigns. For in-house marketers who are already balancing competing responsibilities, mastering these ad management abilities takes a great deal of effort. That is why it is ideal to engage with PPC specialists.</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className="w-[30%] hidden lg:block relative">
                            <div className='box-shadow rounded-lg ml-5 p-8 sidebar sticky top-[-150px]'>
                                <div className="sidebar_banner">
                                    <img src="img/page_img_4.jpg" alt="" />
                                </div>

                                <div className="sidebar_tabs mt-5">
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/social-media-marketing">
                                            <h1 className='text-[18px] font-semibold capitalize'>Social Media Marketing - SMM</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/social-engines-marketing">
                                            <h1 className='text-[18px] font-semibold capitalize'>Social Engines Marketing - SEM</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/guest-posting">
                                            <h1 className='text-[18px] font-semibold capitalize'>Guest Posting</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/web-analytics">
                                            <h1 className='text-[18px] font-semibold capitalize'>Web Analytics</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/local-seo">
                                            <h1 className='text-[18px] font-semibold capitalize'>Local SEO</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/e-commerce-marketing">
                                            <h1 className='text-[18px] font-semibold capitalize'>eCommerce Marketing</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/mobile-app-marketing">
                                            <h1 className='text-[18px] font-semibold capitalize'>mobile-app-marketing</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/influencer-marketing">
                                            <h1 className='text-[18px] font-semibold capitalize'>Influencer Marketing</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/affiliate-marketing">
                                            <h1 className='text-[18px] font-semibold capitalize'>Affiliate Marketing</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/web-traffic">
                                            <h1 className='text-[18px] font-semibold capitalize'>Web Traffic</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/ppc">
                                            <h1 className='text-[18px] font-semibold capitalize'>PPC</h1>
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default PPC