// Import necessary modules and components
import React, { useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';


function ContactUs() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });

    const [formErrors, setFormErrors] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setFormErrors({ ...formErrors, [e.target.name]: '' });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newFormErrors = {};
        if (!formData.name) {
            newFormErrors.name = 'Please enter your name';
        }
        if (!formData.email) {
            newFormErrors.email = 'Please enter your email address';
        }
        if (!formData.phone) {
            newFormErrors.phone = 'Please enter your phone number';
        }
        if (!formData.message) {
            newFormErrors.message = 'Please enter your message';
        }

        if (Object.keys(newFormErrors).length > 0) {
            setFormErrors(newFormErrors);
            return;
        }

        try {
            const response = await fetch('http://localhost:5007/api/submitContact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                console.log('Contact submitted successfully!');
                alert('Contact Form submitted successfully!');
                navigate("/");

            } else {
                console.error('Failed to submit proposal');
            }
        } catch (error) {
            console.error('Error submitting proposal', error);
        }
    };

    return (
        <>
            <Header />
            <div className="container-fluid py-12 lg:py-24 bg-cover bg-center" style={{ backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)" }}>
                <div className="container mx-auto py-12">
                    <h1 className='text-center text-white text-[34px] md:text-[42px] lg:text-[56px] xl:text-[86px] font-bold heading uppercase'>Contact Us</h1>
                </div>
            </div>

            <div className="container-fluid py-12 ">
                <div className="container mx-auto">
                    <div className="flex flex-wrap lg:flex-nowrap items-center">
                        <div className="w-full lg:w-[60%] p-5">
                            <div className="mx-auto">
                                <div className="w-full bg-white rounded-lg darks:border ">
                                    <div className="sm:p-6 space-y-4 md:space-y-6 sm:p-8">
                                        <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>

                                            <div className="flex justify-between flex-wrap">
                                                <div className='w-full md:w-[48%] space-y-4'>
                                                    <div>
                                                        <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 darks:text-white">Full Name <span className='text-red-600'> *</span></label>
                                                        <input
                                                            type="text"
                                                            name="name"
                                                            id="name"
                                                            onChange={handleChange}
                                                            className={`bg-gray-50 border border-[#222] text-gray-900 sm:text-md rounded-lg focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500`}
                                                            placeholder="Your Name"
                                                            required=""
                                                        />
                                                        {formErrors.name && <p className="text-red-600">{formErrors.name}</p>}
                                                    </div>
                                                    <div>
                                                        <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 darks:text-white">Email Address <span className='text-red-600'> *</span></label>
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            id="email"
                                                            onChange={handleChange}
                                                            className={`bg-gray-50 border border-[#222] text-gray-900 sm:text-md rounded-lg focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500`}
                                                            placeholder="name@company.com"
                                                            required=""
                                                        />
                                                        {formErrors.email && <p className="text-red-600">{formErrors.email}</p>}
                                                    </div>
                                                    <div>
                                                        <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 darks:text-white">Phone <span className='text-red-600'> *</span></label>
                                                        <input
                                                            type="text"
                                                            name="phone"
                                                            id="phone"
                                                            onChange={handleChange}
                                                            placeholder="1236547896"
                                                            className={`bg-gray-50 border border-[#222] text-gray-900 sm:text-md rounded-lg focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500 `}
                                                            required=""
                                                        />
                                                        {formErrors.phone && <p className="text-red-600">{formErrors.phone}</p>}
                                                    </div>
                                                </div>
                                                <div className='w-full md:w-[48%]'>
                                                    <div>
                                                        <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 darks:text-white">Comment <span className='text-red-600'> *</span></label>
                                                        <textarea id="message" name='message' rows="9"
                                                            className={`bg-gray-50 border border-[#222] text-gray-900 sm:text-md rounded-lg focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500`} onChange={handleChange} placeholder="Write your thoughts here..."
                                                        ></textarea>
                                                        {formErrors.message && <p className="text-red-600">{formErrors.message}</p>}
                                                    </div>
                                                </div>
                                            </div>
                                            <button
                                                type="submit"
                                                className="w-full bg-[#222] text-white focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center darks:bg-black darks:hover:bg-primary-700 darks:focus:ring-primary-800"
                                            >
                                                SEND NOW
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="w-full lg:w-[40%] px-8 lg:px-0 py-5">
                            <h1 className='text-[34px] font-bold heading mb-3'> Get In Touch</h1>

                            <div className="flex flex-wrap justify-between">
                                <div className='border-2 border-[transparent] p-5 w-full sm:w-[48%] rounded-lg group hover:border-[#e3b299] shadow-lg'>
                                    <p className="text-[15px] xl:text-[17px] font-medium cursor-pointer heading">
                                        <span className="mr-[10px] text-[20px]"><i className="fa-regular fa-envelope"></i></span>info@bonafidetech.com
                                    </p>
                                </div>
                                <div className='border-2 border-[transparent] p-5 w-full mt-5 sm:mt-0 sm:w-[48%] rounded-lg group hover:border-[#e3b299] shadow-lg'>
                                    <p className="text-[15px] xl:text-[17px] font-medium cursor-pointer heading">
                                        <span className="mr-[10px] text-[20px]"><i className="fa-solid fa-mobile-screen-button"></i></span>1236547896
                                    </p>
                                </div>
                            </div>
                            <div className='border-2 border-[transparent] p-5 mt-5 rounded-lg group hover:border-[#e3b299] shadow-lg'>
                                <p className="text-[15px] xl:text-[17px] font-medium cursor-pointer heading">
                                    <span className="mr-[10px] text-[20px]"><i className="fa-solid fa-location-dot"></i></span>Plot No: D-160, First Floor, Phase-8, Industrial Area, Sahibzada Ajit Singh Nagar, Punjab 160071
                                </p>
                            </div>

                            <div className="icons_tx flex mt-5">
                                <div className='cursor-pointer h-10 w-10 mr-3 border border-2 hover:border-[#e3b299] rounded-lg pl-[10px] pt-[8px] border-black'><a href="https://www.facebook.com/bonafidetechnologies/"><i class="fa-brands fa-facebook text-[18px]"></i></a></div>
                                <div className='cursor-pointer h-10 w-10 mr-3 border border-2 hover:border-[#e3b299] rounded-lg pl-[10px] pt-[8px] border-black'><a href="https://www.instagram.com/bonafidetechnologies/"><i class="fa-brands text-[18px] fa-instagram"></i></a></div>
                                <div className='cursor-pointer h-10 w-10 mr-3 border border-2 hover:border-[#e3b299] rounded-lg pl-[10px] pt-[8px] border-black'><a href="https://linkedin.com/authwall?trk=bf&trkInfo=AQGbKx7WEyvV6AAAAY5aag9gxYuh8zKj0IJX7iqLuHhmI_j4sR4uxw7dBuGVoBnMt3vyuEQcx1Z0zD_Wsz46B5amIjBk187dYtQII1Nbvx0qd3DDA7FXxImAFBmVpFvZeoiURxQ=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fbonafide-technologies"><i class="fa-brands text-[18px] fa-linkedin-in"></i></a></div>
                                <div className='cursor-pointer h-10 w-10 mr-3 border border-2 hover:border-[#e3b299] rounded-lg pl-[10px] pt-[8px] border-black'><a href="https://wa.me/7814471295"><i class="fa-brands fa-whatsapp text-[18px]"></i></a></div>
                                <div className='cursor-pointer h-10 w-10 mr-3 border border-2 hover:border-[#e3b299] rounded-lg pl-[10px] pt-[8px] border-black'><a href="https://www.upwork.com/agencies/bonafidetech/"><i class="fa-brands fa-upwork text-[18px]"></i></a></div>
                                <div className='cursor-pointer h-10 w-10 mr-3 border border-2 hover:border-[#e3b299] rounded-lg pl-[10px] pt-[8px] border-black'><a href="https://pro.fiverr.com/agencies/BFTConsultants"><img src="img/fiverr.png" alt="" /></a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3430.2222075735126!2d76.70440911221752!3d30.712152974488248!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fefb9233941b9%3A0xa766865159ebf59a!2sBonafide%20Technologies!5e0!3m2!1sen!2sin!4v1707385040725!5m2!1sen!2sin" width="100%" height="450" allowFullScreen="" loading="lazy" title="contact-map" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <Footer />
        </>
    );
}

export default ContactUs;
