import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { Link } from "react-router-dom";


function Javascript() {
  return (
    <>
      <Header />
      <div className="container-fluid py-24 bg-cover bg-center" style={{ backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)" }}>
        <div className="container mx-auto py-12">
          <h1 className='text-center text-white text-[34px] md:text-[42px] lg:text-[56px] xl:text-[86px] font-bold heading uppercase'>JavaScript</h1>
        </div>
      </div>

      <div className="container-fluid py-12">
        <div className="container mx-auto">
          <div className="flex flex-wrap md:flex-nowrap">
            <div className="w-full lg:w-[70%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
              <img src="img/javascript.jpg" className='w-full' alt="" />

              <div className='mt-8'>
                <h1 className='text-[32px] font-semibold my-3 heading'>JavaScript</h1>
                <p className='text-[18px] font-normal text-[#636363] '>JavaScript is a dynamic computer language that offers simple tools for developing websites. Our software development company has experience working on both simple and difficult projects because of the multiple projects that it has completed. </p><br />
                <p className='text-[18px] font-normal text-[#636363] '>With only a few easy actions, you can hire JavaScript developers from Bonafide Technologies and receive innovative solutions quickly. </p>
                <p className='text-[18px] font-normal text-[#636363] '>Our JavaScript developers use a wide range of tools and frameworks to produce perfect JavaScript code by utilizing JavaScript technology. Their proficiency in front-end and back-end programming allows them to solve complex problems and complete projects on time. </p>
                <p className='text-[18px] font-normal text-[#636363] '>JavaScript, or JS, is a stable, scalable, and reliable programming language used by our company's JavaScript Programmers. With a qualified JavaScript developer on the team, our JavaScript Web Development team provides complete JavaScript Services to meet the needs of your company. </p>
                <p className='text-[18px] font-normal text-[#636363] '>Utilize our efficient JavaScript services to grow your company. We offer them in India, USA, Canada, Australia, Malaysia, and other countries.</p>
              </div>

              <div className='my-8'>
                <h1 className='text-[32px] font-semibold my-3 heading'> What We Provide</h1>
                <p className='text-[18px] font-normal text-[#636363] '>With a wide range of JavaScript development services and technological expertise, our JavaScript developers enhance the value of your brand even more. View the JavaScript web and app development services we offer below. </p><br />

                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                  <div className="flex flex-wrap justify-between">
                    <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                      <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>JavaScript Web & Mobile App Development</h1>
                      <p className='text-[18px] font-normal text-[#636363] '>By working with Bonafide Technologies, you ensure your web and app launch successfully. Our expertise ensures that everything works perfectly from the beginning of your development process. We also provide cross-platform solutions to help your brand connect with more people.</p>
                    </div>
                    <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                      <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>JavaScript Integration Services</h1>
                      <p className='text-[18px] font-normal text-[#636363] '>We have a full-stack JavaScript developer on staff to provide our clients with exceptional JavaScript integration services. Our software developers deliver projects on schedule and have experience integrating JavaScript libraries. Through the integration of the JS library and user experience optimization, they can enhance the performance of the website.</p>
                    </div>
                    <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                      <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>JavaScript Back-end Development</h1>
                      <p className='text-[18px] font-normal text-[#636363] '>Modern website designs have a higher chance of drawing visitors than traditional ones. Our talented designers employ their analysis of online trends and creative ideas to create state-of-the-art eCommerce websites. To code quickly and efficiently, they use tools and JavaScript frameworks. Choose a JavaScript developer based on the requirements of your company.</p>
                    </div>
                    <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                      <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>JavaScript Front-end Development</h1>
                      <p className='text-[18px] font-normal text-[#636363] '>Hire a JavaScript developer who offers various businesses state-of-the-art front-end development services. Our front-end developers build web and mobile app solutions quickly and efficiently using the React, Angular, and Vuejs frameworks. Our front-end developers are of the highest caliber and deliver on time.</p>
                    </div>
                    <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                      <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Custom JavaScript Software Development</h1>
                      <p className='text-[18px] font-normal text-[#636363] '>Hiring a javascript developer gives you access to an expert who will understand your business's needs and provide a solution that serves your goals. Our software developers customize websites to increase the value of your brand and business. A full-stack developer can create scalable and quick JavaScript software using the right tools and frameworks.</p>
                    </div>
                    <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                      <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>JavaScript Enterprise Development</h1>
                      <p className='text-[18px] font-normal text-[#636363] '>Many companies can benefit from our full-stack developer's complete Javascript business development solutions. You get cutting-edge web solutions when you work with our JavaScript developers. Also, we have collaborated with multiple businesses, providing them with advanced solutions tailored to their needs.</p>
                    </div>
                    <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                      <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>CMS Development</h1>
                      <p className='text-[18px] font-normal text-[#636363] '>We offer customized CMS development services for many kinds of website concepts. You can effortlessly generate, classify, arrange, edit, and release web content while working with our knowledgeable CMS development team. We provide a quick and affordable web content management system that generates leads.</p>
                    </div>

                  </div>
                </div>
                <h1 className='text-[32px] font-semibold my-3 heading'>OUR JAVASCRIPT DEVELOPMENT FRAMEWORKS</h1>
                <p className='text-[18px] font-normal text-[#636363] '>
                  <ul>
                    <li className='text-[18px] font-normal text-[#636363] '><strong>ReactJS :</strong>Facebook supports an open-source framework called Reach, which is the newest tool for creating sophisticated and highly interactive web apps for businesses. Its greatest choice for user-facing development is due to its flexibility, time efficiency, and reactivity. At Bonafide Technologies, we specialize in using our slick and user-friendly ReactJS solutions to fuel your online business.</li>
                    <li className='text-[18px] font-normal text-[#636363] '><strong>NodeJS :</strong>Node.js is an open-source server-side JavaScript environment built on top of Chrome's V8 Javascript engine: Because of its ability to handle several tasks at once, it is a great choice when high scalability is required. Our Node.js development procedures are designed to provide error-free consistency and efficiency for servers and browsers.</li>
                  </ul>
                </p><br />

                <div className="faqs my-12">
                  <h1 className='text-[32px] font-medium my-3 heading'>FAQs</h1>
                  <div id="accordion-collapse" data-accordion="collapse">
                    <h2 id="accordion-collapse-heading-1">
                      <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200  hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                        <span className='text-[20px] text-[#222] text-left'>How to Find the Best Javascript Technology?</span>
                        <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                        </svg>
                      </button>
                    </h2>
                    <div id="accordion-collapse-body-1" class="hidden" aria-labelledby="accordion-collapse-heading-1">
                      <div class="p-5 border border-b-0 border-gray-200 darks:border-gray-700 darks:bg-gray-900">
                        <p className='text-[18px] font-normal text-[#636363] '>You can find the best JavaScript technology by understanding your project needs. Explore what features and functions are important for the software. It will help to pick the right technology for your project and a freelance javascript developer.<br />
                          Moreover, you can get a free consultation from Bonafide Technologies and know which is the best JavaScript technology for your project. You can hire our JavaScript developers.
                        </p>
                      </div>
                    </div>
                    <h2 id="accordion-collapse-heading-2">
                      <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-2" aria-expanded="false" aria-controls="accordion-collapse-body-2">
                        <span className='text-[20px] text-[#222] text-left'>How Long Does It Take to Build a Javascript Solution?</span>
                        <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                        </svg>
                      </button>
                    </h2>
                    <div id="accordion-collapse-body-2" class="hidden" aria-labelledby="accordion-collapse-heading-2">
                      <div class="p-5 border border-b-0 border-gray-200 darks:border-gray-700">
                        <p className='text-[18px] font-normal text-[#636363] '>On average, a JavaScript solution takes 3-6 months. However, the development time depends on the project size, complexity level, set of features, business objectives, and requirements.</p>
                      </div>
                    </div>
                    <h2 id="accordion-collapse-heading-3">
                      <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-3" aria-expanded="false" aria-controls="accordion-collapse-body-3">
                        <span className='text-[20px] text-[#222] text-left'>What Are The Benefits of Choosing Javascript?</span>
                        <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                        </svg>
                      </button>
                    </h2>
                    <div id="accordion-collapse-body-3" class="hidden" aria-labelledby="accordion-collapse-heading-3">
                      <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                        <p className='text-[18px] font-normal text-[#636363] '>JavaScript is a scripting language and is widely used by many big companies. You can find JavaScript developers effectively if you know the benefits. It helps to hire the best freelance javascript developer in a short time. Have a look at them before choosing JavaScript.</p>
                        <ul>
                          <li className='text-[18px] font-normal text-[#636363] '>Speed up the execution of a program</li>
                          <li className='text-[18px] font-normal text-[#636363] '>Easy to understand and learn</li>
                          <li className='text-[18px] font-normal text-[#636363] '>Highly popular language</li>
                          <li className='text-[18px] font-normal text-[#636363] '>Provides numerous interfaces</li>
                          <li className='text-[18px] font-normal text-[#636363] '>Add snippets of predefined codes and more</li>
                        </ul>
                      </div>
                    </div>

                    <h2 id="accordion-collapse-heading-4">
                      <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-4" aria-expanded="false" aria-controls="accordion-collapse-body-4">
                        <span className='text-[20px] text-[#222] text-left'>Is Javascript Front-End or Back-End Technology?</span>
                        <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                        </svg>
                      </button>
                    </h2>
                    <div id="accordion-collapse-body-4" class="hidden" aria-labelledby="accordion-collapse-heading-4">
                      <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                        <p className='text-[18px] font-normal text-[#636363] '>JavaScript is a front-end technology and is used to create user-friendly interfaces. Also, simple, complex, and innovative designs can be created with JS. However, it is also used for back-end development.<br />
                          You can interview javascript developers and ask some questions related to front-end and back-end to check their skills. This process helps in building interactive user interfaces. Also, hire the best freelance javascript developer.</p>
                      </div>
                    </div>

                    <h2 id="accordion-collapse-heading-5">
                      <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-5" aria-expanded="false" aria-controls="accordion-collapse-body-5">
                        <span className='text-[20px] text-[#222] text-left'>Do You Provide Post-Launch Support?</span>
                        <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                        </svg>
                      </button>
                    </h2>
                    <div id="accordion-collapse-body-5" class="hidden" aria-labelledby="accordion-collapse-heading-5">
                      <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                        <p className='text-[18px] font-normal text-[#636363] '>Yes, we do. Our qualified javascript developers understand some surprises can appear after the launch of your website. So, we provide free post-launch support to remove all the errors and problems on your website or app.<br />
                          Our team analyzes the reason for the problem and fixes it in a short time. Moreover, we employ experienced candidates for design and development jobs for the web page. So, you can find javascript developers who are dedicated and qualified.</p>
                      </div>
                    </div>

                    <h2 id="accordion-collapse-heading-6">
                      <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-6" aria-expanded="false" aria-controls="accordion-collapse-body-6">
                        <span className='text-[20px] text-[#222] text-left'>How Can I Get Updates About Javascript Website Development?</span>
                        <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                        </svg>
                      </button>
                    </h2>
                    <div id="accordion-collapse-body-6" class="hidden" aria-labelledby="accordion-collapse-heading-6">
                      <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                        <p className='text-[18px] font-normal text-[#636363] '>When you hire JavaScript developers, we assign a project manager to your project. He/she handles all the processes and specifies the work. So, you can get information about the progress of your app from the project manager.<br />
                          Moreover, you can contact us at any time and learn about your project. </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
            <div className="w-[30%] hidden lg:block relative">
              <div className='box-shadow rounded-lg ml-5 p-8 sidebar sticky top-[-150px]'>
                <div className="sidebar_banner">
                  <img src="img/page_img_4.jpg" alt="" />
                </div>

                <div className="sidebar_tabs mt-5">
                  <div
                    className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                    <Link to="/full-stack">
                      <h1 className='text-[18px] font-semibold capitalize'>full stack</h1>
                    </Link>
                  </div>
                  <div
                    className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                    <Link to="/mern-stack">
                      <h1 className='text-[18px] font-semibold capitalize'>mern stack</h1>
                    </Link>
                  </div>
                  <div
                    className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                    <Link to="/javascript">
                      <h1 className='text-[18px] font-semibold capitalize'>java script</h1>
                    </Link>
                  </div>
                  <div
                    className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                    <Link to="/cake-php">
                      <h1 className='text-[18px] font-semibold capitalize'>cake php</h1>
                    </Link>
                  </div>
                  <div
                    className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                    <Link to="/laravel">
                      <h1 className='text-[18px] font-semibold capitalize'>laravel</h1>
                    </Link>
                  </div>
                  <div
                    className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                    <Link to="/reactjs">
                      <h1 className='text-[18px] font-semibold capitalize'>react JS</h1>
                    </Link>
                  </div>
                  <div
                    className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                    <Link to="/php">
                      <h1 className='text-[18px] font-semibold capitalize'>PHP</h1>
                    </Link>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Javascript