import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Andheri() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{
                backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)"
            }}>
                <div className="container mx-auto py-12">
                    <h1 className='text-center text-white text-[32px] md:text-[52px]  font-semibold heading uppercase'>
                        SEO Company in Andheri
                    </h1>
                </div>
            </div>

            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[100%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>SEO Company in Andheri</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Modern-day seems full of new and inventive uses for existing technologies. These factors contribute to the increasing dominance and competitiveness of the market. Nowadays, we can come across extremely high-level associations and new businesses in our neighborhood. And because each of these businesses works flawlessly and continuously to satisfy the needs of its target market, new things are sometimes ignored in the marketplace.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>An excellent way to exceed the market dominance of existing products and establish a strong brand identity for yourself is through SEO services in Andheri.</p>
                                <br />

                                <h1 className='text-[32px] font-semibold my-3 heading'>SEO Services: Take A Flight To Success!</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Before delving into SEO's intricacies, we must understand its entirety and the key components in integrating SEO into your company's digital strategies. Search engine optimization, or SEO, is a well-known and powerful component in digital marketing. Your company can get a lot of traffic and rank well on Google and other search engines by working with an SEO company in Andheri.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>SEO makes your company more visible to consumers and improves client interaction, increasing organic search traffic for your website. Hiring the Best SEO company in Andheri is the key to your online website's success. Stated differently, it highlights key elements that guarantee your website receives sufficient traffic for all relevant searches in search engines. SEO helps search engines index your website and place it in the right position based on the results of the strategies specific to your query.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>Making contact with <strong>"Bonafide Technologies,"</strong> the Andheri SEO professionals, will benefit your online business in the following ways:</p>
                                <ul>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span></strong>
                                        Producing content of the highest quality, completely navigable, and optimized for users and search engines.</li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span></strong>
                                        Determination of suitable keywords based on the most popular searches made by internet users.</li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span></strong>
                                        High-quality backlinks</li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span></strong>
                                        Monitoring the results and ongoing work is being done to optimize the website for all celebrated search engines.</li>
                                </ul>

                                <h1 className='text-[32px] font-semibold my-3 heading'>What Comes Under Our SEO Package</h1>
                                <ul>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span></strong>
                                        Website Audit</li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span></strong>
                                        Competitor Analysis</li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span></strong>
                                        Keyword Research</li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span></strong>
                                        On-Page Optimisation</li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span></strong>
                                        Local SEO </li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span></strong>
                                        Link Building</li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span></strong>
                                        Content Writing</li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span></strong>
                                        Ranking Performance Reports</li>
                                </ul>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Why Choose Us</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>The best SEO company in Andheri, Bonafide Technologies, is what your company needs to attract potential visitors to your website who could become clients. As the top SEO company in Andheri, we know the algorithms Google uses to determine a website's ranking for particular keywords. We deliver tailored SEO services in Andheri to help your website rank well in Google searches. We can optimize your website for Google and other search engines like Yahoo, Bing, etc.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>Our SEO specialists in Andheri apply an SEO approach that raises the ranking of your website and produces results quickly. Within a few months of hiring our SEO team, you will be able to experience the success that comes with having your website ranked higher on the main search engines.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>Bonafide Technologies grows your organization through On-page, Off-page, Technical, and Local SEO. We are an authentic, competent, certified, and talented group of people who use deep techniques to manage optimal SEO for your company, regardless of its sector, size, location, or history. We have the right to call our Andheri SEO Company the most respectable and honest in the business.</p>
                            </div>
                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Our Services</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>At Bonafide Technologies, we provide an extensive range of services that have been thoroughly crafted to improve your online visibility and increase natural search engine traffic. Our group of professionals is a specialist in:</p>
                                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                                    <div className="flex flex-wrap justify-between">
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Link Building Supremacy
                                            </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Creating a network of high-quality backlinks to boost your website's authority and take it to the top of search engine results pages.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Mobile SEO Optimization</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Optimize your website for mobile platforms, ensuring optimal user experiences and increased exposure in mobile search results.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Organic Visibility Growth</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Increasing your website's visibility in search results with tried-and-true SEO techniques will help you stay ahead of the competition.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Strategic Keyword Artistry</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Choose and optimize your keywords carefully to draw in your target audience and boost organic traffic.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Complete SEO Diagnosis</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Perform powerful SEO techniques and services locally in Andheri and conduct thorough website audits to identify improvement areas.</p>
                                        </div>
                                    </div>
                                </div>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Our Process</h1>
                                <ul>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>Keyword Research: </strong>
                                        We begin by performing extensive keyword research to identify the most relevant and high-converting keywords for your business.</li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>On-Page Optimization: </strong>
                                        Our SEO experts optimize the On-page SEO of your website, including meta tags, content, and internal linking, to improve its search engine ranking.</li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"></i></span>Off-Page SEO: </strong>
                                        We build authoritative backlinks and implement Off-page SEO strategies to maintain your website's credibility in your industry. </li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span className='text-[#63E6BE] mr-2'><i class="fa-regular fa-square-check"> </i></span>Regular Reporting:</strong>
                                        We provide complete reports on the progress of your SEO campaign, allowing you to track your website's performance.</li>
                                </ul>
                                <p className='text-[18px] font-normal text-[#636363] '>If you are looking for a top-notch SEO company in Andheri to boost your online visibility, attract more organic traffic, and achieve higher search engine rankings, look no further than Bonafide Technologies SEO Solutions.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>Our team of experts is dedicated to helping your business thrive in the digital world. Contact us today to explore our affordable local SEO packages and take the first step toward online success. Let's elevate your brand together!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Andheri
