import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { Link } from "react-router-dom";


function Bubble() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{
                backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)"
            }}>
                <div className="container mx-auto py-12">
                    <h1
                        className='text-center text-white text-[34px] md:text-[42px] lg:text-[56px] xl:text-[86px] font-bold heading uppercase'>
                        Bubble.io</h1>
                </div>
            </div>

            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[70%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <img src="img/Bubble.io.jpg" className='w-full' alt="" />

                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Bubble.io</h1>
                                <p className='text-[18px] font-normal text-[#636363] '><strong> A Full-stack, No-code Platform</strong> </p>
                                <p className='text-[18px] font-normal text-[#636363] '>Get code-free production-grade
                                    applications. We create innovative Apps with Bubble. Regardless of experience level or role
                                    within an organization, Bubble is suitable for all users. Create online applications with
                                    Bubble, an innovative no-code platform, without ever writing a line of code. </p><br />
                                <p className='text-[18px] font-normal text-[#636363] '>At Bonafide Technologies, we offer
                                    website design, SEO, and fractional CMO services, which are also built on Bubble. With the
                                    advantage of infinite flexibility and the ability to combine the internal business
                                    application and marketing website into one, Bubble has become more popular among businesses
                                    of all sizes. </p>
                            </div>

                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Where Scale Meets Simplicity</h1>
                                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                                    <div className="flex flex-wrap justify-between">
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Faster Development Time</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>The visual development
                                                environment of Bubble allows you to work quickly and efficiently, meaning you
                                                can get your website up and running much faster.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Lower Costs</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Because no coding is
                                                required, bubble website development is usually more affordable than traditional
                                                development.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Greater Flexibility</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Bubble is incredibly
                                                flexible, allowing developers to create custom applications that meet your
                                                specific needs.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Easier Maintenance</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Bubble websites are easy to
                                                maintain and update, even for non-technical users. </p>
                                        </div>

                                    </div>
                                </div>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Ready to Get Started?</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>If you're looking for a fast, affordable,
                                    and flexible way to build your website, then bubble website developers are the perfect
                                    solution. With their expertise, you can create a website that meets your needs and exceeds
                                    your expectations.
                                </p><br />

                                <div className="faqs my-12">
                                    <h1 className='text-[32px] font-medium my-3 heading'> FAQs</h1>
                                    <div id="accordion-collapse" data-accordion="collapse">
                                        <h2 id="accordion-collapse-heading-1">
                                            <button type="button"
                                                class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200  hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                                data-accordion-target="#accordion-collapse-body-1" aria-expanded="true"
                                                aria-controls="accordion-collapse-body-1">
                                                <span className='text-[20px] text-[#222] text-left'>What is Bubble.io?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                        strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-1" class="hidden"
                                            aria-labelledby="accordion-collapse-heading-1">
                                            <div
                                                class="p-5 border border-b-0 border-gray-200 darks:border-gray-700 darks:bg-gray-900">
                                                <p className='text-[18px] font-normal text-[#636363] '>With Bubble.io, users can
                                                    create web applications without knowing how to code due to a visual
                                                    programming platform. Its drag-and-drop interface makes it possible for
                                                    non-programmers to build powerful, interactive web apps.</p>
                                            </div>
                                        </div>
                                        <h2 id="accordion-collapse-heading-2">
                                            <button type="button"
                                                class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                                data-accordion-target="#accordion-collapse-body-2" aria-expanded="false"
                                                aria-controls="accordion-collapse-body-2">
                                                <span className='text-[20px] text-[#222] text-left'>What Kind of Applications
                                                    Can I Build With Bubble.io?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                        strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-2" class="hidden"
                                            aria-labelledby="accordion-collapse-heading-2">
                                            <div class="p-5 border border-b-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Multiple web
                                                    applications, including as social networks, e-commerce platforms,
                                                    marketplaces, CRMs (Customer Relationship Management systems), and project
                                                    management tools, can be made with Bubble.io.</p>
                                            </div>
                                        </div>
                                        <h2 id="accordion-collapse-heading-3">
                                            <button type="button"
                                                class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                                data-accordion-target="#accordion-collapse-body-3" aria-expanded="false"
                                                aria-controls="accordion-collapse-body-3">
                                                <span className='text-[20px] text-[#222] text-left'>Is Bubble.io Suitable For
                                                    Large-Scale Projects?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                        strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-3" class="hidden"
                                            aria-labelledby="accordion-collapse-heading-3">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Bubble.io can be used for
                                                    large-scale projects, although its primary purpose is to construct MVPs
                                                    (Minimum Viable Products) quickly. When developing complicated applications,
                                                    it is crucial to take into account the scalability considerations and
                                                    restrictions of the platform.</p>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-4">
                                            <button type="button"
                                                class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                                data-accordion-target="#accordion-collapse-body-4" aria-expanded="false"
                                                aria-controls="accordion-collapse-body-4">
                                                <span className='text-[20px] text-[#222] text-left'>Can I Integrate External
                                                    Services With Bubble.io?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                        strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-4" class="hidden"
                                            aria-labelledby="accordion-collapse-heading-4">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Yes, you can incorporate
                                                    outside services and data sources into your apps with the help of
                                                    Bubble.io's assortment of plugins and APIs. You can link to services like
                                                    Google Maps for geolocation and Stripe for payments, among others.</p>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-5">
                                            <button type="button"
                                                class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                                data-accordion-target="#accordion-collapse-body-5" aria-expanded="false"
                                                aria-controls="accordion-collapse-body-5">
                                                <span className='text-[20px] text-[#222] text-left'>Is Bubble.io Free to
                                                    Use?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                        strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-5" class="hidden"
                                            aria-labelledby="accordion-collapse-heading-5">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>With various constraints,
                                                    such Bubble branding and use limitations, the free plan provided by
                                                    Bubble.io enables you to create and test applications. Users that need more
                                                    features, more capacity, or wish to get rid of the Bubble branding from
                                                    their apps can choose among paid options.</p>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-6">
                                            <button type="button"
                                                class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3"
                                                data-accordion-target="#accordion-collapse-body-6" aria-expanded="false"
                                                aria-controls="accordion-collapse-body-6">
                                                <span className='text-[20px] text-[#222] text-left'>Is Bubble.io Suitable For
                                                    Professional Development?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                        strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-6" class="hidden"
                                            aria-labelledby="accordion-collapse-heading-6">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Indeed, Bubble.io can be
                                                    used for professional website development, particularly for new firms,
                                                    entrepreneurs, and companies who want to swiftly and affordably construct
                                                    web apps. Before beginning a project, it's crucial to learn the platform's
                                                    capabilities and restrictions.</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className="w-[30%] hidden lg:block relative">
                            <div className='box-shadow rounded-lg ml-5 p-8 sidebar sticky top-[-150px]'>
                                <div className="sidebar_banner">
                                    <img src="img/page_img_4.jpg" alt="" />
                                </div>

                                <div className="sidebar_tabs mt-5">
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/wordpress">
                                            <h1 className='text-[18px] font-semibold capitalize'>Wordpress</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/wix">
                                            <h1 className='text-[18px] font-semibold capitalize'>wix</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/webflow">
                                            <h1 className='text-[18px] font-semibold capitalize'>webflow</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/figma">
                                            <h1 className='text-[18px] font-semibold capitalize'>figma</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/woocommerce">
                                            <h1 className='text-[18px] font-semibold capitalize'>Woo Commerce</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/shopify">
                                            <h1 className='text-[18px] font-semibold capitalize'>shopify</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/bubble">
                                            <h1 className='text-[18px] font-semibold capitalize'>Bubble.io</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/squarespace">
                                            <h1 className='text-[18px] font-semibold capitalize'>Squre Space</h1>
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Bubble