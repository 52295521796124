import React, { useState} from "react";
import Modal from "react-modal";
import AuditForm from "./AuditForm";
import { Link } from "react-router-dom";
function Header() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openNav, setOpenNav] = useState(false);

    const setModelOpen = () => {
        if (isModalOpen === true) {
            setIsModalOpen(false);
        }
        else {
            setIsModalOpen(true);
        }
    }

    const setOpenNavHandler = () => {
        if (openNav === true) {
            setOpenNav(false);
        } else {
            setOpenNav(true);
        }
    };

    return (
        <>
            <div className="container-fluid p-3 lg:py-2 bg-black hidden lg:block">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap items-center">
                        <div className="w-full md:w-[70%]">
                            <div className="flex justify-center md:justify-start">
                                <div className="w-[50%] md:w-[40%] xl:w-[25%] text-right md:text-left px-3 md:px-0">
                                    <p className="text-[18px] text-white cursor-pointer">
                                        <a href="mailto:info@bonafidetech.com"><span class="mr-[10px] text-[18px]"><i class="fa-regular fa-envelope"></i></span>info@bonafidetech.com</a>
                                    </p>
                                </div>
                                <div className="w-[50%] md:w-[60%] ">
                                    <p className="text-[18px] text-white cursor-pointer">
                                        <a href="tel:7814471295">  <span class="mr-[10px] text-[18px]"><i class="fa-solid fa-mobile-screen-button"></i></span>7814471295</a>
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div className="w-full md:w-[30%]">
                            <div className="icons flex justify-center md:justify-end ">
                                <div className='cursor-pointer h-10 w-10 border border-2 rounded-full pl-[10px] pt-[8px] text-white hover:'><a href="https://www.facebook.com/bonafidetechnologies/"><i class="fa-brands fa-facebook text-[18px]"></i></a></div>
                                <div className='cursor-pointer h-10 w-10 ml-3 border border-2 rounded-full pl-[10px] pt-[8px] text-white'><a href="https://www.instagram.com/bonafidetechnologies/"><i class="fa-brands text-[18px] fa-instagram"></i></a></div>
                                <div className='cursor-pointer h-10 w-10 ml-3 border border-2 rounded-full pl-[10px] pt-[8px] text-white'><a href="https://linkedin.com/authwall?trk=bf&trkInfo=AQGbKx7WEyvV6AAAAY5aag9gxYuh8zKj0IJX7iqLuHhmI_j4sR4uxw7dBuGVoBnMt3vyuEQcx1Z0zD_Wsz46B5amIjBk187dYtQII1Nbvx0qd3DDA7FXxImAFBmVpFvZeoiURxQ=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fbonafide-technologies"><i class="fa-brands text-[18px] fa-linkedin-in"></i></a></div>
                                <div className='cursor-pointer h-10 w-10 ml-3 border border-2 rounded-full pl-[10px] pt-[8px] text-white'><a href="https://wa.me/7814471295"><i class="fa-brands fa-whatsapp text-[18px]"></i></a></div>
                                <div className='cursor-pointer h-10 w-10 ml-3 border border-2 rounded-full pl-[10px] pt-[8px] text-white'><a href="https://www.upwork.com/agencies/bonafidetech/"><i class="fa-brands fa-upwork text-[18px]"></i></a></div>
                                <div className='cursor-pointer h-10 w-10 ml-3 border border-2 rounded-full pl-[10px] pt-[8px] text-white'><a href="https://pro.fiverr.com/agencies/BFTConsultants"><img src="img/fiverr.png" alt="" /></a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid p-3 border-b-2 lg:border-b-0">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap items-center">
                        <div className="w-[50%] lg:w-[20%]">
                            <Link to="/" className="hero_img ">
                                <img src="img/logo.png" alt="" />
                            </Link>
                            {/* <h1 className="font-bold text-[42px] heading">LOGO</h1> */}
                        </div>
                        <div className="w-[50%] lg:w-[80%]">
                            <div className=" justify-end items-center hidden lg:flex">
                                <ul className='flex items-center'>
                                    <Link to="/"> <li className='font-semibold text-[16px] mr-3 lg:mr-8 cursor-pointer heading hover:text-[#e3b299]'>HOME</li> </Link>
                                    <Link to="/about-us">
                                        <li className='font-semibold text-[16px] mr-3 lg:mr-8 cursor-pointer heading hover:text-[#e3b299]'>ABOUT US</li>
                                    </Link>
                                    <Link to="/our-work">
                                        <li className='font-semibold text-[16px] mr-3 lg:mr-8 cursor-pointer heading hover:text-[#e3b299]'>OUR WORK</li>
                                    </Link>
                                    <li className='font-semibold text-[16px] mr-3 lg:mr-8 cursor-pointer heading hover:text-[#e3b299]'>BLOG</li>
                                    <Link to="/contact-us">
                                        <li className='font-semibold text-[16px] mr-3 lg:mr-8 cursor-pointer heading hover:text-[#e3b299]'>CONTACT US</li>
                                    </Link>
                                </ul>

                                <div className="Button">
                                    <div className="cursor-pointer px-4 py-3 text-[18px] rounded-lg font-semibold text-white bg-black inline-block" onClick={setModelOpen}>
                                        <span>Free Website Audit</span>
                                    </div>
                                    <Modal isOpen={isModalOpen}>
                                        <div className="flex justify-end">
                                            <i className="fa-solid fa-xmark hover:scale-[1.2] font-bold text-[22px] mx-3" onClick={setModelOpen}></i>
                                        </div>
                                        <AuditForm />
                                    </Modal>
                                </div>
                            </div>

                            <div className="float-right" onClick={setOpenNavHandler}>
                                <i class="fa-solid fa-bars-staggered font-semibold text-[28px] text-black lg:hidden"></i>
                            </div>
                            {openNav && (
                                <div className="fixed top-[0] left-[0] w-full px-8 sm:px-16 py-10 bg-black z-10 h-[100vh] ">
                                    <i class="fa-solid fa-xmark font-semibold text-[28px] text-white float-right" onClick={setOpenNavHandler}></i>
                                    <ul className=''>
                                        <Link to="/">
                                            <li className='font-semibold text-[16px] mb-4 cursor-pointer heading hover:text-[#e3b299] text-white'>HOME</li>
                                        </Link>
                                        <Link to="/about-us">
                                            <li className='font-semibold text-[16px] mb-4 cursor-pointer heading hover:text-[#e3b299] text-white'>ABOUT US</li>
                                        </Link>
                                        <li className='font-semibold text-[16px] mb-4 cursor-pointer heading hover:text-[#e3b299] text-white'>BLOG</li>
                                        <Link to="/our-work">
                                        <li className='font-semibold text-[16px] mb-4 cursor-pointer heading hover:text-[#e3b299] text-white'>OUR WORK</li>
                                        </Link>
                                        <Link to="/contact-us">
                                            <li className='font-semibold text-[16px] mb-4 cursor-pointer heading hover:text-[#e3b299] text-white'>CONTACT US</li>
                                        </Link>
                                        <Link to="/gigs">
                                            <li className='font-semibold text-[16px] mb-4 cursor-pointer heading hover:text-[#e3b299] text-white'>GIGS</li>
                                        </Link>
                                    </ul>

                                    <div className="Button">
                                        <div className="cursor-pointer px-4 py-3 text-[18px] rounded-lg font-semibold text-black bg-white inline-block" onClick={setModelOpen}>
                                            <span>Free Website Audit</span>
                                        </div>
                                        <Modal isOpen={isModalOpen}>
                                            <div className="flex justify-end z-20">
                                                <i className="fa-solid fa-xmark hover:scale-[1.2] font-bold text-[22px] mx-3" onClick={setModelOpen}></i>
                                            </div>
                                            <AuditForm />
                                        </Modal>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid py-3 bg-[#222] relative hidden lg:block z-[999]">
                <div className="container mx-auto ">
                    <ul className="flex flex-wrap md:flex-nowrap justify-center">
                        <div className="group">
                            <li className="font-medium text-[14px] lg:text-[16px] mr-4 lg:mr-10 text-white heading hover:text-[#e3b299]">DIGITAL INTERNET MARKETING</li>
                            <div className="megamenu_1 hidden group-hover:block bg-white w-full absolute left-[0] p-12  shadow">
                                <div className="container mx-auto ">
                                    <div className="flex flex-wrap md:flex-nowrap justify-between ">
                                        <div className="w-full md:w-[30%] p-5 rounded-xl bg-[#f4dacd]">
                                            <h1 className='text-[36px] font-bold heading '>Grow with Bonafidetech.com</h1>
                                            <p className='text-[16px] font-medium '>Boost your online presence with our digital marketing strategies.</p>
                                            <div className="bg-[#222] inline-block text-white px-4 py-3 mt-4 rounded-lg shadow hover:bg-white hover:text-[#222] font-medium hover:font-semibold cursor-pointer " onClick={setModelOpen}>Hire Developer</div>
                                        </div>
                                        <div className="w-full md:w-[33%] px-3 lg:pl-36 mt-5 lg:m-0">
                                            <ul>
                                                <Link to="/seo">
                                                    <li className="font-medium text-[16px] mr-10 mb-4 cursor-pointer heading hover:text-[#e3b299] ">Search Engine Optimization - SEO</li>
                                                </Link>
                                                <Link to="/social-media-marketing">
                                                    <li className="font-medium text-[16px] mr-10 mb-4 cursor-pointer heading hover:text-[#e3b299] ">Social Media Marketing - SMM</li>
                                                </Link>
                                                <Link to="/social-engines-marketing">
                                                    <li className="font-medium text-[16px] mr-10 mb-4 cursor-pointer heading hover:text-[#e3b299]  ">Social Engines Marketing - SEM</li>
                                                </Link>
                                                <Link to="/guest-posting">
                                                    <li className="font-medium text-[16px] mr-10 mb-4 cursor-pointer heading hover:text-[#e3b299] ">Guest Posting</li>
                                                </Link>
                                                <Link to="/local-seo">
                                                    <li className="font-medium text-[16px] mr-10 mb-4 cursor-pointer heading hover:text-[#e3b299] ">Local SEO</li>
                                                </Link>
                                                <Link to="/web-analytics">
                                                    <li className="font-medium text-[16px] mr-10 mb-4 cursor-pointer heading hover:text-[#e3b299] ">Web Analytics</li>
                                                </Link>
                                            </ul>
                                        </div>
                                        <div className="w-full md:w-[33%] px-3">
                                            <ul className="p-0">
                                                <Link to="/e-commerce-marketing">
                                                    <li className="font-medium text-[16px] mr-10 mb-4 cursor-pointer heading hover:text-[#e3b299] ">eCommerce Marketing</li>
                                                </Link>
                                                <Link to="/mobile-app-marketing">
                                                    <li className="font-medium text-[16px] mr-10 mb-4 cursor-pointer heading hover:text-[#e3b299] ">Mobile App Marketing</li>
                                                </Link>
                                                <Link to="/influencer-marketing">
                                                    <li className="font-medium text-[16px] mr-10 mb-4 cursor-pointer heading hover:text-[#e3b299]  ">Influencer Marketing</li>
                                                </Link>
                                                <Link to="/affiliate-marketing">
                                                    <li className="font-medium text-[16px] mr-10 mb-4 cursor-pointer heading hover:text-[#e3b299] ">Affiliate Marketing</li>
                                                </Link>
                                                <Link to="/web-traffic">
                                                    <li className="font-medium text-[16px] mr-10 mb-4 cursor-pointer heading hover:text-[#e3b299] ">Web Traffic</li>
                                                </Link>
                                                <Link to="/ppc">
                                                    <li className="font-medium text-[16px] mr-10 mb-4 cursor-pointer heading hover:text-[#e3b299] ">PPC</li>
                                                </Link>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="group">
                            <li className="font-medium text-[14px] lg:text-[16px] mr-4 lg:mr-10 heading text-white hover:text-[#e3b299]">WEB DESIGNING</li>
                            <div className="megamenu_1 hidden group-hover:block bg-white w-full absolute left-[0] p-12  shadow">
                                <div className="container mx-auto ">
                                    <div className="flex flex-wrap md:flex-nowrap justify-between">
                                        <div className="w-full md:w-[30%] p-5 rounded-xl bg-[#f4dacd]">
                                            <h1 className='text-[36px] font-bold heading '>Web Design Agency</h1>
                                            <p className='text-[16px] font-medium '>Unlock your business’s true potential with our innovative solutions</p>
                                            <div className="bg-[#222] inline-block text-white px-4 py-3 mt-4 rounded-lg shadow hover:bg-white hover:text-[#222] font-medium hover:font-semibold cursor-pointer " onClick={setModelOpen}>Hire Developer</div>
                                        </div>
                                        <div className="w-full md:w-[33%] px-3 lg:pl-36 mt-5 lg:m-0">
                                            <ul>
                                                <Link to="/wordpress">
                                                    <li className="font-medium text-[16px] mr-10 mb-4 cursor-pointer heading hover:text-[#e3b299] ">Wordpress</li>
                                                </Link>
                                                <Link to="/wix">
                                                    <li className="font-medium text-[16px] mr-10 mb-4 cursor-pointer heading hover:text-[#e3b299] ">Wix</li>
                                                </Link>
                                                <Link to="/webflow">
                                                    <li className="font-medium text-[16px] mr-10 mb-4 cursor-pointer heading hover:text-[#e3b299]  ">Webflow</li>
                                                </Link>
                                                <Link to="/figma">
                                                    <li className="font-medium text-[16px] mr-10 mb-4 cursor-pointer heading hover:text-[#e3b299] ">Figma</li>
                                                </Link>
                                                <Link to="/bubble">
                                                    <li className="font-medium text-[16px] mr-10 mb-4 cursor-pointer heading hover:text-[#e3b299] ">Bubble.io</li>
                                                </Link>
                                            </ul>
                                        </div>
                                        <div className="w-full md:w-[33%] px-3">
                                            <ul className="p-0">
                                                <Link to="/woocommerce">
                                                    <li className="font-medium text-[16px] mr-10 mb-4 cursor-pointer heading hover:text-[#e3b299] ">WooCommerce</li>
                                                </Link>
                                                <Link to="/squarespace">
                                                    <li className="font-medium text-[16px] mr-10 mb-4 cursor-pointer heading hover:text-[#e3b299] ">Squre Space</li>
                                                </Link>
                                                <Link to="/shopify">
                                                    <li className="font-medium text-[16px] mr-10 mb-4 cursor-pointer heading hover:text-[#e3b299]  ">Shopify</li>
                                                </Link>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="group">
                            <li className="font-medium text-[14px] lg:text-[16px] mr-4 lg:mr-10 heading text-white hover:text-[#e3b299]">WEB DEVELOPMENT</li>
                            <div className="megamenu_1 hidden group-hover:block bg-white w-full absolute left-[0] p-12  shadow">
                                <div className="container mx-auto ">
                                    <div className="flex flex-wrap md:flex-nowrap justify-between ">
                                        <div className="w-full md:w-[30%] p-5 rounded-xl bg-[#f4dacd]">
                                            <h1 className='text-[36px] font-bold heading '>Web Design Agency</h1>
                                            <p className='text-[16px] font-medium '>Unlock your business’s true potential with our innovative solutions</p>
                                            <div className="bg-[#222] inline-block text-white px-4 py-3 mt-4 rounded-lg shadow hover:bg-white hover:text-[#222] font-medium hover:font-semibold cursor-pointer " onClick={setModelOpen}>Hire Developer</div>
                                        </div>
                                        <div className="w-full md:w-[33%] px-3 lg:pl-36 mt-5 lg:m-0">
                                            <ul>
                                                <Link to="/full-stack">
                                                    <li className="font-medium text-[16px] mr-10 mb-4 cursor-pointer heading hover:text-[#e3b299] ">Full Stack</li>
                                                </Link>
                                                <Link to="/php">
                                                    <li className="font-medium text-[16px] mr-10 mb-4 cursor-pointer heading hover:text-[#e3b299] ">PHP</li>
                                                </Link>
                                                <Link to="/reactjs">
                                                    <li className="font-medium text-[16px] mr-10 mb-4 cursor-pointer heading hover:text-[#e3b299]  ">React</li>
                                                </Link>
                                                <Link to="/laravel">
                                                    <li className="font-medium text-[16px] mr-10 mb-4 cursor-pointer heading hover:text-[#e3b299]  ">Laravel</li>
                                                </Link>
                                            </ul>
                                        </div>
                                        <div className="w-full md:w-[33%] px-3">
                                            <ul className="p-0">
                                                <Link to="/cake-php">
                                                    <li className="font-medium text-[16px] mr-10 mb-4 cursor-pointer heading hover:text-[#e3b299] ">Cake PHP</li>
                                                </Link>
                                                <Link to="/javascript">
                                                    <li className="font-medium text-[16px] mr-10 mb-4 cursor-pointer heading hover:text-[#e3b299] ">Java Script</li>
                                                </Link>
                                                <Link to="/mern-stack">
                                                    <li className="font-medium text-[16px] mr-10 mb-4 cursor-pointer heading hover:text-[#e3b299] ">Mern Stack</li>
                                                </Link>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <li className="font-medium text-[14px] lg:text-[16px] mr-4 lg:mr-10 cursor-pointer heading text-white hover:text-[#e3b299] ">CONTENT WRITING  </li>
                        <Link to="/gigs">
                            <li className="font-medium text-[14px] lg:text-[16px] mr-4 lg:mr-10 cursor-pointer heading text-white hover:text-[#e3b299]">GIGS</li>
                        </Link>
                        <Link to="/resources"> <li className="font-medium text-[14px] lg:text-[16px] mr-4 lg:mr-10 cursor-pointer heading text-white hover:text-[#e3b299] uppercase"> Resources</li></Link>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Header