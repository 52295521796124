import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Vashi() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{
                backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)" }}>
                <div className="container mx-auto py-12">
                    <h1 className='text-center text-white text-[32px] md:text-[52px]  font-semibold heading uppercase'>
                        SEO Company in Vashi
                    </h1>
                </div>
            </div>
        
            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[100%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>SEO Company in Vashi</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Want a reliable SEO Company in Vashi? At
                                    Bonafide Technologies, we design, develop, and plan to enhance our clients' businesses with
                                    an effective SEO approach. With an excellent team of web developers, SEO specialists, and
                                    social media marketing specialists in Mumbai, we are honored to create and deploy the best
                                    user interface and user experience for your company. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>Major services like social media
                                    marketing, social media optimization, search engine marketing, and last but not least, the
                                    top SEO services are all provided by Bonafide Technologies.</p>
                                <br />
                                <h1 className='text-[32px] font-semibold my-3 heading'>Why We're the Right Digital Marketing
                                    Agency in Vashi for You</h1>
        
                                <p className='text-[18px] font-normal text-[#636363] '>Bonafide Technologies in Navi Mumbai
                                    knows how to assist you with Search Engine Optimization, Social Media Marketing, Google Ads,
                                    Facebook Ads, Web Development, and more, based on your company needs. We offer a tailored
                                    marketing plan that can push your company to new heights based on your website audit, your
                                    business objectives, and a detailed competitive study. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>We gradually gaining the trust of
                                    customers from all sectors of the economy. If you're a digital marketer or a business owner
                                    wishing we will assist you to extend your offerings online. We provide complete digital
                                    marketing services, so the only direction your company can go is up. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>Our team can transform your company's
                                    image into reputable online companies that prove your credibility. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>The field of digital marketing is
                                    constantly changing. Our team of vibrant, informed marketers keeps up with the newest
                                    features of marketing to give your brand a boost. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>You get more from using a digital
                                    marketing agency than just improved search engine placement. Lead creation, brand building,
                                    revenue generation, brand visibility, and much more are guaranteed by our all-inclusive
                                    internet marketing services.</p>
                            </div>
                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Our Vision</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Initially engaged in helping companies
                                    conquer their marketing hurdles, Bonafide Technologies quickly evolved into an extension of
                                    their marketing departments. We quickly gained global contracts, seized many chances, and
                                    offered digital solutions.</p>
                                <br />
                                <p className='text-[18px] font-normal text-[#636363] '>We are currently regarded as one of the
                                    fastest-growing digital marketing businesses in India, thanks to our tenacity and thirst for
                                    effective campaigns and ideas. Some of the top brands in India and several other locations
                                    have joined our clientele due to our passion for assisting businesses in expanding and
                                    ranking highly among the digital clutter. </p><br />
                                <h1 className='text-[32px] font-semibold my-3 heading'>How We Approach Our Work</h1>
                                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                                    <div className="flex flex-wrap justify-between">
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Strategic Planning
                                            </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Perfectly outline your
                                                strategy to ensure that digital activities are in line with business objectives
                                                and that you have a clear path to success.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Creative Innovation</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Create impactful innovations
                                                by combining your imagination with data-driven insights to create engaging
                                                campaigns that urge viewers to interact.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Agile Execution </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Execute with flexibility to
                                                create dynamic digital marketing that yields repeatable, quantifiable outcomes.
                                                This includes flexible strategy, quick implementation, and ongoing optimization.
                                            </p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Performance Analysis </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Strive for excellence in your
                                                analysis by doing thorough performance reviews, deciphering data with
                                                understanding, and making proactive changes to increase campaign efficiency and
                                                return on investment.</p>
                                        </div>
                                    </div>
                                </div>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Our Services</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Providing Cutting-Edge Solutions to
                                    Businesses to Draw in and Keep Customers. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>Bonafide Technologies is a well-known
                                    provider of digital marketing services. We give professional solutions to improve your
                                    online presence, draw in interested visitors, and provide quality leads. </p>
                                <ul>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                                className='text-[#63E6BE] mr-2'><i
                                                    class="fa-regular fa-square-check"></i></span></strong>
                                        Digital Marketing</li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                                className='text-[#63E6BE] mr-2'><i
                                                    class="fa-regular fa-square-check"></i></span></strong>
                                        SEO Marketing</li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                                className='text-[#63E6BE] mr-2'><i
                                                    class="fa-regular fa-square-check"></i></span></strong>
                                        Social Media Marketing</li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                                className='text-[#63E6BE] mr-2'><i
                                                    class="fa-regular fa-square-check"></i></span></strong>
                                        Website Development</li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                                className='text-[#63E6BE] mr-2'><i
                                                    class="fa-regular fa-square-check"></i></span></strong>
                                        Website Design</li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                                className='text-[#63E6BE] mr-2'><i
                                                    class="fa-regular fa-square-check"></i></span></strong>
                                        E-Commerce Web Development</li>
                                    <li className='text-[18px] font-normal text-[#636363] '><strong><span
                                                className='text-[#63E6BE] mr-2'><i
                                                    class="fa-regular fa-square-check"></i></span></strong>
                                        Google Ads Strategies</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        )
}

export default Vashi
