import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { Link } from "react-router-dom";


function EcommerceMarketing() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{ backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)" }}>
                <div className="container mx-auto py-12">
                    <h1 className='text-center text-white text-[34px] md:text-[42px] lg:text-[56px] xl:text-[86px] font-bold heading uppercase'>E-commerce Marketing</h1>
                </div>
            </div>

            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[70%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <img src="img/eCommerce-Marketing.jpg" className='w-full' alt="" />

                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>E-commerce Marketing</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Do you want to grow your e-commerce business and make countless sales? For your online store, we help you create a promising growth plan that attracts relevant visitors and turns them into sales.</p><br />
                                <p className='text-[18px] font-normal text-[#636363] '>We are a digital marketing agency specializing in e-commerce and strive to see business owners succeed. At Bonafide Technologies, we work with you to create a convertible growth strategy that contains all marketing routes to produce long-lasting effects. </p><br />
                                <p className='text-[18px] font-normal text-[#636363] '>A single strategy channel will not lead to success in an e-commerce store. A multi-channel online marketing plan that scales while protecting your bottom line is essential for success in e-commerce. </p><br />
                                <p className='text-[18px] font-normal text-[#636363] '>When you work with Bonafide, you'll receive a customized growth plan that coordinates with your company's objectives and converts your marketing efforts to actual results. </p>
                            </div>
                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>What We Provide</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>To handle your social media ads, you use a Facebook ad company, to improve your organic ranks, you hire an SEO agency, and to boost sales, you hire an email marketing expert. Each optimizes its channel for optimal effectiveness and income without taking into account how each channel contributes to your total expansion.</p><br />
                                <p className='text-[18px] font-normal text-[#636363] '>Fortunately, at Bonafide Technologies, we offer all these marketing strategies to make your online business successful.</p><br />

                                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                                    <div className="flex flex-wrap justify-between">
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>SEO</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Getting your e-commerce store to rank higher in search engines like Google is the main goal of SEO (Search Engine Optimization). To help you rank higher than the competition, Indian Internet Marketing uses tactics including content marketing, long-tail keyword targeting, and technical SEO changes to your website.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Paid Search (PPC)</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Using performance max campaigns, our Google Shopping experts can retarget warm leads in your funnel and bring in new business. All e-commerce companies should use paid search advertisements because they provide some of the best returns on advertising spending in India.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Paid Social</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Utilizing the most effective social media marketing channels, our media buyers and creative strategists assist you in acquiring new clients. Compared to other e-commerce marketing companies, we don't count on easy-to-win remarketing campaigns. Getting new clients from Facebook and Instagram is our main goal.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>E-Commerce Web Design & Branding</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>A website's brand is important to eCommerce sites. Your brand, your beliefs, and the things that set your company apart should all be reflected in your online store.</p>
                                            <p className='text-[18px] font-normal text-[#636363] '>We can build a simplified, practical, and functional buying experience using our web design knowledge, guaranteeing that your clients will love your eCommerce site.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Email Marketing</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Use creative email marketing campaigns to stay at the top of your audience's mind and provide customized product recommendations. Our email marketing services will help you increase sales whether your goal is to highlight new products, target cart cancelers, or promote your most recent sales.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Digital Strategy</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Together with your internal team, our team of digital marketing specialists will identify your marketing goals and create a unique plan that will work. To ensure you get genuine, sales-driven results, we take the time to understand your business, your audience, and the best ways to generate an effective digital strategy for your brand and products.</p>
                                        </div>

                                    </div>
                                </div>

                                <div className="faqs my-12">
                                    <h1 className='text-[32px] font-medium my-3 heading'>FAQs</h1>
                                    <div id="accordion-collapse" data-accordion="collapse">
                                        <h2 id="accordion-collapse-heading-1">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200  hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                <span className='text-[20px] text-[#222] text-left'>What Is E-Commerce Marketing?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-1" class="hidden" aria-labelledby="accordion-collapse-heading-1">
                                            <div class="p-5 border border-b-0 border-gray-200 darks:border-gray-700 darks:bg-gray-900">
                                                <p className='text-[18px] font-normal text-[#636363] '>E-commerce marketing refers to the strategies and techniques businesses use to promote and sell their products or services online. It involves various activities such as advertising, content marketing, social media marketing, email marketing, and search engine optimization (SEO) tailored specifically for online retail environments.</p>
                                            </div>
                                        </div>
                                        <h2 id="accordion-collapse-heading-2">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-2" aria-expanded="false" aria-controls="accordion-collapse-body-2">
                                                <span className='text-[20px] text-[#222] text-left'>Why Is E-Commerce Marketing Important?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-2" class="hidden" aria-labelledby="accordion-collapse-heading-2">
                                            <div class="p-5 border border-b-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>E-commerce marketing is crucial for businesses to attract potential customers, increase brand awareness, drive website traffic, generate leads, and lastly boost sales in the highly competitive online marketplace.</p>
                                            </div>
                                        </div>
                                        <h2 id="accordion-collapse-heading-3">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-3" aria-expanded="false" aria-controls="accordion-collapse-body-3">
                                                <span className='text-[20px] text-[#222] text-left'>What Are Some Effective E-Commerce Marketing Strategies?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-3" class="hidden" aria-labelledby="accordion-collapse-heading-3">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Optimizing product listings with visually appealing images and intriguing descriptions, using social media to interact with consumers and showcase products, launching email marketing campaigns to generate leads and promote constant purchases, spending money on paid advertising like Google Ads and Facebook Ads, and regularly reviewing and improving marketing campaigns are all examples of successful e-commerce marketing strategies.</p>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-4">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-4" aria-expanded="false" aria-controls="accordion-collapse-body-4">
                                                <span className='text-[20px] text-[#222] text-left'>How Can I Improve The Visibility Of My E-Commerce Website</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-4" class="hidden" aria-labelledby="accordion-collapse-heading-4">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Focus on putting SEO best practices, such as keyword optimization, relevant and high-quality content creation, mobile friendliness and speed optimization, building backlinks from reliable sources, and search engine optimization of product pages, into practice if you want to increase the visibility of your e-commerce website.</p>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-5">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-5" aria-expanded="false" aria-controls="accordion-collapse-body-5">
                                                <span className='text-[20px] text-[#222] text-left'>What Role Does Social Media Play In E-Commerce Marketing?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-5" class="hidden" aria-labelledby="accordion-collapse-heading-5">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Social media helps businesses promote products, establish connections with their target audience, and increase traffic to their e-commerce websites—all of which are significant aspects of e-commerce marketing. Businesses can interact with people on social media platforms such as Instagram, Facebook, Twitter, Pinterest, and TikTok by using user-generated content, influencer relationships, paid advertising, and organic content.</p>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-6">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-6" aria-expanded="false" aria-controls="accordion-collapse-body-6">
                                                <span className='text-[20px] text-[#222] text-left'>How Can I Measure The Success Of My E-Commerce Marketing Efforts?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-6" class="hidden" aria-labelledby="accordion-collapse-heading-6">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Key performance indicators (KPIs) like website traffic, conversion rate, average order value, cost of acquiring customers, return on investment (ROI) from advertising campaigns, email open and click-through rates, social media engagement metrics, and total sales revenue can all be used to gauge the effectiveness of your e-commerce marketing initiatives. To get information and insights, make use of the built-in reporting features of e-commerce platforms, social media analytics platforms, and web analytics tools like Google Analytics.</p>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-7">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-7" aria-expanded="false" aria-controls="accordion-collapse-body-7">
                                                <span className='text-[20px] text-[#222] text-left'>What Are Some Emerging Trends In E-Commerce Marketing?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-7" class="hidden" aria-labelledby="accordion-collapse-heading-7">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>The use of chatbots and AI-driven customer service solutions, the rise of influencer marketing, the adoption of augmented reality (AR) and virtual reality (VR) technologies for immersive shopping experiences, the growing significance of sustainability and ethical consumerism, and the growing appeal of voice commerce and mobile shopping apps are some of the emerging trends in e-commerce marketing. Keeping abreast of market developments and trends can help companies stay competitive and adjust to shifting consumer demands.</p>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-8">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-8" aria-expanded="false" aria-controls="accordion-collapse-body-8">
                                                <span className='text-[20px] text-[#222] text-left'>What Sets You Apart From Other Indian E-Commerce Marketing Agencies? </span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-8" class="hidden" aria-labelledby="accordion-collapse-heading-8">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>We have a greater understanding of the challenges faced by online retailers because Bonafide Technologies conducts its own successful online stores. These stores started as test projects, grew into real businesses, and have taught us a great deal about the successful and unsuccessful methods for growing e-commerce. If you're looking to recruit real professionals who perform the job, we would like to share our knowledge, experience, and network with you.</p>
                                            </div>
                                        </div>

                                        <h2 id="accordion-collapse-heading-9">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-9" aria-expanded="false" aria-controls="accordion-collapse-body-9">
                                                <span className='text-[20px] text-[#222] text-left'>Which E-Commerce Marketing Channels Are the Best? </span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-9" class="hidden" aria-labelledby="accordion-collapse-heading-9">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>The following marketing channels are the most effective for expanding an e-commerce business, in no particular order:</p>
                                                <ul className='text-[18px] font-normal text-[#636363] '>
                                                    <li>Search Engine Optimization</li>
                                                    <li>Email Marketing</li>
                                                    <li>SMS Marketing </li>
                                                    <li>Influencer Marketing </li>
                                                    <li>Google Ads</li>
                                                    <li>Social Media Marketing</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <h2 id="accordion-collapse-heading-10">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-10" aria-expanded="false" aria-controls="accordion-collapse-body-10">
                                                <span className='text-[20px] text-[#222] text-left'>Does SEO for E-Commerce Matter Online?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-10" class="hidden" aria-labelledby="accordion-collapse-heading-10">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Yes, you can increase the profitability of your e-commerce site with e-commerce SEO. Google provides most of the traffic that generates profits for businesses, with organic search ranking among their top revenue sources.<br />

                                                    This means that if you don't spend money on e-commerce SEO, you run the danger of losing out to customers when they need you most. Additionally, if your e-commerce website is hidden from search engine results pages, very less people will reach out to your website. This means that you lose out on sales, which will hinder your growth.
                                                    However, integrating SEO into your e-commerce plan helps in increasing sales and profits.</p>
                                            </div>
                                        </div>
                                        <h2 id="accordion-collapse-heading-11">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-11" aria-expanded="false" aria-controls="accordion-collapse-body-11">
                                                <span className='text-[20px] text-[#222] text-left'>How Does E-commerce Marketing Even Work?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-11" class="hidden" aria-labelledby="accordion-collapse-heading-11">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>Getting people to visit your online store and become paying customers is the primary goal of eCommerce marketing. This usually involves using different techniques to draw in new visitors. Email marketing, content marketing, social media advertising, PPC advertising for eCommerce, and SEO for eCommerce are a few of these tactics.</p>
                                            </div>
                                        </div>
                                        <h2 id="accordion-collapse-heading-12">
                                            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:bg-gray-100 darks:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-12" aria-expanded="false" aria-controls="accordion-collapse-body-12">
                                                <span className='text-[20px] text-[#222] text-left'>When Will I Start Seeing Results?</span>
                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-collapse-body-12" class="hidden" aria-labelledby="accordion-collapse-heading-12">
                                            <div class="p-5 border border-t-0 border-gray-200 darks:border-gray-700">
                                                <p className='text-[18px] font-normal text-[#636363] '>This will vary based on your approach and the precise definition of "results" in your company. Speaking with our team is the best way to get an answer to this question because times and results might be hard to measure. After launch, eCommerce strategies usually show results in a few weeks. Maintaining satisfactory results, however, depends on various factors that vary from situation to situation.</p>
                                            </div>
                                        </div>


                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className="w-[30%] hidden lg:block relative">
                            <div className='box-shadow rounded-lg ml-5 p-8 sidebar sticky top-[-150px]'>
                                <div className="sidebar_banner">
                                    <img src="img/page_img_4.jpg" alt="" />
                                </div>

                                <div className="sidebar_tabs mt-5">
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/social-media-marketing">
                                            <h1 className='text-[18px] font-semibold capitalize'>Social Media Marketing - SMM</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/social-engines-marketing">
                                            <h1 className='text-[18px] font-semibold capitalize'>Social Engines Marketing - SEM</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/guest-posting">
                                            <h1 className='text-[18px] font-semibold capitalize'>Guest Posting</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/web-analytics">
                                            <h1 className='text-[18px] font-semibold capitalize'>Web Analytics</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/local-seo">
                                            <h1 className='text-[18px] font-semibold capitalize'>Local SEO</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/e-commerce-marketing">
                                            <h1 className='text-[18px] font-semibold capitalize'>eCommerce Marketing</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/mobile-app-marketing">
                                            <h1 className='text-[18px] font-semibold capitalize'>mobile-app-marketing</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/influencer-marketing">
                                            <h1 className='text-[18px] font-semibold capitalize'>Influencer Marketing</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/affiliate-marketing">
                                            <h1 className='text-[18px] font-semibold capitalize'>Affiliate Marketing</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/web-traffic">
                                            <h1 className='text-[18px] font-semibold capitalize'>Web Traffic</h1>
                                        </Link>
                                    </div>
                                    <div
                                        className='shadow rounded-lg px-5 py-6 mb-4 border-r-4 border-[#fff] hover:border-[#f4dacd] cursor-pointer '>
                                        <Link to="/ppc">
                                            <h1 className='text-[18px] font-semibold capitalize'>PPC</h1>
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default EcommerceMarketing