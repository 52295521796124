import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Bangaloree() {
    return (
        <>
            <Header />
            <div className="container-fluid py-24 bg-cover bg-center" style={{
                backgroundImage: "linear-gradient(to right, rgb(0 0 0 / 76%), rgb(0 0 0 / 76%)), url(img/innerhero.webp)" }}>
                <div className="container mx-auto py-12">
                    <h1 className='text-center text-white text-[32px] md:text-[52px]  font-semibold heading uppercase'>
                        SEO Company in Bangalore
                    </h1>
                </div>
            </div>
        
            <div className="container-fluid py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full lg:w-[100%] p-3 sm:p-10 shadow lg:mr-10 rounded-lg">
                            <div className='mt-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>SEO Company in Bangalore</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Digital marketing is headed toward becoming the foundation of any successful company. Thus, any wise businessman cannot dispute the importance of internet marketing. You've come to the right place if you're looking for Bangalore's top digital marketing agency.</p>
                                <p className='text-[18px] font-normal text-[#636363] '>Bonafide Technologies is one of the most well-known SEO companies in Bangalore. As leading digital marketers, we are able to offer our customers personalized solutions that enable them to achieve their full online potential and accomplish their corporate objectives. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>We offer 100% interactive and user-friendly web solutions and applications that are created by combining imagination and creativity. We make it possible for companies to expand, engage, and maintain their online presence.</p>
                                <br />
        
                                <h1 className='text-[32px] font-semibold my-3 heading'>An Experienced and Trusted SEO Service Provider</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Digital marketing is now necessary to guarantee that a company has a strong online presence. SEO, SEM (Google, Bing, and Yahoo advertisements), SMO (Facebook, Instagram, LinkedIn, Twitter, Youtube, Pinterest, Tumblr, and Snapchat for all promotions), Email marketing, content marketing, ORM, local SEO promotion, remarketing, and retargeting are some of the services you need in your digital marketing agency. </p>
                                <p className='text-[18px] font-normal text-[#636363] '>We provide excellent and reasonably priced internet marketing services to all kinds of companies. Bonafide Technologies helps you grow by increasing website visibility, traffic, and sales. As the leading SEO company in Bangalore, we use every trick in the book, along with creative ideas, to help our clients achieve their business objectives. With digital marketing, you can grow your company to new heights and achieve incredible success in your sector. </p>
                            </div>
                            <div className='my-8'>
                                <h1 className='text-[32px] font-semibold my-3 heading'>What We Offer</h1>
                                <div className='px-5 pb-5 bg-[#f4dacd70] mt-5'>
                                    <div className="flex flex-wrap justify-between">
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Digital Marketing 
                                            </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Many techniques are available in digital marketing to help achieve the company goal of generating more sales leads. Bonafide Technologies is a leading digital marketing agency that has been servicing valued customers for many years. Our main goal is to exceed the competition by offering the best strategies and solutions to boost sales. Our digital marketing solutions include various successful tactics and innovative techniques that support your growth and position compared to competitors.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Search Engine Optimization – SEO</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Being one of the best SEO companies in Bangalore, we offer SEO solutions that work by conducting in-depth research to raise the rating of your website. Our committed SEO specialists are aware of the updated SEO strategies needed to improve website ranking and generate more leads for your company. We provide high-quality optimization, which includes keyword analysis. We are experts in various SEO techniques that help place your company on the search engine results page.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Social Media Optimization - SMO</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>We provide a range of targeted social media marketing strategies that keep the company active on numerous social media channels, boosting its online presence and generating more leads for sales. We create engaging social media profiles for companies that allow them to interact with their audience and increase the number of new sales leads they receive. We are also proficient in outside tasks like creating widgets, blogging, and posting images to advertise your business on different social media platforms.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Search Engine Marketing – SEM </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>Our goal as an ROI-driven search engine marketing company is to boost company sales through the purchase of sponsored advertising on any search engine. At Bonafide Technologies, we have a committed, experienced team that can handle these kinds of customer requirements and create search engine marketing plans that maximize return on investment, like Google Adwords. Our PPC Service Specialists strive to bring in unique visitors and reward pay-per-click partnerships to monetize your website.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>B2B Marketing Services </h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>A committed staff of B2B marketing consultants at Bonafide Technologies is skilled at serving your loyal customers on your behalf. We are dedicated to your achievement and have a reputation for working hard. We create long-term B2B marketing service plans using social media, blogging, infographics, and other techniques.</p>
                                        </div>
                                        <div className="w-full md:w-[49%] shadow border-2 rounded-lg border-[#f4dacd] p-5 mt-5">
                                            <h1 className='text-[22px] sm:text-[28px] font-semibold mb-3 heading'>Pay Per Click Services – PPC</h1>
                                            <p className='text-[18px] font-normal text-[#636363] '>We are professionals at using PPC to assist you in acquiring company leads more quickly. You need to pay the advertiser on a click-by basis if you find a result. Our team of professionals is committed to creating Google adverts that are find-structured and contain the most relevant search keywords. Using our PPC service will provide you the opportunity to obtain more leads early in the day without requiring additional time investment. You can quickly achieve sizable conversions with PPC.</p>
                                        </div>
                                    </div>
                                </div>
                                <h1 className='text-[32px] font-semibold my-3 heading'>Hire Top SEO Company in Bangalore, India</h1>
                                <p className='text-[18px] font-normal text-[#636363] '>Hire Bonafide Technologies, our SEO company in Bangalore, to help your company become the industry leader in its field. You can boost your company's online presence by using our SEO services at the most affordable price. Contact us for any queries. Our SEO professionals are here to answer your questions at all times.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        )
}

export default Bangaloree
