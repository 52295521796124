import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import { arrayBufferToBase64 } from "../utils/ImageBinaryConverter";
import { useNavigate } from 'react-router-dom';


function GigForm() {
  const navigate = useNavigate();

  let { singleGigId } = useParams();
  const [singleGig, setSingleGig] = useState([]);
  const [singleGigImg, setSingleGigImg] = useState([]);
  const [gigPackage, setGigPackage] = useState();
  const [gigPrice, setGigPrice] = useState();
  const [loading, setLoading] = useState(true);
  const routeLocation = useLocation();  // Change the variable name
  const queryParams = new URLSearchParams(routeLocation.search);
  let Package = queryParams.get('Package');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [subcategory, setSubcategory] = useState('');
  const [gigPack, setGigPack] = useState('');
  const [price, setPrice] = useState('');
  const [message, setMessage] = useState('');

  const handlNnameChange= (e) => {
    setName(e.target.value);
    console.log(name)
  };

  const handlEmailChange = (e) => {
    setEmail(e.target.value);
    console.log(email)
  };
  
  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
    console.log(phone)

  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
    console.log(message)

  };
  const handleSubmit = async (e) => {
    e.preventDefault();
  
        console.log("message:", message);
        console.log("price:", price);
        console.log("gigPack:", gigPack);
        console.log("category:", category);
        console.log("subcategory:", subcategory);        
        console.log("title:", title);
        console.log("phone:", phone);
        console.log("email", email);
        console.log("name:", name);

        var gigFormData = {};
        gigFormData.message = message;
        gigFormData.price = price;
        gigFormData.gigPack = gigPack;
        gigFormData.category = category;
        gigFormData.category = category;
        gigFormData.title = title;
        gigFormData.phone = phone;
        gigFormData.email = email;
        gigFormData.name = name;

        try {
          console.log("gigFormData",gigFormData);
          const response = await fetch('http://localhost:5007/api/createGigForm', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(gigFormData),
            });
            if (response.ok) {
              console.log('Gig data submitted successfully');
              navigate("/");

          } else {
              console.error('Failed to submit gig data');
          }
          } catch (error) {
              console.error('Error during GigForm data submission:', error);
          }
        
  }
  useEffect(() => {
    if(Package === 'basic'){
      setGigPackage('Basic')
      setGigPrice(singleGig.basicPrice)
    }else if(Package ==='standard'){
      setGigPackage('Standard')
      setGigPrice(singleGig.standardPrice)
    }else if(Package === 'premium'){
      setGigPackage('Premium')
      setGigPrice(singleGig.premiumPrice)
    }
    setPrice(gigPrice)
    setGigPack(gigPackage)
    setSubcategory(singleGig.subcategory)
    setCategory(singleGig.category)
    setTitle(singleGig.title)

    fetch(`http://localhost:5007/api/getGigById/${singleGigId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setSingleGig(data);
        setLoading(false); 
        let base64String1 = '';
        if (
          data.selectedImageUrl &&
          data.selectedImageUrl[0] &&
          data.selectedImageUrl[0].data
        ) {
          base64String1 = arrayBufferToBase64(data.selectedImageUrl[0].data.data);
          setSingleGigImg(base64String1);
        }
      })
      .catch((error) => {
        console.error('Error fetching gig by ID:', error);
        setLoading(false); 
      });
  }, [singleGigId][gigPrice]);
  return (
    <>
        <Header/>
          <div className="container-fluid py-12">
             <div className="container mx-auto">
                  <div className="flex">
                  <div className="w-[50%]">
                        {loading ? (
                                      <div class="text-center mt-12">
                                      <div role="status">
                                          <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin darks:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                          </svg>
                                          <span class="sr-only">Loading...</span>
                                      </div>
                                  </div>
                        ) : (
                          <>
                            <form onSubmit={handleSubmit} className="space-y-5">
                                <div>
                                    <label for="name" className="block mb-2 text-sm font-medium text-gray-900 darks:text-gray-300">Name</label>
                                    <input type="text" id="name" className="bg-gray-50 border border-[#222] text-gray-900 sm:text-md rounded-lg focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500" placeholder="Your Name" onChange={handlNnameChange} required  />
                                </div>
                                <div>
                                    <label for="email" className="block mb-2 text-sm font-medium text-gray-900 darks:text-gray-300">Your email</label>
                                    <input type="email" id="email" className="bg-gray-50 border border-[#222] text-gray-900 sm:text-md rounded-lg focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500" placeholder="name@flowbite.com" onChange={handlEmailChange} required  />
                                </div>
                                <div>
                                    <label for="phone" className="block mb-2 text-sm font-medium text-gray-900 darks:text-gray-300">Phone</label>
                                    <input type="text" id="phone" className="bg-gray-50 border border-[#222] text-gray-900 sm:text-md rounded-lg focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500" placeholder="Let us know how we can help you" onChange={handlePhoneChange} required />
                                </div>

                                {/* <div>
                                    <label for="title" className="block mb-2 text-sm font-medium text-gray-900 darks:text-gray-300">Title</label>
                                    <input type="title" id="title" value={singleGig.title} className="bg-gray-50 border border-[#222] text-gray-900 sm:text-md rounded-lg focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500" placeholder="name@flowbite.com" required />
                                </div>

                                <div>
                                    <label for="category" className="block mb-2 text-sm font-medium text-gray-900 darks:text-gray-300">Category</label>
                                    <input type="category" value={singleGig.category} id="category" className="bg-gray-50 border border-[#222] text-gray-900 sm:text-md rounded-lg focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500" placeholder="name@flowbite.com" required />
                                </div>

                                <div>
                                    <label for="subcategory" className="block mb-2 text-sm font-medium text-gray-900 darks:text-gray-300">Sub Category</label>
                                    <input type="subcategory"  value={singleGig.subcategory} id="subcategory" className="bg-gray-50 border border-[#222] text-gray-900 sm:text-md rounded-lg focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500" placeholder="name@flowbite.com" required />
                                </div>
                                <div >
                                    <label for="package" className="block mb-2 text-sm font-medium text-gray-900 darks:text-white">Package</label>
                                    <input type="text" id="package" value={gigPackage} className="bg-gray-50 border border-[#222] text-gray-900 sm:text-md rounded-lg focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500" placeholder="Let us know how we can help you" required />
                                </div>

                                <div >
                                    <label for="price" className="block mb-2 text-sm font-medium text-gray-900 darks:text-white">Price</label>
                                    <input type="text" id="price" value={`$${gigPrice}`} className="bg-gray-50 border border-[#222] text-gray-900 sm:text-md rounded-lg focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500" placeholder="Let us know how we can help you" required />
                                </div> */}

                                <div className="sm:col-span-2">
                                    <label for="message" className="block mb-2 text-sm font-medium text-gray-900 darks:text-gray-400">Your message</label>
                                    <textarea id="message" rows="6" className="bg-gray-50 border border-[#222] text-gray-900 sm:text-md rounded-lg focus:ring-black focus:border-black block w-full p-2.5 darks:bg-gray-700 darks:border-gray-600 darks:placeholder-gray-400 darks:text-white darks:focus:ring-blue-500 darks:focus:border-blue-500" placeholder="Leave a comment..." onChange={handleMessageChange}></textarea>
                                </div>

                                <button type="submit" className="bg-green-500 text-white focus:outline-none font-medium rounded-lg text-md px-5 py-2.5 text-center ">
                                  Submit
                                </button>
                            </form>
                          </>
                        )}
                      </div>
                      <div className='w-[50%] px-8 relative'>
                            <div className='px-5'>
                            <h2 className='text-[28px] md:text-[26px] font-bold heading mb-5'>{singleGig.title}</h2>
                                <img src={`data:image/png;base64,${singleGigImg}`} alt="" className='w-full' />
                                <p className='text-[18px] md:text-[20px] font-medium text-[#222] mt-3 capitalize'>{singleGig.category} <span className='mx-2'><i className="fa-solid fa-arrow-right"></i></span> {singleGig.subcategory}</p>
                            </div>
                      </div>
                  </div>
             </div>
          </div>
        <Footer/>

    </>
  )
}

export default GigForm